import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Button,
  Modal,
  Label,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Input
} from "reactstrap";

import {createCategory, uploadCategoryImage} from "../../actions/products";

const options = {
  autoClose: 2000,
  // closeButton:,
  type: toast.TYPE.INFO,
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_LEFT,
  pauseOnHover: true
  // progress: 0.2
};

class UploadCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryModal: false,
      categoryName: "",
      parentCategory: 0,
      image: null
    };
    this.toggleCategoryModal = this.toggleCategoryModal.bind(this);
    this.uploadCategoryImage = this.uploadCategoryImage.bind(this);
    this.onImageUploaded = this.onImageUploaded.bind(this);
  }


  toggleCategoryModal() {
    this.setState(prevState => ({
      categoryModal: !prevState.categoryModal
    }));
  }

  onImageUploaded(image) {
    if (!image) {
      this.setState({imageError: 'Please select image.', image: null});
      return false;
    }
    if (!image.type.match(/jpg|jpeg/)) {
      this.setState({imageError: 'Please select valid image.', image: null});
      return false;
    }
    if (image.size > 2000000) {
      this.setState({imageError: 'Image size too big', image: null});
      return false;
    }
    this.setState({
      image: image,
      imageError: ''
    });
  }

  uploadCategoryImage() {
    let category_id=this.state.parentCategory;
    if (category_id === 0) {
      toast.error("Please choose a category", options);
      return false;
    }

    if (this.state.image === null)
      toast.error("Please choose a image", options)
    else {
      this.props.uploadCategoryImage(category_id, this.state.image);
      this.toggleCategoryModal()
    }

  }

  render() {
    const { categoryName } = this.state;
    // console.log(this.props);
    return (
      <div className={`d-inline-block mr-3 animated fadeIn ${this.props.className}`}>
        <Button
          color="primary"
          className="place-order w-100 new-btn-css"
          onClick={this.toggleCategoryModal}
        >
          <span class="new-btn-span"><img src="/images/candy.svg" alt="" /></span>
          Upload Img Category
        </Button>
        <Modal
          isOpen={this.state.categoryModal}
          fade={false}
          toggle={this.toggleCategoryModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCategoryModal}>
            Update Category Image
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Input
                type="select"
                name="parentCategory"
                id="parentCategory"
                onChange={e => {
                  this.setState({ parentCategory: e.target.value });
                }}
              >
                <option value="0">Select Category</option>
                {this.props.categories &&
                  this.props.categories.length > 0 &&
                  this.props.categories.map((row, index) => {
                    return (
                      <option value={row.category_id}>
                        {row.category_name}
                      </option>
                    );
                  })}
              </Input>
              <Label className="mt-4">Upload Category Image</Label>
              <Input type="file"
                     onChange={(e) => this.onImageUploaded(e.target.files[0])}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" outline onClick={this.toggleCategoryModal}>
              Cancel
            </Button>{" "}
            <Button
              color="primary"
              onClick={() => {
                this.uploadCategoryImage();
              }}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    uploadCategoryImage: (productId, image) => dispatch(uploadCategoryImage(productId, image))
  };
}
function mapStateToProps(state) {
  return {
  };
}

export default connect(
  mapStateToProps,
  bindActions
)(UploadCategory);
