import React from "react";
import {connect} from "react-redux";

import {
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from "classnames";
import ProductSuggestions from "./ProductSuggestions";
import ProductPricing from "./ProductPrice";

import {getProduct, updateBranchProductStatus} from "../../actions/products";

class ProductTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            branch_id: 0,
            branch_name: "All branch",
            activeTab: "1",
            dropdownOpen: false
        };

        this.branchtoggle = this.branchtoggle.bind(this);
        this.handleBranchChange = this.handleBranchChange.bind(this);
        this.tabToggle = this.tabToggle.bind(this);
        this.updateBranchProductStatus = this.updateBranchProductStatus.bind(this);
        // this.props.getProduct(this.props.currentProduct.product_id, this.state.branch_id);
    }

    branchtoggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    handleBranchChange(branch_id, branch_name) {
        this.setState({branch_id: branch_id, branch_name: branch_name});
        this.props.getProduct(this.props.currentProduct.product_id, branch_id);
    }

    updateBranchProductStatus(status) {
        const {currentProduct} = this.props;
        const {branch_id} = this.state;
        let params = {
            branch_id: branch_id,
            product_id: currentProduct.product_id,
            status: status
        };
        this.props.updateBranchProductStatus(
            currentProduct.product_id,
            branch_id,
            params
        );
    }

    componentDidUpdate(prevProps) {
        const {loggedInUser, currentProduct} = this.props;
        if (
            (loggedInUser !== prevProps.loggedInUser ||
                currentProduct.product_id !== prevProps.currentProduct.product_id) &&
            loggedInUser &&
            loggedInUser.branch_list
        ) {

            let is_false = loggedInUser.branch_list[0].branch_list[0].branch_id == 0;
            let branch_name = loggedInUser.branch_list[0].branch_list[is_false ? 1 : 0].branch_name;
            let branch_id = loggedInUser.branch_list[0].branch_list[is_false ? 1 : 0].branch_id;


            this.setState({
                branch_id: branch_id,
                branch_name: branch_name
            });
        }
    }

    tabToggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const {branch_name, branch_id} = this.state;
        let branch_list = [];

        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            for (let j = 0; j < this.props.loggedInUser.branch_list.length; j++) {
                const item = this.props.loggedInUser.branch_list[j];
                if (j === 0) {
                    branch_list = item.branch_list;
                    if (branch_list[0].branch_id === 0) {
                        branch_list.splice(0, 1);
                    }
                    break;
                }
            }
        }
        return (
            <Row className="product-tabs">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({active: this.state.activeTab === "1"})}
                            onClick={() => {
                                this.tabToggle("1");
                            }}
                        >
                            Pricing
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: this.state.activeTab === "2"})}
                            //   onClick={() => { this.toggle('2'); }}
                            disabled
                        >
                            Analytics
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab} className="w-100">
                    <TabPane tabId="1">
                        <Row>
                            <Col sm="4" className="product-view-filter mb-2">
                                <Dropdown
                                    isOpen={this.state.dropdownOpen}
                                    toggle={this.branchtoggle}
                                    className="place-order mb-2"
                                >
                                    <DropdownToggle
                                        caret
                                        className="w-50"
                                        color="primary"
                                        outline
                                    >
                                        {branch_name}
                                    </DropdownToggle>
                                    <DropdownMenu className="">
                                        {branch_list &&
                                        branch_list.map((row, index) => {
                                            if (row.branch_id != 0)
                                                return (
                                                    <DropdownItem
                                                        key={row.branch_id}
                                                        onClick={() => {
                                                            this.handleBranchChange(
                                                                row.branch_id,
                                                                row.branch_name
                                                            );
                                                        }}
                                                    >
                                                        {row.branch_name}
                                                    </DropdownItem>
                                                );
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                            <Col sm="2" className="offset-6">
                                {this.props.currentProduct && (
                                    <Input
                                        type="select"
                                        className="mb-3"
                                        name="category"
                                        id="category"
                                        onChange={e => {
                                            // this.setState({});
                                            this.updateBranchProductStatus(e.target.value);
                                            // console.log(e.target.value);
                                        }}
                                        value={this.props.currentProduct.stock_status}
                                    >
                                        {this.props.currentProduct.product_status_list &&
                                        this.props.currentProduct.product_status_list.length >
                                        0 &&
                                        this.props.currentProduct.product_status_list.map(
                                            (row, index) => {
                                                return (
                                                    <option value={row.status_id}>
                                                        {row.status_name}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </Input>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <ProductPricing
                                    productData={this.props.currentProduct}
                                    branch_id={branch_id}
                                />
                            </Col>
                            <Col sm="6">
                                <ProductSuggestions
                                    branch_id={branch_id}
                                    productData={this.props.currentProduct}
                                />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>Coming soon!</Row>
                    </TabPane>
                </TabContent>
            </Row>
        );
    }
}

function bindActions(dispatch) {
    return {
        getProduct: (product_id, branch_id) =>
            dispatch(getProduct(product_id, branch_id)),
        updateBranchProductStatus: (product_id, branch_id, params) =>
            dispatch(updateBranchProductStatus(product_id, branch_id, params))
    };
}

function mapStateToProps(state) {
    return {
        currentProduct: state.products.currentProduct,
        loggedInUser: state.commonData.loggedInUser
    };
}

export default connect(
    mapStateToProps,
    bindActions
)(ProductTabs);
