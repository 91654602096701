import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";


import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input
} from "reactstrap";
import classnames from "classnames";

// import moment from "moment";
// import ProductTabs from "./ProductTabs";

import { getCustomer, getCustomerOrdersList } from "../../actions/customers";
import OrdersTable from "./OrdersTable";
import Address from "./Address";
import {formatNumbersWithThousandSeparator} from "../../utils";
import config from "../../config";

class ProductView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoDropdownOpen: false,
      customer_id: "",
      activeTab: "1",
      perPage: 10,
      offset: 0
    };
    let customer_id = this.props.match.params.customer_id;
    this.setState({ customer_id: customer_id });
    this.props.getCustomer(customer_id);

    this.tabToggle = this.tabToggle.bind(this);

    this.handlePagination = this.handlePagination.bind(this);

    const { perPage } = this.state;
    let default_params = {
      offset: 0,
      perPage: perPage,
      sort: "ASC"
    };
    this.props.getCustomerOrdersList(customer_id, default_params);
  }

  tabToggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  handlePagination(offset) {
    const { perPage } = this.state;
    this.setState({ offset: offset });
    let params = {
      offset: offset,
      perPage: perPage,
      sort: "ASC"
    };

    this.props.getCustomerOrdersList(
      this.props.match.params.customer_id,
      params
    );
  }

  render() {
    const customerData = this.props.customer;
    const customerInfo = customerData.customer_info
      ? customerData.customer_info
      : "";
      const customer_revenue = customerData.customer_revenue
          ? customerData.customer_revenue
          : "";
    return (
      //   bill_count: "1"
      // customer_id: "91763"
      // date_time: null
      // discount_value: "37.35"
      // total: "222.23"
      <div className="animated fadeIn">
        <Row className="ml-4 mr-4 customerView">
          <Col sm="12">
            <Link to="/customers" className="mb-2 mt-2 d-block">
              <i class="fas fa-arrow-circle-left mr-2" /> Back to Customer list
            </Link>
          </Col>
          <Col sm="2" className="customerView-side p-0">
            <div className="profile-wrap text-center p-3">
              <div className="pad-btm">
                <img
                  className="img-circle img-md"
                  src={`${config.baseUrl}frontend/panel/img/profile-photos/1.png`}
                  alt="Profile"
                />
              </div>
              <div className="text-primary mt-2">
                {customerInfo &&
                  `${
                  customerInfo.customer_first_name
                  } ${customerInfo.customer_last_name || ""}`}
              </div>
              <div className="text-black-50 small">
                {customerInfo && `${customerInfo.customer_phone}`}
              </div>
            </div>
            <div className="wallet p-3 bg-white">
              <span className="small">Manage Credits</span>
              <br />
              <span className="font-weight-bolder">
                {  customerInfo && customerInfo.customer_wallet_balance
                ?
                  formatNumbersWithThousandSeparator(Math.round(customerInfo && customerInfo.customer_wallet_balance))
                  : 0
                }
              </span>
            </div>
              <div className="wallet p-3 bg-white">
                  <span className="small">Bill Count</span>
                  <br />
                  <span className="font-weight-bolder">
                    {(customer_revenue && customer_revenue.bill_count)
                    ? formatNumbersWithThousandSeparator(Math.round(customer_revenue && customer_revenue.bill_count))
                     : 0
                    }
              </span>
              </div>
              <div className="wallet p-3 bg-white">
                  <span className="small">Gross Revenue</span>
                  <br />
                  <span className="font-weight-bolder">
                    {customer_revenue && customer_revenue.gross_value
                    ? formatNumbersWithThousandSeparator(Math.round(customer_revenue && customer_revenue.gross_value))
                    : 0
                    }
              </span>
              </div>
              <div className="wallet p-3 bg-white">
                  <span className="small">Discount</span>
                  <br />
                  <span className="font-weight-bolder">
                    {customer_revenue && customer_revenue.discount_value
                      ?
                      formatNumbersWithThousandSeparator(Math.round(customer_revenue && customer_revenue.discount_value)) : 0
                    }
              </span>
              </div>
              <div className="wallet p-3 bg-white">
                  <span className="small">Net Revenue</span>
                  <br />
                  <span className="font-weight-bolder">
                    { customer_revenue && customer_revenue.net_value
                      ?
                      formatNumbersWithThousandSeparator(Math.round(customer_revenue && customer_revenue.net_value)) : 0

                    }
              </span>
              </div>
          </Col>
          <Col sm="10" lg="10" className="customerView-main p-0">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={"text-center " + classnames({
                    active: this.state.activeTab === "1"
                  })}
                  onClick={() => {
                    this.tabToggle("1");
                  }}
                >
                  Orders
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={"text-center " + classnames({
                    active: this.state.activeTab === "2"
                  })}
                  onClick={() => {
                    this.tabToggle("2");
                  }}
                >
                  Addresses
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={"text-center " + classnames({
                    active: this.state.activeTab === "3"
                  })}
                  //   onClick={() => { this.tabToggle('3'); }}
                  disabled
                >
                  Analytics
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab} className="w-100">
              <TabPane tabId="1">
                {this.props.customerOrdersList &&
                  this.props.customerOrdersList.aaData && (
                    <OrdersTable
                      orders={this.props.customerOrdersList.aaData}
                      totalData={this.props.customerOrdersList.iTotalRecords}
                      handlePagination={this.handlePagination}
                    />
                  )}
              </TabPane>
              <TabPane tabId="2">
                {this.props.customer &&
                  this.props.customer.customer_address && (
                    <Address addresses={this.props.customer.customer_address} />
                  )}
              </TabPane>
              <TabPane tabId="3">Coming Soon</TabPane>
            </TabContent>
          </Col>
        </Row>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    getCustomer: customer_id => dispatch(getCustomer(customer_id)),
    getCustomerOrdersList: (customer_id, params) =>
      dispatch(getCustomerOrdersList(customer_id, params))
  };
}

function mapStateToProps(state) {
  return {
    customer: state.customers.customer,
    customerOrdersList: state.customers.customerOrdersList
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(ProductView);
