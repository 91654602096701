import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Input,
  Label
} from "reactstrap";

import { addCredit } from "../../actions/wallet";

class CreditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productModal: false,
      transaction_type: 1,
      all_customers: true,
      credits: "",
      customer_list: []
    };

    this.toggleProductModal = this.toggleProductModal.bind(this);
    this.addCredit = this.addCredit.bind(this);
  }

  toggleProductModal() {
    this.setState(prevState => ({
      productModal: !prevState.productModal
    }));
  }

  addCredit() {
    let data = {};
    const {
      credits,
      transaction_type,
      all_customers,
      customer_list
    } = this.state;
    if (credits === "") {
      return;
    }
    data = {
      credits,
      transaction_type,
      all_customers,
      customer_list
    };
    this.props.addCredit(data);
    this.setState({
      credits: "",
      transaction_type: 1,
      all_customers: true,
      customer_list: []
    });
    this.toggleProductModal();
  }
  render() {
    const { credits, transaction_type, all_customers } = this.state;
    return (
      <div className={`animated fadeIn ${this.props.className}`}>
        <Button
          color="success"
          outline
          className="place-order w-100"
          onClick={this.toggleProductModal}
        >
          Add Credits
        </Button>
        <Modal
          isOpen={this.state.productModal}
          fade={false}
          toggle={this.toggleProductModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleProductModal}>
            Add Credits
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Input
                type="select"
                className="mb-3"
                name="transaction_type"
                id="transaction_type"
                onChange={e => {
                  this.setState({ transaction_type: e.target.value });
                }}
                value={transaction_type}
              >
                <option value="1">Add Credits</option>
                <option value="2">Deduct Credits</option>
              </Input>
              <Input
                type="select"
                className="mb-3"
                name="all_customers"
                id="all_customers"
                onChange={e => {
                  this.setState({ all_customers: e.target.value });
                }}
                value={all_customers}
              >
                <option value="true">All Customers</option>
              </Input>
              <Input
                type="text"
                className="mb-3"
                placeholder="Credits"
                autoComplete="productName"
                onChange={e => {
                  this.setState({ credits: e.target.value });
                }}
                value={credits}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" outline onClick={this.toggleProductModal}>
              Cancel
            </Button>{" "}
            <Button
              color="primary"
              onClick={() => {
                this.addCredit();
              }}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    addCredit: data => dispatch(addCredit(data))
  };
}
function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  bindActions
)(CreditModal);
