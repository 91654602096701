import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import Barcode from "react-barcode";
import QRCode from 'qrcode.react';
import {getOrder} from "../../actions/orders";

let options = {
    width: 2,
    height: 100,
    format: "CODE128",
    displayValue: true,
    fontOptions: "",
    font: "monospace",
    textAlign: "center",
    textPosition: "bottom",
    textMargin: 2,
    fontSize: 20,
    background: "#ffffff",
    lineColor: "#000000",
    margin: 10,
    marginTop: undefined,
    marginBottom: undefined,
    marginLeft: undefined,
    marginRight: undefined
}

class PrintOrder extends Component {

    constructor(props) {
        super(props);
        let orderId = this.props.match.params.orderId;
        this.props.getOrder(orderId);
    }

    branchtoggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    componentDidUpdate(prevProps) {
        if (
            Object.keys(this.props.currentOrder).length != 0 &&
            this.props.currentOrder != prevProps.currentOrder
        ) {
            window.print();
            // window.location.reload();
        }
        // window.onafterprint = window.close();
    }

    render() {

        const {currentOrder} = this.props;
        // console.log(currentOrder.reference_number.slice(-6), "check index");
        // console.log(currentOrder);
        let refrenceNo = currentOrder.reference_number;



        // let fre = refrenceNo.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '-').split(' ').join('-').toLowerCase()
        // console.log(fre, "fre");
        // var res = refrenceNo.substr(-6);
        // console.log(string.indexOf(refrenceNo) !== -1)
        // let refMin = refrenceNo.split(-2);
        // console.log(refMin.length(-3), "last 6 digits of ref number");
        return (
            <div className="animated fadeIn print-order">
                <div class="bill">
                    {/*<div className="text-center">*/}
                    {/*<Barcode value={`${currentOrder.bill_id}`}/>*/}
                    {/*</div>*/}

                    {/*<div class="space"/>*/}
                    {/*<h1 className="fs-16">Tax Invoice</h1>*/}
                    {/*<br/>*/}
                    {/*<p className="dot-border"/>*/}

                    <p className="center mb-0">
                        <strong>{currentOrder.company_name}</strong>
                    </p>
                    <p className="center mb-0">{currentOrder.branch_name} | {currentOrder.branch_phone && (
                        <span>
                            {currentOrder.branch_phone}
                        </span>
                    )}</p>
                    {currentOrder.print_options &&
                    currentOrder.print_options.header.map((row, index) => {
                        // console.log(row, "this");
                        return (
                            <p className="text-center">
                                <span>{row.value}</span>
                            </p>
                        );
                    })}
                    <p className="dot-border mb-0"/>
                    <h2 className="center big-bark">{currentOrder.bill_id}
                        {currentOrder.reference_number  && (
                            <span> ({currentOrder.reference_number.substr(currentOrder.reference_number.length - 4)})</span>
                        )}
                        {/*{currentOrder.reference_number && currentOrder.order_source_id === 6 &&  (*/}
                        {/*    <span> ({refrenceNo.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '').substring(refrenceNo.length - 6, refrenceNo.length)})</span>*/}
                        {/*)}*/}
                    </h2>
                    <h2 className="text-center big-bark">
                        <span>
                            {currentOrder.delivered_by_partner == 0 && (
                                <span>{currentOrder.exp_del_date.substring(currentOrder.exp_del_date.length - 8, currentOrder.exp_del_date.length)}, By Potful</span>
                            )}
                            {currentOrder.delivered_by_partner == 1 && (
                                <span>{currentOrder.service_type == 3 && (
                                    <span>{currentOrder.exp_del_date.substring(currentOrder.exp_del_date.length - 8, currentOrder.exp_del_date.length)}, By {currentOrder.order_source_name}</span>
                                )}</span>
                            )}
                        </span>
                    </h2>
                    {/*<br/>*/}
                    {/*<p className="dot-border"/>*/}
                    <p className="dot-border mb-3"/>
                    <h2 className="big-bark">
                        Packer Temperature:
                    </h2>
                    <p className="dot-border mb-3"></p>
                    <h2 className="big-bark">
                        Rider &nbsp;&nbsp;&nbsp;Temperature:
                    </h2>
                    <p className="dot-border"></p>
                    {/*<div className="text-center mb-1">*/}
                    {/*<h2>Safety Video</h2>*/}
                    {/*<QRCode className="text-center" value="https://bit.ly/2xEp4aF" width="200" />*/}
                    {/*</div>*/}
                    {/*{currentOrder.print_options &&*/}
                    {/*currentOrder.print_options.header.map((row, index) => {*/}
                    {/*// console.log(row, "this");*/}
                    {/*return (*/}
                    {/*<p className="mb-0 left pt-0">*/}
                    {/*<strong>{row.value}</strong>*/}
                    {/*</p>*/}
                    {/*);*/}
                    {/*})}*/}
                    {/*For feedback call {currentOrder.branch_phone} or write to*/}
                    {/*feedback@potful.in*/}
                    {/*<div class="dot-border mt-0"/>*/}
                    {/*<div class="dot-border"/>*/}
                    {/*<br/>*/}
                    <p className="mb-0">
                        <strong>
                            Name:{" "}
                            {`${currentOrder.customer_first_name}  ${currentOrder.customer_second_name}`}
                        </strong>
                    </p>
                    <p className="mb-0">
                        <strong>Order #: {currentOrder.bill_id}</strong>
                    </p>
                    <p className="mb-0">
                        <strong>Order Time: </strong>
                        {currentOrder.date_time}
                    </p>
                    <p className="mb-0">
                        <strong>Delivery Time: </strong>
                        {currentOrder.exp_del_date}
                    </p>
                    {currentOrder.reference_number && (
                        <p className="mb-0">
                            <strong>Reference Number: </strong>
                            {currentOrder.reference_number}
                        </p>
                    )}
                    {/*<br/>*/}
                    <div class="dot-border"/>
                    {/*<br/>*/}
                    {/*<p class="small-space"/>*/}
                    <table>
                        <thead>
                        <tr>
                            <th>Item</th>
                            <th>Qty</th>
                            <th>Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentOrder.bill_products &&
                        currentOrder.bill_products.map((row, index) => {
                            return (
                                <tr>
                                    <td className="">
                                        {row.product_name} ({row.serving_size_name})
                                    </td>
                                    <td>x {row.qty}</td>
                                    <td>{row.product_price}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td>
                                <strong className="mb-1 d-block">Sub Total</strong>
                            </td>
                            <td/>
                            <td>
                                <strong className="d-block border-bottom-dotted">{currentOrder.bill_subtotal}</strong>
                            </td>
                        </tr>
                        {/*{currentOrder.coupon_text != null &&  (*/}
                        {currentOrder.bill_discount_value != 0 && (
                            <tr>
                                <td>
                                    <span>Coupon ({currentOrder.coupon_text || "Discount"})</span>
                                    {/*<strong>Coupon ({currentOrder.coupon_value})</strong>*/}
                                </td>
                                <td/>
                                <td>
                                    <span>{currentOrder.bill_discount_value != 0 && (
                                        <span>{currentOrder.bill_discount_value}</span>
                                    )}</span>
                                </td>
                            </tr>
                        )}
                        {currentOrder.delivery_charge_value && (
                            <tr>
                                <td>
                                    <span>Delivery Charge</span>
                                </td>
                                <td/>
                                <td>{currentOrder.delivery_charge_value}</td>
                            </tr>
                        )}
                        {currentOrder.packing_charge_value && (
                            <tr>
                                <td>
                                    <span>Packing Charge</span>
                                </td>
                                <td/>
                                <td>{currentOrder.packing_charge_value}</td>
                            </tr>
                        )}

                        {currentOrder.bill_taxes &&
                        currentOrder.bill_taxes.map((obj, index) => {
                            return (
                                <tr>
                                    <td>
                                        <span>{obj.tax_type_name}</span>
                                    </td>
                                    <td/>
                                    <td>{obj.amount}</td>
                                </tr>
                            );
                        })}

                        {currentOrder.credits_used > 0 && (
                            <tr>
                                <td>
                                    <span>Potful Credits</span>
                                </td>
                                <td/>
                                <td>-{currentOrder.credits_used}</td>
                            </tr>
                        )}
                        <tr>
                            <td>
                                <strong className=" d-block mb-1"><h4><strong>Your Total</strong></h4></strong>
                            </td>
                            <td/>
                            <td>
                                <strong className="mt-1 d-block mb-1 border-top-dotted"><h4>
                                    <strong>{currentOrder.bill_total}</strong></h4></strong>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                    <div className="dot-border"/>
                    {/*<div className="mt-2">*/}
                    {/*    {currentOrder.estimated_savings && (<div>*/}
                    {/*            <table>*/}
                    {/*                <tr>*/}
                    {/*                    <td><h4><strong>Price on Potful App</strong></h4></td>*/}
                    {/*                    <td><h4><strong>{currentOrder.estimated_cost}</strong></h4></td>*/}
                    {/*                </tr>*/}
                    {/*            </table>*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</div>*/}

                    {/*<p class="small-space"/>*/}
                    <div class="dot-border"/>
                    {/*<p class="small-space"/>*/}
                    <p className="mb-0">
                        <strong>
                            Delivery Address:
                        </strong>
                        {currentOrder.customer_address &&
                        currentOrder.customer_address.address_text}

                        {currentOrder.customer_address &&
                        currentOrder.customer_address.google_address}
                    </p>
                    <p className="mb-0">
                        <strong>Phone</strong> : {currentOrder.customer_phone}
                    </p>
                    <p className="mb-0">
                        <strong>Order Source</strong> : {currentOrder.order_source_name}
                    </p>

                    <p className="mb-0">
                        <strong>Serve Type</strong> : {currentOrder.service_type_name}
                    </p>
                    {currentOrder.comments && (
                        <p className="mb-0">
                            <strong>Instruction</strong><span> : {currentOrder.comments}</span>
                        </p>
                    )}
                    <p className="mb-0">
                        <strong>Payment </strong> : {currentOrder.payment_mode_value}
                    </p>
                    {/*<br/>*/}
                    <div class="dot-border"/>
                    <p class="center"/>

                    {/*<p class="center">{currentOrder.print_options && currentOrder.print_options.header}</p>*/}

                    <div class="center">
                        {currentOrder.estimated_savings && (
                            <div className="mb-1"><h1 class="mb-4">You save {currentOrder.estimated_savings}/- on this
                                order on <br/>potful app/web</h1>
                                <div className="dot-border"/>
                            </div>)}
                        {currentOrder.print_options &&
                        currentOrder.print_options.footer.map((row, index) => {
                            // console.log(row, "this");
                            return (
                                <p className="mb-1">
                                    {row.value}
                                </p>
                            );
                        })}

                    </div>


                    {/*<p class="space"/>*/}
                    <div className="text-center">
                        {currentOrder.bill_count &&
                        currentOrder.bill_count === "0" && (
                            <h1 className="center">N</h1>
                        )}
                        {currentOrder.bill_count &&
                        currentOrder.bill_count !== "0" && (
                            <h1 className="center">R</h1>
                        )}

                        {/*<p className="center">*/}
                        {/*Scan to leave a feedback*/}
                        {/*</p>*/}
                        {/*<QRCode value="https://forms.gle/dUPL9VVwHRvpqHDN9" width="200" />*/}
                        <div className="text-center">
                            <Barcode value={`${currentOrder.bill_id}`}/>
                            {/*{currentOrder.reference_number && (*/}
                            {/*<h2 className="big-bark"> (Ref: {refrenceNo.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '').substring(refrenceNo.length - 6, refrenceNo.length)})</h2>*/}
                            {/*)}*/}
                        </div>
                        {/*<p></p>*/}
                    </div>
                    {/*<p class="space"/>*/}
                </div>
            </div>
        );
    }
}

function bindActions(dispatch) {
    return {
        getOrder: orderId => dispatch(getOrder(orderId))
    };
}

function mapStateToProps(state) {
    return {
        currentOrder: state.orders.currentOrder
    };
}

export default connect(
    mapStateToProps,
    bindActions
)(PrintOrder);
