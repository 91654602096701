import React, {Component} from "react";
import {connect} from "react-redux";
import _ from "lodash";
import {NavLink} from "react-router-dom";
import moment from "moment";
import {DateRangePicker} from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown.js";
import Collapsible from "react-collapsible";

import {Button, ButtonGroup, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Table,} from "reactstrap";
import OrdersSummary from "./AnalyticsSummary";
import OrdersSource from "./AnalyticsSource";
import {getAnalytics, getOrderSourceForAnalyticsTrends} from "../../actions/orders";
import config from "../../config";
import Cookies from "js-cookie";

drilldown(Highcharts);

class Analytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().subtract(7, "d"),
            endDate: moment(),
            preset: "",
            focusedInput: false,
            dropdownOpen: false,
            branch_id: 0,
            orderSourceOpen: false,
            selectedOrderSource: "All Order Sources",
            selectedOrderSourceId: 0,
            branch_name: "All branch",
            chartMode: "quantity",
            cityDropdown: false,
            city_id: "0",
            city_name: "All Cities"

        };
        this.handleOrderSourceChange = this.handleOrderSourceChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.renderDatePresets = this.renderDatePresets.bind(this);
        this.branchtoggle = this.branchtoggle.bind(this);
        this.orderSourceToggle = this.orderSourceToggle.bind(this);
        this.handleBranchChange = this.handleBranchChange.bind(this);
        this.showByQuantity = this.showByQuantity.bind(this);
        this.showBySalesValue = this.showBySalesValue.bind(this);
        this.handleChartData = this.handleChartData.bind(this);
        this.handleTableData = this.handleTableData.bind(this);
        this.citytoggle = this.citytoggle.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.props.getOrderSources();

        const {
            branch_id,
            startDate,
            endDate,
            selectedOrderSourceId,
            city_id
        } = this.state;
        let default_params = {
            branch_id: branch_id,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            order_source: selectedOrderSourceId,
            city_id:city_id
        };

        this.props.getAnalytics(default_params);
    }

    componentDidMount() {
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let firstCityName = branch_list[0].city_name;
                let firstCityId = branch_list[0].city_id;
                this.handleCityChange(firstCityName, firstCityId);
            }
        }
    }


    componentWillReceiveProps(nextProps) {
        if (this.props.loggedInUser && (this.props.loggedInUser.branch_list != nextProps.loggedInUser.branch_list)) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let firstCityName = branch_list[0].city_name;
                let firstCityId = branch_list[0].city_id;
                this.handleCityChange(firstCityName, firstCityId);
            }
        }
    }


    citytoggle() {
        this.setState(prevState => ({
            cityDropdown: !prevState.cityDropdown
        }));
    }

    branchtoggle() {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }

    handleDateChange(startDate, endDate) {
        const {branch_id, selectedOrderSourceId,city_id} = this.state;
        this.setState({startDate: startDate, endDate: endDate});
        let params = {
            branch_id: branch_id,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            order_source: selectedOrderSourceId,
            city_id:city_id
        };
        this.props.getAnalytics(params);
    }

    handleCityChange(city_name, city_id) {
        this.setState({city_id: city_id, city_name: city_name},()=>{
            this.props.loggedInUser.branch_list.map((value) => {
                if (city_id === value.city_id)
                    this.handleBranchChange(value.branch_list[0].branch_id, value.branch_list[0].branch_name);
            });
        });
    }

    handleBranchChange(branch_id, branch_name) {
        const {startDate, endDate,selectedOrderSourceId,city_id} = this.state;
        this.setState({branch_id: branch_id, branch_name: branch_name});
        let params = {
            branch_id: branch_id,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            order_source:selectedOrderSourceId,
            city_id:city_id
        };
        this.props.getAnalytics(params);
    }

    orderSourceToggle() {
        this.setState((prevState) => ({
            orderSourceOpen: !prevState.orderSourceOpen,
        }));
    }

    renderDatePresets() {
        return (
            <div className="date_custom_presets">
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment(), moment());
                    }}
                >
                    Today
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().subtract(1, "d"), moment().subtract(1, "d"));
                        this.setState({focusedInput: false});
                    }}
                >
                    Yesterday
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().subtract(7, "d"), moment());
                        this.setState({focusedInput: false});
                    }}
                >
                    Last 7 days
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().subtract(30, "d"), moment());
                        this.setState({focusedInput: false});
                    }}
                >
                    Last 30 days
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().startOf("month"), moment());
                        this.setState({focusedInput: false});
                    }}
                >
                    This month
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(
                            moment().subtract(1, "months").startOf("month"),
                            moment().subtract(1, "months").endOf("month")
                        );
                        this.setState({focusedInput: false});
                    }}
                >
                    Last month
                </Button>
                <Button className="w-100  mb-2" color="info">
                    Custom Range
                </Button>
            </div>
        );
    }

    showByQuantity() {
        this.setState({chartMode: "quantity"});
    }

    showBySalesValue() {
        this.setState({chartMode: "salesValue"});
    }

    handleOrderSourceChange(id, name) {
        const {
            branch_id,
            startDate,
            endDate,
        } = this.state;
        this.setState({selectedOrderSource: name, selectedOrderSourceId: id});
        let params = {
            branch_id: branch_id,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            order_source: id,
        };
        this.props.getAnalytics(params);
    }

    handleChartData() {
        const {chartMode} = this.state;
        const analyticsGroupedByCategory = _.groupBy(
            this.props.Analytics.data,
            "category_id"
        );

        const chartData = !_.isEmpty(this.props.Analytics.data)
            ? _.map(analyticsGroupedByCategory, (c) => {
                const quantity = _.isArray(c)
                    ? c.reduce((acc, item) => acc + item.total_qty, 0)
                    : 0;
                const salesValue = _.isArray(c)
                    ? c.reduce((acc, item) => acc + item.sales_value, 0)
                    : 0;
                const category = _.head(c).category_name;

                return {
                    name: category,
                    y: chartMode === "quantity" ? quantity : salesValue,
                    drilldown: category,
                };
            })
            : [];

        const drilldownData = !_.isEmpty(this.props.Analytics.data)
            ? _.map(analyticsGroupedByCategory, (c) => {
                const category = _.head(c).category_name;
                const data = _.reduce(
                    c,
                    (result, product) => {
                        const y =
                            chartMode === "quantity"
                                ? product.total_qty
                                : product.sales_value;
                        result.push([product.product_name, y]);
                        return result;
                    },
                    []
                );

                return {
                    name: category,
                    id: category,
                    data,
                };
            })
            : [];

        const chartConfig = {
            chart: {
                type: "column",
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return this.y.toLocaleString("en-IN", {
                                maximumFractionDigits: 2,
                                currency: "INR",
                            });
                        },
                    },
                },
            },
            accessibility: {
                announceNewData: {
                    enabled: true,
                },
            },
            legend: {
                enabled: false,
            },
            title: {
                text: "Product Analytics",
            },
            xAxis: {
                type: "category",
            },
            yAxis: {
                title: {
                    text: _.startCase(chartMode),
                },
            },
            series: [
                {
                    name: "Categories",
                    colorByPoint: true,
                    data: chartData,
                },
            ],
            drilldown: {
                series: drilldownData,
            },
            credits: {
                enabled: false,
            },
        };

        if (!_.isEmpty(this.props.Analytics.data) && this.chartElement)
            Highcharts.chart("productAnalyticsChart", chartConfig);
    }

    handleTableData() {
        const analyticsGroupedByCategory = _.groupBy(
            this.props.Analytics.data,
            "category_id"
        );
        const headers = [
            "Product",
            "Quantity",
            "Sales Value",
            "% Category",
            "% Total",
        ];
        return _.map(analyticsGroupedByCategory, (c) => {
            const totalQuantity = _.reduce(
                c,
                (res, p) => res + p.total_qty,
                0
            ).toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                currency: "INR",
            });

            const totalSalesValue = _.reduce(
                c,
                (res, p) => res + p.sales_value,
                0
            ).toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                currency: "INR",
            });

            const totalCategoryPercentage = _.reduce(
                c,
                (res, p) => res + p["%_share_cat"],
                0
            ).toFixed(1);

            const totalPercentage = _.reduce(
                c,
                (res, p) => res + p["%_share_total"],
                0
            ).toFixed(1);

            return (
                <div className="table-holder">
                    <h5 className="table-header-analytics">{_.head(c).category_name}</h5>
                    <Table responsive className="my-table">
                        <thead>
                        <tr className="my-table-heading">
                            {_.map(headers, (header, index) => (
                                <td key={index} className="font-weight-bold">
                                    {header}
                                </td>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {_.map(c, (p) => (
                            <tr>
                                <td>{p.product_name}</td>
                                <td>

                                    <Collapsible trigger={p.total_qty.toLocaleString("en-IN", {
                                        maximumFractionDigits: 2,
                                        currency: "INR",
                                    })}>
                                        {p.serving_size.map((value) => {
                                            return <p>{value.serving_size_name + ': ' + value.units}</p>
                                        })}
                                    </Collapsible>
                                </td>
                                <td>
                                    {p.sales_value.toLocaleString("en-IN", {
                                        maximumFractionDigits: 2,
                                        currency: "INR",
                                    })}
                                </td>
                                <td>{p["%_share_cat"]}%</td>
                                <td>{p["%_share_total"]}%</td>
                            </tr>
                        ))}
                        <tr>
                            <td className="font-weight-bold">Total</td>
                            <td className="font-weight-bold">{totalQuantity}</td>
                            <td className="font-weight-bold">{totalSalesValue}</td>
                            <td className="font-weight-bold">{totalCategoryPercentage}%</td>
                            <td className="font-weight-bold">{totalPercentage}%</td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            );
        });
    }

    render() {
        const {branch_id, startDate, endDate, branch_name,selectedOrderSourceId,city_id,city_name} = this.state;

        let city_list = [];
        let branch_list = [];
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {

            this.props.loggedInUser.branch_list.map((item)=>{
                if(city_id===item.city_id)
                    branch_list = item.branch_list;
                city_list.push(item);
            });
        }

        this.handleChartData();

        return (
            <div className="animated fadeIn">
                <Row className="ml-2 mr-2 mt-2 orders">
                    <Col className="orders-main px-3">
                        <Row>
                            <div class="p-3 today-side col-sm-4 col-md-3 col-lg-2 border-right">
                                <Dropdown
                                    isOpen={this.state.cityDropdown}
                                    toggle={this.citytoggle}
                                    className="place-order w-100 mb-3"
                                >
                                    <DropdownToggle caret className="w-100" color="primary">
                                        {city_name}
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        {city_list &&
                                        city_list.map((row, index) => {
                                            return (
                                                <DropdownItem
                                                    key={index}
                                                    onClick={() => {
                                                        this.handleCityChange(
                                                            row.city_name,
                                                            row.city_id
                                                        );
                                                    }}
                                                >
                                                    {row.city_name}
                                                </DropdownItem>
                                            );
                                        })}
                                    </DropdownMenu>
                                </Dropdown>

                                <Dropdown
                                    isOpen={this.state.dropdownOpen}
                                    toggle={this.branchtoggle}
                                    className="place-order w-100 mb-3"
                                >
                                    <DropdownToggle caret className="w-100" color="primary">
                                        {branch_name}
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        {branch_list &&
                                        branch_list.map((row, index) => {
                                            return (
                                                <DropdownItem
                                                    key={index}
                                                    onClick={() => {
                                                        this.handleBranchChange(
                                                            row.branch_id,
                                                            row.branch_name
                                                        );
                                                    }}
                                                >
                                                    {row.branch_name}
                                                </DropdownItem>
                                            );
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                                <h5 className="nav-header">Navigation</h5>
                                <ul className="nav flex-column ul-hover-active">
                                    <li className="nav-link px-0">
                                        <NavLink to="/analytics">Product Sales</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/category_analytics">Payment Source</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/time_analytics">Sales By Time</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/partner_analytics">Partner Analytics</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/trends_analytics">Sales Analytics</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/customer_analytics">Customer Analytics</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="today-main py-3 col-sm-8 col-md-9 col-lg-10">
                                <Row className="align-items-center">
                                    <Col  md="3" className="px-3 mb-3">
                                        <DateRangePicker
                                            calendarInfoPosition="before"
                                            renderCalendarInfo={this.renderDatePresets}
                                            startDate={startDate}
                                            startDateId="FromstartDate"
                                            endDate={endDate}
                                            endDateId="FromendDate"
                                            onDatesChange={({startDate, endDate}) => {
                                                if (!startDate) {
                                                    startDate = endDate;
                                                }

                                                if (!endDate) {
                                                    endDate = startDate;
                                                }
                                                return this.handleDateChange(startDate, endDate);
                                            }}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={(focusedInput) =>
                                                this.setState({focusedInput})
                                            }
                                            orientation={this.state.orientation}
                                            openDirection={this.state.openDirection}
                                            isOutsideRange={() => false}
                                            anchorDirection={"left"}
                                        />
                                    </Col>
                                    <Col md="3" className="px-3 mb-3 h-35">
                                        <ButtonGroup className="check-btn w-100 custom-btn">
                                            <Button
                                                active={this.state.chartMode === "quantity"}
                                                onClick={this.showByQuantity}
                                            >
                                                By Quantity
                                            </Button>
                                            <Button
                                                active={this.state.chartMode === "salesValue"}
                                                onClick={this.showBySalesValue}
                                            >
                                                By Sales Value
                                            </Button>
                                        </ButtonGroup>
                                    </Col>

                                    <Col md="3" className="px-3 mb-3 h-35">
                                        <a
                                            target="_blank"
                                            href={`${
                                                config.baseUrl
                                            }admin/downloadProductSales?branch_id=${branch_id}&city_id=${city_id}&from_date=${startDate.format(
                                                "YYYY-MM-DD"
                                            )}&to_date=${endDate.format(
                                                "YYYY-MM-DD"
                                            )}&order_source=${selectedOrderSourceId}&access_token=${`${Cookies.get("token_dashboard")}`}`}
                                        >
                                            <Button className="w-100  mb-2" color="primary">
                                                Download
                                            </Button>
                                        </a>
                                    </Col>
                                    <Col md="3">
                                        <Dropdown
                                            isOpen={this.state.orderSourceOpen}
                                            toggle={this.orderSourceToggle}
                                            className="place-order w-100 mb-3"
                                        >
                                            <DropdownToggle caret className="w-100" color="primary">
                                                {this.state.selectedOrderSource}
                                            </DropdownToggle>

                                            <DropdownMenu className="w-100">
                                                <DropdownItem
                                                    key="0"
                                                    onClick={() => {
                                                        this.handleOrderSourceChange(
                                                            0,
                                                            "All Order Sources"
                                                        );
                                                    }}
                                                >
                                                    All
                                                </DropdownItem>
                                                {!_.isEmpty(this.props.OrderSources) &&
                                                !_.isEmpty(this.props.OrderSources.data) &&
                                                this.props.OrderSources.data.map((row, index) => {
                                                    return (
                                                        <DropdownItem
                                                            key={index}
                                                            onClick={() => {
                                                                this.handleOrderSourceChange(
                                                                    row.order_source_id,
                                                                    row.order_source_name
                                                                );
                                                            }}
                                                        >
                                                            {row.order_source_name}
                                                        </DropdownItem>
                                                    );
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>
                                    {this.props.Analytics && (
                                        <OrdersSummary numbers={this.props.Analytics.numbers}/>
                                    )}
                                    {this.props.Analytics && (
                                        <OrdersSource
                                            sources={this.props.Analytics.data_by_order_source}
                                            in_house_data={this.props.Analytics.in_house}
                                        />
                                    )}
                                </Row>
                                <Row className="mb-5">
                                    <div
                                        id="productAnalyticsChart"
                                        style={{width: "100%"}}
                                        ref={(el) => (this.chartElement = el)}
                                    />
                                </Row>
                                <Row style={{display: "grid", gridTemplateColumns: "1fr"}}>
                                    {this.handleTableData()}
                                </Row>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

function bindActions(dispatch) {
    return {
        getAnalytics: (params) => dispatch(getAnalytics(params)),
        getOrderSources: (params) => dispatch(getOrderSourceForAnalyticsTrends(params)),
    };
}

function mapStateToProps(state) {
    return {
        Analytics: state.orders.Analytics,
        loggedInUser: state.commonData.loggedInUser,
        OrderSources: state.orders.OrderSources,

    };
}

export default connect(mapStateToProps, bindActions)(Analytics);
