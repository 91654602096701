import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";

import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Input
} from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import { addCoupon } from "../../actions/branch";
import { getProductList } from "../../actions/products";

class Coupons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponModal: false,
      branch_id: this.props.branch_id,
      coupon_type: "",
      discount_value: "",
      min_bill: "",
      max_discount: 0,
      applied_on_id: 0,
      discount_product: 0,
      coupon_text: "",
      disableProduct: false,
      disableDiscProduct: false,
      disableMaxDiscAmt: false
    };

    this.toggleCouponModal = this.toggleCouponModal.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
    this.submitCoupon = this.submitCoupon.bind(this);
    this.handleCouponType = this.handleCouponType.bind(this);
    this.props.getProductList();
  }

  componentDidUpdate(previousProps, previousState) {
    const { branch_id } = this.props;
    if (branch_id !== previousProps.branch_id) {
      this.setState({ branch_id: branch_id });
    }
  }

  toggleCouponModal() {
    this.setState(prevState => ({
      couponModal: !prevState.couponModal
    }));
  }

  selectProduct(product, type) {
    product = product[0];
    if (product && product.product_id) {
      if (type == "discounted_product") {
        this.setState({
          discount_product: product.product_id
        });
      } else {
        this.setState({
          applied_on_id: product.product_id
        });
      }
    } else {
      this.setState({
        applied_on_id: ""
      });
    }
  }

  handleCouponType(coupon_type) {
    // console.log(coupon_type);
    let disableProduct,
      disableDiscProduct,
      disableMaxDiscAmt = false;
    switch (parseInt(coupon_type)) {
      case 1:
        // Flat discount Don’t show products
        // max disc amount no there
        disableProduct = true;
        disableDiscProduct = true;
        disableMaxDiscAmt = true;
        break;
      case 2:
        // Discount in percentage(Minimum bill value and Maximum discount value is considered)
        // Same as 1 (max bill amount is max disc amount - and is required)
        disableProduct = true;
        disableDiscProduct = true;
        break;
      case 3:
        // Flat Discount on purchase of specific product
        // applied_on_id - product price id - from product list
        disableDiscProduct = true;
        disableMaxDiscAmt = true;
        break;
      case 4:
        // Complementary product(Minimum bill amount is the criteria)
        // applied_on_id - 0
        // discount_product -  product price id - from product list
        disableMaxDiscAmt = true;
        disableProduct = true;
        break;
      case 5:
        // Complementary product on purchase of another product
        // applied_on_id - product price id - from product list
        // discount_product -  product price id - from product list
        disableMaxDiscAmt = true;
        break;
      default:
        return; // reset all
    }
    if (disableMaxDiscAmt) {
      this.setState({
        max_discount: ""
      });
    }
    if (disableDiscProduct) {
      this.setState({
        discount_product: 0
      });
    }
    if (disableProduct) {
      this.setState({
        applied_on_id: 0
      });
    }
    this.setState({
      coupon_type,
      disableProduct,
      disableDiscProduct,
      disableMaxDiscAmt
    });
    // console.log(this.state);
  }

  submitCoupon() {
    const {
      branch_id,
      coupon_type,
      discount_value,
      min_bill,
      max_discount,
      applied_on_id,
      discount_product,
      coupon_text
    } = this.state;

    let params = {};

    if (!coupon_text || !coupon_type || !discount_value) {
      // console.log("missing params");
      return;
    }
    params.branch_id = branch_id;
    params.coupon_type = coupon_type;
    params.coupon_text = coupon_text;
    params.discount_value = discount_value;

    params.min_bill = min_bill;
    params.max_discount = max_discount || 0;

    params.applied_on_id = applied_on_id;
    params.discount_product = discount_product;

    this.setState({
      couponModal: false
    });
    this.props.addCoupon(params);
    // console.log(this.state);
  }

  render() {
    const {
      couponModal,
      branch_id,
      coupon_type,
      discount_value,
      min_bill,
      max_discount,
      coupon_text,
      disableProduct,
      disableDiscProduct,
      disableMaxDiscAmt
    } = this.state;

    let allProducts = [];
    if (this.props.productsList.length) {
      this.props.productsList.map((ele, idx) => {
        if (ele.products.length) {
          ele.products.map((product, idx) => {
            allProducts.push(product);
          });
        }
      });
    }

    return (
      <Col sm="3" className="m-0 pl-0">
        <Button
          color="primary"
          className="place-order w-100"
          onClick={this.toggleCouponModal}
        >
          Add Coupon
        </Button>
        <Modal
          isOpen={couponModal}
          fade={false}
          toggle={this.toggleCouponModal}
        >
          <ModalHeader toggle={this.toggleCouponModal}>Add Coupon</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Input
                type="text"
                className="mb-3"
                placeholder="Coupon Code"
                autoComplete="coupon_text"
                onChange={e => {
                  this.setState({ coupon_text: e.target.value });
                }}
                value={coupon_text}
              />

              <Input
                type="select"
                className="mb-3"
                name="coupon_type"
                id="coupon_type"
                onChange={e => {
                  this.handleCouponType(e.target.value);
                }}
              >
                <option value="">Choose the coupon type</option>
                {this.props.couponTypes &&
                  this.props.couponTypes.length > 0 &&
                  this.props.couponTypes.map((element, index) => {
                    return (
                      <option value={element.coupon_type_id}>
                        {element.coupon_type_name}
                      </option>
                    );
                  })}
              </Input>

              <Input
                type="text"
                className="mb-3"
                placeholder="Discount Percentage / Price"
                autoComplete="discount"
                onChange={e => {
                  this.setState({ discount_value: e.target.value });
                }}
                value={discount_value}
              />
              <Input
                type="text"
                className="mb-3"
                placeholder="Min Bill Amount"
                autoComplete="discount"
                onChange={e => {
                  this.setState({ min_bill: e.target.value });
                }}
                value={min_bill}
              />

              <Input
                type="text"
                className="mb-3"
                placeholder="Max Discount Amount"
                autoComplete="max_discount"
                onChange={e => {
                  this.setState({ max_discount: e.target.value });
                }}
                value={max_discount}
                disabled={disableMaxDiscAmt}
              />

              <Typeahead
                onChange={selected => {
                  this.selectProduct(selected);
                }}
                labelKey="product_name"
                placeholder="Applicable on Product"
                className="mb-3"
                options={allProducts}
                disabled={disableProduct}
              />

              <Typeahead
                onChange={selected => {
                  this.selectProduct(selected, "discounted_product");
                }}
                labelKey="product_name"
                placeholder="Discounted Product"
                className="mb-3"
                options={allProducts}
                disabled={disableDiscProduct}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" outline onClick={this.toggleCouponModal}>
              Cancel
            </Button>{" "}
            <Button
              color="primary"
              onClick={() => {
                this.submitCoupon();
              }}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </Modal>
      </Col>
    );
  }
}

function bindActions(dispatch) {
  return {
    addCoupon: params => dispatch(addCoupon(params)),
    getProductList: () => dispatch(getProductList())
  };
}

function mapStateToProps(state) {
  return {
    productsList: state.products.productsList
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(Coupons);
