import React, { Component } from "react";
import { connect } from "react-redux";
import { checkLatLng } from "../../utils";

import { Col, Modal, ModalBody, Input, Label, Button } from "reactstrap";

import Map from "../Branch/CreateBranchMap";

import { addAddress } from "../../actions/billing";

class AddAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 12.9716,
      lng: 77.5946,
      address_type: 1,
      google_address: "",
      house_no: "",
      landmark: "",
      polyCords: [],
      showNoBranch: false,
      houseError : false,
    };

    if (Object.keys(this.props.selectedAddress).length) {
      const { selectedAddress } = this.props;
      this.setState({
        google_address: selectedAddress.selectedAddress,
        lat: selectedAddress.latValue,
        lng: selectedAddress.lngValue
      });
    }
    this.handleMapChange = this.handleMapChange.bind(this);
    this.addAddress = this.addAddress.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedAddress &&
      Object.keys(this.props.selectedAddress).length &&
      this.props.selectedAddress != prevProps.selectedAddress
    ) {
      const { selectedAddress, posMeta, branch_id } = this.props;
      this.setState({
        google_address: selectedAddress.selectedAddress,
        lat: selectedAddress.lat,
        lng: selectedAddress.lng
      });
      let coords = [];
      let branch_list = posMeta.branch_list;
      branch_list.map((row, index) => {
        console.log(branch_id);
        if (branch_id == row.branch_id) {
          coords = row.branch_serving_area.serving_area;
          console.log(coords);

          coords.push(coords[0]);

          coords.map((ll, index) => {
            ll &&
              Object.keys(ll).forEach(function(key) {
                ll[key] = Number(ll[key]);
              });
          });
          console.log(coords);
          this.setState({ polyCords: coords });
        }
      });
    }
  }

  addAddress() {
    const {
      lat,
      lng,
      address_type,
      google_address,
      house_no,
      landmark,
      houseError
    } = this.state;
    const { customer, branch_id } = this.props;

    if (!customer.customer_id && !lat && !lng && !branch_id) {
      console.log("missing params");
      return;
    }
    if(!house_no.trim()) {
      this.setState({houseError : true});
      return;
    }
    let params = {
      customer_id: customer.customer_id,
      latlng: `${lat},${lng}`,
      address_type: address_type,
      branch_id: branch_id,
      google_address: google_address,
      address_text: `${house_no}, ${landmark}`
    };

    this.props.addAddress(params);
    this.props.toggleModal();
  }

  handleMapChange(latValue, lngValue, address) {
    const { posMeta, branch_id } = this.props;

    let branch_list = posMeta.branch_list;
    console.log("Check ", latValue, lngValue, " exists in any branch");
    let branch_found = false;
    branch_list.map((row, index) => {
      console.log(branch_id);
      if (branch_id == row.branch_id) {
        let pointArray = [];
        let serving_area = row.branch_serving_area.serving_area;
        serving_area.map((point, index) => {
          let tmpArray = [];
          tmpArray.push(point.lat);
          tmpArray.push(point.lng);
          pointArray.push(tmpArray);
        });

        branch_found = checkLatLng({ latValue, lngValue }, pointArray);
        console.log("customer address found ", branch_found);
        if (branch_found) {
          this.setState({
            google_address: address,
            lat: latValue,
            lng: lngValue
          });
          this.setState({ showNoBranch: false });
        } else {
          this.setState({ showNoBranch: true });
          this.setState({
            google_address: "",
            lat: "",
            lng: ""
          });
          console.log("customer address not servicable");
        }
      }
    });
  }

  render() {
    const {
      lat,
      lng,
      google_address,
      house_no,
      landmark,
      showNoBranch,
      polyCords
    } = this.state;
    return (
      <Modal
        isOpen={this.props.showModal}
        fade={false}
        toggle={this.props.toggleModal}
        className="p-0 add-address-modal"
      >
        <ModalBody className="p-0">
          <Map
            google={this.props.google}
            center={{ lat, lng }}
            height="350px"
            zoom={13}
            handleMapChange={this.handleMapChange}
            polyCords={polyCords}
          />
          <Col sm="12" className="p-2 address-type mt-4">
            <p>Address Type</p>
            <Label check>
              <Input
                type="radio"
                value="1"
                name="addressType"
                onChange={e => {
                  this.setState({ address_type: 1 });
                }}
              />{" "}
              Home
            </Label>
            <Label check>
              <Input
                type="radio"
                value="2"
                name="addressType"
                onChange={e => {
                  this.setState({ address_type: 2 });
                }}
              />{" "}
              Office
            </Label>
            <Label check>
              <Input
                type="radio"
                value="3"
                name="addressType"
                onChange={e => {
                  this.setState({ address_type: 3 });
                }}
              />{" "}
              Others
            </Label>
          </Col>
          <Col sm="12" className="p-2">
            <Input
              type="text"
              className="mb-3 mt-2"
              placeholder="Address line 1"
              autoComplete="house_no"
              onChange={e => {
                this.setState({ house_no: e.target.value });
              }}
              tabIndex="0"
              value={house_no}
            />
            {this.state.houseError && (
              <div className="text-danger p-0 pl-2">
                Enter Address Line 1
              </div>
            )}
            <Input
              type="text"
              className="mb-3 mt-4"
              placeholder="Google address"
              autoComplete="google_address"
              disabled
              tabIndex="1"
              value={google_address}
            />
            <Input
              type="text"
              className="mb-3 mt-4"
              placeholder="Landmark"
              autoComplete="Landmark"
              onChange={e => {
                this.setState({ landmark: e.target.value });
              }}
              tabIndex="2"
              value={landmark}
            />
            {!showNoBranch && (
              <Button
                color="danger"
                className="add-address mb-4 mt-2 w-100"
                onClick={() => {
                  this.addAddress();
                }}
              >
                Add Address
              </Button>
            )}
            {showNoBranch && (
              <div className="alert alert-danger w-100">
                <strong>Note:</strong> This customer address is not currently
                servicable
              </div>
            )}
          </Col>
        </ModalBody>
      </Modal>
    );
  }
}
function bindActions(dispatch) {
  return {
    addAddress: params => dispatch(addAddress(params))
  };
}

function mapStateToProps(state) {
  return {};
}
export default connect(
  mapStateToProps,
  bindActions
)(AddAddress);
