import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import { Link } from "react-router-dom";

import { markPaymentCollected } from "../../actions/cash";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { Container, Col, Row, Button, InputGroup, Input } from "reactstrap";
import CashTable from "./RequestedWalletTable";

import {
  getWalletRequestList,
  updateTransactionRequest
} from "../../actions/wallet";

class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction_type: 0,
      startDate: moment().subtract(7, "d"),
      endDate: moment(),
      perPage: 10,
      offset: 0,
      searchText: "",
      preset: "",
      focusedInput: false,
      dropdownOpen: false
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.renderDatePresets = this.renderDatePresets.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.updateTransactionRequest = this.updateTransactionRequest.bind(this);

    const {
      startDate,
      endDate,
      perPage,
      offset,
      searchText,
      transaction_type
    } = this.state;

    let default_params = {
      transaction_type,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: offset,
      perPage: perPage,
      sort: "ASC"
    };
    this.props.getWalletRequestList(default_params);
    console.log(default_params);
  }

  handleTypeChange(type) {
    const { startDate, endDate, searchText, perPage, offset } = this.state;
    this.setState({ transaction_type: type });
    let params = {
      transaction_type: type,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: offset,
      perPage: perPage,
      sort: "ASC"
    };
    this.props.getWalletRequestList(params);
  }

  handlePagination(offset) {
    const {
      startDate,
      endDate,
      searchText,
      perPage,
      transaction_type
    } = this.state;
    this.setState({ offset: offset });
    console.log("offset", offset);
    let params = {
      transaction_type: transaction_type,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: offset,
      perPage: perPage,
      sort: "ASC"
    };
    this.props.getWalletRequestList(params);
  }

  handleSearch(searchText) {
    const {
      startDate,
      endDate,
      offset,
      perPage,
      transaction_type
    } = this.state;
    this.setState({ searchText: searchText });
    console.log("searchText", searchText);
    let params = {
      transaction_type: transaction_type,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: offset,
      perPage: perPage,
      sort: "ASC"
    };
    this.props.getWalletRequestList(params);
  }

  handleDateChange(startDate, endDate) {
    const { perPage, offset, searchText, transaction_type } = this.state;
    this.setState({ startDate: startDate, endDate: endDate });
    console.log("order status ", this.state.orderStatus);
    let params = {
      transaction_type: transaction_type,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: offset,
      perPage: perPage,
      sort: "ASC"
    };
    this.props.getWalletRequestList(params);
  }

  updateTransactionRequest(request_id, status) {
    let params = {
      status
    };
    const {
      startDate,
      endDate,
      perPage,
      offset,
      searchText,
      transaction_type
    } = this.state;

    let filterParams = {
      transaction_type,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: offset,
      perPage: perPage,
      sort: "ASC"
    };
    this.props.updateTransactionRequest(request_id, params, filterParams);
  }

  renderDatePresets() {
    return (
      <div className="date_custom_presets">
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment(), moment());
          }}
        >
          Today
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().subtract(1, "d"), moment().subtract(1, "d"));
            this.setState({ focusedInput: false });
          }}
        >
          Yesterday
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().subtract(7, "d"), moment());
            this.setState({ focusedInput: false });
          }}
        >
          Last 7 days
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().subtract(30, "d"), moment());
            this.setState({ focusedInput: false });
          }}
        >
          Last 30 days
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().startOf("month"), moment());
            this.setState({ focusedInput: false });
          }}
        >
          This month
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(
              moment()
                .subtract(1, "months")
                .startOf("month"),
              moment()
                .subtract(1, "months")
                .endOf("month")
            );
            this.setState({ focusedInput: false });
          }}
        >
          Last month
        </Button>
        <Button className="w-100  mb-2" color="info">
          Custom Range
        </Button>
      </div>
    );
  }

  render() {
    const { searchText, startDate, endDate, transaction_type } = this.state;
    let activeList = [];
    console.log(
      this.props.walletRequestList && this.props.walletRequestList.aaData
    );
    if (this.props.walletRequestList && this.props.walletRequestList.aaData) {
      activeList = this.props.walletRequestList.aaData.filter(function (
        transaction
      ) {
        return transaction.status == 1;
      });
    }
    console.log(activeList);

    return (
      <div className="animated fadeIn">
        <div className="ml-4 mr-4 cash pt-4 px-3">
          <Row className="w-100">
            <Col sm="2" className="mb-3">
              <Input
                type="select"
                className="mb-3 bg-white"
                name="transaction_type"
                id="transaction_type"
                onChange={e => {
                  this.handleTypeChange(e.target.value);
                }}
                value={transaction_type}
              >
                <option value="0">All</option>
                <option value="1">Credit</option>
                <option value="2">Debit</option>
              </Input>
            </Col>
            <Col sm="4">
              <DateRangePicker
                calendarInfoPosition="before"
                renderCalendarInfo={this.renderDatePresets}
                startDate={startDate}
                startDateId="FromstartDate"
                endDate={endDate}
                endDateId="FromendDate"
                onDatesChange={({ startDate, endDate }) =>
                  this.handleDateChange(startDate, endDate)
                }
                focusedInput={this.state.focusedInput}
                onFocusChange={focusedInput =>
                  this.setState({ focusedInput })
                }
                orientation={this.state.orientation}
                openDirection={this.state.openDirection}
                isOutsideRange={() => false}
                anchorDirection={"left"}
              />
            </Col>
          </Row>
          {activeList.map((transaction, idx) => {
            return (
              <Row className="w-100 justify-content-end mb-3 mt-3 cash-summary bg-primary p-4">
                <Col sm="12" className="text-center">
                  New Request
                    <br />A new credit request has been made by{" "}
                  {`${transaction.user_first_name} ${transaction.user_second_name}`}
                  , here are your options
                    <br />
                  <br />
                  <Button
                    color="success"
                    className="mr-2"
                    onClick={() =>
                      this.updateTransactionRequest(transaction.id, 2)
                    }
                  >
                    Accept
                    </Button>
                  <Button
                    color="danger"
                    onClick={() =>
                      this.updateTransactionRequest(transaction.id, 3)
                    }
                  >
                    Cancel
                    </Button>
                </Col>
              </Row>
            );
          })}

          <Row>
            <Col sm="4" className="offset-sm-8 p-0 pr-3 border-0">
              <InputGroup className="mb-3 w-100">
                <Input
                  type="text"
                  placeholder="Search"
                  autoComplete="searchText"
                  className="w-100"
                  onChange={e => {
                    this.handleSearch(e.target.value);
                  }}
                  value={searchText}
                />
              </InputGroup>
            </Col>
          </Row>
          {this.props.walletRequestList &&
            this.props.walletRequestList.aaData && (
              <CashTable
                orders={this.props.walletRequestList.aaData}
                totalData={this.props.walletRequestList.iTotalRecords}
                handlePagination={this.handlePagination}
              />
            )}
        </div>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    getWalletRequestList: params => dispatch(getWalletRequestList(params)),
    updateTransactionRequest: (request_id, params) =>
      dispatch(updateTransactionRequest(request_id, params))
  };
}

function mapStateToProps(state) {
  return {
    walletRequestList: state.wallet.walletRequestList
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(Wallet);
