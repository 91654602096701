import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import {Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row} from "reactstrap";

import MonthView from "./MonthView";
import {getDashboardData, sendFCMToken} from "../../actions/dashboard";
import EachChart from "./EachChart";
import {messaging} from "../../init-fcm";
import {getFCMKey} from "../../utils";
// import * as firebase from "firebase/app";
// import * as firebase from "firebase/app";
import firebase from 'firebase/app';


class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            branch_id: 0,
            dropdownOpen: false,
            citydropdownOpen: false,
            branch_name: "All branch",
            city_name: "All Cities",
            city_id: "0"
        };

        this.branchtoggle = this.branchtoggle.bind(this);
        this.citytoggle = this.citytoggle.bind(this);
        this.handleBranchChange = this.handleBranchChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        const {branch_id,city_id} = this.state;
        this.props.getDashboardData(branch_id,city_id);


    }

    citytoggle() {
        this.setState(prevState => ({
            citydropdownOpen: !prevState.citydropdownOpen
        }));
    }

    branchtoggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    handleBranchChange(branch_id, branch_name) {
        const {city_id} = this.state;
        this.setState({branch_id: branch_id, branch_name: branch_name});
        this.props.getDashboardData(branch_id,city_id);
    }

    handleCityChange(city_name, city_id) {
        this.setState({city_id: city_id, city_name: city_name},()=>{
            this.props.loggedInUser.branch_list.map((value) => {
                if (city_id === value.city_id)
                    this.handleBranchChange(value.branch_list[0].branch_id, value.branch_list[0].branch_name);
            });
        });
    }

    componentDidMount() {

        this.registerFCMReceiver();
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let firstCityName = branch_list[0].city_name;
                let firstCityId = branch_list[0].city_id;
                this.handleCityChange(firstCityName, firstCityId);
            }
        }
    }


    componentWillReceiveProps(nextProps) {
        if (this.props.loggedInUser && (this.props.loggedInUser.branch_list != nextProps.loggedInUser.branch_list)) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let firstCityName = branch_list[0].city_name;
                let firstCityId = branch_list[0].city_id;
                this.handleCityChange(firstCityName, firstCityId);
            }
        }
    }

    registerFCMReceiver() {

        let fcmToken = getFCMKey();
        if (firebase.messaging.isSupported())
            if (fcmToken === undefined || fcmToken.length !== 10) {
                Notification.requestPermission().then(async (permission) => {
                    if (permission === 'granted') {
                        const token = await messaging.getToken();
                        this.props.setFCMToken(token);
                    } else {
                        // console.log('Some features need this permission to work');
                    }
                });
            }
    }

    render() {
        let todayData = {};
        let weekData = {};
        let monthData = {};
        if (this.props.dashboardData) {
            if (
                this.props.dashboardData.today &&
                this.props.dashboardData.same_day_last_week
            ) {
                todayData.total = {
                    name: "Net Sales",
                    className: "each-chart total",
                    'today': Number(Math.round(this.props.dashboardData.today.total)),
                    'last week': Number(
                        Math.round(this.props.dashboardData.same_day_last_week.total)
                    ),
                    color: "#26a69a"
                };
                todayData.discount = {
                    name: "Discount",
                    className: "each-chart discount",
                    'today': Number(Math.round(this.props.dashboardData.today.discount)),
                    'last week': Number(
                        Math.round(this.props.dashboardData.same_day_last_week.discount)
                    ),
                    color: "#03a9f4"
                };
                todayData.bill_count = {
                    name: "Orders",
                    className: "each-chart orders",
                    'today': Math.round(this.props.dashboardData.today.bill_count),
                    'last week': Math.round(
                        this.props.dashboardData.same_day_last_week.bill_count
                    ),
                    color: "#25476a"
                };
                todayData.avg_value = {
                    name: "ABV",
                    className: "each-chart avg",
                    'today': Number(Math.round(this.props.dashboardData.today.avg_value)),
                    'last week': Number(
                        Math.round(this.props.dashboardData.same_day_last_week.avg_value)
                    ),
                    color: "#ffb300"
                };
                // vishwa change
                todayData.avg_delivery = {
                    name: "ADT",
                    className: "each-chart adt",
                    'today': Number(Math.round(this.props.dashboardData.today.avg_value)),
                    'last week': Number(
                        Math.round(this.props.dashboardData.same_day_last_week.avg_value)
                    ),
                    color: "#ffb300"
                };
                // vishwa change
                todayData.gross = {
                    name: "Gross Sales",
                    className: "each-chart gross",
                    'today': Number(Math.round(this.props.dashboardData.today.gross)),
                    'last week': Number(
                        Math.round(this.props.dashboardData.same_day_last_week.gross)
                    ),
                    color: "#8bc34a"
                };
            }

            if (
                this.props.dashboardData.this_week &&
                this.props.dashboardData.last_week
            ) {
                weekData.total = {
                    name: "Net Sales",
                    className: "each-chart total",
                    'today': Number(Math.round(this.props.dashboardData.this_week.total)),
                    'last week': Number(Math.round(this.props.dashboardData.last_week.total)),
                    color: "#26a69a"
                };
                weekData.discount = {
                    name: "Discount",
                    className: "each-chart discount",
                    'today': Number(
                        Math.round(this.props.dashboardData.this_week.discount)
                    ),
                    'last week': Number(
                        Math.round(this.props.dashboardData.last_week.discount)
                    ),
                    color: "#03a9f4"
                };
                weekData.bill_count = {
                    name: "Orders",
                    className: "each-chart orders",
                    'today': Math.round(this.props.dashboardData.this_week.bill_count),
                    'last week': Math.round(this.props.dashboardData.last_week.bill_count),
                    color: "#25476a"
                };
                weekData.avg_value = {
                    name: "ABV",
                    className: "each-chart avg",
                    'today': Number(
                        Math.round(this.props.dashboardData.this_week.avg_value)
                    ),
                    'last week': Number(
                        Math.round(this.props.dashboardData.last_week.avg_value)
                    ),
                    color: "#ffb300"
                };
                // vishwa change
                weekData.avg_delivery = {
                    name: "ABV",
                    className: "each-chart avg",
                    'today': Number(
                        Math.round(this.props.dashboardData.this_week.avg_value)
                    ),
                    'last week': Number(
                        Math.round(this.props.dashboardData.last_week.avg_value)
                    ),
                    color: "#ffb300"
                };
                //vishwa change
                weekData.gross = {
                    name: "Gross Sales",
                    className: "each-chart gross",
                    'today': Number(Math.round(this.props.dashboardData.this_week.gross)),
                    'last week': Number(Math.round(this.props.dashboardData.last_week.gross)),
                    color: "#8bc34a"
                };
            }
            if (
                this.props.dashboardData.this_month &&
                this.props.dashboardData.last_month
            ) {
                monthData.total = {
                    name: "Net Sales",
                    className: "each-month total",
                    current: Math.round(this.props.dashboardData.this_month.total),
                    before: Math.round(this.props.dashboardData.last_month.total),
                    color: "#26a69a"
                };
                monthData.discount = {
                    name: "Discount",
                    className: "each-month discount",
                    current: Math.round(this.props.dashboardData.this_month.discount),
                    before: Math.round(this.props.dashboardData.last_month.discount),
                    color: "#03a9f4"
                };
                monthData.bill_count = {
                    name: "Orders",
                    className: "each-month orders",
                    current: Math.round(this.props.dashboardData.this_month.bill_count),
                    before: Math.round(this.props.dashboardData.last_month.bill_count),
                    color: "#25476a"
                };
                monthData.avg_value = {
                    name: "ABV",
                    className: "each-month avg",
                    current: Math.round(this.props.dashboardData.this_month.avg_value),
                    before: Math.round(this.props.dashboardData.last_month.avg_value),
                    color: "#ffb300"
                };

                monthData.gross = {
                    name: "Gross Sales",
                    className: "each-month gross",
                    current: Math.round(this.props.dashboardData.this_month.gross),
                    before: Math.round(this.props.dashboardData.last_month.gross),
                    color: "#8bc34a"
                };
            }
        }
        const {branch_name,city_id,
            city_name} = this.state;

        let city_list = [];
        let branch_list = [];
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            this.props.loggedInUser.branch_list.map((item)=>{
                if(city_id===item.city_id)
                    branch_list = item.branch_list;
                city_list.push(item);
            });
        }
        return (
            <div className="animated fadeIn dashboard">
                {this.props.dashboardData && (
                    <Row className="m-0">

                        <Col md="3" className="bg-white border-left">
                            <Dropdown
                                isOpen={this.state.citydropdownOpen}
                                toggle={this.citytoggle}
                                className="branch-dropdown w-100 mb-2 mt-4"
                            >
                                <DropdownToggle caret className="w-100" color="primary">
                                    {city_name}
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    {city_list &&
                                    city_list.map((row, index) => {
                                        return (
                                            <DropdownItem
                                                className="w-100"
                                                onClick={() => {
                                                    this.handleCityChange(
                                                        row.city_name,
                                                        row.city_id
                                                    );
                                                }}
                                            >
                                                {row.city_name}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </Dropdown>


                            <Dropdown
                                isOpen={this.state.dropdownOpen}
                                toggle={this.branchtoggle}
                                className="branch-dropdown w-100 mb-2"
                            >
                                <DropdownToggle caret className="w-100" color="primary">
                                    {branch_name}
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    {branch_list &&
                                    branch_list.map((row, index) => {
                                        return (
                                            <DropdownItem
                                                className="w-100"
                                                onClick={() => {
                                                    this.handleBranchChange(
                                                        row.branch_id,
                                                        row.branch_name
                                                    );
                                                }}
                                            >
                                                {row.branch_name}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                            <div className="py-4 px-2">
                                <div className="mb-3">
                                    <h5 className="mb-0">MTD SALES</h5>
                                    <div className="text-muted f-sm">
                                        AS ON {moment().format("MMM DD")}
                                    </div>
                                </div>
                                {this.props.dashboardData.this_month && (
                                    <MonthView data={monthData.gross}/>
                                )}
                                {this.props.dashboardData.this_month && (
                                    <MonthView data={monthData.discount}/>
                                )}
                                {this.props.dashboardData.this_month && (
                                    <MonthView data={monthData.total}/>
                                )}
                                {this.props.dashboardData.this_month && (
                                    <MonthView data={monthData.bill_count}/>
                                )}
                                {this.props.dashboardData.this_month && (
                                    <MonthView data={monthData.avg_value}/>
                                )}
                            </div>
                        </Col>
                        <Col md="9" className="mt-3">
                            <Row className="bg-white mx-0 p-2 pt-4">
                                <Col sm="12">
                                    <Col sm="6" className=" p-0 float-left">
                                        <h5 className="mb-0">TODAY'S SALES</h5>
                                        <div className="text-muted f-sm">
                                            AS ON {moment().format("HH:mm a")}
                                        </div>
                                    </Col>

                                </Col>
                            </Row>
                            <Row className="bg-white mx-0 p-2 pt-1">
                                {this.props.dashboardData.today && (
                                    <EachChart data={todayData.gross}/>
                                )}
                                {this.props.dashboardData.today && (
                                    <EachChart data={todayData.total}/>
                                )}
                                {this.props.dashboardData.today && (
                                    <EachChart data={todayData.bill_count}/>
                                )}
                                {this.props.dashboardData.today && (
                                    <EachChart data={todayData.avg_value}/>
                                )}
                            </Row>
                            <Row className="bg-white mx-0 p-2">
                                <Col sm="12" className="mb-3">
                                    <h5 className="mb-0">WEEK'S SALES</h5>
                                    <div className="text-muted f-sm">
                                        AS ON {moment().format("HH:mm a")}
                                    </div>
                                </Col>
                                {this.props.dashboardData.this_week && (
                                    <EachChart data={weekData.gross}/>
                                )}
                                {this.props.dashboardData.this_week && (
                                    <EachChart data={weekData.total}/>
                                )}
                                {this.props.dashboardData.this_week && (
                                    <EachChart data={weekData.bill_count}/>
                                )}
                                {this.props.dashboardData.this_week && (
                                    <EachChart data={weekData.avg_value}/>
                                )}
                            </Row>
                        </Col>
                    </Row>
                )}
            </div>
        );
    }
}

function bindActions(dispatch) {
    return {
        getDashboardData: (branch,city_id) => dispatch(getDashboardData(branch,city_id)),
        setFCMToken: token => dispatch(sendFCMToken(token))
    };
}

function mapStateToProps(state) {
    return {
        dashboardData: state.dashboard.dashboardData,
        loggedInUser: state.commonData.loggedInUser
    };
}

export default connect(
    mapStateToProps,
    bindActions
)(Dashboard);
