import {
  START_LOADING,
  STOP_LOADING,
  SET_MESSAGE,
  SET_MESSAGE_TYPE,
  CLEAR_MESSAGE,
  AUTH_TOKEN,
  SET_SPINNER,
  SET_LOGGED_IN_USER,
  SET_MENU_LIST
} from "../actions/commonData";
const initialState = {
  loading: false,
  message: "",
  messageType: "success",

  spinnerActive: false
};
const commonData = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, loading: true };
    case STOP_LOADING:
      return { ...state, loading: false };
    case SET_MESSAGE:
      return { ...state, message: action.payload };
    case SET_MESSAGE_TYPE:
      return { ...state, messageType: action.payload };
    case CLEAR_MESSAGE:
      return { ...state, message: "" };
    case SET_SPINNER:
      return { ...state, spinnerActive: action.payload };
    case AUTH_TOKEN:
      return { ...state, tokenStatus: action.payload };
    case SET_LOGGED_IN_USER:
      return { ...state, loggedInUser: action.payload };
    case SET_MENU_LIST:
        return { ...state, menu_list: action.payload };      
    default:
      return state;
  }
};
export default commonData;
