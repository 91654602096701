import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../config";
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardImg,
  Modal,
  ModalBody
} from "reactstrap";

import { getProduct } from "../../actions/products";

class ProductPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.updateCart = this.updateCart.bind(this);
  }

  componentDidUpdate(previousProps, previousState) {
    const { productData, branch_id, showModal } = this.props;
    if (showModal && showModal !== previousProps.showModal) {
      // this.props.getProduct(productData.product_id, branch_id);
    }
  }

  updateCart(productMeta, type) {
    // console.log(productMeta, type);
    this.props.addtoCart(productMeta, type);
    this.forceUpdate();
  }

  render() {
    const productData = this.props.productData;
    let productPricing = productData.serving_size;
    const cartList = this.props.cartList;

    return (
      <Modal
        isOpen={this.props.showModal}
        fade={false}
        toggle={this.props.toggleModal}
        className="p-0 product-modal"
      >
        <ModalBody className="p-0">
          <Card inverse className="each-product-card">
            <CardImg
              width="100%"
              src={`${config.imgUrl}frontend/images/products/${
                this.props.productData.product_id
              }.jpg`}
              alt={this.props.productData.product_name}
            />
            <CardTitle className="pl-2">
              {this.props.productData.product_name}
            </CardTitle>
          </Card>
          <div className="serving-list p-2 pl-4 pr-4 mt-2 mb-4">
            {!productPricing && (
              <div className="alert alert-danger w-100">
                <strong>Note:</strong> Choose customer and address to add items
                to cart.
              </div>
            )}
            {productPricing &&
              productPricing.length > 0 &&
              productPricing.map((price, index) => {
                return (
                  <Row className="m-0 mb-2 p-4 pt-1 each-serving w-100">
                    <Col sm="8" className="pl-2 pr-0 pb-1">
                      {price.serving_size_name}
                      <br />
                      <b>
                        {price.product_price}
                      </b>
                    </Col>
                    <Col sm="4" className="p-1">
                      <span
                        className="remove-qty p-1 pl-3 pr-3"
                        onClick={() => {
                          this.updateCart(
                            {
                              product_id: this.props.productData.product_id,
                              product_name: this.props.productData.product_name,
                              serving_size_name: price.serving_size_name,
                              price: price.product_price,
                              product_price_id: price.product_price_id
                            },
                            "sub"
                          );
                        }}
                      >
                        -
                      </span>
                      <span className="m-2 p-1 pl-2 pr-2 font-weight-bolder">
                        {cartList && cartList[price.product_price_id]
                          ? cartList[price.product_price_id]
                          : "0"}
                      </span>
                      <span
                        className="add-qty p-1 pl-3 pr-3"
                        onClick={() => {
                          this.updateCart(
                            {
                              product_id: this.props.productData.product_id,
                              product_name: this.props.productData.product_name,
                              serving_size_name: price.serving_size_name,
                              price: price.product_price,
                              product_price_id: price.product_price_id
                            },
                            "add"
                          );
                        }}
                      >
                        +
                      </span>
                    </Col>
                  </Row>
                );
              })}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
function bindActions(dispatch) {
  return {};
}

function mapStateToProps(state) {
  return {};
}
export default connect(
  mapStateToProps,
  bindActions
)(ProductPrice);
