import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Input,
  Label
} from "reactstrap";

import { createProduct } from "../../actions/products";

class NewProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productModal: false,
      productName: "",
      category: "",
      description: "",
      isVeg: 1,
      product_type: 1,
      isTaxable: 0,
      hasPackingCharge: 0
      // rejectReason: ""
    };

    this.toggleProductModal = this.toggleProductModal.bind(this);
    this.submitProduct = this.submitProduct.bind(this);
  }

  toggleProductModal() {
    this.setState(prevState => ({
      productModal: !prevState.productModal
    }));
  }
  submitProduct() {
    let data = {};
    const {
      productName,
      category,
      description,
      product_type,
      isVeg,
      isTaxable,
      hasPackingCharge
    } = this.state;
    if (productName === "") {
      return;
    }
    data = {
      product_name: productName,
      product_category: parseInt(category),
      product_type: parseInt(product_type),
      product_desc: description,
      is_veg: parseInt(isVeg),
      taxable: parseInt(isTaxable),
      packing_charges: parseInt(hasPackingCharge)
    };
    this.props.createProduct(data);
    this.setState({
      productModal: false,
      productName: "",
      category: "",
      description: "",
      isVeg: 1,
      product_type: 1,
      isTaxable: 0,
      hasPackingCharge: 0
    });
  }
  render() {
    const {
      productName,
      category,
      description,
      isVeg,
      product_type,
      isTaxable,
      hasPackingCharge
    } = this.state;
    return (
      <div className={`animated fadeIn ${this.props.className}`}>
        <Button
          color="primary"
          className="place-order w-100"
          onClick={this.toggleProductModal}
        >
          New Product
        </Button>
        <Modal
          isOpen={this.state.productModal}
          fade={false}
          toggle={this.toggleProductModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleProductModal}>
            Create Product
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Input
                type="text"
                className="mb-3"
                placeholder="Product Name"
                autoComplete="productName"
                onChange={e => {
                  this.setState({ productName: e.target.value });
                }}
                value={productName}
              />

              <Input
                type="select"
                className="mb-3"
                name="category"
                id="category"
                onChange={e => {
                  this.setState({ category: e.target.value });
                }}
              >
                 <option value="">
                        Choose a category
                      </option>
                {this.props.categories &&
                  this.props.categories.length > 0 &&
                  this.props.categories.map((row, index) => {
                    return (
                      <option value={row.category_id}>
                        {row.category_name}
                      </option>
                    );
                  })}
              </Input>

              <Input
                type="textarea"
                className="mb-3"
                placeholder="Product Description"
                autoComplete="description"
                onChange={e => {
                  this.setState({ description: e.target.value });
                }}
                value={description}
              />

              <Input
                type="select"
                className="mb-3"
                name="isVeg"
                id="isVeg"
                onChange={e => {
                  this.setState({ isVeg: e.target.value });
                }}
              >
                <option value="1">Veg</option>
                <option value="0">Non veg</option>
                <option value="2">Egg</option>
              </Input>

              <Input
                type="select"
                className="mb-3"
                name="productType"
                id="product_type"
                onChange={e => {
                  this.setState({ product_type: e.target.value });
                }}
              >
                <option value="1">Food</option>
                <option value="2">Liqour</option>
              </Input>
            </FormGroup>
            <FormGroup check>
              <div>
                <Label check>
                  <Input type="checkbox" value="1"  onChange={e => {
                  this.setState({ isTaxable: e.target.value });
                }}
                /> Is the Product Taxable?
                </Label>
              </div>
              <div>
                <Label check>
                  <Input type="checkbox" value="1"  onChange={e => {
                  this.setState({ hasPackingCharge: e.target.value });
                }} /> Packing Charges Apply?
                </Label>
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" outline onClick={this.toggleProductModal}>
              Cancel
            </Button>{" "}
            <Button
              color="primary"
              onClick={() => {
                this.submitProduct();
              }}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    createProduct: data => dispatch(createProduct(data))
  };
}
function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  bindActions
)(NewProduct);
