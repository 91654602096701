import React, {Component} from "react";
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {createProductOption, getProductOptionList, uploadProductOptionImage} from "../../actions/products";
import {connect} from "react-redux";
import {toast} from "react-toastify";

const options = {
    autoClose: 2000,
    // closeButton:,
    type: toast.TYPE.INFO,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_LEFT,
    pauseOnHover: true
    // progress: 0.2
};

class ServingSize extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageUploadModal: false,
            createOptionModal: false,
            isEditable: true,
            selectedOption: 0,
            selectedOptionName: ""
        }
        this.toggleBannerModal = this.toggleBannerModal.bind(this);
        this.submitImageUpload = this.submitImageUpload.bind(this);
        this.onImageUploaded = this.onImageUploaded.bind(this);
        this.toggleCreateOptionModal = this.toggleCreateOptionModal.bind(this);
        this.toggleImageUploadModal = this.toggleImageUploadModal.bind(this);
        this.props.getProductOptionList();
    }

    toggleBannerModal(editable, optionId, selectedOptionName) {
        this.setState(prevState => ({
            BannerModal: !prevState.BannerModal,
            isEditable: editable,
            selectedOption: optionId,
            selectedOptionName: selectedOptionName
        }));
    }

    toggleImageUploadModal(option_id, option_name) {
        this.setState(prevState => ({
            imageUploadModal: !prevState.imageUploadModal,
            selectedOption: option_id,
            selectedOptionName: option_name
        }));
    }

    toggleCreateOptionModal(optionId, selectedOptionName) {
        this.setState(prevState => ({
            createOptionModal: !prevState.createOptionModal,
            selectedOption: optionId,
            selectedOptionName: selectedOptionName
        }));
    }

    submitImageUpload() {
        if (this.state.image === null)
            toast.error("Please choose a image", options)
        else {
            this.props.uploadProductOptionImage(this.state.selectedOption, this.state.image);
            this.toggleImageUploadModal(this.state.selectedOption, this.state.selectedOptionName)
        }
    }

    submitCreateOption() {
        if (this.state.selectedOptionName.length < 4)
            toast.error("Option name is too short", options)
        else {
            this.props.createProductOption(this.state.selectedOptionName);
            this.toggleCreateOptionModal(this.state.selectedOption, this.state.selectedOptionName)
        }
    }

    onImageUploaded(image) {
        if (!image) {
            this.setState({imageError: 'Please select image.', image: null});
            return false;
        }
        if (!image.type.match(/png/)) {
            this.setState({imageError: 'Please select valid image.', image: null});
            return false;
        }
        if (image.size > 2000000) {
            this.setState({imageError: 'Image size too big', image: null});
            return false;
        }
        this.setState({
            image: image,
            imageError: ''
        });
    }


    render() {

        const productServingList = this.props.productServingList;


        return (
            <div className="animated fadeIn dashboard">
                <Button
                    color="primary"
                    className="d-inline-block mr-2"
                    onClick={() => {
                        this.toggleCreateOptionModal(0, '')
                    }}
                    outline
                >
                    Create Serving Size
                </Button>


                {productServingList && productServingList.data && productServingList.data.map(item => (
                    <div className="banner-row border-top pt-2 mt-2">
                        <div className="row ">
                            <div className="col-md-1">
                                <img className="img-fluid" src={`${item.image_url}`}
                                     alt=""/>
                            </div>
                            <div className="col-md-9">
                                <div className="can-toggle can-toggle--size-small mt-4">
                                    <input id={`${item.serving_size_id}`} type="checkbox"
                                           defaultChecked={item.serving_size_status==1}
                                           disabled
                                    />
                                    <label htmlFor={`${item.serving_size_id}`}>
                                        <div className="can-toggle__switch" data-checked="On"
                                             data-unchecked="Off"></div>
                                        <div className="can-toggle__label-text">
                                            <strong>{item.serving_size_name}</strong></div>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <button className="btn btn-sm btn-warning mt-4" onClick={() => {

                                    this.toggleImageUploadModal(item.serving_size_id, item.serving_size_name)
                                }}>Update
                                    Image
                                </button>
                            </div>
                        </div>
                    </div>
                ))
                }
                <Modal
                    isOpen={this.state.createOptionModal}
                    fade={false}
                    className={this.props.className}
                >
                    <ModalHeader>Create Option</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>Serving Size name</Label>
                            <Input
                                type="text"
                                className="mb-3 form-control"
                                placeholder="Serving Size Name"
                                onChange={(e) => {
                                    this.setState({selectedOptionName: e.target.value});
                                }}
                                value={this.state.selectedOptionName}

                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" outline onClick={() => {
                            this.toggleCreateOptionModal(0, "")
                        }}>
                            Cancel
                        </Button>

                        <Button color="primary" onClick={() => {
                            this.submitCreateOption()
                        }}
                        >Save
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal
                    isOpen={this.state.imageUploadModal}
                    fade={false}
                    className={this.props.className}
                >
                    <ModalHeader>Update Option Image</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>Serving Size name</Label>
                            <Input
                                disabled
                                type="text"
                                className="mb-3 form-control"
                                placeholder="Serving Size Name"
                                value={this.state.selectedOptionName}

                            />
                            <Label>Upload Image</Label>
                            <Input
                                type="file"
                                className="mb-3"
                                onChange={(e) => this.onImageUploaded(e.target.files[0])}
                            />
                            {/*<p>Multiselect</p>*/}
                            {/*<MultiSelect options={} value={} labelledBy={}*/}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" outline onClick={() => {
                            this.toggleImageUploadModal(true, 0, "")
                        }}>
                            Cancel
                        </Button>

                        <Button
                            color="primary" onClick={() => {
                            this.submitImageUpload()
                        }}
                        >
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}


function bindActions(dispatch) {
    return {
        getProductOptionList: () => dispatch(getProductOptionList()),
        uploadProductOptionImage: (optionId, image) => dispatch(uploadProductOptionImage(optionId, image)),
        createProductOption: (optionName) => dispatch(createProductOption(optionName))
    };
}

function mapStateToProps(state) {
    return {
        productServingList: state.products.productServingList,
    };
}

export default connect(
    mapStateToProps,
    bindActions
)(ServingSize);

