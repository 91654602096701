import React, {Component} from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import moment from "moment";
import {DateRangePicker} from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import Highcharts from "highcharts";
import _ from "lodash";
import drilldown from "highcharts/modules/drilldown.js";
import {
    Badge,
    Button,
    ButtonGroup,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
} from "reactstrap";
import {getAnalyticsCategory} from "../../actions/orders";

drilldown(Highcharts);

class CategoryAnalyticsCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().subtract(7, "d"),
            endDate: moment(),
            orderStatus: 0,
            perPage: 10,
            offset: 0,
            searchText: "",
            preset: "",
            focusedInput: false,
            dropdownOpen: false,
            branch_id: 0,
            branch_name: "All branch",
            chartMode: "billCount",
            cityDropdown: false,
            city_id: "0",
            city_name: "All Cities"
        };

        this.filterOrders = this.filterOrders.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.renderDatePresets = this.renderDatePresets.bind(this);
        this.branchtoggle = this.branchtoggle.bind(this);
        this.handleBranchChange = this.handleBranchChange.bind(this);
        this.showByBillCount = this.showByBillCount.bind(this);
        this.showByTotal = this.showByTotal.bind(this);
        this.handlePayOnDeliveryTableData = this.handlePayOnDeliveryTableData.bind(
            this
        );
        this.handlePaymentSourceTableData = this.handlePaymentSourceTableData.bind(
            this
        );
        this.citytoggle = this.citytoggle.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);

        const {
            branch_id,
            startDate,
            endDate,
            perPage,
            searchText,
            orderStatus,
            city_id
        } = this.state;
        let default_params = {
            branch_id: branch_id,
            status: orderStatus,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id: city_id
        };
        this.props.getAnalyticsCategory(default_params);
    }

    componentDidMount() {
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let firstCityName = branch_list[0].city_name;
                let firstCityId = branch_list[0].city_id;
                this.handleCityChange(firstCityName, firstCityId);
            }
        }
    }


    componentWillReceiveProps(nextProps) {
        if (this.props.loggedInUser && (this.props.loggedInUser.branch_list != nextProps.loggedInUser.branch_list)) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let firstCityName = branch_list[0].city_name;
                let firstCityId = branch_list[0].city_id;
                this.handleCityChange(firstCityName, firstCityId);
            }
        }
    }

    showByBillCount() {
        this.setState({chartMode: "billCount"});
    }

    showByTotal() {
        this.setState({chartMode: "total"});
    }

    citytoggle() {
        this.setState(prevState => ({
            cityDropdown: !prevState.cityDropdown
        }));
    }
    branchtoggle() {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }

    handleDateChange(startDate, endDate) {
        const {branch_id, orderStatus, perPage, offset, searchText,city_id} = this.state;
        this.setState({startDate: startDate, endDate: endDate});
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id:city_id
        };
        this.props.getAnalyticsCategory(params);
    }

    handleCityChange(city_name, city_id) {
        this.setState({city_id: city_id, city_name: city_name}, () => {
            this.props.loggedInUser.branch_list.map((value) => {
                if (city_id === value.city_id)
                    this.handleBranchChange(value.branch_list[0].branch_id, value.branch_list[0].branch_name);
            });
        });
    }

    handleBranchChange(branch_id, branch_name) {
        const {
            orderStatus,
            perPage,
            offset,
            searchText,
            startDate,
            endDate,
            city_id
        } = this.state;
        this.setState({branch_id: branch_id, branch_name: branch_name});
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id: city_id
        };
        this.props.getAnalyticsCategory(params);
    }

    filterOrders(bill_status) {
        const {branch_id, startDate, endDate, perPage, searchText,city_id} = this.state;
        this.setState({orderStatus: bill_status});
        let params = {
            branch_id: branch_id,
            status: bill_status,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id:city_id
        };
        this.props.getAnalyticsCategory(params);
    }

    renderDatePresets() {
        return (
            <div className="date_custom_presets">
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment(), moment());
                    }}
                >
                    Today
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().subtract(1, "d"), moment().subtract(1, "d"));
                        this.setState({focusedInput: false});
                    }}
                >
                    Yesterday
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().subtract(7, "d"), moment());
                        this.setState({focusedInput: false});
                    }}
                >
                    Last 7 days
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().subtract(30, "d"), moment());
                        this.setState({focusedInput: false});
                    }}
                >
                    Last 30 days
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().startOf("month"), moment());
                        this.setState({focusedInput: false});
                    }}
                >
                    This month
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(
                            moment().subtract(1, "months").startOf("month"),
                            moment().subtract(1, "months").endOf("month")
                        );
                        this.setState({focusedInput: false});
                    }}
                >
                    Last month
                </Button>
                <Button className="w-100  mb-2" color="info">
                    Custom Range
                </Button>
            </div>
        );
    }

    handleChartData() {
        const {chartMode} = this.state;
        const {
            break_up: breakUpData,
            pod_data: podData,
        } = this.props.AnalyticsCategory;
        let allData = [];
        if (!_.isEmpty(breakUpData)) {
            allData = [...breakUpData];
            allData.push({
                bill_count: podData.count,
                total: podData.value,
                payment_mode_value: "Pay on Delivery",
            });
        }
        const chartData = !_.isEmpty(allData)
            ? allData.map((data) => ({
                name: _.startCase(_.lowerCase(data.payment_mode_value)),
                y: chartMode === "billCount" ? +data.bill_count : +data.total,
            }))
            : [];

        const chartConfig = {
            chart: {
                type: "column",
            },
            accessibility: {
                announceNewData: {
                    enabled: true,
                },
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return this.y.toLocaleString("en-IN", {
                                maximumFractionDigits: 2,
                                currency: "INR",
                            });
                        },
                    },
                },
            },
            legend: {
                enabled: false,
            },
            title: {
                text: "Payment Source",
            },
            xAxis: {
                type: "category",
            },
            yAxis: {
                title: {
                    text: _.startCase(chartMode),
                },
            },
            series: [
                {
                    name: "Sources",
                    colorByPoint: true,
                    data: chartData,
                },
            ],
            credits: {
                enabled: false,
            },
        };
        if (!_.isEmpty(allData) && this.chartElement)
            Highcharts.chart("paymentSourceChart", chartConfig);
    }

    handlePayOnDeliveryTableData() {
        const {pod_data: podData} = this.props.AnalyticsCategory;

        const headers = ["Payment Source", "Bill Count", "Collected Amount", "Uncollected Amount", "Banking Amount"];
        return !_.isEmpty(podData) ? (
            <div className="table-holder w-100">
                <h5 className="table-header-analytics">For Unit</h5>
                <Table responsive className="my-table">
                    <thead>
                    <tr className="my-table-heading">
                        {_.map(headers, (header) => (
                            <td className="font-weight-bold">{header}</td>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Payment on Delivery</td>
                        <td>
                            {podData.count.toLocaleString("en-IN", {
                                maximumFractionDigits: 2,
                                currency: "INR",
                            })}
                        </td>
                        <td>
                            {podData.collected_amount.toLocaleString("en-IN", {
                                maximumFractionDigits: 2,
                                currency: "INR",
                            })}
                        </td>
                        <td>
                            {podData.uncollected_amount.toLocaleString("en-IN", {
                                maximumFractionDigits: 2,
                                currency: "INR",
                            })}
                        </td>
                        <td>
                            {podData.banking_amount.toLocaleString("en-IN", {
                                maximumFractionDigits: 2,
                                currency: "INR",
                            })}
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        ) : (
            []
        );
    }

    handlePaymentSourceTableData() {
        const {break_up: breakUpData} = this.props.AnalyticsCategory;

        const headers = [
            "Payment Source",
            "Bill Count",
            "Sales Value",
            "Percentage Share",
        ];
        const totalBillCount = _.reduce(
            breakUpData,
            (res, p) => res + +p.bill_count,
            0
        ).toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            currency: "INR",
        });
        const totalSalesValue = _.reduce(
            breakUpData,
            (res, p) => res + +p.total,
            0
        ).toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            currency: "INR",
        });

        const totalPercentageShare = _.reduce(
            breakUpData,
            (res, p) => res + +p["%_share"],
            0
        ).toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            currency: "INR",
        });

        return !_.isEmpty(breakUpData) ? (
            <div className="table-holder w-100">
                <h5 className="table-header-analytics">Break Up</h5>
                <Table className="my-table">
                    <thead>
                    <tr className="my-table-heading">
                        {_.map(headers, (header) => (
                            <td className="font-weight-bold">{header}</td>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {_.map(breakUpData, (p) => (
                        <tr>
                            <td>{_.startCase(_.lowerCase(p.payment_mode_value))}</td>
                            <td>
                                {parseFloat(p.bill_count, 10).toLocaleString("en-IN", {
                                    maximumFractionDigits: 2,
                                    currency: "INR",
                                })}
                            </td>
                            <td>
                                {parseFloat(p.total, 10).toLocaleString("en-IN", {
                                    maximumFractionDigits: 2,
                                    currency: "INR",
                                })}
                            </td>
                            <td>{p["%_share"]}%</td>
                        </tr>
                    ))}
                    <tr>
                        <td className="font-weight-bold">Total</td>
                        <td className="font-weight-bold">{totalBillCount}</td>
                        <td className="font-weight-bold">{totalSalesValue}</td>
                        <td className="font-weight-bold">{totalPercentageShare}%</td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        ) : (
            []
        );
    }

    render() {
        const {startDate, endDate, branch_name, orderStatus,city_id,city_name} = this.state;

        let orderStatusList = [];
        if (this.props.AnalyticsCategory && this.props.AnalyticsCategory.meta) {
            orderStatusList = this.props.AnalyticsCategory.meta;
        }
        let statusList = {
            ORDER_TEMP: "Temp",
            ORDER_ONLINE_PAYMENT_PROCESSING: "Payment Pending",
            ORDER_PLACED: "New Order",
            ORDER_ACCEPTED: "In Process",
            ORDER_PACKED: "Ready",
            ORDER_ASSIGNED: "Assigned",
            ORDER_DISPATCHED: "Dispatched",
            ORDER_DELIVERED: "Delivered",
            ORDER_REJECTED: "Rejected",
            ORDER_CANCELLED: "Canceled",
        };
        let city_list = [];
        let branch_list = [];
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {

            this.props.loggedInUser.branch_list.map((item)=>{
                if(city_id===item.city_id)
                    branch_list = item.branch_list;
                city_list.push(item);
            });
        }
        let all_branch_outline = true;
        if (orderStatus === 0) {
            all_branch_outline = false;
        }

        this.handleChartData();

        return (
            <div className="animated fadeIn">
                <Row className="ml-2 mr-2 mt-2 orders">
                    <Col md="3" lg="3" xl="2" className="p-2 orders-side d-none">
                        <hr/>
                        <label className="filter-label">Order History</label>
                        <Button
                            color="primary"
                            className="w-100 text-left orderStatusBtn"
                            outline={all_branch_outline}
                            onClick={() => {
                                this.filterOrders(0);
                            }}
                        >
                            All Orders
                            <Badge className="float-right ml-1 p-1 orderCount">
                                {this.props.AnalyticsCategory &&
                                this.props.AnalyticsCategory.order_list &&
                                this.props.AnalyticsCategory.order_list.iTotalRecords}
                            </Badge>
                        </Button>
                        {orderStatusList &&
                        orderStatusList.length > 0 &&
                        orderStatusList.map((element, index) => {
                            let outline =
                                element.bill_status !== this.state.orderStatus ? true : false;
                            return (
                                <Button
                                    color="primary"
                                    className="w-100 text-left orderStatusBtn"
                                    outline={outline}
                                    onClick={() => {
                                        this.filterOrders(element.bill_status);
                                    }}
                                >
                                    {statusList[element.order_status_value]}
                                    <Badge className="float-right ml-1 p-1 orderCount">
                                        {element.bill_count}
                                    </Badge>
                                </Button>
                            );
                        })}
                    </Col>

                    <Col className="orders-main px-3">
                        <Row className="">
                            <div class="p-3 today-side col-sm-4 col-md-3 col-lg-2 border-right">

                                <Dropdown
                                    isOpen={this.state.cityDropdown}
                                    toggle={this.citytoggle}
                                    className="place-order w-100 mb-3"
                                >
                                    <DropdownToggle caret className="w-100" color="primary">
                                        {city_name}
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        {city_list &&
                                        city_list.map((row, index) => {
                                            return (
                                                <DropdownItem
                                                    key={index}
                                                    onClick={() => {
                                                        this.handleCityChange(
                                                            row.city_name,
                                                            row.city_id
                                                        );
                                                    }}
                                                >
                                                    {row.city_name}
                                                </DropdownItem>
                                            );
                                        })}
                                    </DropdownMenu>
                                </Dropdown>


                                <Dropdown
                                    isOpen={this.state.dropdownOpen}
                                    toggle={this.branchtoggle}
                                    className="place-order w-100 mb-3"
                                >
                                    <DropdownToggle caret className="w-100" color="primary">
                                        {branch_name}
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        {branch_list &&
                                        branch_list.map((row, index) => {
                                            return (
                                                <DropdownItem
                                                    key={index}
                                                    onClick={() => {
                                                        this.handleBranchChange(
                                                            row.branch_id,
                                                            row.branch_name
                                                        );
                                                    }}
                                                >
                                                    {row.branch_name}
                                                </DropdownItem>
                                            );
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                                <h5 className="nav-header">Navigation</h5>
                                <ul className="nav flex-column ul-hover-active">
                                    <li className="nav-link px-0">
                                        <NavLink to="/analytics">Product Sales</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/category_analytics">Payment Source</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/time_analytics">Sales By Time</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/partner_analytics">Partner Analytics</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/trends_analytics">Sales Analytics</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/customer_analytics">Customer Analytics</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div class="today-main py-3 col-sm-8 col-md-9 col-lg-10">
                                <Row>
                                    <Col lg="4" md="6" className="px-3 mb-3">
                                        <DateRangePicker
                                            calendarInfoPosition="before"
                                            renderCalendarInfo={this.renderDatePresets}
                                            startDate={startDate}
                                            minimumNights={0}
                                            startDateId="FromstartDate"
                                            endDate={endDate}
                                            endDateId="FromendDate"
                                            onDatesChange={({startDate, endDate}) => {
                                                if (!startDate) {
                                                    startDate = endDate;
                                                }

                                                if (!endDate) {
                                                    endDate = startDate;
                                                }
                                                return this.handleDateChange(startDate, endDate);
                                            }}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={(focusedInput) =>
                                                this.setState({focusedInput})
                                            }
                                            orientation={this.state.orientation}
                                            openDirection={this.state.openDirection}
                                            isOutsideRange={() => false}
                                            anchorDirection={"left"}
                                        />
                                    </Col>
                                    <Col lg="4" md="6" className="px-3 mb-3 w-100 h-35">
                                        <ButtonGroup className="check-btn w-100 custom-btn">
                                            <Button
                                                active={this.state.chartMode === "billCount"}
                                                onClick={this.showByBillCount}
                                            >
                                                By Bill Count
                                            </Button>
                                            <Button
                                                active={this.state.chartMode === "total"}
                                                onClick={this.showByTotal}
                                            >
                                                By Total
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                                <Row className="mb-5">
                                    <div
                                        id="paymentSourceChart"
                                        style={{width: "100%"}}
                                        ref={(el) => (this.chartElement = el)}
                                    />
                                </Row>
                                <Row>{this.handlePayOnDeliveryTableData()}</Row>
                                <Row>{this.handlePaymentSourceTableData()}</Row>
                                <div className="alert alert-primary new-alert mt-3">
                                    <strong className="mr-1">Note: </strong>{" "}
                                    <span> Only Completed orders are visible here</span>
                                </div>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

function bindActions(dispatch) {
    return {
        getAnalyticsCategory: (params) => dispatch(getAnalyticsCategory(params)),
    };
}

function mapStateToProps(state) {
    return {
        AnalyticsCategory: state.orders.AnalyticsCategory,
        loggedInUser: state.commonData.loggedInUser,
    };
}

export default connect(mapStateToProps, bindActions)(CategoryAnalyticsCategory);
