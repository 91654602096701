import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Badge,
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Input,
  Card,
  CardTitle,
  CardImg,
  CardImgOverlay
} from "reactstrap";

import {
  getBillingCustomerByPhone,
  updateCustomerName,
  getBillingCustomerAddress
} from "../../actions/billing";
import BranchSelection from "./BranchSelection";
import AddAddress from "./AddAddress";

class BillingCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branch_id: this.props.branch_id,
      customer_phone: "",
      customer_name: "",
      branch_address: "",
      lat: "",
      lng: "",
      selectedAddress: "",
      selectedCustomerLocation: {},
      showModal: false,
      addressModal: false
    };
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.updateCustomerName = this.updateCustomerName.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleAddressModal = this.toggleAddressModal.bind(this);
    this.handleBranchChange = this.handleBranchChange.bind(this);
  }

  toggleModal() {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  }

  toggleAddressModal() {
    this.setState(prevState => ({
      addressModal: !prevState.addressModal
    }));
  }
  componentDidUpdate(prevProps) {
    const { customer } = this.props;
    if (customer !== prevProps.customer) {
      this.setState({
        customer_name: customer.customer_first_name,
        customer_phone: customer.customer_phone
      });
    }
  }

  updateCustomerName(name) {
    // console.log(name);
    const { customer } = this.props;
    const { customer_phone } = this.state;
    this.props.updateCustomerName(customer.customer_id, name, customer_phone);
  }

  handlePhoneChange(phone) {
    this.setState({ customer_phone: phone });
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (!phone.match(phoneno)) {
      return;
    }
    // console.log(phone);
    this.props.getBillingCustomerByPhone(phone);
    this.toggleModal();
  }

  handleBranchChange(branch_id, selectedCustomerLocation) {
    const { customer } = this.props;
    if (branch_id) {
      this.props.getBillingCustomerAddress(customer.customer_id, branch_id);
      this.props.handleBranchChange(branch_id, "");
      // this.forceUpdate();
      this.setState({ selectedCustomerLocation });
    }
  }

  getAddressType(address_type) {
    switch (parseInt(address_type)) {
      case 1:
        return "Home";
      case 2:
        return "Office";
      default:
        return "Others";
    }
  }

  render() {
    const {
      customer_phone,
      customer_name,
      selectedAddress,
      selectedCustomerLocation
    } = this.state;
    const { customer, customerAddress } = this.props;
    // console.log(customerAddress);
    // console.log(this.props.loggedInUser);
    return (
      <div className="billing-left-scroll">
        <div
          className="back pt-4 pr-4 pl-4 pb-2  bg-dark-grey"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fas fa-arrow-circle-left mr-1" /> Back to Dashboard
        </div>
        <div className="bg-dark-grey pt-2 pr-4 pl-4 pb-4">
          <h5>Customer Details</h5>
          <Input
            type="text"
            className="mb-3 mt-4"
            placeholder="Customer Phone"
            autoComplete="customer_phone"
            onChange={e => {
              this.handlePhoneChange(e.target.value);
            }}
            tabIndex="0"
            value={customer_phone}
          />

          <Input
            type="text"
            className="mb-3"
            placeholder="Customer Name"
            autoComplete="customer_name"
            required="required"
            onChange={e => {
              this.setState({ customer_name: e.target.value });
            }}
            value={customer_name}
          />
          <Button
            className="w-75 load-customer no-radius"
            onClick={() => {
              this.updateCustomerName(customer_name);
            }}

            color="success"
          >
            Update Name
          </Button>
          <Button
            className="w-25 load-customer no-radius"
            onClick={() => {
              this.toggleModal()
            }}

            color="warning"
          >
            <i className="fa fa-map-marker"></i>
          </Button>
        </div>
        <div className="addresses p-4 bg-white mb-2">
          {customerAddress.map((row, index) => {
            return (
              <div className="each-address mb-2" key={row.address_id}>
                <div className="title font-weight-bold p-2 ">
                  {this.getAddressType(row.address_type)}
                </div>
                <div className="address p-2 pt-0 text-muted small">
                  {row.address_text}
                  <br />
                  {row.google_address}
                </div>
                <Button
                  className={`w-100 select-address bg-white ${
                    selectedAddress == row.address_id ? "selected" : ""
                    }`}
                  onClick={() => {
                    this.setState({ selectedAddress: row.address_id });
                    this.props.handleAddressChange(row.address_id);
                    this.props.handleCustomerChange(customer.customer_id);
                  }}
                >
                  {selectedAddress == row.address_id ? "Selected" : "Select"}
                </Button>
              </div>
            );
          })}
        </div>
        {this.props.branch_id && (
          <Button
            color="danger"
            className="w-100 add-address mt-4"
            onClick={() => {
              this.toggleAddressModal();
            }}
          >
            Add Address
          </Button>
        )}
        <AddAddress
          showModal={this.state.addressModal}
          toggleModal={this.toggleAddressModal}
          customer={customer}
          branch_id={this.props.branch_id}
          posMeta={this.props.posMeta}
          selectedAddress={selectedCustomerLocation}

        />
        <BranchSelection
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          posMeta={this.props.posMeta}
          handleBranchChange={this.handleBranchChange}
        />
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    getBillingCustomerByPhone: phone =>
      dispatch(getBillingCustomerByPhone(phone)),
    updateCustomerName: (customer_id, name, phone) =>
      dispatch(updateCustomerName(customer_id, name, phone)),
    getBillingCustomerAddress: (customer_id, branch_id) =>
      dispatch(getBillingCustomerAddress(customer_id, branch_id))
  };
}

function mapStateToProps(state) {
  return {
    loggedInUser: state.commonData.loggedInUser,
    customer: state.billing.customer,
    customerAddress: state.billing.customerAddress
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(BillingCustomer);
