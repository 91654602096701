import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Input
} from "reactstrap";

import { updateOrder, getDeliveryGuys } from "../../actions/orders";

class OrderEscalationsActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rejectModal: false,
      assignModal: false,
      orderStatus: "",
      rejectReason: 0,
      deliveryBoy: "",
      rejectError: false
    };

    this.toggleRejectModal = this.toggleRejectModal.bind(this);
    this.toggleAssignModal = this.toggleAssignModal.bind(this);
    this.assignDeliveryGuy = this.assignDeliveryGuy.bind(this);
    this.setRejectReason = this.setRejectReason.bind(this);
  }

  toggleAssignModal() {
    this.props.getDeliveryGuys(this.props.order.branch_id);
    this.setState(prevState => ({
      assignModal: !prevState.assignModal
    }));
  }

  toggleRejectModal() {
    this.setState(prevState => ({
      rejectModal: !prevState.rejectModal
    }));
  }

  assignDeliveryGuy() {
    this.props.updateOrder(
      6,
      this.props.order.bill_id,
      "",
      this.state.deliveryBoy,
      this.props.filterParams
    );
    this.setState({ assignModal: false });
  }

  setRejectReason() {
    if (!this.state.rejectReason) {
      this.setState({ rejectError: true });
      return;
    }
    this.props.updateOrder(
      9,
      this.props.order.bill_id,
      this.state.rejectReason,
      "",
      this.props.filterParams
    );
    this.setState({ rejectError: false, rejectModal: false });
  }
  renderActions(order) {
    let status = parseInt(order.bill_status);
    console.log(this.props);
    switch (status) {
      case 1:
        return (
          <div>
            <Button color="primary" outline disabled>
              Temp
            </Button>
          </div>
        );
      case 2:
        return (
          <div>
            <Button color="primary" outline disabled>
              Payment Pending
            </Button>
          </div>
        );
      case 3:
        return (
          <div>
            <Button
              color="success"
              onClick={() => {
                this.props.updateOrder(
                  4,
                  order.bill_id,
                  "",
                  "",
                  this.props.filterParams
                );
              }}
            >
              Accept
            </Button>
            <Button
              color="danger"
              className="ml-3"
              onClick={this.toggleRejectModal}
            >
              Reject
            </Button>
            <Modal
              isOpen={this.state.rejectModal}
              fade={false}
              toggle={this.toggleRejectModal}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggleRejectModal}>
                Reject Order (Order ID: {order.bill_id})
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Input
                    type="select"
                    name="select"
                    id="exampleSelect"
                    onChange={e => {
                      this.setState({ rejectReason: e.target.value });
                    }}
                  >
                    <option value="">Choose a reason</option>
                    {this.props.liveOrderMeta &&
                      this.props.liveOrderMeta.reject_reason &&
                      this.props.liveOrderMeta.reject_reason.length > 0 &&
                      this.props.liveOrderMeta.reject_reason.map(
                        (element, index) => {
                          console.log(element);
                          return (
                            <option value={element.id}>{element.reason}</option>
                          );
                        }
                      )}
                  </Input>
                </FormGroup>
                {this.state.rejectError && (
                  <div className="alert alert-danger w-100">
                    Chooose Reject Reason
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={this.toggleRejectModal}
                >
                  Cancel
                </Button>{" "}
                <Button
                  color="danger"
                  onClick={() => {
                    this.setRejectReason();
                  }}
                >
                  Reject Order
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        );

      case 4:
        return (
          <div>
            <Button
              color="primary"
              onClick={() => {
                this.props.updateOrder(
                  5,
                  order.bill_id,
                  "",
                  "",
                  this.props.filterParams
                );
              }}
            >
              Mark Packed
            </Button>
          </div>
        );
      case 5:
        return (
          <div>
            <Button
              color="primary"
              className="ml-3"
              onClick={this.toggleAssignModal}
            >
              Assign Order
            </Button>
            <Modal
              isOpen={this.state.assignModal}
              fade={false}
              toggle={this.toggleAssignModal}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggleAssignModal}>
                Assign Order (Order ID: {order.bill_id})
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Input
                    type="select"
                    name="select"
                    id="exampleSelect"
                    onChange={e => {
                      this.setState({ deliveryBoy: e.target.value });
                    }}
                  >
                    <option value="">Choose a Delivery Agent</option>
                    {this.props.deliveryGuys &&
                      this.props.deliveryGuys.in_house &&
                      this.props.deliveryGuys.in_house.length > 0 &&
                      this.props.deliveryGuys.in_house.map((element, index) => {
                        console.log(element);
                        return (
                          <option value={element.user_id}>
                            {element.user_first_name} {element.user_second_name}
                          </option>
                        );
                      })}
                    {/* {this.props.deliveryGuys &&
                      this.props.deliveryGuys.other_services &&
                      this.props.deliveryGuys.other_services.length > 0 &&
                      this.props.deliveryGuys.other_services.map(
                        (element, index) => {
                          console.log(element);
                          return (
                            <option value={element.delivery_service_id}>
                              {element.delivery_service_name}
                            </option>
                          );
                        }
                      )} */}
                  </Input>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={this.toggleAssignModal}
                >
                  Cancel
                </Button>{" "}
                <Button
                  color="success"
                  onClick={() => {
                    this.assignDeliveryGuy();
                  }}
                >
                  Assign
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        );
      case 6:
        return (
          <div>
            <Button
              color="primary"
              onClick={() => {
                this.props.updateOrder(
                  7,
                  order.bill_id,
                  "",
                  "",
                  this.props.filterParams
                );
              }}
            >
              Mark Dispatched
            </Button>
          </div>
        );
      case 7:
        return (
          <div>
            <Button
              color="info"
              onClick={() => {
                this.props.updateOrder(
                  8,
                  order.bill_id,
                  "",
                  "",
                  this.props.filterParams
                );
              }}
            >
              Mark Delivered
            </Button>
          </div>
        );
      case 8:
        return (
          <div>
            <Button color="success" disabled>
              Delivered
            </Button>
          </div>
        );
      case 9:
        return (
          <div>
            <Button color="primary" outline disabled>
              Rejected
            </Button>
          </div>
        );
      case 10:
        return (
          <div>
            <Button color="primary" outline disabled>
              Canceled
            </Button>
          </div>
        );
      default:
        return "";
    }
  }

  render() {
    const order = this.props.order;
    return <div className="order_actions">{this.renderActions(order)}</div>;
  }
}

function bindActions(dispatch) {
  return {
    updateOrder: (status, order_id, reason, assigned_to, params) =>
      dispatch(updateOrder(status, order_id, reason, assigned_to, params)),
    getDeliveryGuys: branch_id => dispatch(getDeliveryGuys(branch_id))
  };
}
function mapStateToProps(state) {
  return {
    liveOrderMeta: state.orders.liveOrderMeta,
    deliveryGuys: state.orders.deliveryGuys
  };
}

export default connect(
  mapStateToProps,
  bindActions
)(OrderEscalationsActions);
