import {
    SET_BANNER_LIST
} from "../actions/banners";

const initialState = {
    bannerList: [],
};

const banners = (state = initialState, action) => {
    switch (action.type) {
        case SET_BANNER_LIST:
            return {...state, bannerList: action.payload};
        default:
            return state;
    }
};

export default banners;
