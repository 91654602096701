import React, {Component} from "react";
import {connect} from "react-redux";
import config from "../../config";
import {
    Button,
    Col,
    Badge,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";
import OrderScreenTable from "./OrderScreenTable";

import {
    getLiveOrdersList,
    getLiveOrderMeta,
    getAllLiveOrdersList
} from "../../actions/orders";
import {chunkify} from "../../utils";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

class OrderScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // date default is -7 days
            orderStatus: 0, // default all orders
            perPage: 10,
            offset: 0,
            searchText: "",
            dropdownOpen: false,
            branch_id: 0,
            branch_name: "All branch",
            demoArr: [['something', 'here', 'just', 'for', 'test'], ['something', 'here', 'just', 'for', 'test'], ['something', 'here', 'just', 'for', 'test']],
            counter: 30
        };
        this.filterOrders = this.filterOrders.bind(this);
        this.branchtoggle = this.branchtoggle.bind(this);
        this.handleBranchChange = this.handleBranchChange.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.getNewData = this.getNewData.bind(this);

        const {branch_id, orderStatus, perPage, offset, searchText} = this.state;
        // console.log("order status ", this.state.orderStatus);
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC"
        };
        const self = this;
        this.getNewData(params);
        (function refreshData() {
            self.newDataRefreshId = setTimeout(() => {
                self.getNewData(params)
                refreshData()
            }, 90000);
        })()

    }


    componentWillUnmount() {
        clearTimeout(this.newDataRefreshId);
        clearTimeout(this.myTimeout);
        // clearInterval(this.myInterval);
    }

    getNewData = async (params) => {
        // await this.props.getLiveOrdersList(params);
        await this.props.getAllLiveOrdersList(params);
        await this.props.getLiveOrderMeta();

    }

    counterFunc = () => {
        this.myTimeout = setTimeout(() => {
            const {counter} = this.state;
            if (counter > 0) {
                this.setState((prevState) => ({
                    counter: prevState.counter - 1
                }));
                this.counterFunc();
            }else{
                clearTimeout(this.myTimeout);
            }
        }, 1000);
    }

    startCounter = () => {
        this.setState({counter: 30}, () => {
                this.counterFunc();

            }
        )

    }


    branchtoggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    filterOrders(bill_status) {
        const {branch_id, perPage, searchText} = this.state;
        this.setState({orderStatus: bill_status});
        console.log("order status ", this.state.orderStatus);
        let params = {
            branch_id: branch_id,
            status: bill_status,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC"
        };
        this.props.getLiveOrdersList(params);
        console.log(this.state);
    }

    handleBranchChange(branch_id, branch_name) {
        const {orderStatus, perPage, searchText} = this.state;
        this.setState({branch_id: branch_id, branch_name: branch_name});
        console.log("branch id ", branch_id);
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC"
        };
        this.props.getLiveOrdersList(params);
        console.log(this.state);
    }

    handleSearch(searchText) {
        const {branch_id, perPage, bill_status} = this.state;
        this.setState({searchText: searchText});
        console.log("searchText ", this.state.searchText);
        let params = {
            branch_id: branch_id,
            status: bill_status,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC"
        };
        this.props.getLiveOrdersList(params);
        // console.log(this.state);
    }

    handlePagination(offset) {
        const {orderStatus, perPage, branch_id, searchText} = this.state;
        this.setState({offset: offset});
        console.log("pagingation offset ", offset);
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            searchText: searchText,
            offset: offset,
            perPage: perPage,
            sort: "ASC"
        };

        this.props.getLiveOrdersList(params);
        console.log(this.state);
    }
    componentWillReceiveProps(nextProps){
        if(this.props.spinnerActive != nextProps.spinnerActive && !nextProps.spinnerActive){
            this.startCounter();
        }
    }

    render() {
        const {
            orderStatus,
            perPage,
            branch_id,
            searchText,
            offset,
            branch_name
        } = this.state;
        let products = [];
        let allProducts = [];
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 1000,
            pauseOnHover: false,
            afterChange: () => {
                this.startCounter();
            }

        };
        // console.log(' the spinner value now is ', this.props.spinnerActive);


        // * Start of allLiveOrders
        if (
            this.props.allLiveOrders &&
            this.props.allLiveOrders.aaData &&
            this.props.allLiveOrders.aaData.length > 0
        ) {
            // allProducts = this.props.allLiveOrders.aaData;
            allProducts = this.props.allLiveOrders.aaData.filter(item => parseInt(item.bill_status) >= 4);
            allProducts = chunkify(allProducts, 12, false);
            console.log('the array us ', allProducts);
        }

        //  * End of all Orders List

        if (
            this.props.liveOrders &&
            this.props.liveOrders.aaData &&
            this.props.liveOrders.aaData.length > 0
        ) {
            // products = this.props.liveOrders.aaData.filter(item => parseInt(item.bill_status) >= 4);
            products = this.props.liveOrders.aaData;
            // products = chunkify(products, 12, true);
            // console.log(' i am receiving products ', products);
        }
        let columns = [
            {
                dataField: "bill_id",
                text: "Product ID",
                formatter: this.orderFormatter
            }
        ];
        let orderStatusList = this.props.liveOrders.order_meta;
        let statusList = {
            ORDER_TEMP: "Temp",
            ORDER_ONLINE_PAYMENT_PROCESSING: "Payment Pending",
            ORDER_PLACED: "New Order",
            ORDER_ACCEPTED: "In Process",
            ORDER_PACKED: "Ready",
            ORDER_ASSIGNED: "Assigned",
            ORDER_DISPATCHED: "Dispatched",
            ORDER_DELIVERED: "Delivered",
            ORDER_REJECTED: "Rejected",
            ORDER_CANCELLED: "Canceled"
        };
        let branch_list = [];
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            branch_list = this.props.loggedInUser.branch_list;
        }
        let all_branch_outline = true;
        if (orderStatus === 0) {
            all_branch_outline = false;
        }

        let filterParams = {
            orderStatus: orderStatus,
            perPage: perPage,
            branch_id: branch_id,
            searchText: searchText,
            offset: offset
        };

        return (
            <div className="animated fadeIn">
                <Row className="ml-2 mr-2 mt-2 today">
                    {/* <Col sm="4" md="3" lg="2" className="p-md-3 p-2 today-side">
            <Dropdown
              isOpen={this.state.dropdownOpen}
              toggle={this.branchtoggle}
              className="w-100 mb-2"
            >
              <DropdownToggle caret color="primary" className="w-100">
                {branch_name}
              </DropdownToggle>
              <DropdownMenu className="w-100">
                {branch_list &&
                  branch_list.map((row, index) => {
                    return (
                      <DropdownItem
                        onClick={() => {
                          this.handleBranchChange(
                            row.branch_id,
                            row.branch_name
                          );
                        }}
                      >
                        {row.branch_name}
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </Dropdown>
            <hr />
            <label className="filter-label">STATUS</label>
            <Button
              color="primary"
              className="w-100 text-left orderStatusBtn"
              outline={all_branch_outline}
              onClick={() => {
                this.filterOrders(0);
              }}
            >
              All Orders
              <Badge className="float-right ml-1 p-1 orderCount bg-white">
                {this.props.liveOrders && this.props.liveOrders.iTotalRecords}
              </Badge>
            </Button>
            {orderStatusList &&
              orderStatusList.length > 0 &&
              orderStatusList.map((element, index) => {
                console.log(element);
                let outline =
                  element.bill_status !== this.state.orderStatus ? true : false;
                return (
                  <Button
                    color="primary"
                    className="w-100 text-left orderStatusBtn"
                    outline={outline}
                    onClick={() => {
                      console.log(element.bill_status);
                      this.filterOrders(element.bill_status);
                    }}
                  >
                    {statusList[element.order_status_value]}
                    <Badge className="float-right ml-1 p-1 orderCount bg-white">
                      {element.bill_count}
                    </Badge>
                  </Button>
                );
              })}
          </Col> */}


                    <Col sm="12" className="today-main p-4">
                        <Row className="custom-table-no-border today-table">
                            <Col sm="12" className="p-0">
                                <Row>
                                    <Col sm="9">
                                        <h4 className="order_count_0">OrderScreen's Orders</h4>
                                    </Col>
                                    <Col sm="3">
                                        <InputGroup className="mb-0 w-100">
                                            <Input
                                                type="text"
                                                placeholder="Search"
                                                autoComplete="searchText"
                                                className="w-100"
                                                onChange={e => {
                                                    this.handleSearch(e.target.value);
                                                }}
                                                value={searchText}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Col>

                            <Col sm="12" className="p-0">


                                <div className="bg-black p3">
                                { this.props.allLiveOrders && this.props.allLiveOrders.aaData && this.props.allLiveOrders.aaData.length > 12 &&

                                <div>
                                   <h2 className="heading text-white">{this.state.counter}</h2>
                                </div>
                                }

                                    <Slider {...settings}>

                                        {allProducts.length > 0 && allProducts.map((item, index) => {
                                            return (
                                                <div key={index} className="row display-override">
                                                    {item.map(product => {
                                                        return (

                                                            <Col md="4" className="mb-4">
                                                                <div className="pr-list">
                                                                    <div className="shape-name mb-3">
                                                                        <div className="shape-left">
                                                                            <img
                                                                                src={`${config.imgUrl}frontend/images/order_source/${
                                                                                    product.order_source_id
                                                                                    }.jpg`}
                                                                                alt={product.order_source_name}
                                                                                width="40"
                                                                            />
                                                                        </div>
                                                                        <div className="shape-right">
                                                                            <h6 className="heading text-white">#{product.bill_id}
                                                                                {product.mins_left && <span
                                                                                    className={`float-right font-weight-normal order_count_${product.priority}`}>
                                        {product.mins_left}</span>}
                                                                                <span className="clearfix"></span>
                                                                            </h6>
                                                                            <p className="desc text-white">{product.customer_first_name}</p>
                                                                        </div>
                                                                        <div className="clearfix"></div>
                                                                    </div>
                                                                    {/* <p>{product.customer_phone}</p> */}
                                                                    <div className="row">
                                                                        <div className="col-7">
                                                                            <p className="opacity-less text-white mb-1 small">Assigned
                                                                                to</p>
                                                                            <p className="mb-0 small">{product.bill_assigned_to ? product.bill_assigned_to : ""}</p>
                                                                        </div>
                                                                        <div className="col-5">
                                                                            <p className="opacity-less text-white mb-1 small">Order
                                                                                Status</p>
                                                                            <p className="mb-0 small">{product.current_state_name}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>


                                                        )
                                                    })
                                                    }
                                                </div>


                                            )
                                        })}

                                    </Slider>
                                </div>
                            </Col>


                            {/* {allProducts.length > 0 &&  allProducts.map((item, index) => {
                            return (
                              <div>
                                {console.log('the value of item is ', item)}
                              {item.map(product => {
                                return (

                                    <>
                                    <p>{product.customer_first_name}</p>
                                    <p>{product.bill_id}</p>
                                    <p>{product.mins_left}</p>
                                    </>


                              )
                            })
                            }
                            </div>


                            )
                          })} */}


                            {/* {
                      products.length > 0 && products.map((product, index) => {
                          return (
                              <Col sm="4" key={index} >
                                <p>{product.customer_first_name}</p>
                                <p>{product.bill_id}</p>
                                <p>{product.mins_left}</p>
                              </Col>
                          )
                        })
                    } */}

                            {/* <OrderScreenTable
                  orders={products}
                  totalData={
                    this.props.liveOrders && this.props.liveOrders.iTotalRecords
                  }
                  filterParams={filterParams}
                  handlePagination={this.handlePagination}
                // liveOrderMeta={this.props.liveOrderMeta}
                /> */}

                        </Row>
                        {/* <Row className="p-2">
              <div className="alert alert-primary w-100">
                <strong>Note:</strong> Only today's orders are visible here
              </div>
            </Row> */}
                    </Col>
                </Row>
            </div>
        );
    }
}

function bindActions(dispatch) {
    return {
        getLiveOrdersList: params => dispatch(getLiveOrdersList(params)),
        getAllLiveOrdersList: params => dispatch(getAllLiveOrdersList(params)),
        getLiveOrderMeta: () => dispatch(getLiveOrderMeta())
    };
}

function mapStateToProps(state) {
    return {
        liveOrders: state.orders.liveOrdersList,
        allLiveOrders: state.orders.allLiveOrdersList,
        liveOrderMeta: state.orders.liveOrderMeta,
        loggedInUser: state.commonData.loggedInUser,
        spinnerActive: state.commonData.spinnerActive
    };
}

export default connect(
    mapStateToProps,
    bindActions
)(OrderScreen);
