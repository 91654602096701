import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Col, Row, Button } from "reactstrap";
import ServiceAreaMap from "./ServiceAreaMap";

import { getServiceArea } from "../../actions/branch";
import config from "../../config";
class ServiceArea extends Component {
  constructor(props) {
    super(props);
    this.props.getServiceArea(this.props.branch_id);
  }

  componentDidUpdate(previousProps, previousState) {
    const { branch_id } = this.props;
    if (branch_id !== previousProps.branch_id && branch_id != 0) {
      this.props.getServiceArea(branch_id);
    }
  }
  render() {
    const { serviceArea } = this.props;
    let coords = [];
    if (serviceArea && serviceArea.branch_serving_area && serviceArea.branch_serving_area.serving_area.length) {
      coords = serviceArea.branch_serving_area.serving_area;
      console.log(coords);

      coords.push(coords[0]);

      coords.map((ll, index) => {
        ll &&
          Object.keys(ll).forEach(function(key) {
            ll[key] = Number(ll[key]);
          });
      });
    }
    const { branch_id } = this.props;
    // debugger
    return (
      <Row className="pr-4 pl-4">
        <h5>Serving Area</h5>
        <div className="alert alert-primary w-100">
          Change delivery radious of the branch anytime, this is a live change,
          iOS, Android and will Be affected
        </div>
        <Col sm="12" className="p-0">
          {coords.length && (
            <ServiceAreaMap isMarkerShown={true} coords={coords} serviceArea={serviceArea} />
          )}
        </Col>
        <Col sm="3" className="p-0 mt-2">
          <Link to={`/manage_branch/addServingArea/${branch_id}`}>
            <Button color="primary">Edit service area</Button>
          </Link>
        </Col>
        <div className="alert alert-danger w-100 mt-2">
          <strong>Keep in mind!</strong> Customers who have already added
          products will be affected
        </div>
      </Row>
    );
  }
}

function bindActions(dispatch) {
  return {
    getServiceArea: branch_id => dispatch(getServiceArea(branch_id))
  };
}

function mapStateToProps(state) {
  return {
    serviceArea: state.branch.serviceArea
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(ServiceArea);
