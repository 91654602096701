import firebase from "firebase/app";
import "firebase/messaging";
var messaging = null;
if (firebase.messaging.isSupported()) {
    const initializedFirebaseApp = firebase.initializeApp({
        // Project Settings => Add Firebase to your web app
        apiKey: "AIzaSyAwdvvQEgvQfyrlTxmYf2iAM3JssZ2EvBw",
        authDomain: "kotfirebaseproject-94285.firebaseapp.com",
        databaseURL: "https://kotfirebaseproject-94285.firebaseio.com",
        projectId: "kotfirebaseproject-94285",
        storageBucket: "kotfirebaseproject-94285.appspot.com",
        messagingSenderId: "788084255794",
        appId: "1:788084255794:web:336f6c9611ea643534602b"
    });

    messaging = initializedFirebaseApp.messaging();
    // messaging.usePublicVapidKey(
    //     // Project Settings => Cloud Messaging => Web Push certificates
    //     "BMBy-aKzTgDhft-6dqSEzg_6MW1-j-xBXfVlPh6S-7qkSUqhhk-ccfNB66PAfJH9CcUwLy1S8NVqfOA26HFLirs"
    // );



}
window.messaging = messaging;
export {messaging};
