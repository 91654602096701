import {
  SET_WALLET_REQUEST_LIST,
  SET_WALLET_DATA,
  SET_WALLET_LIST,
  SET_WALLET_CONSTANTS
} from "../actions/wallet";

const initialState = {
  walletList: [],
  walletData: {},
  walletRequestList: [],
  walletContants: {}
};

const wallet = (state = initialState, action) => {
  switch (action.type) {
    case SET_WALLET_DATA:
      return { ...state, walletData: action.payload };
    case SET_WALLET_LIST:
      return { ...state, walletList: action.payload };
    case SET_WALLET_REQUEST_LIST:
      return { ...state, walletRequestList: action.payload };
    case SET_WALLET_CONSTANTS:
      return { ...state, walletContants: action.payload };
    default:
      return state;
  }
};

export default wallet;
