import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { DateRangePicker } from "react-dates";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import {
  Col,
  Row,
  Button,
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import ServiceArea from "./ServiceArea";
import Banners from "./Banners";
import Coupons from "./Coupons";
import Details from "./Details";
import OtherTabs from "./OtherTabs";
import CreateBranch from "./CreateBranch";
import ServingSize from "./ServingSize"

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branch_id: 0,
      branch_name: "All branch"
    };
    this.handleBranchChange = this.handleBranchChange.bind(this);
    this.branchtoggle = this.branchtoggle.bind(this);
  }


    branchtoggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    componentDidMount() {
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {

                let is_false = this.props.loggedInUser.branch_list[0].branch_list[0].branch_name == 0;
                let firstBranchName = this.props.loggedInUser.branch_list[0].branch_list[is_false ? 1 : 0].branch_name;
                let firstBranchId = this.props.loggedInUser.branch_list[0].branch_list[is_false ? 1 : 0].branch_id;
                this.handleBranchChange(firstBranchId, firstBranchName);
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.loggedInUser && (this.props.loggedInUser.branch_list != nextProps.loggedInUser.branch_list)) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let is_false = this.props.loggedInUser.branch_list[0].branch_list[0].branch_id == 0;
                let firstBranchName = this.props.loggedInUser.branch_list[0].branch_list[is_false ? 1 : 0].branch_name;
                let firstBranchId = this.props.loggedInUser.branch_list[0].branch_list[is_false ? 1 : 0].branch_id;

                this.handleBranchChange(firstBranchId, firstBranchName);
            }
        }
    }

  branchtoggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

    handleBranchChange(branch_id, branch_name) {
        const {
            orderStatus,
            perPage,
            offset,
            searchText,
            startDate,
            endDate
        } = this.state;
        this.setState({ branch_id: branch_id, branch_name: branch_name });
        let params = {
            branch_id: branch_id,
            status: orderStatus,
        };
    }

  render() {
    const branch_actions = {
      service_area: { display: "Serving Area", icon: "fas fa-map-marker-alt" },
      view_branch_others: {
        display: "Other Charges",
        icon: "fas fa-rupee-sign"
      },
      banners: { display: "Banners", icon: "fas fa-camera" },
        serving_size: { display: "Serving Size", icon: "fas fa-camera" },
      view_branch_coupons: { display: "Coupons", icon: "fas fa-tag" }
    };

    const { branch_name, branch_id } = this.state;

      let branch_list = [];
      if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
          for (let j = 0; j < this.props.loggedInUser.branch_list.length; j++) {
              const item = this.props.loggedInUser.branch_list[j];
              if (j === 0) {
                  branch_list = item.branch_list;
                  if (branch_list[0].branch_id === 0) {
                      branch_list.splice(0, 1);
                  }
                  break;
              }
          }
      }


    let action = this.props.match.params.branchAction;
    return (
      <div className="animated fadeIn">
        <Row className="ml-2 mr-2 mt-2 branch">
          <Col sm="4" md="3" lg="2" className="branch-side p-0">
            <Dropdown
              isOpen={this.state.dropdownOpen}
              toggle={this.branchtoggle}
              className="branch-toggle w-100 mb-2 p-2 mt-2 "
            >
              <DropdownToggle caret className="w-100 f-black">
                {branch_name}
              </DropdownToggle>
              <DropdownMenu className="w-100">
                {branch_list &&
                  branch_list.map((row, index) => {
                    return (
                      <DropdownItem
                        onClick={() => {
                          this.handleBranchChange(
                            row.branch_id,
                            row.branch_name
                          );
                        }}
                      >
                        {row.branch_name}
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </Dropdown>

            {Object.keys(branch_actions).map(element => {
              let outline = element !== action ? true : false;
              return (
                <Link to={`/branch/${element}`}>
                  <Button
                    className="w-100 text-left mb-2 branch-actions pr-4 pl-4 pt-2 pb-2"
                    outline={outline}
                    onClick={() => {
                      // this.filterOrders(element.bill_status);
                    }}
                  >
                    <i className={`${branch_actions[element].icon} mr-2`} />
                    {branch_actions[element].display}
                  </Button>
                </Link>
              );
            })}
          </Col>

          <Col sm="8" md="9" lg="10" className="orders-main p-4">
            <Row className="">
              <Col sm="12" className="mb-3 p-0">
                {(() => {
                  switch (action) {
                    case "create_branch":
                      return <CreateBranch branch_id={branch_id} />;
                    case "view_branch_coupons":
                      return <Coupons branch_id={branch_id} />;
                    case "view_branch_others":
                      return <OtherTabs branch_id={branch_id} />;

                    case "branch_setting":
                      return <Details branch_id={branch_id} />;
                    case "banners":
                      return <Banners branch_id={branch_id} />;
                      case "serving_size":
                          return <ServingSize branch_id={branch_id} />;
                      case "service_area":
                          return <ServiceArea branch_id={branch_id} isMarkerShown={true} />;
                    default:
                      return <Details branch_id={branch_id} />;
                  }
                })()}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    // getOrderHistory: (params) => dispatch(getOrderHistory(params))
  };
}

function mapStateToProps(state) {
  return {
    // ordersList: state.orders.ordersList,
    loggedInUser: state.commonData.loggedInUser
  };
}

export default connect(
  mapStateToProps,
  bindActions
)(Orders);
