import React, { Component, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import * as scroll from "react-scroll";

import { Col, Row, ListGroup, ListGroupItem, Media } from "reactstrap";
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable, Draggable } from "react-beautiful-dnd";

import NewProduct from "./NewProduct";
import NewCategory from "./NewCategory";
import UploadCategory from "./UploadCategory"
import {
  getProductList,
  getCategoryList,
  sortCategories,
  sortProducts
} from "../../actions/products";
import Categories from "./Categories";

class Products extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      categories: [],
      products: []
    };
    this.props.getCategoryList();
    this.props.getProductList();

    this.onDragEnd = this.onDragEnd.bind(this);
    this.reorder = this.reorder.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { categories, products } = this.state;
    if (nextProps.categoryList.length != categories.length)
      this.setState({ categories: this.props.categoryList });
    if (nextProps.productsList.length != products.length)
      this.setState({ products: this.props.productsList });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.categoryList != this.props.categoryList)
      this.setState({ categories: this.props.categoryList });
    if (prevProps.productsList != this.props.productsList)
      this.setState({ products: this.props.productsList });
  }

  onDragEnd({ source, destination }) {
    if (!destination) {
      return;
    }
    // console.log(source, destination);
    if (destination && destination.droppableId == "categories") {
      const categories = this.reorder(
        this.state.categories,
        source.index,
        destination.index
      );

      this.setState({
        categories
      });

      const sortOrder = [];
      categories.map((row, index) => {
        sortOrder.push(parseInt(row.category_id));
      });
      // console.log(sortOrder);

      this.props.sortCategories(sortOrder);
    } else {
      if (destination.droppableId != source.droppableId) {
        return;
      }
      let catId = destination.droppableId.replace("prodCat-", "");
      catId = parseInt(catId);
      // console.log(catId);
      let prodList = [];
      prodList = this.state.products.find(function (element) {
        return element.category_id == catId;
      });
      if (!prodList.products.length) {
        return;
      }
      const products = this.reorder(
        prodList.products,
        source.index,
        destination.index
      );

      this.setState({
        products
      });

      const sortOrder = [];
      products.map((row, index) => {
        sortOrder.push(parseInt(row.product_id));
      });
      // console.log(sortOrder);

      this.props.sortProducts(sortOrder);
    }
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  render() {
    let products = this.props.productsList;
    const { categories } = this.state;

    return (
      <div className="animated fadeIn">
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Row className="ml-2 mr-2 mt-2 products">
            <Categories />
            <Col md="9" sm="8" lg="10" className="products-main px-3 bg-new-light">
              <div className="row mb-3 bg-new-white pt-3">
                <div className="px-3">
                  <NewCategory categories={categories} />
                  <UploadCategory categories={categories} />
                  <NewProduct categories={categories} />

                  </div>
              </div>
              <Row className="each-product pt-0 pb-3 table-head-new">
                <Col md="5"><div className="pl-4 ml-2">Product Name</div></Col>
                <Col md="2" className="my-auto d-none d-md-block">
                  Category
                </Col>
                <Col md="3" className="my-auto d-none d-md-block">
                  Description
                </Col>
                <Col md="2" className="my-auto d-none d-md-block">
                  Created At
                </Col>
              </Row>
              {products &&
                products.length > 0 &&
                products.map((row, index) => {
                  return (
                    <section id={`category-section-${row.category_id}`} class="row bg-white">
                      <div ref={row.category_id}>
                        <div
                          className="product-category product-category-new"
                        >
                          {row.category_name}
                        </div>
                        <Droppable
                          droppableId={`prodCat-${row.category_id}`}
                          isDropDisabled={false}
                        >
                          {provided => {
                            return (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="w-100"
                              >
                                {row.products &&
                                  row.products.length > 0 &&
                                  row.products.map((eachProduct, index) => {
                                    return (
                                      <Draggable
                                        key={eachProduct.product_id}
                                        draggableId={eachProduct.product_id}
                                        index={index}
                                      >
                                        {provided => {
                                          return (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <div className="w-100 each-product px-3 pt-3 pb-3 table-body-new">
                                                <Row className="">
                                                  <Col md="5" className="my-auto">
                                                    <i class="fas fa-grip-vertical d-inline-block mr-3 new-drag-color" />

                                                    <Link
                                                      to={`/product/${eachProduct.product_id}`}
                                                    >
                                                      {/*<div className="product-image-holder d-inline-block ml-2">*/}
                                                      {/*<Media*/}
                                                      {/*object*/}
                                                      {/*src={`http://admin.potful.in/frontend/images/products/${eachProduct.product_id}.jpg`}*/}
                                                      {/*alt={*/}
                                                      {/*eachProduct.product_name*/}
                                                      {/*}*/}
                                                      {/*className="product-image"*/}
                                                      {/*/>*/}
                                                      {/*</div>*/}
                                                      <Media
                                                        body
                                                        className="ml-2 d-inline-block product-title"
                                                      >
                                                        {eachProduct.product_name}
                                                      </Media>
                                                    </Link>
                                                  </Col>
                                                  <Col sm="2" className="my-auto d-none d-md-block">
                                                    {row.category_name}
                                                  </Col>
                                                  <Col
                                                    sm="3"
                                                    className="my-auto product-desc d-none d-md-block product-desc-opacity"
                                                  >
                                                    {eachProduct.product_desc}
                                                  </Col>
                                                  <Col sm="1" className="my-auto d-none d-md-block">
                                                    {moment(
                                                      eachProduct.date_time
                                                    ).format("DD/MM/YYYY")}
                                                  </Col>
                                                </Row>
                                              </div>
                                            </div>
                                          );
                                        }}
                                      </Draggable>
                                    );
                                  })}
                              </div>
                            );
                          }}
                        </Droppable>
                      </div>
                    </section>
                  );
                })}
            </Col>
          </Row>
        </DragDropContext>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    getProductList: () => dispatch(getProductList()),
    getCategoryList: () => dispatch(getCategoryList()),
    sortCategories: order => dispatch(sortCategories(order)),
    sortProducts: order => dispatch(sortProducts(order))
  };
}

function mapStateToProps(state) {
  return {
    productsList: state.products.productsList,
    categoryList: state.products.categoryList
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(Products);
