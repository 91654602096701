export default {
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
    imgUrl: process.env.REACT_APP_IMG_ENDPOINT,
    adminUrl: process.env.REACT_APP_ADMIN_ENDPOINT,
    cookiePath: "/",
    cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN,
    cookieTokenKey: "token_dashboard",
    cookieFCMKey: "token_fcm",
    brand: process.env.REACT_APP_BRAND,
    // brand: "potful"
    printBill: process.env.REACT_APP_PRINTBILL,
  };
