import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {
  Col,
  Row,
  Button,
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
} from "reactstrap";
import { getAnalyticsByTime } from "../../actions/orders";
import Highcharts from "highcharts";

const timeFrames = [
  "1100",
  "1130",
  "1200",
  "1230",
  "1300",
  "1330",
  "1400",
  "1430",
  "1500",
  "1530",
  "1600",
  "1630",
  "1700",
  "1730",
  "1800",
  "1830",
  "1900",
  "1930",
  "2000",
  "2030",
  "2100",
  "2130",
  "2200",
  "2230",
  "2300",
];

const times = _.reduce(
  timeFrames,
  (res, frame) => {
    const hour = +frame.slice(0, 2);
    const min = +frame.slice(2, 4);
    res[frame] = `${hour === 12 ? hour : hour % 12}${min ? ":" + min : ""} ${
      hour > 12 ? "PM" : "AM"
    }`;
    return res;
  },
  {}
);

const days = [
  "All Days",
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

class TimeAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(7, "d"),
      endDate: moment(),
      orderStatus: 0, // default all orders
      perPage: 10,
      offset: 0,
      searchText: "",
      preset: "",
      focusedInput: false,
      dropdownOpen: false,
      branch_id: 0,
      branch_name: "All branch",
      selectedDay: "All Days",
      selectedDayId: "0",
      dayDropdown: false,
      cityDropdown: false,
      city_id: "0",
      city_name: "All Cities"
    };

    this.filterOrders = this.filterOrders.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.renderDatePresets = this.renderDatePresets.bind(this);
    this.branchtoggle = this.branchtoggle.bind(this);
    this.handleBranchChange = this.handleBranchChange.bind(this);
    this.handleChartData = this.handleChartData.bind(this);
    this.dayToggle = this.dayToggle.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.citytoggle = this.citytoggle.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);

    const {
      branch_id,
      startDate,
      endDate,
      perPage,
      searchText,
      orderStatus,
        city_id
    } = this.state;
    let default_params = {
      branch_id: branch_id,
      status: orderStatus,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: 0,
      perPage: perPage,
      sort: "ASC",
      city_id:city_id
    };
    this.props.getAnalyticsByTime(default_params);
  }

  componentDidMount() {
    if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
      let branch_list = [];
      branch_list = this.props.loggedInUser.branch_list;

      if (branch_list.length > 0) {
        let firstCityName = branch_list[0].city_name;
        let firstCityId = branch_list[0].city_id;
        this.handleCityChange(firstCityName, firstCityId);
      }
    }
  }


  componentWillReceiveProps(nextProps) {
    if (this.props.loggedInUser && (this.props.loggedInUser.branch_list != nextProps.loggedInUser.branch_list)) {
      let branch_list = [];
      branch_list = this.props.loggedInUser.branch_list;

      if (branch_list.length > 0) {
        let firstCityName = branch_list[0].city_name;
        let firstCityId = branch_list[0].city_id;
        this.handleCityChange(firstCityName, firstCityId);
      }
    }
  }

  citytoggle() {
    this.setState(prevState => ({
      cityDropdown: !prevState.cityDropdown
    }));
  }

  branchtoggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleDateChange(startDate, endDate) {
    const { branch_id, orderStatus, perPage, offset, searchText,city_id } = this.state;
    this.setState({ startDate: startDate, endDate: endDate });
    let params = {
      branch_id: branch_id,
      status: orderStatus,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: 0,
      perPage: perPage,
      sort: "ASC",
      city_id:city_id
    };
    this.props.getAnalyticsByTime(params);
  }

  handleCityChange(city_name, city_id) {
    this.setState({city_id: city_id, city_name: city_name},()=>{
      this.props.loggedInUser.branch_list.map((value) => {
        if (city_id === value.city_id)
          this.handleBranchChange(value.branch_list[0].branch_id, value.branch_list[0].branch_name);
      });
    });
  }

  handleBranchChange(branch_id, branch_name) {
    const { orderStatus, perPage, searchText, startDate, endDate ,city_id} = this.state;
    this.setState({ branch_id: branch_id, branch_name: branch_name });
    let params = {
      branch_id: branch_id,
      status: orderStatus,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: 0,
      perPage: perPage,
      sort: "ASC",
      city_id:city_id
    };
    this.props.getAnalyticsByTime(params);
  }

  filterOrders(bill_status) {
    const { branch_id, startDate, endDate, perPage, searchText,city_id } = this.state;
    this.setState({ orderStatus: bill_status });
    let params = {
      branch_id: branch_id,
      status: bill_status,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: 0,
      perPage: perPage,
      sort: "ASC",
      city_id:city_id
    };
    this.props.getAnalyticsByTime(params);
  }

  renderDatePresets() {
    return (
      <div className="date_custom_presets">
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment(), moment());
          }}
        >
          Today
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().subtract(1, "d"), moment().subtract(1, "d"));
            this.setState({ focusedInput: false });
          }}
        >
          Yesterday
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().subtract(7, "d"), moment());
            this.setState({ focusedInput: false });
          }}
        >
          Last 7 days
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().subtract(30, "d"), moment());
            this.setState({ focusedInput: false });
          }}
        >
          Last 30 days
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().startOf("month"), moment());
            this.setState({ focusedInput: false });
          }}
        >
          This month
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(
              moment().subtract(1, "months").startOf("month"),
              moment().subtract(1, "months").endOf("month")
            );
            this.setState({ focusedInput: false });
          }}
        >
          Last month
        </Button>
        <Button className="w-100  mb-2" color="info">
          Custom Range
        </Button>
      </div>
    );
  }

  handleChartData() {
    const groupedByCategory = _.groupBy(
      this.props.TimeAnalytics.data,
      "category_id"
    );

    const chartData = !_.isEmpty(this.props.TimeAnalytics.data)
      ? _.map(groupedByCategory, (category) => {
          const name = _.head(category).category_name;

          const data = _.map(timeFrames, (tf) => {
            const total = _.reduce(category, (res, c) => res + c.data[tf], 0);
            return {
              name: times[tf],
              y: total,
            };
          });

          return {
            name,
            data,
          };
        })
      : [];

    const chartConfig = {
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      legend: {
        layout: "horizontal",
      },
      title: {
        text: "Time Analytics",
      },
      xAxis: {
        type: "category",
      },
      yAxis: {
        title: {
          text: "Quantity",
        },
      },
      series: chartData,
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                currency: "INR",
              });
            },
          },
        },
      },
      credits: {
        enabled: false,
      },
    };

    if (!_.isEmpty(this.props.TimeAnalytics.data) && this.chartElement)
      Highcharts.chart("timeAnalyticsChart", chartConfig);
  }

  handleTableData() {
    const analyticsGroupedByCategory = _.groupBy(
      this.props.TimeAnalytics.data,
      "category_id"
    );
    const headers = ["Product", ..._.values(times)];

    return _.map(analyticsGroupedByCategory, (category) => {
      return (
        <div

          style={{
            display: "block",
            overflowX: "auto",
            whiteSpace: "nowrap",
          }}
          className="table-holder"
        >
          <h5 className="table-header-analytics">
            {_.head(category).category_name}
          </h5>
          <Table responsive className="my-table">
            <thead>
              <tr className="my-table-heading">
                {_.map(headers, (header) => (
                  <td className="font-weight-bold font-sm">{header}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {_.map(category, (p) => (
                <tr>
                  <td>{p.product_name}</td>
                  {_.map(timeFrames, (tf) => {
                    return (
                      <td>
                        {p.data[tf].toLocaleString("en-IN", {
                          maximumFractionDigits: 2,
                          currency: "INR",
                        })}
                      </td>
                    );
                  })}
                </tr>
              ))}
              <tr>
                <td className="font-weight-bold">Total</td>
                {_.map(timeFrames, (tf) => {
                  const total = _.reduce(
                    category,
                    (res, p) => res + +p.data[tf],
                    0
                  );
                  return (
                    <td className="font-weight-bold">
                      {total.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        currency: "INR",
                      })}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </Table>
        </div>
      );
    });
  }

  dayToggle() {
    this.setState((prevState) => ({
      dayDropdown: !prevState.dayDropdown,
    }));
  }

  handleDayChange(day) {
    const {
      orderStatus,
      perPage,
      searchText,
      startDate,
      endDate,
      branch_id,
        city_id
    } = this.state;
    this.setState({ selectedDay: day, selectedDayId: _.indexOf(days, day) });
    let params = {
      branch_id,
      status: orderStatus,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      day_of_the_week: _.indexOf(days, day),
      searchText: searchText,
      offset: 0,
      perPage: perPage,
      sort: "ASC",
      city_id:city_id
    };
    this.props.getAnalyticsByTime(params);
  }

  render() {
    const { startDate, endDate, branch_name, orderStatus,city_id,city_name } = this.state;

    let orderStatusList = [];
    if (this.props.TimeAnalytics && this.props.TimeAnalytics.meta) {
      orderStatusList = this.props.TimeAnalytics.meta;
    }
    let statusList = {
      ORDER_TEMP: "Temp",
      ORDER_ONLINE_PAYMENT_PROCESSING: "Payment Pending",
      ORDER_PLACED: "New Order",
      ORDER_ACCEPTED: "In Process",
      ORDER_PACKED: "Ready",
      ORDER_ASSIGNED: "Assigned",
      ORDER_DISPATCHED: "Dispatched",
      ORDER_DELIVERED: "Delivered",
      ORDER_REJECTED: "Rejected",
      ORDER_CANCELLED: "Canceled",
    };
    let city_list = [];
    let branch_list = [];
    if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {

      this.props.loggedInUser.branch_list.map((item)=>{
        if(city_id===item.city_id)
          branch_list = item.branch_list;
        city_list.push(item);
      });
    }
    let all_branch_outline = true;
    if (orderStatus === 0) {
      all_branch_outline = false;
    }

    this.handleChartData();

    return (
      <div className="animated fadeIn">
        <Row className="ml-2 mr-2 mt-2 orders">
          <Col md="3" lg="3" xl="2" className="p-2 orders-side d-none">
            <hr />
            <label className="filter-label">Order History</label>
            <Button
              color="primary"
              className="w-100 text-left orderStatusBtn"
              outline={all_branch_outline}
              onClick={() => {
                this.filterOrders(0);
              }}
            >
              All Orders
              <Badge className="float-right ml-1 p-1 orderCount">
                {this.props.TimeAnalytics &&
                  this.props.TimeAnalytics.order_list &&
                  this.props.TimeAnalytics.order_list.iTotalRecords}
              </Badge>
            </Button>
            {orderStatusList &&
              orderStatusList.length > 0 &&
              orderStatusList.map((element, index) => {
                let outline =
                  element.bill_status !== this.state.orderStatus ? true : false;
                return (
                  <Button
                    color="primary"
                    className="w-100 text-left orderStatusBtn"
                    outline={outline}
                    onClick={() => {
                      this.filterOrders(element.bill_status);
                    }}
                  >
                    {statusList[element.order_status_value]}
                    <Badge className="float-right ml-1 p-1 orderCount">
                      {element.bill_count}
                    </Badge>
                  </Button>
                );
              })}
          </Col>
          <Col className="orders-main px-3">
            <Row className="">
              <div class="p-3 today-side col-sm-4 col-md-3 col-lg-2 border-right">
                <Dropdown
                    isOpen={this.state.cityDropdown}
                    toggle={this.citytoggle}
                    className="place-order w-100 mb-3"
                >
                  <DropdownToggle caret className="w-100" color="primary">
                    {city_name}
                  </DropdownToggle>
                  <DropdownMenu className="w-100">
                    {city_list &&
                    city_list.map((row, index) => {
                      return (
                          <DropdownItem
                              key={index}
                              onClick={() => {
                                this.handleCityChange(
                                    row.city_name,
                                    row.city_id
                                );
                              }}
                          >
                            {row.city_name}
                          </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>


                <Dropdown
                  isOpen={this.state.dropdownOpen}
                  toggle={this.branchtoggle}
                  className="place-order w-100 mb-3"
                >
                  <DropdownToggle caret className="w-100" color="primary">
                    {branch_name}
                  </DropdownToggle>
                  <DropdownMenu className="w-100">
                    {branch_list &&
                      branch_list.map((row, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() => {
                              this.handleBranchChange(
                                row.branch_id,
                                row.branch_name
                              );
                            }}
                          >
                            {row.branch_name}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
                <h5 className="nav-header">Navigation</h5>
                  <ul className="nav flex-column ul-hover-active">
                      <li className="nav-link px-0">
                          <NavLink to="/analytics">Product Sales</NavLink>
                      </li>
                      <li className="nav-link px-0">
                          <NavLink to="/category_analytics">Payment Source</NavLink>
                      </li>
                      <li className="nav-link px-0">
                          <NavLink to="/time_analytics">Sales By Time</NavLink>
                      </li>
                      <li className="nav-link px-0">
                          <NavLink to="/partner_analytics">Partner Analytics</NavLink>
                      </li>
                      <li className="nav-link px-0">
                          <NavLink to="/trends_analytics">Sales Analytics</NavLink>
                      </li>
                      <li className="nav-link px-0">
                          <NavLink to="/customer_analytics">Customer Analytics</NavLink>
                      </li>
                  </ul>
              </div>
              <div class="today-main py-3 col-sm-8 col-md-9 col-lg-10">
                <Row>
                  <Col lg="4" md="6" className="px-3 mb-3">
                    <DateRangePicker
                      calendarInfoPosition="before"
                      renderCalendarInfo={this.renderDatePresets}
                      startDate={startDate}
                      startDateId="FromstartDate"
                      endDate={endDate}
                      endDateId="FromendDate"
                      onDatesChange={({ startDate, endDate }) => {
                        if (!startDate) {
                          startDate = endDate;
                        }

                        if (!endDate) {
                          endDate = startDate;
                        }
                        return this.handleDateChange(startDate, endDate);
                      }}
                      focusedInput={this.state.focusedInput}
                      onFocusChange={(focusedInput) =>
                        this.setState({ focusedInput })
                      }
                      orientation={this.state.orientation}
                      openDirection={this.state.openDirection}
                      isOutsideRange={() => false}
                      anchorDirection={"left"}
                    />
                  </Col>
                  <Col lg="4" md="6">
                    <Dropdown
                      isOpen={this.state.dayDropdown}
                      toggle={this.dayToggle}
                      className="place-order mb-3"
                    >
                      <DropdownToggle caret className="w-100" color="primary">
                        {this.state.selectedDay}
                      </DropdownToggle>
                      <DropdownMenu className="w-100">
                        {days.map((day, index) => {
                          return (
                            <DropdownItem
                              key={index}
                              onClick={() => {
                                this.handleDayChange(day);
                              }}
                            >
                              {day}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
                <Row className="mb-5">
                  <div
                    id="timeAnalyticsChart"
                    style={{ width: "100%" }}
                    ref={(el) => (this.chartElement = el)}
                  />
                </Row>
                <Row style={{ display: "grid", gridTemplateColumns: "1fr" }}>
                  {this.handleTableData()}
                </Row>
                <div className="alert alert-primary new-alert mt-3">
                  <strong className="mr-1">Note: </strong>{" "}
                  <span> Only Completed orders are visible here</span>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    getAnalyticsByTime: (params) => dispatch(getAnalyticsByTime(params)),
  };
}

function mapStateToProps(state) {
  return {
    TimeAnalytics: state.orders.TimeAnalytics,
    loggedInUser: state.commonData.loggedInUser,
  };
}

export default connect(mapStateToProps, bindActions)(TimeAnalytics);
