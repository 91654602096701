import React, {Component} from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import moment from "moment";
import {DateRangePicker} from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css";
import {Badge, Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Table,} from "reactstrap";
import {getAnalyticsCustomer} from "../../actions/orders";
import {VirtualTable,} from "@devexpress/dx-react-grid-bootstrap4";
import {HighlightedCell} from "../../theme-sources/highlighted-cell";
import _ from "lodash";
import Highcharts from "highcharts";

class CustomerTrendsAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().subtract(7, "months"),
            endDate: moment(),
            orderStatus: 0, // default all orders
            perPage: 10,
            offset: 0,
            searchText: "",
            preset: "",
            focusedInput: false,
            dropdownOpen: false,
            branch_id: 0,
            branch_name: "All branch",
            cityDropdown: false,
            city_id: "0",
            city_name: "All Cities"
        };

        this.filterOrders = this.filterOrders.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.renderDatePresets = this.renderDatePresets.bind(this);
        this.branchtoggle = this.branchtoggle.bind(this);
        this.handleBranchChange = this.handleBranchChange.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleChartData = this.handleChartData.bind(this);
        this.handleTableData = this.handleTableData.bind(this);
        this.citytoggle = this.citytoggle.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);

        const {
            branch_id,
            startDate,
            endDate,
            perPage,
            searchText,
            orderStatus,
            city_id
        } = this.state;
        let default_params = {
            branch_id: branch_id,
            status: orderStatus,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id: city_id
        };
        this.props.getAnalyticsCustomer(default_params);
    }

    componentDidMount() {
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let firstCityName = branch_list[0].city_name;
                let firstCityId = branch_list[0].city_id;
                this.handleCityChange(firstCityName, firstCityId);
            }
        }
    }


    componentWillReceiveProps(nextProps) {
        if (this.props.loggedInUser && (this.props.loggedInUser.branch_list != nextProps.loggedInUser.branch_list)) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let firstCityName = branch_list[0].city_name;
                let firstCityId = branch_list[0].city_id;
                this.handleCityChange(firstCityName, firstCityId);
            }
        }
    }

    citytoggle() {
        this.setState(prevState => ({
            cityDropdown: !prevState.cityDropdown
        }));
    }

    branchtoggle() {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }

    handleDateChange(startDate, endDate) {
        const {branch_id, orderStatus, perPage, offset, searchText} = this.state;
        this.setState({startDate: startDate, endDate: endDate});
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
        };
        this.props.getAnalyticsCustomer(params);
    }

    handleCityChange(city_name, city_id) {
        this.setState({city_id: city_id, city_name: city_name},()=>{
            this.props.loggedInUser.branch_list.map((value) => {
                if (city_id === value.city_id)
                    this.handleBranchChange(value.branch_list[0].branch_id, value.branch_list[0].branch_name);
            });
        });
    }

    handleBranchChange(branch_id, branch_name) {
        const {orderStatus, perPage, searchText, startDate, endDate,city_id} = this.state;
        this.setState({branch_id: branch_id, branch_name: branch_name});
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id:city_id
        };
        this.props.getAnalyticsCustomer(params);
    }

    filterOrders(bill_status) {
        const {branch_id, startDate, endDate, perPage, searchText,city_id} = this.state;
        this.setState({orderStatus: bill_status});
        let params = {
            branch_id: branch_id,
            status: bill_status,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id:city_id
        };
        this.props.getAnalyticsCustomer(params);
    }

    handlePagination(offset) {
        const {
            branch_id,
            startDate,
            endDate,
            orderStatus,
            searchText,
            perPage,
            city_id
        } = this.state;
        this.setState({offset: offset});
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: offset,
            perPage: perPage,
            sort: "ASC",
            city_id:city_id
        };
        this.props.getAnalyticsCustomer(params);
    }

    handleSearch(searchText) {
        const {
            branch_id,
            startDate,
            endDate,
            orderStatus,
            offset,
            perPage,
            city_id
        } = this.state;
        this.setState({searchText: searchText});
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: offset,
            perPage: perPage,
            sort: "ASC",
            city_id:city_id
        };
        this.props.getAnalyticsCustomer(params);
    }

    renderDatePresets() {
        return (
            <div className="date_custom_presets">
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment(), moment());
                    }}
                >
                    Today
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().subtract(1, "d"), moment().subtract(1, "d"));
                        this.setState({focusedInput: false});
                    }}
                >
                    Yesterday
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().subtract(7, "d"), moment());
                        this.setState({focusedInput: false});
                    }}
                >
                    Last 7 days
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().subtract(30, "d"), moment());
                        this.setState({focusedInput: false});
                    }}
                >
                    Last 30 days
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().startOf("month"), moment());
                        this.setState({focusedInput: false});
                    }}
                >
                    This month
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(
                            moment().subtract(1, "months").startOf("month"),
                            moment().subtract(1, "months").endOf("month")
                        );
                        this.setState({focusedInput: false});
                    }}
                >
                    Last month
                </Button>
                <Button className="w-100  mb-2" color="info">
                    Custom Range
                </Button>
            </div>
        );
    }

    handleChartData() {
        const {data} = this.props.AnalyticsCustomer;

        const newCustomersData = !_.isEmpty(data)
            ? data.map((d) => {
                return {
                    name: d.order_source_name,
                    y: +d.new_customers_count,
                };
            })
            : [];

        const repeatCustomerData = !_.isEmpty(data)
            ? _.map(data, (d) => {
                return {
                    name: d.order_source_name,
                    y: +d.repeat_customers_count,
                };
            })
            : [];

        const chartConfig = {
            chart: {
                type: "column",
            },
            accessibility: {
                announceNewData: {
                    enabled: true,
                },
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return this.y.toLocaleString("en-IN", {
                                maximumFractionDigits: 2,
                                currency: "INR",
                            });
                        },
                    },
                },
            },
            legend: {
                enabled: true,
            },
            title: {
                text: "New vs Repeat Customer",
            },
            xAxis: {
                type: "category",
            },
            yAxis: {
                title: {
                    text: "Customers",
                },
            },
            series: [
                {
                    name: "New Customers",
                    colorByPoint: true,
                    data: newCustomersData,
                },
                {
                    name: "Repeat Customers",
                    colorByPoint: true,
                    data: repeatCustomerData,
                },
            ],
            credits: {
                enabled: false,
            },
        };
        if (!_.isEmpty(data) && this.chartElement)
            Highcharts.chart("newRepeatCustomerChart", chartConfig);
    }

    handleTableData() {
        const {data} = this.props.AnalyticsCustomer;

        const headers = [
            "Source",
            "New Customers",
            "Repeat Customers",
            "Total Customers",
            "Repeat Share",
        ];
        const totalNewCustomers = _.reduce(
            data,
            (res, p) => res + +p.new_customers_count,
            0
        ).toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            currency: "INR",
        });

        const totalRepeatCustomers = _.reduce(
            data,
            (res, p) => res + +p.repeat_customers_count,
            0
        ).toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            currency: "INR",
        });
        const totalCustomers = _.reduce(
            data,
            (res, p) => res + +p.total,
            0
        ).toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            currency: "INR",
        });

        const totalPercentageShare = _.reduce(
            data,
            (res, p) => res + +p["%_share"] || 0,
            0
        ).toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            currency: "INR",
        });
        const repeatShare = _.reduce(
            data,
            (res, p) => res + +p["repeat_share"] || 0,
            0
        ).toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            currency: "INR",
        });
        return !_.isEmpty(data) ? (
            <div className="table-holder">
                <h5 className="table-header-analytics">Break Up</h5>
                <Table responsive className="my-table">
                    <thead>
                    <tr className="my-table-heading">
                        {_.map(headers, (header) => (
                            <td className="font-weight-bold">{header}</td>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {_.map(data, (p) => (
                        <tr>
                            <td>
                                {p.order_source_name.toLocaleString("en-IN", {
                                    maximumFractionDigits: 2,
                                    currency: "INR",
                                })}
                            </td>
                            <td>
                                {p.new_customers_count.toLocaleString("en-IN", {
                                    maximumFractionDigits: 2,
                                    currency: "INR",
                                })}
                            </td>
                            <td>
                                {p.repeat_customers_count.toLocaleString("en-IN", {
                                    maximumFractionDigits: 2,
                                    currency: "INR",
                                })}
                            </td>
                            <td>
                                {p.total.toLocaleString("en-IN", {
                                    maximumFractionDigits: 2,
                                    currency: "INR",
                                })}
                            </td>
                            <td>{p["repeat_share"] + " %"}</td>

                        </tr>
                    ))}
                    <tr>
                        <td className="font-weight-bold">Total</td>
                        <td className="font-weight-bold">{totalNewCustomers}</td>
                        <td className="font-weight-bold">{totalRepeatCustomers}</td>
                        <td className="font-weight-bold">{totalCustomers}</td>
                        <td className="font-weight-bold">-</td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        ) : (
            []
        );
    }

    render() {
        const Cell = (props) => {
            const {column} = props;
            if (column.name === "data[1100]") {
                return <HighlightedCell {...props} />;
            }
            return <VirtualTable.Cell {...props} />;
        };

        const columns = [
            {name: "order_source_name", title: "Source"},
            {name: "new_customers_count", title: "New Customers"},
            {name: "repeat_customers_count", title: "Repeat Customers"},
            {name: "total", title: "Total"},
            {name: "repeat_share", title: "Repeat Share"},

        ];
        const rows = this.props.AnalyticsCustomer.data;

        const tableColumnExtensions = [
            {columnName: "new_customers_count", align: "right"},
            {columnName: "repeat_customers_count", align: "right"},
        ];
        const currencyColumns = ["new_customers_count", "repeat_customers_count"];
        const booleanColumns = ["shipped"];
        const {startDate, endDate, branch_name, orderStatus,city_id,city_name} = this.state;

        let orderStatusList = [];
        if (this.props.AnalyticsCustomer && this.props.AnalyticsCustomer.meta) {
            orderStatusList = this.props.AnalyticsCustomer.meta;
        }
        let statusList = {
            ORDER_TEMP: "Temp",
            ORDER_ONLINE_PAYMENT_PROCESSING: "Payment Pending",
            ORDER_PLACED: "New Order",
            ORDER_ACCEPTED: "In Process",
            ORDER_PACKED: "Ready",
            ORDER_ASSIGNED: "Assigned",
            ORDER_DISPATCHED: "Dispatched",
            ORDER_DELIVERED: "Delivered",
            ORDER_REJECTED: "Rejected",
            ORDER_CANCELLED: "Canceled",
        };
        let city_list = [];
        let branch_list = [];
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {

            this.props.loggedInUser.branch_list.map((item)=>{
                if(city_id===item.city_id)
                    branch_list = item.branch_list;
                city_list.push(item);
            });
        }
        let all_branch_outline = true;
        if (orderStatus === 0) {
            all_branch_outline = false;
        }

        this.handleChartData();

        return (
            <div className="animated fadeIn">
                <Row className="ml-2 mr-2 mt-2 orders">
                    <Col md="3" lg="3" xl="2" className="p-2 orders-side d-none">
                        <hr/>
                        <label className="filter-label">Order History</label>
                        <Button
                            color="primary"
                            className="w-100 text-left orderStatusBtn"
                            outline={all_branch_outline}
                            onClick={() => {
                                this.filterOrders(0);
                            }}
                        >
                            All Orders
                            <Badge className="float-right ml-1 p-1 orderCount">
                                {this.props.AnalyticsCustomer &&
                                this.props.AnalyticsCustomer.order_list &&
                                this.props.AnalyticsCustomer.order_list.iTotalRecords}
                            </Badge>
                        </Button>
                        {orderStatusList &&
                        orderStatusList.length > 0 &&
                        orderStatusList.map((element, index) => {
                            let outline =
                                element.bill_status !== this.state.orderStatus ? true : false;
                            return (
                                <Button
                                    color="primary"
                                    className="w-100 text-left orderStatusBtn"
                                    outline={outline}
                                    onClick={() => {
                                        this.filterOrders(element.bill_status);
                                    }}
                                >
                                    {statusList[element.order_status_value]}
                                    <Badge className="float-right ml-1 p-1 orderCount">
                                        {element.bill_count}
                                    </Badge>
                                </Button>
                            );
                        })}
                    </Col>

                    <Col className="orders-main px-3">
                        <Row>
                            <div className="p-3 today-side col-sm-4 col-md-3 col-lg-2 border-right">

                                <Dropdown
                                    isOpen={this.state.cityDropdown}
                                    toggle={this.citytoggle}
                                    className="place-order w-100 mb-3"
                                >
                                    <DropdownToggle caret className="w-100" color="primary">
                                        {city_name}
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        {city_list &&
                                        city_list.map((row, index) => {
                                            return (
                                                <DropdownItem
                                                    key={index}
                                                    onClick={() => {
                                                        this.handleCityChange(
                                                            row.city_name,
                                                            row.city_id
                                                        );
                                                    }}
                                                >
                                                    {row.city_name}
                                                </DropdownItem>
                                            );
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown
                                    isOpen={this.state.dropdownOpen}
                                    toggle={this.branchtoggle}
                                    className="place-order w-100 mb-3"
                                >
                                    <DropdownToggle caret className="w-100" color="primary">
                                        {branch_name}
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        {branch_list &&
                                        branch_list.map((row, index) => {
                                            return (
                                                <DropdownItem
                                                    key={index}
                                                    onClick={() => {
                                                        this.handleBranchChange(
                                                            row.branch_id,
                                                            row.branch_name
                                                        );
                                                    }}
                                                >
                                                    {row.branch_name}
                                                </DropdownItem>
                                            );
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                                <h5 className="nav-header">Navigation</h5>
                                <ul className="nav flex-column ul-hover-active">
                                    <li className="nav-link px-0">
                                        <NavLink to="/analytics">Product Sales</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/category_analytics">Payment Source</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/time_analytics">Sales By Time</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/partner_analytics">Partner Analytics</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/trends_analytics">Sales Analytics</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/customer_analytics">Customer Analytics</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="today-main py-3 col-sm-8 col-md-9 col-lg-10">
                                <Row>
                                    <Col lg="4" md="6" className="px-3 mb-3">
                                        <DateRangePicker
                                            calendarInfoPosition="before"
                                            renderCalendarInfo={this.renderDatePresets}
                                            startDate={startDate}
                                            startDateId="FromstartDate"
                                            endDate={endDate}
                                            endDateId="FromendDate"
                                            onDatesChange={({startDate, endDate}) => {
                                                // console.log({ startDate, endDate })

                                                if (!startDate) {
                                                    startDate = endDate;
                                                }

                                                if (!endDate) {
                                                    endDate = startDate;
                                                }
                                                return this.handleDateChange(startDate, endDate);
                                            }}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={(focusedInput) =>
                                                this.setState({focusedInput})
                                            }
                                            orientation={this.state.orientation}
                                            openDirection={this.state.openDirection}
                                            isOutsideRange={() => false}
                                            anchorDirection={"left"}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-5">
                                    <div
                                        id="newRepeatCustomerChart"
                                        style={{width: "100%"}}
                                        ref={(el) => (this.chartElement = el)}
                                    />
                                </Row>
                                <Row style={{display: "grid", gridTemplateColumns: "1fr"}}>
                                    {this.handleTableData()}
                                </Row>
                                <div className="alert alert-primary new-alert mt-3">
                                    <strong className="mr-1">Note: </strong>{" "}
                                    <span> Only Completed orders are visible here</span>
                                </div>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

function bindActions(dispatch) {
    return {
        getAnalyticsCustomer: (params) => dispatch(getAnalyticsCustomer(params)),
    };
}

function mapStateToProps(state) {
    return {
        AnalyticsCustomer: state.orders.AnalyticsCustomer,
        loggedInUser: state.commonData.loggedInUser,
        // ordersList
    };
}

export default connect(mapStateToProps, bindActions)(CustomerTrendsAnalytics);
