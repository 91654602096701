import {
  SET_CUSTOMERS_LIST,
  SET_CUSTOMER,
  SET_CUSTOMER_ORDER_LIST
} from "../actions/customers";

const initialState = {
  customersList: [],
  customer: {},
  customerOrdersList: []
};

const orders = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMERS_LIST:
      return { ...state, customersList: action.payload };
    case SET_CUSTOMER:
      return { ...state, customer: action.payload };
    case SET_CUSTOMER_ORDER_LIST:
      return { ...state, customerOrdersList: action.payload };
    default:
      return state;
  }
};

export default orders;
