import {
  SET_BILLING_CUSTOMER,
  SET_ORDER_META,
  SET_POS_META,
  SET_BILLING_CUSTOMER_ADDRESS,
  SET_ORDER_SUCCESS,
  SET_MENU
} from "../actions/billing";

const initialState = {
  customer: [],
  customerAddress: [],
  orderMeta: {},
  posMeta: {},
  orderSuccess: "",
  menu: []
};

const billing = (state = initialState, action) => {
  switch (action.type) {
    case SET_BILLING_CUSTOMER:
      return { ...state, customer: action.payload };
    case SET_BILLING_CUSTOMER_ADDRESS:
      return { ...state, customerAddress: action.payload };
    case SET_ORDER_META:
      return { ...state, orderMeta: action.payload };
    case SET_POS_META:
      return { ...state, posMeta: action.payload };
    case SET_ORDER_SUCCESS:
      return { ...state, orderSuccess: action.payload };
    case SET_MENU:
      return { ...state, menu: action.payload };
    default:
      return state;
  }
};

export default billing;
