import { combineReducers } from "redux";
import common_reducer from "./commonData";
import auth_reducer from "./auth";
import dashboard_reducer from "./dashboard";
import orders_reducer from "./orders";
import products_reducer from "./products";
import banners_reducer from "./banners";
import customers_reducer from "./customers";
import staff_reducer from "./staff";
import cash_reducer from "./cash";
import branch_reducer from "./branch";
import billing_reducer from "./billing";
import wallet_reducer from "./wallet";
import { reducer as form } from "redux-form";

const rootReducer = combineReducers({
  commonData: common_reducer,
  auth: auth_reducer,
  dashboard : dashboard_reducer,
  orders : orders_reducer,
  products : products_reducer,
  customers : customers_reducer,
  staff : staff_reducer,
  cash : cash_reducer,
  branch : branch_reducer,
  billing : billing_reducer,
  wallet : wallet_reducer,
  banners : banners_reducer,
  form
});

export default rootReducer;
