import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Button,
  Col,
  Row,
  Input,
  InputGroupAddon,
  InputGroup,
  Collapse
} from "reactstrap";

class ProductPrice extends Component {
  constructor(props) {
    super(props);
    const { posMeta } = this.props;
    this.state = {
      coupon_text: "",
      serviceType: "",
      orderSource: "",
      paymentType: "",
      delivery_charge: this.props.delivery_charge,
      packaging_charge: this.props.packaging_charge,
      discount_amount: this.props.discount_amount,
      reference_number: "",
      instruction: "",
      collapse: false,
      delivery_by: 0
    };
    this.updateCart = this.updateCart.bind(this);
    this.toggle = this.toggle.bind(this);
    this.validateCoupon = this.validateCoupon.bind(this);
    this.handleCustomCharges = this.handleCustomCharges.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  componentWillReceiveProps({ cartList }) {
    // console.log("componentWillReceiveProps");
    // console.log(cartList);
    this.setState({ ...this.state, cartList });
  }

  componentDidUpdate(previousProps, previousState) {
    const { posMeta, orderMeta } = this.props;
    const { serviceType, orderSource, paymentType } = this.state;
    if (previousProps.posMeta != posMeta) {
      if (
        !paymentType &&
        posMeta &&
        posMeta.payment_modes &&
        posMeta.payment_modes.length > 0
      ) {
        this.setState({
          paymentType: posMeta.payment_modes[0].payment_mode_id
        });
      }

      if (
        !serviceType &&
        posMeta &&
        posMeta.service_types &&
        posMeta.service_types.length > 0
      ) {
        this.setState({
          service_types: posMeta.service_types[0].serve_type_id
        });
      }

      if (
        !orderSource &&
        posMeta &&
        posMeta.order_sources &&
        posMeta.order_sources.length > 0
      ) {
        this.setState({
          orderSource: posMeta.order_sources[0].order_source_id
        });
      }
    }

    if (previousProps.orderMeta != orderMeta) {
      if (orderMeta.coupon && orderMeta.coupon.coupon_name) {
        this.setState({
          coupon_text: orderMeta.coupon.coupon_name
        });
      }
    }
  }

  handleCustomCharges() {
    const { discount_amount, delivery_charge, packaging_charge } = this.state;
    this.props.handleCustomCharges(
      discount_amount,
      delivery_charge,
      packaging_charge
    );
  }

  updateCart(productMeta, type) {
    // console.log(productMeta, type);
    this.props.addtoCart(productMeta, type);
  }

  validateCoupon() {
    const { coupon_text } = this.state;
    // console.log(coupon_text);
    this.props.validateCoupon(coupon_text);
  }

  submitOrder() {
    const {
      serviceType,
      orderSource,
      paymentType,
      discount_amount,
      delivery_charge,
      packaging_charge,
      reference_number,
      instruction,
      delivery_by
    } = this.state;
    let params = {
      serviceType,
      orderSource,
      paymentType,
      discount_amount,
      delivery_charge,
      packaging_charge,
      reference_number,
      instruction,
      delivery_by
    };
    // console.log(params);
    this.props.placeOrder(params);
  }

  render() {
    const { orderMeta, productListMeta, cartList, posMeta } = this.props;
    const {
      coupon_text,
      discount_amount,
      delivery_charge,
      packaging_charge,
      reference_number,
      instruction
    } = this.state;
    let updateCart = this.updateCart;

    return (
      <div className="billing-left-scroll">
        <div className="p-2">Customer Cart</div>
        <Col sm="12" className="p-0 product-list">
          {Object.keys(cartList).length === 0 && (
            <Row className="p-2 pb-4 pt-4 each-item w-100 m-0">
              <Col
                sm="12"
                className="pl-2 pr-0 pb-1 font-weight-bolder text-center"
              >
                Add items to cart
              </Col>
            </Row>
          )}
          {Object.keys(cartList).map(function(key) {
            if (cartList[key] > 0) {
              let productMeta = productListMeta[key];
              return (
                <Row className="p-2 pb-4 pt-4 each-item w-100 m-0">
                  <Col sm="8" className="pl-2 pr-0 pb-1 ">
                    <span className="font-weight-bolder">
                      {productMeta.product_name}
                    </span>
                    <span className="ml-2">
                      {productMeta.serving_size_name}
                    </span>
                  </Col>
                  <Col sm="4" className="p-1 font-weight-bold">
                    {productMeta.price * cartList[key]}
                  </Col>
                  <Col sm="12" className="p-1">
                    <span
                      className="remove-qty p-1 pl-3 pr-3"
                      onClick={() => {
                        updateCart(
                          {
                            product_id: productMeta.product_id,
                            product_name: productMeta.product_name,
                            serving_size_name: productMeta.serving_size_name,
                            price: productMeta.price,
                            product_price_id: productMeta.product_price_id
                          },
                          "sub"
                        );
                      }}
                    >
                      -
                    </span>
                    <span className="m-2 p-1 pl-2 pr-2">{cartList[key]}</span>

                    <span
                      className="add-qty p-1 pl-3 pr-3"
                      onClick={() => {
                        updateCart(
                          {
                            product_id: productMeta.product_id,
                            product_name: productMeta.product_name,
                            serving_size_name: productMeta.serving_size_name,
                            price: productMeta.price,
                            product_price_id: productMeta.product_price_id
                          },
                          "add"
                        );
                      }}
                    >
                      +
                    </span>
                  </Col>
                </Row>
              );
            } else {
              return "";
            }
          })}
        </Col>
        <Col sm="12">
          <InputGroup className="mb-3 mt-4">
            <Input
              type="text"
              placeholder="Coupon Code"
              autoComplete="coupon_text"
              onChange={e => {
                this.setState({ coupon_text: e.target.value });
              }}
              tabIndex="0"
              value={coupon_text}
            />
            <InputGroupAddon addonType="append">
              <Button
                color="success"
                outline
                onClick={() => {
                  this.validateCoupon();
                }}
              >
                Validate
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <Button
            color="primary"
            id="toggleCharges"
            outline="true"
            className="w-100 mb-2"
            onClick={() => {
              this.toggle();
            }}
          >
            Edit Discount and Charges
          </Button>
          <Collapse toggler="#toggleCharges" isOpen={true}>
            <Input
              type="number"
              className="mb-3 mt-4"
              placeholder="Custom Discount Amount"
              autoComplete="disc_amt"
              onChange={e => {
                this.setState({ discount_amount: e.target.value });
              }}
              onBlur={e => {
                this.setState({ discount_amount: e.target.value });
                this.handleCustomCharges();
              }}
              tabIndex="0"
              value={discount_amount}
            />
            <Input
              type="number"
              className="mb-3 mt-4"
              placeholder="Custom Packaging Charge"
              autoComplete="disc_amt"
              onChange={e => {
                this.setState({ packaging_charge: e.target.value });
              }}
              onBlur={e => {
                this.setState({ packaging_charge: e.target.value });
                this.handleCustomCharges();
              }}
              tabIndex="0"
              value={packaging_charge}
            />
            <Input
              type="number"
              className="mb-3 mt-4"
              placeholder="Custom Delivery Charge"
              autoComplete="disc_amt"
              onChange={e => {
                this.setState({ delivery_charge: e.target.value });
              }}
              onBlur={e => {
                this.setState({ delivery_charge: e.target.value });
                this.handleCustomCharges();
              }}
              tabIndex="0"
              value={delivery_charge}
            />
          </Collapse>
        </Col>
        <div className="amount-breakdown p-4">
          <table className="w-100">
            <tbody>
              <tr className="pb-2">
                <td>Subtotal</td>
                <td className="font-weight-bolder text-right">
                  {(orderMeta && orderMeta.subtotal) || 0}
                </td>
              </tr>
              {orderMeta && orderMeta.coupon && (
                <tr className="pb-2">
                  <td>
                    Discount
                    <br />
                    <span className="text-muted">
                      {orderMeta.coupon.coupon_name}
                    </span>
                  </td>

                  <td className="font-weight-bolder text-right">
                    {(orderMeta.coupon.discount && orderMeta.coupon.discount) ||
                      0}
                  </td>
                </tr>
              )}
              {orderMeta &&
                orderMeta.charges &&
                orderMeta.charges.length > 0 &&
                orderMeta.charges.map((obj, index) => {
                  return (
                    <tr className="pb-2">
                      <td>{obj.name}</td>

                      <td className="text-right">{obj.value}</td>
                    </tr>
                  );
                })}
              {orderMeta &&
                orderMeta.taxes &&
                orderMeta.taxes.length > 0 &&
                orderMeta.taxes.map((obj, index) => {
                  return (
                    <tr className="pb-2">
                      <td>{obj.tax_type_name}</td>

                      <td className="text-right">{obj.total_value}</td>
                    </tr>
                  );
                })}
            </tbody>
            <tfoot>
              <tr className="pb-2">
                <td>Total</td>

                <td className="font-weight-bolder text-right">
                  {(orderMeta && orderMeta.total) || 0}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="other-selection p-2">
          {posMeta &&
            posMeta.payment_modes &&
            posMeta.payment_modes.length > 0 && (
              <Input
                type="select"
                className="mb-3"
                name="paymentType"
                id="paymentType"
                onChange={e => {
                  this.setState({ paymentType: e.target.value });
                }}
                value={this.state.paymentType}
              >
                {posMeta.payment_modes.map((element, index) => {
                  return (
                    <option
                      value={element.payment_mode_id}
                      key={element.payment_mode_id}
                    >
                      {element.payment_mode_value}
                    </option>
                  );
                })}
              </Input>
            )}
          {posMeta &&
            posMeta.order_sources &&
            posMeta.order_sources.length > 0 && (
              <Input
                type="select"
                className="mb-3"
                name="orderSource"
                id="orderSource"
                onChange={e => {
                  this.setState({ orderSource: e.target.value });
                }}
                value={this.state.orderSource}
              >
                {posMeta.order_sources.map((element, index) => {
                  return (
                    <option
                      value={element.order_source_id}
                      key={element.order_source_id}
                    >
                      {element.order_source_name}
                    </option>
                  );
                })}
              </Input>
            )}
          {posMeta &&
            posMeta.service_types &&
            posMeta.service_types.length > 0 && (
              <Input
                type="select"
                className="mb-3"
                name="serviceType"
                id="serviceType"
                onChange={e => {
                  this.setState({ serviceType: e.target.value });
                }}
                value={this.state.serviceType}
              >
                {posMeta.service_types.map((element, index) => {
                  return (
                    <option
                      value={element.serve_type_id}
                      key={element.serve_type_id}
                    >
                      {element.serve_type_name}
                    </option>
                  );
                })}
              </Input>
            )}


          <Input
              type="select"
              className="mb-3"
              name="deliveryBy"
              id="deliveryBy"
              onChange={e => {
                this.setState({ delivery_by: e.target.value });
              }}
              value={this.state.delivery_by}
          >
            <option value="0">Delivery by Potful</option>
            <option value="1">Delivery by Partner</option>
          </Input>
          <Input
            type="text"
            className="mb-3"
            placeholder="Reference No"
            autoComplete="reference_number"
            onChange={e => {
              this.setState({ reference_number: e.target.value });
            }}
            tabIndex="0"
            value={reference_number}
          />
          <Input
            type="textarea"
            className="mb-3"
            placeholder="Instructions"
            autoComplete="instruction"
            onChange={e => {
              this.setState({ instruction: e.target.value });
            }}
            value={instruction}
          />
        </div>
        <Button
          color="success"
          className="w-100 add-address"
          onClick={() => {
            this.submitOrder();
          }}
        >
          Checkout
        </Button>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {};
}

function mapStateToProps(state) {
  return {};
}
export default connect(
  mapStateToProps,
  bindActions
)(ProductPrice);
