import config from "../config";
import { getToken, setToken, clearToken } from "../utils";
import { setMessage, setMessageType, setAuthToken } from "./commonData";
import axios from "axios";
import { setSpinner } from "./commonData";
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const SET_LOGGEDIN_USER = "SET_LOGGEDIN_USER";
export const SET_TEMP_TOKEN = "SET_TEMP_TOKEN";
export const SET_FCM_TOKEN = "SET_FCM_TOKEN";


const options = {
    autoClose: 2000,
    // closeButton:,
    type: toast.TYPE.INFO,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_LEFT,
    pauseOnHover: true
    // progress: 0.2
};

var axiosInstance = axios.create({
  baseURL: config.baseUrl,
  timeout: 150000,
  headers: {
    "Content-Type": "application/json",
    version: "2.0",
    // "Authorization": `Token=${getToken()}`
  }
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.status === 401) {

    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  } else {
    // Something happened in setting up the request that triggered an Error
  }
  return Promise.reject(error);
});

function setLoggedInUser(payload) {
  return { type: SET_LOGGEDIN_USER, payload: payload };
}

function setTempToken(payload) {
  return { type: SET_TEMP_TOKEN, payload: payload };
}

function storeFCMToken(payload){
  return {
    type: SET_FCM_TOKEN,
    payload: payload
  }
}

export function forgotPassword(username) {
  // authentication/forgotPassword
  return function (dispatch) {
    axiosInstance
      .post(`${config.baseUrl}authentication/forgotPassword`, {
        username: username,
      })
      .then(response => {
        if (response.data) {
          dispatch(setTempToken(response.data.data));
        }
      }).catch(err => {
        dispatch(setSpinner(false));
        // dispatch(setSpinner(false));
        // console.log(err);
        toast.error("Oops!! Something went wrong", options)
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
      })

  };
}

export function setFCMToken(token){
  return function(dispatch){
    axiosInstance
      .post(`${config.baseUrl}authentication/update_fcm`, {
        fcm_token: token
    })
      .then(response => {
        // console.log("responnnnnneeee", response)
        if(response.data){
          dispatch(storeFCMToken(token));
        }
      })
  }
}

export function verifyUserOTP(otp, temp_access_token) {
  // authentication/verifyUserOTP
  return function (dispatch) {
    axiosInstance
      .post(`${config.baseUrl}authentication/verifyUserOTP`, {
        otp_text: otp,
        temp_access_token: temp_access_token
      })
      .then(response => {
        if (response.data) {
          dispatch(setTempToken(response.data.data));
        }
      }).catch(err => {
        // dispatch(setSpinner(false));
        // if(err.response && err.response.data) {
        //   dispatch(setMessage(err.response.data.errors));
        // } else {
        //   dispatch(setMessage("Oops!! Something went wrong"));
        // }
        //
        // dispatch(setMessageType("danger"));
        toast.error("Opps! Something went wrong", options)
      });
  };
}

export function setPassword(username, temp_access_token, password) {
  // authentication/forgotPassword
  return function (dispatch) {
    axiosInstance
      .post(`${config.baseUrl}authentication/setPassword`, {
        password: password
      }, {
          headers: {
            "Content-Type": "application/json",
            version: "2.0",
            "Authorization": `Token=${temp_access_token}`,
          }
        })
      .then(response => {
        if (response.data && response.data.data && response.data.data.status) {
          // setTempToken(response.authorization_token);
            toast.info("Password reset successfully, please login again.", options)
        }
      }).catch(err => {
        dispatch(setSpinner(false));
        toast.error("Opps! Something went wrong", options)
      });
  };
}


// Login with username and password
export function login(username, password) {
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .post(`${config.baseUrl}authentication/verify_user_login`, {
        username: username,
        password: password,
        portal: 1
      })
      .then(response => {
        dispatch(setSpinner(false));
        // console.log("responseeeeee", response);
        let res = response.data;
        if (res.data && res.data.accessToken) {
          let data = res.data;
          setToken(data.accessToken);
          dispatch(setAuthToken(true));
          dispatch(setLoggedInUser(data));
          window.location.reload(true);
        } else {
          dispatch(setSpinner(false));
          dispatch(setAuthToken(false));
            toast.error("Oops! Something went wrong", options)
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
        }

        // dispatch(setSettingsList(allConfigurations));
      }).catch(err => {
        dispatch(setSpinner(false));
        toast.error("Oops! Something went wrong", options)
        // if(err.response && err.response.data) {
        //   dispatch(setMessage(err.response.data.errors));
        // } else {
        //   dispatch(setMessage("Oops!! Something went wrong"));
        // }
        //
        // dispatch(setMessageType("danger"));
      });
  };
}

export function logout() {
  let token = getToken();
  return function (dispatch) {
    fetch(`${config.baseUrl}logout`, {
      method: "GET",
      body: null,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      }
    })
      .then(response => response.json())
      .then(response => {
        clearToken();
        dispatch(setLoggedInUser([]));
      })
      .catch(err => {
        dispatch(setSpinner(false));
          toast.error("Oops! Something went wrong", options)
        // if(err.response && err.response.data) {
        //   dispatch(setMessage(err.response.data.errors));
        // } else {
        //   dispatch(setMessage("Oops!! Something went wrong"));
        // }
        //
        // dispatch(setMessageType("danger"));
      });
  };
}
