import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import {formatNumbersWithThousandSeparator} from "../../utils";

class Orders extends Component {
  render() {
    const numbers = this.props.numbers;
    console.log(numbers);
    return (
      <div className="col-md-12">
        {numbers && (
          <Row className="order-summary">
            <Col sm="12" md="2" className="order-summary-card">
              <Card className="text-white bg-orders">
                <CardBody className="">
                  <h6>Orders</h6>
                  <div className="text-value">{formatNumbersWithThousandSeparator(Math.round(numbers.bill_count))}</div>

                  {/* <div>Average: {Math.round(numbers.avg_bill_count)}</div> */}
                  {/*<br />*/}
                  {/*<div>Average</div>*/}
                  {/*<div className="text-sub-value">{Math.round(numbers.avg_bill_count)}</div>*/}
                  {/*<hr />*/}
                  {/*<Row className="p-0">*/}
                  {/*<Col sm="6" className="p-0">*/}
                  {/*Processed <br />*/}
                  {/*<span className="text-sub-value">{Math.round(numbers.total_bill_processed)}</span>*/}
                  {/*</Col>*/}
                  {/*<Col sm="6" className="p-0">*/}
                  {/*Unprocessed <br />*/}
                  {/*<span className="text-sub-value">{Math.round(numbers.total_bill_unprocessed)}</span>*/}
                  {/*</Col>*/}
                  {/*</Row>*/}
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" md="2" className="order-summary-card">
              <Card className="text-white bg-gross">
                <CardBody className="">
                  <h6>Gross <span className="d-none d-xl-inline">Revenue</span></h6>
                  <div className="text-value">{formatNumbersWithThousandSeparator(Math.round(numbers.gross_revenue))}</div>
                  {/* <div>Average: {Math.round(numbers.avg_bill_value)}</div> */}
                  {/*<br />*/}
                  {/*<div>Average</div>*/}
                  {/*<div className="text-sub-value">{Math.round(numbers.avg_bill_value)}</div>*/}
                  {/*<hr />*/}
                  {/*<Row className="p-0">*/}
                  {/*<Col sm="6" className="p-0">*/}
                  {/*Processed <br />*/}
                  {/*<span className="text-sub-value">{Math.round(numbers.processed_bill_value)}</span>*/}
                  {/*</Col>*/}
                  {/*<Col sm="6" className="p-0">*/}
                  {/*Unprocessed <br />*/}
                  {/*<span className="text-sub-value">{Math.round(numbers.unprocessed_bill_value)}</span>*/}
                  {/*</Col>*/}
                  {/*</Row>*/}
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" md="2" className="order-summary-card">
              <Card className="text-white bg-discount">
                <CardBody className="">
                  <h6>Discount</h6>
                  <div className="text-value">{formatNumbersWithThousandSeparator(Math.round(numbers.discount_value))}</div>
                  {/* <div>Average: {Math.round(numbers.avg_discount_value)}</div> */}
                  {/*<br />*/}
                  {/*<div>Average</div>*/}
                  {/*<div className="text-sub-value">{Math.round(numbers.avg_discount_value)}</div>*/}
                  {/*<hr />*/}
                  {/*<Row className="p-0">*/}
                  {/*<Col sm="6" className="p-0">*/}
                  {/*Processed <br />*/}
                  {/*<span className="text-sub-value">NA</span>*/}
                  {/*</Col>*/}
                  {/*<Col sm="6" className="p-0">*/}
                  {/*Unprocessed <br />*/}
                  {/*<span className="text-sub-value">NA</span>*/}
                  {/*</Col>*/}
                  {/*</Row>*/}
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" md="2" className="order-summary-card">
              <Card className="text-white bg-net">
                <CardBody className="">
                  <h6>Net Sales</h6>
                  <div className="text-value">{formatNumbersWithThousandSeparator(Math.round(numbers.net_value))}</div>
                  {/* <div>Average: {Math.round(numbers.avg_discount_value)}</div> */}
                  {/*<br />*/}
                  {/*<div>Average</div>*/}
                  {/*<div className="text-sub-value">{Math.round(numbers.avg_discount_value)}</div>*/}
                  {/*<hr />*/}
                  {/*<Row className="p-0">*/}
                  {/*<Col sm="6" className="p-0">*/}
                  {/*Processed <br />*/}
                  {/*<span className="text-sub-value">NA</span>*/}
                  {/*</Col>*/}
                  {/*<Col sm="6" className="p-0">*/}
                  {/*Unprocessed <br />*/}
                  {/*<span className="text-sub-value">NA</span>*/}
                  {/*</Col>*/}
                  {/*</Row>*/}
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" md="2" className="order-summary-card">
              <Card className="text-white bg-lost">
                <CardBody className="">
                  <h6><span className="d-none d-xl-inline">Revenue</span> Lost ({numbers.lost_bill_count})</h6>
                  <div className="text-value">{formatNumbersWithThousandSeparator(Math.round(numbers.revenue_lost))}</div>
                  {/* <div>Average: {numbers.avg_revenue_lost || 0}</div> */}
                </CardBody>
              </Card>
            </Col>
              <Col sm="6" md="2" className="order-summary-card">
                  <Card className="text-white bg-adt">
                      <CardBody className="">
                          <h6>ADT (Min)</h6>
                          <div className="text-value">{formatNumbersWithThousandSeparator(Math.round(numbers.adt))}</div>
                          {/* <div>Average: {numbers.avg_revenue_lost || 0}</div> */}
                      </CardBody>
                  </Card>
              </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default Orders;
// adt: 2
// avg_value: "541.868421"
// bill_count: "19"
// discount_value: "200.00"
// gross_revenue: 10295.5
// lost_bill_count: "1"
// net_value: "10095.50"
// revenue_lost: "347.90"
