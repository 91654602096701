import {
    SET_ANALYTICS_CATEGORY_LIST,
    SET_CATEGORY_LIST,
    SET_CURRENT_PRODUCT,
    SET_PRODUCT_SERVING_LIST,
    SET_PRODUCT_SUGGESTION_LIST,
    SET_PRODUCTS_LIST,
} from "../actions/products";

const initialState = {
    productsList: [],
    currentProduct: {},
    categoryList: [],
    productaAnalyticsList: [],
    suggestionList: [],
    productServingList: []
};

const products = (state = initialState, action) => {
    switch (action.type) {
        case SET_PRODUCTS_LIST:
            return {...state, productsList: action.payload};
        case SET_CURRENT_PRODUCT:
            return {...state, currentProduct: action.payload};
        case SET_CATEGORY_LIST:
            return {...state, categoryList: action.payload};
        case SET_PRODUCT_SUGGESTION_LIST:
            return {...state, suggestionList: action.payload};
        case SET_PRODUCT_SERVING_LIST:
            return {...state, productServingList: action.payload};
        case SET_ANALYTICS_CATEGORY_LIST:
            return {...state, productaAnalyticsList: action.payload};
        default:
            return state;
    }
};

export default products;
