import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polygon,
  Circle
} from "react-google-maps";

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCDGzqs5nUncCrBHcmxUXN1OuQpWzIOpW4",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `70vh` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={11}
    defaultCenter={{
      lat: props.serviceArea.lat,
      lng: props.serviceArea.lng
    }}
  >
    {props.isMarkerShown && (
      <Marker
        position={{ lat: props.serviceArea.lat, lng: props.serviceArea.lng }}
      />
    )}
    {props.coords.length > 0 && (
      <Polygon
        path={props.coords}
        //key={1}
        options={{
          fillColor: "#000",
          fillOpacity: 0.4,
          strokeColor: "#000",
          strokeOpacity: 1,
          strokeWeight: 1
        }}
      />
    )}
    {/* {props.circle && ( */}
    <Circle
      center={{
        lat: parseFloat(props.serviceArea.lat),
        lng: parseFloat(props.serviceArea.lng)
      }}
      radius={5000}
      options={{
        fillColor: "#FFFF99",
        fillOpacity: 0.5,
        strokeColor: "red",
        strokeOpacity: 1,
        strokeWeight: 1
      }}
    />
    {/* )} */}
  </GoogleMap>
));

export default MyMapComponent;
