import Cookies from "js-cookie";
import config from "../config";

import {messaging} from "../init-fcm";


export const setToken = (value, tokenKey = config.cookieTokenKey) => {
    // console.log(value);
    // console.log(tokenKey);
    Cookies.set(tokenKey, value, {
        expires: 1,
        path: config.cookiePath,
        domain: config.cookieDomain
    });
};

export const setFCMToken = (value, tokenKey = config.cookieFCMKey) => {
    Cookies.set(tokenKey, value, {
        expires: 1,
        path: config.cookiePath,
        domain: config.cookieDomain
    });
};

export const getToken = (tokenKey = config.cookieTokenKey) => {
    let token = Cookies.get(tokenKey);
    return token;
};

export const getFCMKey = (tokenKey = config.cookieFCMKey) => {
    let token = Cookies.get(tokenKey);
    return token;
};


export const clearToken = (tokenKey = config.cookieTokenKey, fcmKey = config.cookieFCMKey) => {
    Cookies.remove(tokenKey, {
        domain: config.cookieDomain,
        path: config.cookiePath
    });

    if (messaging != null) {
        messaging.deleteToken(getFCMKey()).then(() => {
            // console.log('Token deleted.');
        }).catch((err) => {
            // console.log('Unable to delete token. ', err);
        });
    }
    Cookies.remove(fcmKey, {
        domain: config.cookieDomain,
        path: config.cookiePath
    });

    // navigator.serviceWorker.getRegistrations().then(function (registrations) {
    //     for (let registration of registrations) {
    //         registration.unregister()
    //     }
    // });
};

export const checkLatLng = (point, vs) => {
    // ray-casting algorithm based on
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
    // console.log("Checking for");
    // console.log(point);
    // console.log("in");
    // console.log(vs);

    var x = parseFloat(point.latValue),
        y = parseFloat(point.lngValue);

    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        var xi = parseFloat(vs[i][0]),
            yi = parseFloat(vs[i][1]);
        var xj = parseFloat(vs[j][0]),
            yj = parseFloat(vs[j][1]);

        var intersect =
            yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
    }
    // console.log(inside);
    return inside;
};

export const formatNumbersWithThousandSeparator = (val) => {
    if (val) {

        var parts = val.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }
    return null;
}

export const chunkify = (array, chunk = 12) => {
    let res = [];

    var i, j, temparray;
    for (i = 0, j = array.length; i < j; i += chunk) {
        temparray = array.slice(i, i + chunk);
        res.push(temparray);
    }
    return res.reverse();
}

