import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  Col,
  Row,
  Container,
  Media,
  CardHeader,
  Badge,
  Button,
  FormGroup,
  Input,
  Label
} from "reactstrap";

// import moment from "moment";

import { getProduct, getCategoryList } from "../../actions/products";

class ProductEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productModal: false,
      productName: "",
      category: "",
      description: "",
      isVeg: 1,
      product_type: 1,
      isTaxable: 0,
      hasPackingCharge: 0
      // rejectReason: ""
    };


    let productId = this.props.match.params.productId;
    this.props.getProduct(productId);

    this.props.getCategoryList();
  }

  componentDidUpdate(previousProps, previousState) {
    const { currentProduct } = this.props;
    if (currentProduct !== previousProps.currentProduct) {
      this.setState({
        productName: currentProduct.product_name,
        category: currentProduct.product_category,
        description: currentProduct.product_desc,
        isVeg: currentProduct.is_veg,
        product_type: currentProduct.product_type,
        isTaxable: currentProduct.is_taxable,
        hasPackingCharge: currentProduct.packing_charge_applies
      });
    }
  }

  render() {
    const productData = this.props.currentProduct;
    const {
      productName,
      category,
      description,
      isVeg,
      product_type,
      isTaxable,
      hasPackingCharge
    } = this.state;
    let categories = this.props.categoryList;

    return (
      <div className="animated product-edit  fadeIn">
        <Container>
          <Row className="mt-2 bg-white product-edit-row">
            <Col sm="12">Add product </Col>

            <Col sm="12">Upload Product Image </Col>

            <Col sm="12">
              <FormGroup>

                <Label>Category</Label>
                <Input
                  type="select"
                  className="mb-3"
                  name="category"
                  id="category"
                  onChange={e => {
                    this.setState({ category: e.target.value });
                  }}
                  value={category}
                >
                  <option value="">
                    Choose a category
                      </option>
                  {categories &&
                    categories.length > 0 &&
                    categories.map((row, index) => {
                      return (
                        <option value={row.category_id}>
                          {row.category_name}
                        </option>
                      );
                    })}
                </Input>

                <Label>Product Name</Label>
                <Input
                  type="text"
                  className="mb-3"
                  placeholder="Product Name"
                  autoComplete="productName"
                  onChange={e => {
                    this.setState({ productName: e.target.value });
                  }}
                  value={productName}
                />

                <Label>Product Description</Label>
                <Input
                  type="textarea"
                  className="mb-3"
                  placeholder="Product Description"
                  autoComplete="description"
                  onChange={e => {
                    this.setState({ description: e.target.value });
                  }}
                  value={description}
                />

                <Label>Veg-Non Veg</Label>
                <Input
                  type="select"
                  className="mb-3"
                  name="isVeg"
                  id="isVeg"
                  onChange={e => {
                    this.setState({ isVeg: e.target.value });
                  }}
                  value={isVeg}
                >
                  <option value="1">Veg</option>
                  <option value="0">Non veg</option>
                  <option value="2">Egg</option>
                </Input>

                <Label>Product Type</Label>
                <Input
                  type="select"
                  className="mb-3"
                  name="productType"
                  id="product_type"
                  onChange={e => {
                    this.setState({ product_type: e.target.value });
                  }}
                  value={product_type}
                >
                  <option value="1">Food</option>
                  <option value="2">Liqour</option>
                </Input>

                <Label>Is Taxable?</Label>
                <Input
                  type="select"
                  className="mb-3"
                  name="isTaxable"
                  id="isTaxable"
                  onChange={e => {
                    this.setState({ isTaxable: e.target.value });
                  }}
                  value={isTaxable}
                >
                  <option value="2">No</option>
                  <option value="1">Yes</option>
                </Input>
                <Label>Packing Charges Apply?</Label>
                <Input
                  type="select"
                  className="mb-3"
                  name="hasPackingCharge"
                  id="hasPackingCharge"
                  onChange={e => {
                    this.setState({ hasPackingCharge: e.target.value });
                  }}
                  value={hasPackingCharge}
                >
                  <option value="2">No</option>
                  <option value="1">Yes</option>
                </Input>
              </FormGroup>
            </Col>
            <Col sm="12">
              <Link to={`/product/${productData.product_id}/`}>
                <Button color="primary" outline>
                  Cancel
            </Button>
              </Link>
              <Link to={`/product/${productData.product_id}/`}>
                <Button color="primary" className="ml-2">
                  Save
            </Button>
              </Link>
            </Col>


          </Row>
        </Container>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    getProduct: productId => dispatch(getProduct(productId)),
    getCategoryList: () => dispatch(getCategoryList())
  };
}

function mapStateToProps(state) {
  return {
    currentProduct: state.products.currentProduct,
    categoryList: state.products.categoryList
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(ProductEdit);
