import config from "../config";
import {getToken} from "../utils";
import {setMessage, setMessageType, setSpinner} from "./commonData";
import axios from "axios";

export const SET_BANNER_LIST = "SET_PRODUCTS_LIST";

var axiosInstance = axios.create({
    baseURL: config.baseUrl,
    timeout: 150000,
    headers: {
        "Content-Type": "application/json",
        version: "2.0",
        Authorization: `Token=${getToken()}`
    }
});

var axiosInstanceImageUpload = axios.create({
    baseURL: config.adminUrl,
    timeout: 150000,
    headers: {
        "Content-Type": "multipart/form-data",
        version: "2.0",
        Authorization: `Token=${getToken()}`,
    },
});

axiosInstance.interceptors.response.use(
    function (response) {
        // console.log("interceptors.response");
        return response;
    },
    function (error) {
        if (error.response) {

        } else if (error.request) {

        } else {

        }
        return Promise.reject(error);
    }
);

function setBannerList(payload) {
    return {type: SET_BANNER_LIST, payload: payload};
}


export function getBannerList() {

    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .get(`manage_branch/loadBannerList`)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(setBannerList(response.data.data));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                dispatch(setMessage("Oops!! Something went wrong"));
                dispatch(setMessageType("danger"));
            });
    };
}

export function createBanner(params) {

    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .post(`manage_branch/createBanner`,params)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(setMessage("Banner Creation Successful"));
                    dispatch(getBannerList(response));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                dispatch(setMessage("Oops!! Something went wrong"));
                dispatch(setMessageType("danger"));
            });
    };
}

export function updateBannerItem(params) {

    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .post(`manage_branch/updateBannerItemStatus`,params)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(setMessage("Banner updated for the branch Successful"));
                    dispatch(getBannerList(response));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                dispatch(setMessage("Oops!! Something went wrong"));
                dispatch(setMessageType("danger"));
            });
    };
}


export function uploadBannerImage(bannerId, image) {
    return function (dispatch) {
        const formData = new FormData();
        formData.append("banner_image", image);
        dispatch(setSpinner(true));
        axiosInstanceImageUpload
            .post(`manage_branch/updateBannerImage/${bannerId}`, formData)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(setMessage("Banner Image upload complete"));
                    dispatch(getBannerList());
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                if (err.response && err.response.data) {
                    dispatch(setMessage(err.response.data.errors));
                } else {
                    dispatch(setMessage("Oops!! Something went wrong"));
                }
                dispatch(setMessageType("danger"));
            });
    };
}



