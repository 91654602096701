import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import soundfile from './audio/a_old_telephone.mp3'


import React, {Component} from 'react';
import ReactDOM from "react-dom";
import {HashRouter, Route, Switch, withRouter} from 'react-router-dom';
import './App.scss';

import {getLoggedInUser} from "./actions/commonData";
// Containers
import {DefaultLayout} from './containers';
// Pages
import Login from './views/Login/Login';
import Page404 from './views/Page404';
import Page500 from './views/Page500';
import AddServiceArea from "./views/AddServiceArea";
import Billing from './views/Billing/Billing';
// import { renderRoutes } from 'react-rout er-config';
import {composeWithDevTools} from "redux-devtools-extension";
import {applyMiddleware, createStore} from "redux";
import {connect, Provider} from "react-redux";
import {getToken} from "./utils";

import rootReducer from "./reducers";
import {createLogger} from "redux-logger";
import PrintOrder from './views/PrintOrder/PrintOrder';
import PrintSettlement from './views/PrintSettlement/PrintSettlement';

import {toast, ToastContainer} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
// import * as firebase from "firebase";
import firebase from 'firebase/app';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
// Call it once in your app. At the root of your app is the best place
// console.log('is toast there ', toast);
// toast.configure();

import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
var thunkMiddleware = require('redux-thunk').default;

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunkMiddleware, createLogger({collapsed: true})))
);


class App extends Component {

    constructor(props) {
        super(props);
        this.isActive = true;
    }
    componentDidMount() {

        // if ('Notification' in window) {
        //     // API supported
        //
        //     alert("Supported");
        // } else {
        //     // API not supported
        //     alert("Not - Supported");
        // }

        window.addEventListener("focus", this.onFocus);

        let token = getToken();
        // console.log(this.props.tokenStatus);
        // console.log(token);
        if (token === undefined) {
            this.props.history.push("/login");
        } else {
            this.props.getLoggedInUser();
        }

        if (firebase.messaging.isSupported())
            navigator.serviceWorker.addEventListener("message", (message) => {
                // console.log('navigator message ', message);
                // console.log(this.props.location.pathname);
                let audio = new Audio(soundfile);
                audio.load();
                audio.play();
                let notificationTitle = "";
                let notificationBody = "";
                if (this.props.location.pathname !== "/today") {
                    if (message && message.data && message.data['firebase-messaging-msg-data'] && message.data['firebase-messaging-msg-data'].data && message.data['firebase-messaging-msg-data'].data.body) {
                        notificationTitle = message.data['firebase-messaging-msg-data'].data.title;
                        notificationBody = message.data['firebase-messaging-msg-data'].data.body;
                    }
                    if (notificationTitle && notificationBody) {
                        toast.warn(<div><h4> {notificationTitle} </h4> <h5>{notificationBody}</h5></div>);
                    }
                }

            });

        // firebase.messaging().onMessage(notification => {
        //     alert('Notification received!', notification);
        // });
        //
        // messaging.onMessage((payload) => {
        //     console.log('Message received.', payload);
        //     // ...
        // });
    }

    componentWillUnmount() {
        window.removeEventListener("focus", this.onFocus)

    }


    onFocus = () => {
        // console.log("Not sure");
    };

    render() {
        return (
            <HashRouter>
                <Switch>
                    <Route exact path="/login" name="Login Page" component={Login}/>
                    <Route exact path="/print-order/:orderId" name="Print Order" component={PrintOrder}/>
                    <Route exact path="/print-settlement/:orderId" name="Print Settlement" component={PrintSettlement}/>
                    <Route exact path="/billing" name="Billing" component={Billing}/>
                    <Route exact path="/manage_branch/addServingArea/:branchId" name="Add service area"
                           component={AddServiceArea}/>
                    <Route exact path="/404" name="Page 404" component={Page404}/>
                    <Route exact path="/500" name="Page 500" component={Page500}/>
                    <Route path="/" name="Home" component={DefaultLayout}/>
                </Switch>
            </HashRouter>
        );
    }
}

function bindActions(dispatch) {
    return {
        getLoggedInUser: () => dispatch(getLoggedInUser())
        // setFCMToken: (token) => dispatch(setFCMToken(token))
    };
}

function mapStateToProps(state) {
    return {
        tokenStatus: state.commonData.tokenStatus,
        loggedInUser: state.loggedInUser,

    };
}

const RootWithAuth = withRouter(
    connect(
        mapStateToProps,
        bindActions
    )(App)
);


ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <RootWithAuth/>
        </HashRouter>
        <ToastContainer autoClose={false}/>
    </Provider>,
    document.getElementById("root")
);
