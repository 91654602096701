import React, { Component } from "react";
import {
    Button,
    Card,
    CardBody,
    CardGroup,
    Col,
    Container,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row, Alert
} from "reactstrap";
import MDSpinner from "react-md-spinner";
import { connect } from "react-redux";
import { Link, withRouter, Redirect } from "react-router-dom";
import {
    login,
    setPassword,
    verifyUserOTP,
    forgotPassword
} from "../../actions/auth";
import { clearMessage } from "../../actions/commonData";
import { getToken } from "../../utils";
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const options = {
    autoClose: 2000,
    // closeButton:,
    type: toast.TYPE.INFO,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_LEFT,
    pauseOnHover: true
    // progress: 0.2
};

class Login_historic extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            user: {},
            isLoading: false,
            userName: "",
            password: "",
            otp: "",
            forgotPasswordShow: false,
            setPasswordShow: false,
            loginShow: true,
            newPassword: "",
            confirmPassword: "",
            mobileError: "",
            passwordError: "",
            confirmPasswordError: "",
            otpError: "",
            tmp_access: ""
        };
    }

    componentDidMount() {
        const token = getToken();
        if (token !== undefined) {

            this.props.history.push("/");
        }
    }

    componentDidUpdate() {

        // setTimeout(
        //   function () {
        //     this.props.clearMessage();
        //   }.bind(this),
        //   10000
        // );
    }

    onDismiss = () => {
        this.props.clearMessage();
    };

    phoneValidation = (value) => {
        return value && /^(0|[1-9][0-9]{9})$/i.test(value) ? true : false;
    };

    passwordValidation = (value) => {
        return value && /^([0-9]{4})$/i.test(value) ? true : false;
    };

    otpValidation = (value) => {
        return value && /^([0-9]{6})$/i.test(value) ? true : false;
    };

    confirmPasswordValidation = (value) => {
        const { newPassword } = this.state;
        return value && value === newPassword ? true : false;
    };

    requiredValidation = (value) => {
        return value || typeof value !== "number" ? true : false;
    };

    navigateTo = (step) => {
        this.setState({
            setPasswordShow: false,
            forgotPasswordShow: false,
            loginShow: false,
            verifyOTPShow: false
        });
        switch (step) {
            case "login":
                this.setState({ loginShow: true });
                break;
            case "verifyOTP":
                this.setState({ verifyOTPShow: true });
                break;
            case "forgotPassword":
                this.setState({ forgotPasswordShow: true });
                break;
            case "setPassword":
                this.setState({ setPasswordShow: true });
                break;
            default:
                this.setState({ loginShow: true });
        }
    };

    handleLogin = () => {
        const { userName, password } = this.state;
        this.setState({
            mobileError: "",
            passwordError: "",
            otpError: "",
            confirmPasswordError: ""
        });
        // this.navigateTo();

        this.props.login(userName, password);
    };

    handleForgotPassword = () => {
        const { userName } = this.state;

        this.props.forgotPassword(userName);
        this.navigateTo("verifyOTP");
    };

    handleVerifyOTP = () => {
        const { otp } = this.state;
        this.props.verifyUserOTP(otp, this.props.temp_access_token.accessToken);
        this.navigateTo("setPassword");
    };

    handleSetPassword = () => {
        const { userName, newPassword, confirmPassword } = this.state;
        if (newPassword == confirmPassword) {
            this.props.setPassword(userName, this.props.temp_access_token.accessToken, newPassword);
        } else {
            // alert("Password mismatch");
            toast.error("Password Mismatch", options)
        }

        this.navigateTo("login");

    };

    render() {
        const {
            forgotPasswordShow,
            setPasswordShow,
            verifyOTPShow,
            loginShow,
            otp,
            newPassword,
            userName,
            password,
            confirmPassword
            // mobileError,
            // passwordError,
            // confirmPasswordError,
            // otpError
        } = this.state;
        const { spinnerActive, message, alertClass } = this.props;

        return (
            <>
                <div className="login-page">
                    {spinnerActive && (
                        <div className="spinner-overlay-new">
                            <MDSpinner
                                singleColor="#ee5a36"
                                size="75"
                                borderSize={10}
                                style={{ margin: "0 auto" }}
                                className=""
                            />
                        </div>
                    )}
                    <div className="login-left">
                        <div className="d-table-middle">
                            <div class="d-table-m">
                                <div className="row">
                                    <div className="col-lg-6 offset-lg-3 text-center">
                                        {message && (
                                            <Alert
                                                color={alertClass}
                                                isOpen={message !== "" ? true : false}
                                                toggle={this.onDismiss}
                                            >
                                                {message}
                                            </Alert>
                                        )}
                                        <div className="mb-5">
                                            <img src="images/restro/logo-left.svg" className="mr-2" alt="" />
                                            <img src="images/restro/logo-right.svg" alt="" />
                                        </div>
                                        {loginShow && (
                                            <Form>
                                                <InputGroup className="mb-3 white-input-group">
                                                    <Input
                                                        type="text"
                                                        placeholder="Username"
                                                        autoComplete="username"
                                                        onChange={e => {
                                                            this.setState({ userName: e.target.value });
                                                        }}
                                                        value={userName}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3 white-input-group">
                                                    <Input
                                                        type="password"
                                                        placeholder="Password"
                                                        autoComplete="current-password"
                                                        onChange={e => {
                                                            this.setState({ password: e.target.value });
                                                        }}
                                                        onKeyPress={e => {
                                                            if (e.key === 'Enter') {
                                                                this.setState({ password: e.target.value });
                                                                this.handleLogin();
                                                            }
                                                        }}
                                                        value={password}
                                                    />
                                                </InputGroup>
                                                <div className="mb-2">
                                                    <Button
                                                        color="primary"
                                                        className="btn-block btn-new-brand"
                                                        onClick={() => {
                                                            this.handleLogin();
                                                        }}
                                                    >
                                                        Login
                                                    </Button>
                                                </div>
                                                <div className="text-center">
                                                    <Button
                                                        color="link"
                                                        className="px-0 text-new-brand"
                                                        onClick={() => {
                                                            this.navigateTo("forgotPassword");
                                                        }}
                                                    >
                                                        Forgot password?
                                                    </Button>
                                                </div>
                                            </Form>
                                        )}
                                        {forgotPasswordShow && (
                                            <Form>
                                                <h5 className="mb-3">Forgot Password?</h5>
                                                <InputGroup className="mb-3 white-input-group">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="icon-user" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="text"
                                                        placeholder="Enter username"
                                                        autoComplete="username"
                                                        onChange={e => {
                                                            this.setState({ userName: e.target.value });
                                                        }}
                                                        value={userName}
                                                    />
                                                </InputGroup>
                                                <div className="mb-2">
                                                    <Button
                                                        color="primary"
                                                        className="btn-block btn-new-brand"
                                                        onClick={() => {
                                                            this.handleForgotPassword();
                                                        }}
                                                    >
                                                        Forgot Password
                                                    </Button></div>
                                                <div>
                                                    <Button
                                                        color="link"
                                                        className="px-0 text-new-brand"
                                                        onClick={() => {
                                                            this.navigateTo("login");
                                                        }}
                                                    >
                                                        Login?
                                                    </Button></div>
                                            </Form>
                                        )}
                                        {verifyOTPShow && (
                                            <Form>
                                                <h5 className="mb-3">Verify OTP?</h5>
                                                <InputGroup className="mb-3 white-input-group">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="icon-user" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="text"
                                                        placeholder="Enter OTP"
                                                        autoComplete="OTP"
                                                        onChange={e => {
                                                            this.setState({ otp: e.target.value });
                                                        }}
                                                        value={otp}
                                                    />
                                                </InputGroup>
                                                <div className="mb-2">
                                                    <Button
                                                        color="primary"
                                                        className="btn-block btn-new-brand"
                                                        onClick={() => {
                                                            this.handleVerifyOTP();
                                                        }}
                                                    >
                                                        Verify OTP
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        color="link"
                                                        className="px-0 text-new-brand"
                                                        onClick={() => {
                                                            this.navigateTo("login");
                                                        }}
                                                    >
                                                        Login?
                                                    </Button></div>
                                            </Form>
                                        )}
                                        {setPasswordShow && (
                                            <Form>
                                                <h5 className="mb-3">Verify OTP?</h5>
                                                <InputGroup className="mb-3 white-input-group">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="icon-lock" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="password"
                                                        placeholder="Enter new password"
                                                        onChange={e => {
                                                            this.setState({ newPassword: e.target.value });
                                                        }}
                                                        value={newPassword}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3 white-input-group">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="icon-lock" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="password"
                                                        placeholder="Confirm new password"
                                                        onChange={e => {
                                                            this.setState({
                                                                confirmPassword: e.target.value
                                                            });
                                                        }}
                                                        value={confirmPassword}
                                                    />
                                                </InputGroup>
                                                <div className="mb-2">
                                                    <Button
                                                        color="primary"
                                                        className="btn-block btn-new-brand"
                                                        onClick={() => {
                                                            this.handleSetPassword();
                                                        }}
                                                    >
                                                        Set New Password
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        color="link"
                                                        className="px-0 text-new-brand"
                                                        onClick={() => {
                                                            this.navigateTo("login");
                                                        }}
                                                    >
                                                        Login?
                                                    </Button>
                                                </div>
                                            </Form>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="login-left-abs"><p>Copyrights, Restro360 Technologies Pvt Ltd.</p></div>
                    </div>
                    <div className="login-right">
                        <div class="login-right-text">
                            <div class="row">
                                <div class="col-lg-8 offset-lg-2">
                                    <h5>Restro360</h5>
                                    <h4>MANAGE YOUR <span>BUSINESS</span> BETTER</h4>
                                    <p>Aenean sed nibh a magna posuere tempor. Nunc faucibus pellentesque nunc in aliquet. Donec congue, nunc vel tempor congue, enim sapien lobortis ipsum, in volutpat sem ex in ligula.</p>
                                    <div><button className="btn btn-new-brand btn-primary font-weight-bold px-5">Whats's New</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </>
        );
    }
}

function bindActions(dispatch) {
    return {
        login: (username, password) => dispatch(login(username, password)),
        setPassword: (userName, accessToken, newPassword) => dispatch(setPassword(userName, accessToken, newPassword)),
        verifyUserOTP: (opt, accessToken) =>
            dispatch(verifyUserOTP(opt, accessToken)),
        forgotPassword: (userName) => dispatch(forgotPassword(userName)),
        clearMessage: () => dispatch(clearMessage())
    };
}


function mapStateToProps(state) {
    return {
        temp_access_token: state.auth.temp_access_token,
        loggedInUser: state.auth.loggedInUser,
        spinnerActive: state.commonData.spinnerActive,
        message: state.commonData.message,
        alertClass: state.commonData.messageType
    };
}
const LoginWithAuth = withRouter(
    connect(
        mapStateToProps,
        bindActions
    )(Login_historic)
);
export default LoginWithAuth;
