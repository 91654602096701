import {
  SET_LIVE_ORDERS_LIST,
  SET_ALL_LIVE_ORDERS_LIST,
  SET_CURRENT_ORDER,
  SET_ORDERS_LIST,
  SET_DELIVERY_GUYS_LIST,
  SET_LIVE_ORDER_META,
  SET_ANALYTICS,
  SET_LIVE_ESCALATION_ORDERS_LIST,
  SET_LIVE_PRE_ORDER_ORDERS_LIST,
  SET_ANALYTICS_CATEGORY,
  SET_DISPATCH_ORDER,
  SET_ANALYTICS_BY_TIME,
  SET_ANALYTICS_PARTNER_REPORT,
  SET_ANALYTICS_PAYMENT_MODE,
  SET_ANALYTICS_TRENDS,
  SET_ANALYTICS_CUSTOMER,
  SET_UNDISPATCHED,
  SET_ORDER_SOURCE,
    SET_SETTLEMENT,
    SET_PAYMENT_RECIVED,
} from "../actions/orders";

const initialState = {
  liveOrdersList: [],
  allLiveOrdersList: [],
  liveOrdersEscalationList: [],
  livePreOrderOrdersList: [],
  currentOrder: {},
  ordersList: [],
  Analytics: [],
  unDispatched: [],
  AnalyticsCategory: [],
  PaymentModeAnalytics: [],
  PartnerAnalytics: [],
  AnalyticsTrends: [],
  AnalyticsCustomer: [],
    SettlementReport: [],
  TimeAnalytics: [],
    PaymentReceived:[],
  deliveryGuys: [],
  liveOrderMeta: {},
  dispatchOrder: [],
};

const orders = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_LIVE_ORDERS_LIST:
      return { ...state, allLiveOrdersList: action.payload };
    case SET_LIVE_ORDERS_LIST:
      return { ...state, liveOrdersList: action.payload };
    case SET_LIVE_ESCALATION_ORDERS_LIST:
      return { ...state, liveOrdersEscalationList: action.payload };
    case SET_LIVE_PRE_ORDER_ORDERS_LIST:
      return { ...state, livePreOrderOrdersList: action.payload };
    case SET_CURRENT_ORDER:
      return { ...state, currentOrder: action.payload };
    case SET_ORDERS_LIST:
      return { ...state, ordersList: action.payload };
    case SET_ANALYTICS:
      return { ...state, Analytics: action.payload };
      case SET_PAYMENT_RECIVED:
          return { ...state, PaymentReceived: action.payload };
    case SET_ANALYTICS_CUSTOMER:
      return { ...state, AnalyticsCustomer: action.payload };
    case SET_UNDISPATCHED:
      return { ...state, unDispatched: action.payload };
    case SET_ANALYTICS_TRENDS:
      return { ...state, AnalyticsTrends: action.payload };
      case SET_SETTLEMENT:
          return { ...state, SettlementReport: action.payload };
    case SET_ORDER_SOURCE:
      return { ...state, OrderSources: action.payload };
    case SET_ANALYTICS_CATEGORY:
      return { ...state, AnalyticsCategory: action.payload };
    case SET_ANALYTICS_BY_TIME:
      return { ...state, TimeAnalytics: action.payload };
    case SET_ANALYTICS_PAYMENT_MODE:
      return { ...state, PaymentModeAnalytics: action.payload };
    case SET_ANALYTICS_PARTNER_REPORT:
      return { ...state, PartnerAnalytics: action.payload };
    case SET_DELIVERY_GUYS_LIST:
      return { ...state, deliveryGuys: action.payload };
    case SET_LIVE_ORDER_META:
      return { ...state, liveOrderMeta: action.payload };
    default:
      return state;
  }
};

export default orders;
