import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../config";

import {
  Row,
  Col,
  Card,
  CardTitle,
  CardImg,
  Modal,
  ModalBody
} from "reactstrap";
import ProductPriceModal from "./ProductPriceModal";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  }

  render() {
    return (
      <Col
        sm="3"
        className="each-product p-2 pt-3 pb-3 each-product-h"
        onClick={this.toggleModal}
      >
        <Card inverse className="each-product-card non-abs">
          <CardImg
            width="100%"
            src={`${config.imgUrl}frontend/images/products/${
              this.props.productData.product_id
              }.jpg`}
            alt={this.props.productData.product_name}
          />
          <CardTitle>{this.props.productData.product_name}</CardTitle>
        </Card>
        <ProductPriceModal
          productData={this.props.productData}
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          branch_id={this.props.branch_id}
          addtoCart={this.props.addtoCart}
          cartList={this.props.cartList}
        />
      </Col>
    );
  }
}

function bindActions(dispatch) {
  return {};
}

function mapStateToProps(state) {
  return {};
}
export default connect(
  mapStateToProps,
  bindActions
)(Product);
