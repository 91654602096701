import React, {Component} from "react";
import {connect} from "react-redux";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import {Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Row} from "reactstrap";

import StaffTable from "./StaffTable";
import StaffModal from "./StaffModal";
import {getStaffList, getStaffMeta, updateStatus} from "../../actions/staff";

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userType: 0, // default all users
            perPage: 10,
            offset: 0,
            searchText: "",
            preset: "",
            focusedInput: false,
            dropdownOpen: false,
            citydropdownOpen: false,
            branch_id: 0,
            branch_name: "All branch",
            city_id: 0,
            city_name: "All Cities"
        };
        this.filterUsers = this.filterUsers.bind(this);
        this.branchtoggle = this.branchtoggle.bind(this);
        this.handleBranchChange = this.handleBranchChange.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.updateStaffStatus = this.updateStaffStatus.bind(this);
        this.citytoggle = this.citytoggle.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);

        const {
            branch_id,
            perPage,
            offset,
            searchText,
            userType,
        } = this.state;
        let default_params = {
            branch_id: branch_id,
            userType: userType,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC"
        };
        // this.props.getStaffList(default_params);
        this.props.getStaffMeta();
        // console.log(this.props);
    }

    citytoggle() {
        this.setState(prevState => ({
            citydropdownOpen: !prevState.citydropdownOpen
        }));
    }

    branchtoggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    componentDidMount() {
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let firstCityName = branch_list[0].city_name;
                let firstCityId = branch_list[0].city_id;
                this.handleCityChange(firstCityName, firstCityId);
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.loggedInUser && (this.props.loggedInUser.branch_list != nextProps.loggedInUser.branch_list)) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let firstCityName = branch_list[0].city_name;
                let firstCityId = branch_list[0].city_id;
                this.handleCityChange(firstCityName, firstCityId);
            }
        }
    }

    handleCityChange(city_name, city_id) {
        this.setState({city_id: city_id, city_name: city_name}, () => {
            this.props.loggedInUser.branch_list.map((value) => {
                if (city_id === value.city_id)
                    this.handleBranchChange(value.branch_list[0].branch_id, value.branch_list[0].branch_name);
            });
        });
    }

    handleBranchChange(branch_id, branch_name) {
        const {
            userType,
            perPage,
            offset,
            searchText,
            city_id
        } = this.state;
        this.setState({branch_id: branch_id, branch_name: branch_name});
        // console.log("branch id ", branch_id);
        let params = {
            branch_id: branch_id,
            userType: userType,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            city_id,
            sort: "ASC"
        };
        this.props.getStaffList(params);

    }

    filterUsers(userType) {
        const {
            branch_id,
            startDate,
            endDate,
            perPage,
            offset,
            searchText,
            city_id
        } = this.state;
        this.setState({userType: userType});
        // console.log("order status ", this.state.orderStatus);
        let params = {
            branch_id: branch_id,
            userType: userType,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id: city_id
        };
        this.props.getStaffList(params);

    }

    handlePagination(offset) {
        const {
            branch_id,
            startDate,
            endDate,
            userType,
            searchText,
            perPage,
            city_id
        } = this.state;
        this.setState({offset: offset});
        // console.log("offset", offset);
        let params = {
            branch_id: branch_id,
            userType: userType,
            searchText: searchText,
            offset: offset,
            perPage: perPage,
            sort: "ASC",
            city_id: city_id
        };
        this.props.getStaffList(params);

    }

    handleSearch(searchText) {
        const {
            branch_id,
            userType,
            offset,
            perPage,
            city_id
        } = this.state;
        this.setState({searchText: searchText});
        // console.log("searchText", searchText);
        let params = {
            branch_id: branch_id,
            userType: userType,
            searchText: searchText,
            offset: offset,
            perPage: perPage,
            sort: "ASC",
            city_id
        };
        this.props.getStaffList(params);
    }

    updateStaffStatus(user_id, status) {
        const {
            branch_id,
            userType,
            searchText,
            perPage,
            city_id
        } = this.state;
        let listParams = {
            branch_id: branch_id,
            userType: userType,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id
        }
        this.props.updateStatus(user_id, status, listParams);
    }

    render() {
        const {
            branch_name,
            branch_id,
            userType,
            searchText,
            perPage,
            city_name,
            city_id
        } = this.state;
        let userTypeList = [];
        if (this.props.staffMeta && this.props.staffMeta.user_types) {
            userTypeList = this.props.staffMeta.user_types;
        }

        let userStatusList = [];
        if (this.props.staffMeta && this.props.staffMeta.user_status) {
            userStatusList = this.props.staffMeta.user_status;
        }

        let city_list = [];
        let branch_list = [];
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {

            this.props.loggedInUser.branch_list.map((item)=>{
                if(city_id===item.city_id)
                    branch_list = item.branch_list;
                city_list.push(item);
            });
        }

        let all_staff_outline = true;
        if (userType === 0) {
            all_staff_outline = false;
        }

        let listParams = {
            branch_id: branch_id,
            userType: userType,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id: city_id
        }

        // console.log(this.props.staffList);
        return (
            <div className="animated fadeIn">
                <Row className="ml-2 mr-2 mt-2 staff">
                    <Col sm="4" md="3" lg="2" className="py-3 staff-side">
                        <Dropdown
                            isOpen={this.state.citydropdownOpen}
                            toggle={this.citytoggle}
                            className="place-order w-100 mb-2"
                        >
                            <DropdownToggle caret className="w-100" color="primary">
                                {city_name}
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {city_list &&
                                city_list.map((row, index) => {
                                    return (
                                        <DropdownItem
                                            onClick={() => {
                                                this.handleCityChange(
                                                    row.city_name,
                                                    row.city_id
                                                );
                                            }}
                                        >
                                            {row.city_name}
                                        </DropdownItem>
                                    );
                                })}
                            </DropdownMenu>
                        </Dropdown>

                        <Dropdown
                            isOpen={this.state.dropdownOpen}
                            toggle={this.branchtoggle}
                            className="place-order w-100 mb-2"
                        >
                            <DropdownToggle caret className="w-100" color="primary">
                                {branch_name}
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {branch_list &&
                                branch_list.map((row, index) => {
                                    return (
                                        <DropdownItem
                                            onClick={() => {
                                                this.handleBranchChange(
                                                    row.branch_id,
                                                    row.branch_name
                                                );
                                            }}
                                        >
                                            {row.branch_name}
                                        </DropdownItem>
                                    );
                                })}
                            </DropdownMenu>
                        </Dropdown>
                        <hr/>
                        <StaffModal userTypeList={userTypeList} listParams={listParams} branch_list={branch_list}/>
                        <hr/>
                        <label className="filter-label">Manage Staff</label>
                        <Button
                            color="primary"
                            className="w-100 text-left mb-2"
                            outline={all_staff_outline}
                            onClick={() => {
                                this.filterUsers(0);
                            }}
                        >
                            All Staff
                        </Button>
                        {userTypeList &&
                        userTypeList.length > 0 &&
                        userTypeList.map((element, index) => {
                            let outline =
                                element.type_id !== this.state.userType ? true : false;
                            return (
                                <Button
                                    color="primary"
                                    className="w-100 text-left mb-2 "
                                    outline={outline}
                                    onClick={() => {
                                        this.filterUsers(element.type_id);
                                    }}
                                >
                                    {element.type_name}
                                </Button>
                            );
                        })}
                    </Col>

                    <Col sm="8" md="9" lg="10" className="orders-main p-3">
                        <Row className="">
                            <Col sm={{size: '6', offset: 6}} md={{size: '3', offset: 9}} className="p-0 pr-2 border-0">
                                <InputGroup className="mb-3 w-100">
                                    <Input
                                        type="text"
                                        placeholder="Search"
                                        autoComplete="searchText"
                                        className="w-100"
                                        onChange={e => {
                                            this.handleSearch(e.target.value);
                                        }}
                                        value={searchText}
                                    />
                                </InputGroup>
                            </Col>
                            {this.props.staffList && userStatusList && userTypeList &&
                            this.props.staffList.aaData && (
                                <StaffTable
                                    staff={this.props.staffList.aaData}
                                    totalData={
                                        this.props.staffList &&
                                        this.props.staffList.iTotalRecords.total_count
                                    }
                                    handlePagination={this.handlePagination}
                                    userStatusList={userStatusList}
                                    userTypeList={userTypeList}
                                    updateStaffStatus={this.updateStaffStatus}
                                />
                            )}
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

function bindActions(dispatch) {
    return {
        getStaffList: params => dispatch(getStaffList(params)),
        getStaffMeta: () => dispatch(getStaffMeta()),
        updateStatus: (user_id, status, listParams) => dispatch(updateStatus(user_id, status, listParams)),
    };
}

function mapStateToProps(state) {
    return {
        staffList: state.staff.staffList,
        staffMeta: state.staff.staffMeta,
        loggedInUser: state.commonData.loggedInUser
    };
}

export default connect(
    mapStateToProps,
    bindActions
)(Orders);
