import DefaultLayout from "./containers/DefaultLayout";

import Today from "./views/Today";
import Dashboard from "./views/Dashboard";
import Orders from "./views/Orders";
import Products from "./views/Products";
import Staff from "./views/Staff";
import Billing from "./views/Billing";
import Cash from "./views/Cash";
import Customers from "./views/Customers";
import Branch from "./views/Branch";
import Login from "./views/Login/Login";
import OrderView from "./views/OrderView";
import ProductView from "./views/ProductView";
import ProductEdit from "./views/ProductEdit";
import AddServiceArea from "./views/AddServiceArea";
import CustomerView from "./views/CustomerView/CustomerView";
import PrintOrder from "./views/PrintOrder/PrintOrder";
import PrintSettlement from "./views/PrintSettlement/PrintSettlement";
import Wallet from "./views/Wallet/Wallet";
import RequestedWallet from "./views/Wallet/RequestedWallet";
import TodayEscalations from "./views/TodayEscalations";
import PreOrders from "./views/PreOrder";
import OrderScreen from "./views/OrderScreen";
import EscalationScreen from "./views/EscalationScreen/TodayEscalationsScreen";
import Analytics from "./views/Analytics/Analytics";
import ProductsAnalytics from "./views/ProductAnalytics";
import CategoryAnalytics from "./views/CategoryAnalytics";
import TimeAnalytics from "./views/TimeAnalytics";
import PartnerAnalytics from "./views/PartnerAnalytics";
import PaymentModeAnalytics from "./views/PaymentModeAnalytics";
import TrendsAnalytics from "./views/TrendsAnalytics";
import CustomerTrendsAnalytics from "./views/CustomerTrendsAnalytics";
import Dispatch from "./views/Dispatch";

const routes = [
  { path: "/", name: "Home", component: DefaultLayout, exact: true },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/login", name: "Login - Restro", component: Login },
  { path: "/orders", name: "Orders", component: Orders },
  { path: "/products", name: "Products", component: Products },
  { path: "/staff", name: "Staff", component: Staff },
  { path: "/analytics", name: "Analytics", component: Analytics },
  {
    path: "/category_analytics",
    name: "Analytics",
    component: CategoryAnalytics,
  },
  {
    path: "/time_analytics",
    name: "Analytics By Time",
    component: TimeAnalytics,
  },
  {
    path: "/partner_analytics",
    name: "Analytics By Partner",
    component: PartnerAnalytics,
  },
  {
    path: "/customer_analytics",
    name: "Customer Trends",
    component: CustomerTrendsAnalytics,
  },
  {
    path: "/trends_analytics",
    name: "Sales Trends",
    component: TrendsAnalytics,
  },
  {
    path: "/payment_mode_analytics",
    name: "Analytics By Partner",
    component: PaymentModeAnalytics,
  },
  {
    path: "/productanalytics",
    name: "Analytics",
    component: ProductsAnalytics,
  },
  { path: "/billing", name: "Billing", component: Billing },
  // { path: "/epson", name: "Epson", component: Epson },
  { path: "/cash", name: "Cash", component: Cash },
  { path: "/branch/:branchAction", name: "Branch", component: Branch },
  { path: "/branch", name: "Branch", component: Branch, exact: true },
  { path: "/customers", name: "Customers", component: Customers },
  { path: "/dispatch", name: "Dispatch", component: Dispatch },
  {
    path: "/customer/:customer_id",
    name: "Customers",
    component: CustomerView,
  },
  { path: "/today", name: "Today", component: Today },
  { path: "/escalations", name: "Escalations", component: TodayEscalations },
  { path: "/orderScreens", name: "OrderScreen", component: OrderScreen },
  {
    path: "/escalationScreen",
    name: "escalationScreen",
    component: EscalationScreen,
  },
  { path: "/preorders", name: "Pre Orders", component: PreOrders },
  { path: "/order/:orderId", name: "Order View", component: OrderView },
  {
    path: "/product/:productId/edit",
    name: "Product Edit",
    component: ProductEdit,
    exact: true,
  },
  {
    path: "/product/:productId",
    name: "Product View",
    component: ProductView,
    exact: true,
  },
  {
    path: "/manage_branch/addServingArea/:branchId",
    name: "Product View",
    component: AddServiceArea,
    exact: true,
  },
  { path: "/print-order/:orderId", name: "Print Order", component: PrintOrder },
  { path: "/print-settlement/:orderId", name: "Print Settlement", component: PrintSettlement },
  // { path: "/pos/printBill/:orderId", name: "Print Order", component: PrintOrder },

  { path: "/wallets", name: "Wallets", component: Wallet, exact: true },
  {
    path: "/wallets/walletRequests",
    name: "Requested Wallet Transactions",
    component: RequestedWallet,
  },
];

export default routes;
