import {
  SET_CASH_LIST,
  SET_CASH_SUMMARY_LIST,
  SET_PAYMENT_MODES,
} from "../actions/cash";

const initialState = {
  cashList: [],
  cashSummary: [],
  paymentModes: [],
};

const cash = (state = initialState, action) => {
  switch (action.type) {
    case SET_CASH_LIST:
      return { ...state, cashList: action.payload };
    case SET_CASH_SUMMARY_LIST:
      return { ...state, cashSummary: action.payload };
    case SET_PAYMENT_MODES:
      return { ...state, paymentModes: action.payload };
    default:
      return state;
  }
};

export default cash;
