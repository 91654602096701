import BootstrapTable from "react-bootstrap-table-next";
import _ from "lodash";
import { Link } from "react-router-dom";
import React from "react";

import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import moment from "moment";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

const RemotePagination = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  columns,
}) => (
  <div>
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        page,
        sizePerPage,
        totalSize,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <div className="table-responsive new-table-css last-child-right table-margin-minus">
          <BootstrapTable
            remote
            keyField="bill_id"
            data={data}
            columns={columns}
            onTableChange={onTableChange}
            {...paginationTableProps}
          />
          <div className="float-left text-muted pt-2 ml-3">
            <p>
              Showing:{" "}
              {(paginationProps.page - 1) * paginationProps.sizePerPage + 1} to{" "}
              {paginationProps.page * paginationProps.sizePerPage <
              paginationProps.totalSize
                ? paginationProps.page * paginationProps.sizePerPage
                : paginationProps.totalSize}{" "}
              of {paginationProps.totalSize} entries
            </p>
          </div>
          <div className="float-right mr-3">
            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}
    </PaginationProvider>
  </div>
);

class CustomersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: this.props.orders,
      sizePerPage: 10,
      paymentModal: false,
      dropdownOpen: false,
      selectedOrder: {
        payment_mode_value: "",
      },
    };
    this.collectFormatter = this.collectFormatter.bind(this);
    this.billFormatter = this.billFormatter.bind(this);
    this.togglePaymentModal = this.togglePaymentModal.bind(this);
    this.paymentModeFormatter = this.paymentModeFormatter.bind(this);
    this.paymentSelectToggle = this.paymentSelectToggle.bind(this);
    this.handlePaymentModeChange = this.handlePaymentModeChange.bind(this);
  }

  handlePaymentModeChange(id, mode) {
    this.togglePaymentModal({});
    this.paymentSelectToggle();
    this.props.updatePaymentMode(id, this.state.selectedOrder.bill_id);
  }

  paymentSelectToggle() {
    this.setState((prevState) => ({ dropdownOpen: !prevState.dropdown }));
  }

  handleTableChange = (type, { page, sizePerPage }) => {
    this.setState(() => ({
      page,
    }));
    this.props.handlePagination((page - 1) * sizePerPage);
  };

  collectFormatter = function (cell, row, rowIndex, formatExtraData) {
    if (row.cash_collected == 0) {
      return (
        <Button
          size="sm"
          onClick={() => {
            this.props.markPaymentCollected(row.bill_id);
          }}
        >
          Acknowledge
        </Button>
      );
    } else {
      return (
        <div>
          {_.startCase(_.lowerCase(row.payment_mode_value))} by{" "}
          <span className="font-weight-bold">
            {row.collect_by_fname} {row.collected_by_lname}
          </span>
        </div>
      );
    }
  };

  billFormatter = function (cell, row, rowIndex, formatExtraData) {
    return (
      <div>
        <Link to={`/order/${row.bill_id}`}>
          <span className="text-blue-brand font-weight-bold">
            {row.bill_id}
          </span>
        </Link>
      </div>
    );
  };

  executiveFormatter = function (cell, row, rowIndex, formatExtraData) {
    return (
      <div>
        <span className="text-blue-brand font-weight-bold">{`${row.user_first_name} ${row.user_second_name}`}</span>
        <br />
        <span className="text-muted">{row.user_phone}</span>
      </div>
    );
  };
  deliveredFormatter = function (cell, row, rowIndex, formatExtraData) {
    return (
      <div>
        {cell == 1 && <span>By {row.order_source_name}</span>}
        {cell == 0 && <span>By Potful</span>}
      </div>
    );
  };

  // Custom column formatter
  customerFormatter = function (cell, row, rowIndex, formatExtraData) {
    return (
      <div>
        <span className="text-blue-brand font-weight-bold">{`${row.customer_first_name} ${row.customer_second_name}`}</span>
        <br />
        <span className="text-muted">{row.customer_phone}</span>
      </div>
    );
  };

  dateFormatter = function (cell, row, rowIndex, formatExtraData) {
    return moment(cell).format("MMM DD, YYYY hh:mm a");
  };

  revenueFormatter = function (cell, row, rowIndex, formatExtraData) {
    return <div>{Math.round(cell)}</div>;
  };
  togglePaymentModal({ billId = null }) {
    if (billId) {
      const { orders } = this.props;
      const order = orders.find((o) => o.bill_id === billId);
      this.setState({ selectedOrder: order });
    }
    this.setState({ dropdownOpen: false });
    this.setState((prevState) => ({ paymentModal: !prevState.paymentModal }));
  }

  paymentModeFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      // row.cash_collected == 0
      <div className="d-flex justify-content-between">
        <div className="flex-grow-1">{row.payment_mode_value}</div>
        {row.cash_collected == 0 && (
          <div>
            <Button
              size="sm"
              color="dark"
              outline
              onClick={() => this.togglePaymentModal({ billId: row.bill_id })}
            >
              Update
            </Button>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { data, sizePerPage, page } = this.state;

    const columns = [
      {
        dataField: "bill_id",
        text: "Order #",
        sort: true,
        formatter: this.billFormatter,
      },
      {
        dataField: "order_source_name",
        text: "Order Source",
        sort: true,
      },
      {
        dataField: "customer_first_name",
        text: "Customer",
        sort: true,
        formatter: this.customerFormatter,
      },
      {
        dataField: "bill_total",
        text: "Amount",
        sort: true,
        formatter: this.revenueFormatter,
      },
      {
        dataField: "user_first_name",
        text: "Partner",
        sort: true,
        formatter: this.executiveFormatter,
      },
      {
        dataField: "delivered_by_partner",
        text: "Delivered by",
        sort: true,
        formatter: this.deliveredFormatter,
      },
      {
        dataField: "exp_del_date",
        text: "Date",
        sort: true,
      },
      {
        dataField: "payment_mode_value",
        text: "Payment Mode",
        formatter: this.paymentModeFormatter,
      },
      {
        dataField: "collect_by",
        text: "Payment Status",
        formatter: this.collectFormatter,
      },
    ];

    return (
      <div className="w-100 orders-table custom-table-no-border">
        <Modal
          isOpen={this.state.paymentModal}
          toggle={this.togglePaymentModal}
        >
          <ModalHeader toggle={this.togglePaymentModal}>
            Update Payment Mode
          </ModalHeader>
          <ModalBody>
            <Dropdown
              isOpen={this.state.dropdownOpen}
              toggle={this.paymentSelectToggle}
              className="place-order w-100 mb-3"
            >
              <DropdownToggle caret className="w-100" color="primary">
                {this.state.selectedOrder.payment_mode_value || ""}
              </DropdownToggle>
              <DropdownMenu className="w-100">
                {this.props.paymentModes &&
                  this.props.paymentModes.map((row, index) => {
                    return (
                      <DropdownItem
                        key={index}
                        onClick={() => {
                          this.handlePaymentModeChange(
                            row.payment_mode_id,
                            row.payment_mode_value
                          );
                        }}
                      >
                        {row.payment_mode_value}
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </Dropdown>
          </ModalBody>
        </Modal>
        <RemotePagination
          data={this.props.orders}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={this.props.totalData}
          onTableChange={this.handleTableChange}
          columns={columns}
        />
      </div>
    );
  }
}

export default CustomersTable;
