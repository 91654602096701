import React,{ useState }  from "react";
import {connect} from "react-redux";
import MultiSelect from "react-multi-select-component";


import {
    Card,
    Button,
    CardTitle,
    Row,
    Col,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    FormGroup,
    Label, DropdownItem
} from "reactstrap";

import {
    addPrice,
    getProduct,
    updateProductPriceStatus
} from "../../actions/products";

class ProductTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            priceModal: false,
            price: "",
            serving_size: "",
            disable_existing_price: true,
            selectBranchList:[]
        };

        this.togglePriceModal = this.togglePriceModal.bind(this);
        this.submitPrice = this.submitPrice.bind(this);
        this.updatePriceStatus = this.updatePriceStatus.bind(this);
        this.selectBranchList = this.selectBranchList.bind(this);
    }

    componentDidUpdate(previousProps, previousState) {
        const {productData, branch_id} = this.props;
        if (
            productData.product_id !== previousProps.productData.product_id ||
            branch_id !== previousProps.branch_id
        ) {
            if (branch_id != 0 && productData.product_id) {
                this.props.getProduct(productData.product_id, branch_id);
            }
        }
    }

    selectBranchList(val) {
        this.setState(prevState => ({
            selectBranchList: val
        }));
    }

    togglePriceModal() {
        this.setState(prevState => ({
            priceModal: !prevState.priceModal
        }));
    }

    submitPrice() {
        const {price, serving_size,disable_existing_price} = this.state;
        const branch_list = this.state.selectBranchList.map((val,index)=>{
            return val.value;
        });
        if(branch_list.length===0){
            alert("Please choose at least one branch");
            return;
        }
        if (price > 0) {
            this.props.addPrice({
                product_id: this.props.productData.product_id,
                product_size_id: serving_size,
                price: price,
                branch_ids:branch_list,
                disable_existing_price:Number(disable_existing_price)
            });
            this.setState({
                priceModal: false,
                price: "",
                serving_size: "",
                selectBranchList:[],
                disable_existing_price:0
            });
        }
    }

    updatePriceStatus(price_id, status) {
        let params = {
            product_price_id: price_id,
            status: status
        };
        const {productData, branch_id} = this.props;
        this.props.updateProductPriceStatus(
            productData.product_id,
            branch_id,
            params
        );
    }

    render() {

        const {branch_name, price, serving_size,selectBranchList,disable_existing_price} = this.state;
        const productData = this.props.productData;
        let productPricing = productData.serving_size;
        let price_status_list = productData.price_status_list;
        let serving_size_list = productData.serving_size_list;


        let branch_list1 = [];
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            for (let j = 0; j < this.props.loggedInUser.branch_list.length; j++) {
                let item = this.props.loggedInUser.branch_list[j];
                if (j === 0) {
                    for (let k = 0; k < item.branch_list.length; k++) {
                        let branch = item.branch_list[k];
                        if(branch.branch_id==0)
                            continue;
                        branch_list1.push({label:branch.branch_name,value:branch.branch_id})
                    }
                    break;
                }
            }
        }

        return (
            <Card body className="border-0 p-0">
                <CardTitle>Pricing for {branch_name} Branch</CardTitle>

                <Button
                    color="primary"
                    className="w-100"
                    onClick={this.togglePriceModal}
                    outline
                >
                    Add Price
                </Button>
                <Modal
                    isOpen={this.state.priceModal}
                    fade={false}
                    toggle={this.togglePriceModal}
                    className={this.props.className}
                >
                    <ModalHeader toggle={this.togglePriceModal}>Add Price</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>Size</Label>
                            <Input
                                type="select"
                                className="mb-3"
                                name="category"
                                id="category"
                                onChange={e => {
                                    this.setState({serving_size: e.target.value});
                                }}
                            >
                                <option value={""}>Choose a serving size</option>
                                {serving_size_list &&
                                serving_size_list.length > 0 &&
                                serving_size_list.map((row, index) => {
                                    return (
                                        <option value={row.serving_size_id}>
                                            {row.serving_size_name}
                                        </option>
                                    );
                                })}
                            </Input>
                            <Label>Price</Label>
                            <Input
                                type="text"
                                className="mb-3"
                                placeholder=""
                                autoComplete="price"
                                onChange={e => {
                                    this.setState({price: e.target.value});
                                }}
                                value={price}
                            />
                            <div>
                                <label>Choose Branch</label>
                                <MultiSelect
                                    options={branch_list1}
                                    value={selectBranchList}
                                    onChange={(val)=>this.selectBranchList(val)}
                                />
                            </div>
                            <div className="pl-4 pt-4">
                                <Label>
                                    <Input type="checkbox" checked={disable_existing_price} onChange={e => {
                                        this.setState({ disable_existing_price: e.target.checked });
                                    }}
                                    /> Disable existing price for all branch?
                                </Label>

                            </div>

                            {/*<div className="alert alert-primary new-alert"><strong className="mr-1">Note: </strong>*/}
                            {/*    <span> Any size options that are currently active will be automatically disabled</span></div>*/}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" outline onClick={this.togglePriceModal}>
                            Cancel
                        </Button>{" "}

                        <Button
                            color="primary"
                            onClick={() => {
                                this.submitPrice();
                            }}
                        >
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>

                <Row className="each-price p-3">
                    {productPricing &&
                    productPricing.length > 0 &&
                    productPricing.map((price, index) => {
                        return (
                            <Col sm="12" className="p-4 price-bg mt-2">
                                <Row className="">
                                    <Col sm="8">
                                        <div className="title">{price.serving_size_name}</div>
                                        <div className="price">
                                            {price.product_price}
                                        </div>
                                    </Col>
                                    <Col sm="4">
                                        <Input
                                            type="select"
                                            className="mb-3"
                                            name="category"
                                            id="category"
                                            onChange={e => {
                                                // this.setState({});
                                                this.updatePriceStatus(
                                                    price.product_price_id,
                                                    e.target.value
                                                );
                                            }}
                                            value={price.product_size_status}
                                        >
                                            {/* <option value="">Only for this branch</option> */}
                                            {price_status_list &&
                                            price_status_list.length > 0 &&
                                            price_status_list.map((row, index) => {
                                                return (
                                                    <option value={row.status_id}>
                                                        {row.status_name}
                                                    </option>
                                                );
                                            })}
                                        </Input>
                                    </Col>
                                </Row>
                            </Col>
                        );
                    })}
                </Row>
            </Card>
        );
    }
}

function bindActions(dispatch) {
    return {
        addPrice: data => dispatch(addPrice(data)),
        getProduct: (product_id, branch_id) =>
            dispatch(getProduct(product_id, branch_id)),
        updateProductPriceStatus: (product_id, branch_id, params) =>
            dispatch(updateProductPriceStatus(product_id, branch_id, params))
    };
}

function mapStateToProps(state) {
    return {
        currentProduct: state.products.currentProduct,
        loggedInUser: state.commonData.loggedInUser
    };
}

export default connect(
    mapStateToProps,
    bindActions
)(ProductTabs);
