import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import { Link } from "react-router-dom";

import { markPaymentCollected } from "../../actions/cash";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import {
  Container,
  Col,
  Row,
  Button,
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Input
} from "reactstrap";
import CashTable from "./WalletTable";

import { getWalletData, getWalletList } from "../../actions/wallet";
import AddCredit from "./AddCredit";
import {formatNumbersWithThousandSeparator} from "../../utils";

class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction_type: 0,
      startDate: moment().subtract(7, "d"),
      endDate: moment(),
      perPage: 10,
      offset: 0,
      searchText: "",
      preset: "",
      focusedInput: false,
      dropdownOpen: false
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.renderDatePresets = this.renderDatePresets.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);

    this.props.getWalletData();

    const {
      startDate,
      endDate,
      perPage,
      offset,
      searchText,
      transaction_type
    } = this.state;

    let default_params = {
      transaction_type,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: offset,
      perPage: perPage,
      sort: "ASC"
    };
    this.props.getWalletList(default_params);
    console.log(default_params);
  }

  handleTypeChange(type) {
    const { startDate, endDate, searchText, perPage, offset } = this.state;
    this.setState({ transaction_type: type });
    let params = {
      transaction_type: type,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: offset,
      perPage: perPage,
      sort: "ASC"
    };
    this.props.getWalletList(params);
  }

  handlePagination(offset) {
    const {
      startDate,
      endDate,
      searchText,
      perPage,
      transaction_type
    } = this.state;
    this.setState({ offset: offset });
    console.log("offset", offset);
    let params = {
      transaction_type: transaction_type,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: offset,
      perPage: perPage,
      sort: "ASC"
    };
    this.props.getWalletList(params);
  }

  handleSearch(searchText) {
    const {
      startDate,
      endDate,
      offset,
      perPage,
      transaction_type
    } = this.state;
    this.setState({ searchText: searchText });
    console.log("searchText", searchText);
    let params = {
      transaction_type: transaction_type,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: offset,
      perPage: perPage,
      sort: "ASC"
    };
    this.props.getWalletList(params);
  }

  handleDateChange(startDate, endDate) {
    const { perPage, offset, searchText, transaction_type } = this.state;
    this.setState({ startDate: startDate, endDate: endDate });
    console.log("order status ", this.state.orderStatus);
    let params = {
      transaction_type: transaction_type,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: offset,
      perPage: perPage,
      sort: "ASC"
    };
    this.props.getWalletList(params);
  }

  renderDatePresets() {
    return (
      <div className="date_custom_presets">
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment(), moment());
          }}
        >
          Today
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().subtract(1, "d"), moment().subtract(1, "d"));
            this.setState({ focusedInput: false });
          }}
        >
          Yesterday
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().subtract(7, "d"), moment());
            this.setState({ focusedInput: false });
          }}
        >
          Last 7 days
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().subtract(30, "d"), moment());
            this.setState({ focusedInput: false });
          }}
        >
          Last 30 days
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().startOf("month"), moment());
            this.setState({ focusedInput: false });
          }}
        >
          This month
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(
              moment()
                .subtract(1, "months")
                .startOf("month"),
              moment()
                .subtract(1, "months")
                .endOf("month")
            );
            this.setState({ focusedInput: false });
          }}
        >
          Last month
        </Button>
        <Button className="w-100  mb-2" color="info">
          Custom Range
        </Button>
      </div>
    );
  }

  render() {
    const { searchText, startDate, endDate, transaction_type } = this.state;

    return (
      <div className="animated fadeIn">
        <div className="ml-2 mr-2 mt-2 cash p-3">
          <Row>
            <Col md="2" sm="6" className="mb-2">
              <Input
                type="select"
                className="mb-3"
                name="transaction_type"
                id="transaction_type"
                onChange={e => {
                  this.handleTypeChange(e.target.value);
                }}
                value={transaction_type}
              >
                <option value="0">All</option>
                <option value="1">Credit</option>
                <option value="2">Debit</option>
              </Input>
            </Col>
            <Col md="5" sm="6" className="mb-3">
              <DateRangePicker
                calendarInfoPosition="before"
                renderCalendarInfo={this.renderDatePresets}
                startDate={startDate}
                startDateId="FromstartDate"
                endDate={endDate}
                endDateId="FromendDate"
                onDatesChange={({ startDate, endDate }) =>
                  this.handleDateChange(startDate, endDate)
                }
                focusedInput={this.state.focusedInput}
                onFocusChange={focusedInput =>
                  this.setState({ focusedInput })
                }
                orientation={this.state.orientation}
                openDirection={this.state.openDirection}
                isOutsideRange={() => false}
                anchorDirection={"left"}
              />
            </Col>
            <Col md="3" sm="6" className="mb-3">
              <Link to={`/wallets/walletRequests`} target="_blank">
                <Button className="w-100  mb-2" color="primary">
                  Credits Requested
                  </Button>
              </Link>
            </Col>
            <Col md="2" sm="6">
              <AddCredit />
            </Col>
          </Row>
          <Row className="mt-3 cash-summary">
            <Col sm="4" className="text-center mb-3">
              <div className="bg-primary p-4 ">
                <span className="summary-title text-white">
                  {" "}
                    {formatNumbersWithThousandSeparator(Math.round(this.props.walletData.credits_added_this_week) || 0)}
                </span>
                <br />
                Added this week
                </div>
            </Col>
            <Col sm="4" className="text-center mb-3">
              <div className="bg-primary p-4">
                <span className="summary-title text-white">
                  {" "}
                    {formatNumbersWithThousandSeparator(Math.round(this.props.walletData.credits_used_this_week) || 0)}
                </span>
                <br />
                Used this week
              </div>
            </Col>
            <Col sm="4" className="text-center mb-3">
              <div className="bg-success p-4">
                <span className="summary-title text-white">
                  {" "}
                    {formatNumbersWithThousandSeparator(Math.round(this.props.walletData.total_balance) || 0)}
                </span>
                <br />
                Total Balance Overall
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col sm={{ size: '6', offset: 6 }} md={{ size: '3', offset: 9 }} className="float-right p-0 pr-2 border-0">
              <InputGroup className="mb-3 w-100">
                <Input
                  type="text"
                  placeholder="Search"
                  autoComplete="searchText"
                  className="w-100"
                  onChange={e => {
                    this.handleSearch(e.target.value);
                  }}
                  value={searchText}
                />
              </InputGroup>
            </Col>
          </Row> */}

          {this.props.walletList && this.props.walletList.aaData && (
            <CashTable
              orders={this.props.walletList.aaData}
              totalData={this.props.walletList.iTotalRecords}
              handlePagination={this.handlePagination}
            />
          )}
        </div>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    getWalletList: params => dispatch(getWalletList(params)),
    getWalletData: () => dispatch(getWalletData())
  };
}

function mapStateToProps(state) {
  return {
    walletList: state.wallet.walletList,
    walletData: state.wallet.walletData,
    loggedInUser: state.commonData.loggedInUser
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(Wallet);
