import { SET_LOGGEDIN_USER,SET_TEMP_TOKEN, SET_FCM_TOKEN } from "../actions/auth";

const initialState = {
  loggedInUser: []
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGGEDIN_USER:
      return { ...state, loggedInUser: action.payload };
      case SET_TEMP_TOKEN:
          return { ...state, temp_access_token: action.payload };
      case SET_FCM_TOKEN:
        return {...state, fcm_token: action.payload};
    default:
      return state;
  }
};

export default auth;
