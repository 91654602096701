import config from "../config";
import { getToken, setToken, clearToken } from "../utils";
import { setMessage, setMessageType, setAuthToken } from "./commonData";
import { isEmpty } from "lodash";
import axios from "axios";
import { func } from "prop-types";
import moment from "moment";
import { setSpinner } from "./commonData";

export const SET_CUSTOMERS_LIST = "SET_CUSTOMERS_LIST";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_CUSTOMER_ORDER_LIST = "SET_CUSTOMER_ORDER_LIST";

var axiosInstance = axios.create({
  baseURL: config.baseUrl,
  timeout: 150000,
  headers: {
    "Content-Type": "application/json",
    version: "2.0",
    Authorization: `Token=${getToken()}`
  }
});


function setCustomersList(payload) {
  return { type: SET_CUSTOMERS_LIST, payload: payload };
}

function setCustomer(payload) {
  return { type: SET_CUSTOMER, payload: payload };
}

function setCustomerOrdersList(payload) {
  return { type: SET_CUSTOMER_ORDER_LIST, payload: payload };
}
export function getCustomersList(params) {
  let default_params = {
    date_type : 1,
    from_date: moment()
      .subtract(7, "d")
      .format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    searchText: "",
    offset: 0,
    perPage: 10,
    sort: "ASC"
  };
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `customers/listCustomers?from_date=${params.from_date}&to_date=${
          params.to_date
        }&sort=${params.sort}&length=${
          params.perPage
        }&search=${params.searchText}&start=${params.offset}&date_type=${
          params.date_type
        }`)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setCustomersList(response.data.data));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      }).catch(err => {
        dispatch(setSpinner(false));
        if(err.response && err.response.data) {
          dispatch(setMessage(err.response.data.errors));
        } else {
          dispatch(setMessage("Oops!! Something went wrong"));
        }
        dispatch(setMessageType("danger"));
      });
  };
}


export function toggleSMSAction(customer_id, sms_opt_in, listParams) {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `customers/sms_opt_in/${customer_id}/${sms_opt_in}`)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(getCustomersList(listParams));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      }).catch(err => {
        dispatch(setSpinner(false));
        if(err.response && err.response.data) {
          dispatch(setMessage(err.response.data.errors));
        } else {
          dispatch(setMessage("Oops!! Something went wrong"));
        }
        
        dispatch(setMessageType("danger"));
      });
  };
}

export function getCustomer(customer_id) {
  
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `customers/getCustomerProfile/${customer_id}`)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setCustomer(response.data.data));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      }).catch(err => {
        dispatch(setSpinner(false));
        if(err.response && err.response.data) {
          dispatch(setMessage(err.response.data.errors));
        } else {
          dispatch(setMessage("Oops!! Something went wrong"));
        }
        dispatch(setMessageType("danger"));
      });
  };
}

export function getCustomerOrdersList(customer_id, params) {

  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `customers/getCustomerBills/${customer_id}?&sort=${params.sort}&length=${
          params.perPage
        }&start=${params.offset}`)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setCustomerOrdersList(response.data.data));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      }).catch(err => {
        dispatch(setSpinner(false));
        if(err.response && err.response.data) {
          dispatch(setMessage(err.response.data.errors));
        } else {
          dispatch(setMessage("Oops!! Something went wrong"));
        }
        dispatch(setMessageType("danger"));
      });
  };
}
