import React, {Component} from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {Badge, Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Table,} from "reactstrap";
import {getAnalyticsPartnerReport} from "../../actions/orders";
import _ from "lodash";

class PartnerAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderStatus: 0, // default all orders
            perPage: 10,
            offset: 0,
            searchText: "",
            preset: "",
            focusedInput: false,
            dropdownOpen: false,
            yearDropdown: false,
            monthDropdown: false,
            branch_id: 0,
            branch_name: "All branch",
            selectedYear: moment().get("year"),
            selectedMonth: this.getMonths()[moment().get("month") + 1],
            selectedMonthId: moment().get("month") + 1,
            cityDropdown: false,
            city_id: "0",
            city_name: "All Cities"
        };

        this.filterOrders = this.filterOrders.bind(this);
        this.branchtoggle = this.branchtoggle.bind(this);
        this.handleBranchChange = this.handleBranchChange.bind(this);
        this.yearToggle = this.yearToggle.bind(this);
        this.getYears = this.getYears.bind(this);
        this.getMonths = this.getMonths.bind(this);
        this.yearToggle = this.yearToggle.bind(this);
        this.monthToggle = this.monthToggle.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
        this.handleMonthChange = this.handleMonthChange.bind(this);
        this.citytoggle = this.citytoggle.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);

        const {
            orderStatus,
            perPage,
            selectedYear,
            selectedMonthId,
            branch_id,
            city_id
        } = this.state;

        let params = {
            branch_id: branch_id,
            status: orderStatus,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            year: selectedYear,
            month: selectedMonthId,
            city_id: city_id
        };

        this.props.getAnalyticsPartnerReport(params);
    }

    componentDidMount() {
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let firstCityName = branch_list[0].city_name;
                let firstCityId = branch_list[0].city_id;
                this.handleCityChange(firstCityName, firstCityId);
            }
        }
    }


    componentWillReceiveProps(nextProps) {
        if (this.props.loggedInUser && (this.props.loggedInUser.branch_list != nextProps.loggedInUser.branch_list)) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let firstCityName = branch_list[0].city_name;
                let firstCityId = branch_list[0].city_id;
                this.handleCityChange(firstCityName, firstCityId);
            }
        }
    }

    citytoggle() {
        this.setState(prevState => ({
            cityDropdown: !prevState.cityDropdown
        }));
    }

    branchtoggle() {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }

    handleCityChange(city_name, city_id) {
        this.setState({city_id: city_id, city_name: city_name},()=>{
            this.props.loggedInUser.branch_list.map((value) => {
                if (city_id === value.city_id)
                    this.handleBranchChange(value.branch_list[0].branch_id, value.branch_list[0].branch_name);
            });
        });
    }

    handleBranchChange(branch_id, branch_name) {
        const {
            orderStatus,
            perPage,
            selectedYear,
            selectedMonthId,
            searchText,
            city_id
        } = this.state;
        this.setState({branch_id: branch_id, branch_name: branch_name});
        let params = {
            branch_id: branch_id,
            year: selectedYear,
            month: selectedMonthId,
            status: orderStatus,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id: city_id
        };
        this.props.getAnalyticsPartnerReport(params);
    }

    filterOrders(bill_status) {
        const {
            branch_id,
            perPage,
            selectedYear,
            selectedMonthId,
            searchText,
            city_id
        } = this.state;
        this.setState({orderStatus: bill_status});
        let params = {
            branch_id: branch_id,
            status: bill_status,
            year: selectedYear,
            month: selectedMonthId,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
          city_id:city_id
        };
        this.props.getAnalyticsPartnerReport(params);
    }

    handleYearChange(year) {
        const {
            branch_id,
            orderStatus,
            perPage,
            selectedMonthId,
            searchText,
            city_id
        } = this.state;
        this.setState({selectedYear: year});
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            year: year,
            month: selectedMonthId,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id:city_id
        };
        this.props.getAnalyticsPartnerReport(params);
    }

    handleMonthChange(month) {
        console.log(month, _.indexOf(this.getMonths(), month));
        const {
            orderStatus,
            perPage,
            searchText,
            selectedYear,
            branchId,
            city_id
        } = this.state;
        this.setState({
            selectedMonth: month,
            selectedMonthId: _.indexOf(this.getMonths(), month),
        });

        let params = {
            branch_id: branchId,
            status: orderStatus,
            year: selectedYear,
            month: _.indexOf(this.getMonths(), month),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id:city_id
        };
        this.props.getAnalyticsPartnerReport(params);
    }

    getYears() {
        const years = [];
        _.each(_.range(2012, moment().get("year") + 1), (year) => {
            years.push(year.toString());
        });
        return years;
    }

    getMonths() {
        return [
            "All",
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
    }

    yearToggle() {
        this.setState((prevState) => ({
            yearDropdown: !prevState.yearDropdown,
        }));
    }

    monthToggle() {
        this.setState((prevState) => ({
            monthDropdown: !prevState.monthDropdown,
        }));
    }

    handleTableData() {
        const analyticsGroupedByBranch = _.groupBy(
            this.props.PartnerAnalytics.data,
            "branch_name"
        );

        const headers = [
            "First Name",
            "Last Name",
            "No. of Orders",
            "Order Value",
            "Fixed Salary",
            "Payout/Order",
            "Delivery Payout",
            "Incentives",
            "Total Payout",
            "Cost/Order",
            "Order/Day",
        ];
        return _.map(analyticsGroupedByBranch, (c) => {
            const totalOrderCount = _.reduce(
                c,
                (res, p) => res + +p.total_order_count,
                0
            );
            const totalOrderValue = _.reduce(
                c,
                (res, p) => res + +p.total_order_value,
                0
            ).toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                currency: "INR",
            });

            const totalDeliveryPayout = _.reduce(
                c,
                (res, p) => res + +p.delivery_payout,
                0
            ).toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                currency: "INR",
            });

            const totalSalary = _.reduce(
                c,
                (res, p) => res + +p.salary_amount,
                0
            ).toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                currency: "INR",
            });

            const totalPerOrderValue = _.reduce(
                c,
                (res, p) => res + +p.per_order_value,
                0
            );

            const totalIncentive = _.reduce(
                c,
                (res, p) => res + +p.total_incentive,
                0
            ).toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                currency: "INR",
            });

            const totalPayout = _.reduce(
                c,
                (res, p) => res + +p.total_salary,
                0
            ).toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                currency: "INR",
            });

            const totalCostPerOrder = _.reduce(
                c,
                (res, p) => {
                    const cpo = +p.total_salary / +p.total_order_count;
                    return res + cpo;
                },
                0
            );

            const totalOrderPerDay = _.reduce(
                c,
                (res, p) => res + +p.order_per_day || 0,
                0
            ).toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                currency: "INR",
            });

            return (
                <div className="table-holder">
                    <h5 className="table-header-analytics">{_.head(c).branch_name}</h5>
                    <Table responsive className="my-table">
                        <thead>
                        <tr className="my-table-heading">
                            {_.map(headers, (header) => (
                                <td className="font-weight-bold">{header}</td>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {_.map(c, (p) => (
                            <tr>
                                <td>{p.user_first_name}</td>
                                <td>{p.user_second_name}</td>
                                <td>
                                    {parseFloat(p.total_order_count, 10).toLocaleString(
                                        "en-IN",
                                        {
                                            maximumFractionDigits: 2,
                                            currency: "INR",
                                        }
                                    )}
                                </td>
                                <td>
                                    {parseFloat(p.total_order_value, 10).toLocaleString(
                                        "en-IN",
                                        {
                                            maximumFractionDigits: 2,
                                            currency: "INR",
                                        }
                                    )}
                                </td>
                                <td>
                                    {parseFloat(p.salary_amount, 10).toLocaleString("en-IN", {
                                        maximumFractionDigits: 2,
                                        currency: "INR",
                                    })}
                                </td>
                                <td>
                                    {parseFloat(p.per_order_value, 10).toLocaleString("en-IN", {
                                        maximumFractionDigits: 2,
                                        currency: "INR",
                                    })}
                                </td>
                                <td>
                                    {parseFloat(p.delivery_payout, 10).toLocaleString("en-IN", {
                                        maximumFractionDigits: 2,
                                        currency: "INR",
                                    })}
                                </td>
                                <td>
                                    {p.total_incentive &&
                                    parseFloat(p.total_incentive, 10).toLocaleString(
                                        "en-IN",
                                        {
                                            maximumFractionDigits: 2,
                                            currency: "INR",
                                        }
                                    )}
                                </td>
                                <td>
                                    {p.total_salary &&
                                    parseFloat(p.total_salary, 10).toLocaleString("en-IN", {
                                        maximumFractionDigits: 2,
                                        currency: "INR",
                                    })}
                                </td>
                                <td>
                                    {(+p.total_salary / +p.total_order_count).toLocaleString(
                                        "en-IN",
                                        {
                                            maximumFractionDigits: 2,
                                            currency: "INR",
                                        }
                                    )}
                                </td>
                                <td>
                                    {p.order_per_day &&
                                    parseFloat(p.order_per_day, 10).toLocaleString("en-IN", {
                                        maximumFractionDigits: 2,
                                        currency: "INR",
                                    })}
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td className="font-weight-bold">Total</td>
                            <td></td>
                            <td className="font-weight-bold">{totalOrderCount}</td>
                            <td className="font-weight-bold">{totalOrderValue}</td>
                            <td className="font-weight-bold">{totalSalary}</td>
                            <td className="font-weight-bold">{totalPerOrderValue}</td>
                            <td className="font-weight-bold">{totalDeliveryPayout}</td>
                            <td className="font-weight-bold">{totalIncentive}</td>
                            <td className="font-weight-bold">{totalPayout}</td>
                            <td className="font-weight-bold">
                                {totalCostPerOrder.toLocaleString("en-IN", {
                                    maximumFractionDigits: 2,
                                    currency: "INR",
                                })}
                            </td>
                            <td className="font-weight-bold">{totalOrderPerDay}</td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            );
        });
    }

    render() {
        const {branch_name, orderStatus,city_id,city_name} = this.state;

        let orderStatusList = [];
        if (this.props.PartnerAnalytics && this.props.PartnerAnalytics.meta) {
            orderStatusList = this.props.PartnerAnalytics.meta;
        }
        let statusList = {
            ORDER_TEMP: "Temp",
            ORDER_ONLINE_PAYMENT_PROCESSING: "Payment Pending",
            ORDER_PLACED: "New Order",
            ORDER_ACCEPTED: "In Process",
            ORDER_PACKED: "Ready",
            ORDER_ASSIGNED: "Assigned",
            ORDER_DISPATCHED: "Dispatched",
            ORDER_DELIVERED: "Delivered",
            ORDER_REJECTED: "Rejected",
            ORDER_CANCELLED: "Canceled",
        };
        let city_list = [];
        let branch_list = [];
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {

            this.props.loggedInUser.branch_list.map((item)=>{
                if(city_id===item.city_id)
                    branch_list = item.branch_list;
                city_list.push(item);
            });
        }
        let all_branch_outline = true;
        if (orderStatus === 0) {
            all_branch_outline = false;
        }

        return (
            <div className="animated fadeIn">
                <Row className="ml-2 mr-2 mt-2 orders">
                    <Col md="3" lg="3" xl="2" className="p-2 orders-side d-none">
                        <hr/>
                        <label className="filter-label">Order History</label>
                        <Button
                            color="primary"
                            className="w-100 text-left orderStatusBtn"
                            outline={all_branch_outline}
                            onClick={() => {
                                this.filterOrders(0);
                            }}
                        >
                            All Orders
                            <Badge className="float-right ml-1 p-1 orderCount">
                                {this.props.PartnerAnalytics &&
                                this.props.PartnerAnalytics.order_list &&
                                this.props.PartnerAnalytics.order_list.iTotalRecords}
                            </Badge>
                        </Button>
                        {orderStatusList &&
                        orderStatusList.length > 0 &&
                        orderStatusList.map((element, index) => {
                            let outline =
                                element.bill_status !== this.state.orderStatus ? true : false;
                            return (
                                <Button
                                    color="primary"
                                    className="w-100 text-left orderStatusBtn"
                                    outline={outline}
                                    onClick={() => {
                                        this.filterOrders(element.bill_status);
                                    }}
                                >
                                    {statusList[element.order_status_value]}
                                    <Badge className="float-right ml-1 p-1 orderCount">
                                        {element.bill_count}
                                    </Badge>
                                </Button>
                            );
                        })}
                    </Col>

                    <Col className="orders-main px-3">
                        <Row className="">
                            <div class="p-3 today-side col-sm-4 col-md-3 col-lg-2 border-right">

                                <Dropdown
                                    isOpen={this.state.cityDropdown}
                                    toggle={this.citytoggle}
                                    className="place-order w-100 mb-3"
                                >
                                    <DropdownToggle caret className="w-100" color="primary">
                                        {city_name}
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        {city_list &&
                                        city_list.map((row, index) => {
                                            return (
                                                <DropdownItem
                                                    key={index}
                                                    onClick={() => {
                                                        this.handleCityChange(
                                                            row.city_name,
                                                            row.city_id
                                                        );
                                                    }}
                                                >
                                                    {row.city_name}
                                                </DropdownItem>
                                            );
                                        })}
                                    </DropdownMenu>
                                </Dropdown>

                                <Dropdown
                                    isOpen={this.state.dropdownOpen}
                                    toggle={this.branchtoggle}
                                    className="place-order w-100 mb-3"
                                >
                                    <DropdownToggle caret className="w-100" color="primary">
                                        {branch_name}
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        {branch_list &&
                                        branch_list.map((row, index) => {
                                            return (
                                                <DropdownItem
                                                    key={index}
                                                    onClick={() => {
                                                        this.handleBranchChange(
                                                            row.branch_id,
                                                            row.branch_name
                                                        );
                                                    }}
                                                >
                                                    {row.branch_name}
                                                </DropdownItem>
                                            );
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                                <h5 className="nav-header">Navigation</h5>
                                <ul className="nav flex-column ul-hover-active">
                                    <li className="nav-link px-0">
                                        <NavLink to="/analytics">Product Sales</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/category_analytics">Payment Source</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/time_analytics">Sales By Time</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/partner_analytics">Partner Analytics</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/trends_analytics">Sales Analytics</NavLink>
                                    </li>
                                    <li className="nav-link px-0">
                                        <NavLink to="/customer_analytics">Customer Analytics</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div class="today-main py-3 col-sm-8 col-md-9 col-lg-10">
                                <Row>
                                    <Col lg="4" md="6" className="px-3 mb-3">
                                        <Dropdown
                                            isOpen={this.state.yearDropdown}
                                            toggle={this.yearToggle}
                                            className="place-order mb-3"
                                        >
                                            <DropdownToggle caret className="w-100" color="primary">
                                                {this.state.selectedYear}
                                            </DropdownToggle>
                                            <DropdownMenu className="w-100">
                                                {this.getYears().map((year, index) => {
                                                    return (
                                                        <DropdownItem
                                                            key={index}
                                                            onClick={() => {
                                                                this.handleYearChange(year);
                                                            }}
                                                        >
                                                            {year}
                                                        </DropdownItem>
                                                    );
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>
                                    <Col lg="4" md="6" className="px-3 mb-3">
                                        <Dropdown
                                            isOpen={this.state.monthDropdown}
                                            toggle={this.monthToggle}
                                            className="place-order mb-3"
                                        >
                                            <DropdownToggle caret className="w-100" color="primary">
                                                {this.state.selectedMonth}
                                            </DropdownToggle>
                                            <DropdownMenu className="w-100">
                                                {this.getMonths().map((month, index) => {
                                                    return (
                                                        <DropdownItem
                                                            key={index}
                                                            onClick={() => {
                                                                this.handleMonthChange(month);
                                                            }}
                                                        >
                                                            {month}
                                                        </DropdownItem>
                                                    );
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                                <Row style={{display: "grid", gridTemplateColumns: "1fr"}}>
                                    {this.handleTableData()}
                                </Row>
                                <div className="alert alert-primary new-alert mt-3">
                                    <strong className="mr-1">Note: </strong>{" "}
                                    <span> Only Completed orders are visible here</span>
                                </div>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

function bindActions(dispatch) {
    return {
        getAnalyticsPartnerReport: (params) =>
            dispatch(getAnalyticsPartnerReport(params)),
    };
}

function mapStateToProps(state) {
    return {
        PartnerAnalytics: state.orders.PartnerAnalytics,
        loggedInUser: state.commonData.loggedInUser,
    };
}

export default connect(mapStateToProps, bindActions)(PartnerAnalytics);
