import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import {DateRangePicker} from "react-dates";
import config from "../../config";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import Cookies from 'js-cookie';

import {
    Badge,
    Button,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    Row
} from "reactstrap";

import OrdersSummary from "./OrdersSummary";
import OrdersTable from "./OrdersTable";
import OrdersSource from "./OrdersSource";
import {getOrderHistory} from "../../actions/orders";
import {debounce} from "throttle-debounce";

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // date default is -7 days
            startDate: moment().subtract(7, "d"),
            endDate: moment(),
            orderStatus: 0, // default all orders
            perPage: 10,
            offset: 0,
            searchText: "",
            preset: "",
            city_id: "0",
            city_name: "All Cities",
            focusedInput: false,
            dropdownOpen: false,
            citydropdownOpen: false,
            branch_id: 0,
            branch_name: "All branch"
        };
        this.filterOrders = this.filterOrders.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.renderDatePresets = this.renderDatePresets.bind(this);
        this.branchtoggle = this.branchtoggle.bind(this);
        this.citytoggle = this.citytoggle.bind(this);

        this.handleBranchChange = this.handleBranchChange.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);


        const {
            branch_id,
            startDate,
            endDate,
            perPage,
            offset,
            searchText,
            orderStatus
        } = this.state;
        let default_params = {
            branch_id: branch_id,
            status: orderStatus,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC"
        };
        this.props.getOrderHistory(default_params);
        // console.log(this.props);
        this.autocompleteSearchDebounced = debounce(700, this.autocompleteSearch);

    }

    changeQuery = event => {
        this.setState({q: event.target.value}, () => {
            this.autocompleteSearchDebounced(this.state.q);
        });
    };

    branchtoggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    citytoggle() {
        this.setState(prevState => ({
            citydropdownOpen: !prevState.citydropdownOpen
        }));
    }

    componentDidMount() {

        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;
            if (branch_list.length > 0) {
                let firstCityName = branch_list[0].city_name;
                let firstCityId = branch_list[0].city_id;
                this.handleCityChange(firstCityName, firstCityId);
            }
        }
    }


    handleCityChange(city_name, city_id) {
        this.setState({city_id: city_id, city_name: city_name},()=>{
            this.props.loggedInUser.branch_list.map((value) => {
                if (city_id === value.city_id)
                    this.handleBranchChange(value.branch_list[0].branch_id, value.branch_list[0].branch_name);
            });
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.loggedInUser && (this.props.loggedInUser.branch_list != nextProps.loggedInUser.branch_list)) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;
            if (branch_list.length > 0) {
                let firstCityName = branch_list[0].city_name;
                let firstCityId = branch_list[0].city_id;
                this.handleCityChange(firstCityName, firstCityId);
            }
        }
    }


    handleDateChange(startDate, endDate) {
        const {branch_id, orderStatus, perPage, offset, searchText, city_id} = this.state;
        this.setState({startDate: startDate, endDate: endDate});
        // console.log("start date ", startDate, ' and the end date is ', endDate);
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            city_id: city_id,
            sort: "ASC"
        };
        this.props.getOrderHistory(params);
        // console.log(this.state);

    }

    handleBranchChange(branch_id, branch_name) {
        const {
            orderStatus,
            perPage,
            offset,
            searchText,
            startDate,
            endDate,
            city_id
        } = this.state;
        this.setState({branch_id: branch_id, branch_name: branch_name});
        // console.log("branch id ", branch_id);
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            city_id: city_id,
            perPage: perPage,
            sort: "ASC"
        };
        this.props.getOrderHistory(params);
        // console.log(this.state);
    }

    filterOrders(bill_status) {
        const {
            branch_id,
            startDate,
            endDate,
            perPage,
            offset,
            searchText,
            city_id
        } = this.state;
        this.setState({orderStatus: bill_status});
        // console.log("order status ", this.state.orderStatus);
        let params = {
            branch_id: branch_id,
            status: bill_status,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            city_id: city_id,
            perPage: perPage,
            sort: "ASC"
        };
        this.props.getOrderHistory(params);
        // console.log(this.state);
    }

    handlePagination(offset) {
        const {
            branch_id,
            startDate,
            endDate,
            orderStatus,
            searchText,
            perPage,
            city_id
        } = this.state;
        this.setState({offset: offset});
        // console.log("offset", offset);
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: offset,
            city_id: city_id,
            perPage: perPage,
            sort: "ASC"
        };
        this.props.getOrderHistory(params);
        // console.log(this.state);
    }

    autocompleteSearch = query => {
        this.handleSearch(query);
    };

    handleSearch(searchText) {
        const {
            branch_id,
            startDate,
            endDate,
            orderStatus,
            offset,
            perPage,
            city_id
        } = this.state;
        this.setState({searchText: searchText});
        // console.log("searchText", searchText);
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: offset,
            perPage: perPage,
            city_id:city_id,
            sort: "ASC"
        };
        this.props.getOrderHistory(params);
        // console.log(this.state);
    }

    renderDatePresets() {
        return (
            <div className="date_custom_presets">
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment(), moment());
                    }}
                >
                    Today
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().subtract(1, "d"), moment().subtract(1, "d"));
                        this.setState({focusedInput: false});
                    }}
                >
                    Yesterday
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().subtract(7, "d"), moment());
                        this.setState({focusedInput: false});
                    }}
                >
                    Last 7 days
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().subtract(30, "d"), moment());
                        this.setState({focusedInput: false});
                    }}
                >
                    Last 30 days
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().startOf("month"), moment());
                        this.setState({focusedInput: false});
                    }}
                >
                    This month
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(
                            moment()
                                .subtract(1, "months")
                                .startOf("month"),
                            moment()
                                .subtract(1, "months")
                                .endOf("month")
                        );
                        this.setState({focusedInput: false});
                    }}
                >
                    Last month
                </Button>
                <Button className="w-100  mb-2" color="info">
                    Custom Range
                </Button>
            </div>
        );
    }

    render() {
        const {
            startDate,
            endDate,
            branch_name,
            orderStatus,
            searchText,
            city_name,
            branch_id,
            city_id
        } = this.state;
        let orderStatusList = [];
        if (this.props.ordersList && this.props.ordersList.meta) {
            orderStatusList = this.props.ordersList.meta;
        }
        let statusList = {
            ORDER_TEMP: "Temp",
            ORDER_ONLINE_PAYMENT_PROCESSING: "Payment Pending",
            ORDER_PLACED: "New Order",
            ORDER_ACCEPTED: "In Process",
            ORDER_PACKED: "Ready",
            ORDER_ASSIGNED: "Assigned",
            ORDER_DISPATCHED: "Dispatched",
            ORDER_DELIVERED: "Delivered",
            ORDER_REJECTED: "Rejected",
            ORDER_CANCELLED: "Canceled"
        };

        let city_list = [];
        let branch_list = [];
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            this.props.loggedInUser.branch_list.map((item)=>{
                if(city_id===item.city_id)
                    branch_list = item.branch_list;
                city_list.push(item);
            });
        }

        let all_branch_outline = true;
        if (orderStatus === 0) {
            all_branch_outline = false;
        }
        return (
            <div className="animated fadeIn">
                <Row className="ml-2 mr-2 mt-2 orders">
                    <Col md="3" lg="3" xl="2" className="p-2 orders-side d-none">

                        <hr/>
                        <label className="filter-label">Order History</label>
                        <Button
                            color="primary"
                            className="w-100 text-left orderStatusBtn"
                            outline={all_branch_outline}
                            onClick={() => {
                                this.filterOrders(0);
                            }}
                        >
                            All Orders
                            <Badge className="float-right ml-1 p-1 orderCount">
                                {this.props.ordersList &&
                                this.props.ordersList.order_list &&
                                this.props.ordersList.order_list.iTotalRecords}
                            </Badge>
                        </Button>
                        {orderStatusList &&
                        orderStatusList.length > 0 &&
                        orderStatusList.map((element, index) => {
                            // console.log(element);
                            let outline =
                                element.bill_status !== this.state.orderStatus ? true : false;
                            return (
                                <Button
                                    color="primary"
                                    className="w-100 text-left orderStatusBtn"
                                    outline={outline}
                                    onClick={() => {
                                        // console.log(element.bill_status);
                                        this.filterOrders(element.bill_status);
                                    }}
                                >
                                    {statusList[element.order_status_value]}
                                    <Badge className="float-right ml-1 p-1 orderCount">
                                        {element.bill_count}
                                    </Badge>
                                </Button>
                            );
                        })}
                    </Col>

                    <Col className="orders-main p-3">
                        <Row className="">
                            <Col md="2" sm="3" className="">

                                <Dropdown
                                    isOpen={this.state.citydropdownOpen}
                                    toggle={this.citytoggle}
                                    className="place-order w-100 mb-3"
                                >
                                    <DropdownToggle caret className="w-100" color="primary">
                                        {city_name}
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        {city_list &&
                                        city_list.map((row, index) => {
                                            return (
                                                <DropdownItem
                                                    onClick={() => {
                                                        this.handleCityChange(
                                                            row.city_name,
                                                            row.city_id
                                                        );
                                                    }}
                                                >
                                                    {row.city_name}
                                                </DropdownItem>
                                            );
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                            <Col md="2" sm="3" className="">
                                <Dropdown
                                    isOpen={this.state.dropdownOpen}
                                    toggle={this.branchtoggle}
                                    className="place-order w-100 mb-3"
                                >
                                    <DropdownToggle caret className="w-100" color="primary">
                                        {branch_name}
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        {branch_list &&
                                        branch_list.map((row, index) => {
                                            return (
                                                <DropdownItem
                                                    onClick={() => {
                                                        this.handleBranchChange(
                                                            row.branch_id,
                                                            row.branch_name
                                                        );
                                                    }}
                                                >
                                                    {row.branch_name}
                                                </DropdownItem>
                                            );
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                            <Col md="4" sm="6" className="mb-3">
                                <DateRangePicker
                                    calendarInfoPosition="before"
                                    renderCalendarInfo={this.renderDatePresets}
                                    startDate={startDate}
                                    startDateId="FromstartDate"
                                    endDate={endDate}
                                    endDateId="FromendDate"
                                    onDatesChange={({startDate, endDate}) => {
                                        // console.log({ startDate, endDate })

                                        if (!startDate) {
                                            startDate = endDate
                                        }

                                        if (!endDate) {
                                            endDate = startDate;
                                        }
                                        return this.handleDateChange(startDate, endDate)
                                    }
                                    }
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput =>
                                        this.setState({focusedInput})
                                    }
                                    orientation={this.state.orientation}
                                    openDirection={this.state.openDirection}
                                    isOutsideRange={() => false}
                                    anchorDirection={"left"}
                                />
                            </Col>
                            <Col md="2" sm="3" className="">
                                <a
                                    href={`${config.baseUrl}admin/exportBills?from_date=${startDate.format(
                                        "YYYY-MM-DD"
                                    )}&to_date=${endDate.format(
                                        "YYYY-MM-DD"
                                    )}&branch_id=${branch_id}&city_id=${city_id}&access_token=${`${Cookies.get('token_dashboard')}`}`}
                                    target="_blank"
                                >
                                    <Button className="w-100  mb-2" color="primary">
                                        Download
                                    </Button>
                                </a>
                            </Col>
                            {this.props.ordersList && (
                                <OrdersSummary numbers={this.props.ordersList.numbers}/>
                            )}
                            {this.props.ordersList && (
                                <OrdersSource
                                    sources={this.props.ordersList.data_by_order_source}
                                    in_house_data={this.props.ordersList.in_house}
                                    // adt={this.props.ordersList.ADT}
                                />
                            )}

                            <Col sm="12">
                                <Col sm="3" className="float-right p-0 pr-2 border-0">
                                    <InputGroup className="mb-3 w-100">
                                        <Input
                                            type="text"
                                            placeholder="Search"
                                            autoComplete="searchText"
                                            className="w-100"
                                            onChange={this.changeQuery}
                                            value={this.state.q}
                                        />
                                    </InputGroup>
                                </Col>
                            </Col>
                            {this.props.ordersList &&
                            this.props.ordersList.order_list &&
                            this.props.ordersList.order_list.aaData && (
                                <OrdersTable
                                    orders={this.props.ordersList.order_list.aaData}
                                    totalData={
                                        this.props.ordersList.order_list &&
                                        this.props.ordersList.order_list.iTotalDisplayRecords
                                    }
                                    handlePagination={this.handlePagination}
                                />
                            )}
                        </Row>
                        <div className="alert alert-primary new-alert">
                            <strong className="mr-1">Note: {" "}</strong>{" "}
                            <span> Only Completed orders are visible here</span>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

function bindActions(dispatch) {
    return {
        getOrderHistory: params => dispatch(getOrderHistory(params))
    };
}

function mapStateToProps(state) {
    return {
        ordersList: state.orders.ordersList,
        loggedInUser: state.commonData.loggedInUser
    };
}

export default connect(
    mapStateToProps,
    bindActions
)(Orders);
