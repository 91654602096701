import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Card,
  CardTitle,
  CardBody
} from "reactstrap";

class Address extends Component {
  render() {
    return (
      <Row>
        {this.props.addresses.map((element, index) => {
          return (
            <Col sm="6">
              <Card body className="address-card">
                <Row>
                  <Col sm="2" className="address-icon text-center">
                    <i class="fas fa-address-book" />
                  </Col>
                  <Col sm="10">
                    <CardBody className="p-0">
                    {element.address_text},<br/>{element.google_address}
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
          );
        })}
      </Row>
    );
  }
}

export default Address;
