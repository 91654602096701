import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    FormGroup,
    Input
} from "reactstrap";

import {updateOrder, getDeliveryGuys, updateOrderPreTime} from "../../actions/orders";

class OrderActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rejectModal: false,
            assignModal: false,
            orderStatus: "",
            rejectReason: 0,
            is_full_time: null,
            deliveryBoy: "",
            rejectError: false
        };
        this.toggleRejectModal = this.toggleRejectModal.bind(this);
        this.toggleAssignModal = this.toggleAssignModal.bind(this);
        this.assignDeliveryGuy = this.assignDeliveryGuy.bind(this);
        this.setCookTime = this.setCookTime.bind(this);
    }

    toggleAssignModal() {
        this.props.getDeliveryGuys(this.props.order.branch_id);
        this.setState(prevState => ({
            assignModal: !prevState.assignModal
        }));
    }

    toggleRejectModal() {
        this.setState(prevState => ({
            rejectModal: !prevState.rejectModal
        }));
    }

    assignDeliveryGuy() {
        this.props.updateOrder(
            6,
            this.props.order.bill_id,
            "",
            this.state.deliveryBoy,
            this.props.filterParams,
            this.props.order.delivered_by_partner
        );
        this.setState({assignModal: false});
    }
    setCookTime() {
        if (!this.state.is_full_time) {
            this.setState({rejectError: true});
            return;
        }
        this.props.updateOrderPreTime(
            // 9,
            this.props.order.bill_id,
            this.state.is_full_time,
            "",
            this.props.filterParams
        );
        this.setState({rejectError: false, rejectModal: false});
        // this.forceUpdate();

    }

    renderActions(order) {
        let status = (order.is_full_time);

        if (status == null) {
            return (
                <div>
                    <FormGroup>
                        <div className="input-group mb-3 ml-3">
                            <Input
                                type="select"
                                name="select"
                                id="exampleSelect1"

                                onChange={e => {
                                    this.setState({is_full_time: e.target.value});
                                }}
                                // onClick={() => {
                                //     this.setCookTime();
                                // }}
                            >

                                <option>Cook Time</option>
                                <option value={true}>
                                    Full Time
                                </option>
                                <option value={false}>
                                    Half Time
                                </option>
                                )}
                            </Input>
                            <Button
                                color="secondary ml-1"
                                onClick={() => {
                                    this.setCookTime();
                                }}
                            >
                                <span className="fa fa-check"></span>
                            </Button>
                        </div>
                    </FormGroup>
                </div>
            )
        } else if (status == 0)
        {
            return (
                <div>
                    <button className="btn btn-info ml-3" disabled>
                        Half Time
                    </button>
                </div>
            )
        }
    else if
        (status == 1)
        {
            return (
                <div>
                    <button className="btn btn-warning ml-3" disabled>
                        Full Time
                    </button>
                </div>
            )
        }
    }

    render() {
        const order = this.props.order;
        // console.log(order, "Check")
        return <div className="order_actions">{this.renderActions(order)}</div>;
        // noinspection JSAnnotator
        // return (
        //     <div>
        // {order && order.is_full_time == null && (
        //                             <FormGroup>
        //                                 <Input
        //                                     type="select"
        //                                     name="select"
        //                                     id="exampleSelect1"
        //
        //                                     onChange={e => {
        //                                         this.setState({is_full_time: e.target.value});
        //                                         this.setCookTime()
        //                                     }}
        //
        //                                     // onClick={() => {
        //                                     //     this.setCookTime();
        //                                     // }}
        //                                 >
        //
        //                                     <option>Cook Time</option>
        //                                     <option value={true}>
        //                                         Full Time
        //                                     </option>
        //                                     <option value={false}>
        //                                         Half Time
        //                                     </option>
        //                                     )}
        //                                 </Input>
        //                                 <Button
        //                                     color="secondary"
        //                                     onClick={() => {
        //                                         this.setCookTime();
        //                                     }}
        //                                 >
        //                                     <span className="fa fa-check"></span>
        //                                 </Button>
        //                             </FormGroup>
        //                         )};
        //         {order && order.is_full_time == 1 && (
        //             <p>
        //                 Full Time
        //             </p>
        //         )}
        //         {order && order.is_full_time == 0 && (
        //             <p>
        //                 Half Time
        //             </p>
        //         )}
        //     </div>
        // )
    }
}

function bindActions(dispatch) {
    return {
        updateOrderPreTime: (order_id, is_full_time) =>
            dispatch(updateOrderPreTime(order_id, is_full_time)),
        getDeliveryGuys: branch_id => dispatch(getDeliveryGuys(branch_id))
    };
}

function mapStateToProps(state) {
    return {
        liveOrders: state.orders.liveOrdersList,
        PaymentReceived: state.orders.PaymentReceived,
        liveOrderMeta: state.orders.liveOrderMeta,
        deliveryGuys: state.orders.deliveryGuys
    };
}

export default connect(
    mapStateToProps,
    bindActions
)(OrderActions);
