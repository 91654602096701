import React, {Component} from "react";
import {connect} from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";

import {Button, Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import SelectBranch from "./SelectBranch";
import AddCoupon from "./AddCoupon";

import {getCouponsList, getCouponTypes} from "../../actions/branch"

class Coupons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            couponModal: false,
            couponCode: "",
            coupon_types: "",
            discount: "",
            minBillAmt: "",
            coupon_applied_on: "",
            discount_product: "",
            branch_id: this.props.branch_id,
            editCoupon: false,
        };
        this.toggleEditCouponModal = this.toggleEditCouponModal.bind(this)
        this.toggleCouponModal = this.toggleCouponModal.bind(this);
        this.amtFormatter = this.amtFormatter.bind(this);
        this.couponNameFormatter = this.couponNameFormatter.bind(this);
        this.couponTypeFormatter = this.couponTypeFormatter.bind(this);
        this.discountFormatter = this.discountFormatter.bind(this);
        this.props.getCouponTypes();
        // this.setRejectReason = this.setRejectReason.bind(this);
    }

    componentDidUpdate(previousProps, previousState) {
        const {branch_id} = this.props;
        if (branch_id !== previousProps.branch_id) {
            if (branch_id) {
                this.props.getCouponsList(branch_id);
            }
            this.setState({branch_id: branch_id});
        }
    }


    amtFormatter(cell, row, rowIndex, formatExtraData) {
        return (
            <div>
                {cell}
            </div>
        );
    }

    couponNameFormatter(cell, row, rowIndex, formatExtraData) {
        return (
            <div>
                {cell}
            </div>
        );
    }

    couponTypeFormatter(cell, row, rowIndex, formatExtraData) {
        let couponType = this.props.couponTypes && this.props.couponTypes.filter(item => {
            return item.coupon_type_id == cell;
        });
        return (
            <div>{couponType[0].coupon_type_name}</div>
        );
    }

    discountFormatter(cell, row, rowIndex, formatExtraData) {
        return (
            <div>
                {cell}{"%"}
            </div>
        );
    }

    toggleCouponModal() {
        this.setState(prevState => ({
            couponModal: !prevState.couponModal
        }));
    }

    toggleEditCouponModal() {
        this.setState(prevState => ({
            editCoupon: !prevState.editCoupon
        }));
    }

    render() {
        const {
            couponModal,
            couponCode,
            coupon_types,
            discount,
            minBillAmt,
            coupon_applied_on,
            discount_product,
        } = this.state;
        let data = this.props.couponsList;

        const columns = [
            {
                dataField: "coupon_text",
                text: "Coupon Code",
                sort: true,
                formatter: this.couponNameFormatter
            },
            {
                dataField: "coupon_value_type",
                text: "Coupon Type",
                sort: true,
                formatter: this.couponTypeFormatter
            },
            {
                dataField: "min_bamount",
                text: "Minimum Base Amount",
                sort: true,
                formatter: this.amtFormatter
            },
            {
                dataField: "max_damount",
                text: "Max Discount Amount",
                sort: true,
                formatter: this.amtFormatter
            },
            {
                dataField: "coupon_value",
                text: "Discount",
                sort: true,
                formatter: this.discountFormatter
            },

        ];

        return (
            <div className="animated fadeIn dashboard p-1">
                <Row>
                    <Col sm="9" className="m-0 mb-2">
                        <div className="mb-2">BRANCH COUPONS</div>
                        <div className="">
                            Create coupons for discount for iOS, Android and Web
                        </div>
                    </Col>
                    {this.props.branch_id == 0 ? <SelectBranch/> : " "}
                    {this.props.branch_id != 0 &&
                    <AddCoupon branch_id={this.props.branch_id} couponTypes={this.props.couponTypes}/>
                    }
                </Row>
                <Row>
                    <Col sm="12" className="">
                        {this.props.branch_id != 0 && data && data.length > 0 &&
                        <div className="custom-table-no-border">
                            <div className="table-responsive new-table-css last-child-right table-margin-minus">
                                <BootstrapTable
                                    remote
                                    keyField="delivery_charges_id"
                                    data={data}
                                    columns={columns}
                                />
                            </div>
                        </div>
                        }
                    </Col>
                </Row>
                <div className="animated fadeIn dashboard">
                    <div className="banner-row border-top pt-2 mt-2">
                        <div className="row ">
                            <div className="col-md-2">
                                <p className="mb-0">COUPON NAME</p>
                                <h4>POTLUC</h4>
                            </div>
                            <div className="col-md-3">
                                <p className="mb-0">COUPON TYPE</p>
                                <h4>Discount On Bill (Percentage)</h4>
                            </div>
                            <div className="col-md-2">
                                <p className="mb-0">MINIMUM AMOUNT</p>
                                <h4>149</h4>
                            </div>
                            <div className="col-md-2">
                                <p className="mb-0">DISCOUNT AMOUNT</p>
                                <h4>500</h4>
                            </div>
                            <div className="col-md-2">
                                <p className="mb-0">DISCOUNT</p>
                                <h4>20%</h4>
                            </div>
                            <div className="col-md-1">
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    {/*<button type="button" className="btn btn-secondary">Delete</button>*/}
                                    <button type="button" className="btn btn-warning"
                                            onClick={this.toggleEditCouponModal}>Edit
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <p className="mt-4">Active on:
                                    <span className="badge bg-secondary mr-1">Whitefield </span>
                                    <span className="badge bg-secondary mr-1">HSR Layout </span>
                                    <span className="badge bg-secondary mr-1">Koramangala </span>
                                    <span className="badge bg-secondary mr-1">BELL Road </span>
                                    <span className="badge bg-secondary mr-1">Shivaju Nagar </span>
                                    <span className="badge bg-secondary mr-1">Koramangala </span>
                                    <span className="badge bg-secondary mr-1">Audugodi </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="banner-row border-top pt-2 mt-2">
                        <div className="row ">
                            <div className="col-md-2">
                                <p className="mb-0">COUPON NAME</p>
                                <h4>POTLUC</h4>
                            </div>
                            <div className="col-md-3">
                                <p className="mb-0">COUPON TYPE</p>
                                <h4>Discount On Bill (Percentage)</h4>
                            </div>
                            <div className="col-md-2">
                                <p className="mb-0">MINIMUM AMOUNT</p>
                                <h4>149</h4>
                            </div>
                            <div className="col-md-2">
                                <p className="mb-0">DISCOUNT AMOUNT</p>
                                <h4>500</h4>
                            </div>
                            <div className="col-md-2">
                                <p className="mb-0">DISCOUNT</p>
                                <h4>20%</h4>
                            </div>
                            <div className="col-md-1">
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    {/*<button type="button" className="btn btn-secondary">Delete</button>*/}
                                    <button type="button" className="btn btn-warning"
                                            onClick={this.toggleEditCouponModal}>Edit
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <p className="mt-4">Active on:
                                    <span className="badge bg-secondary mr-1">Whitefield </span>
                                    <span className="badge bg-secondary mr-1">HSR Layout </span>
                                    <span className="badge bg-secondary mr-1">Koramangala </span>
                                    <span className="badge bg-secondary mr-1">BELL Road </span>
                                    <span className="badge bg-secondary mr-1">Shivaju Nagar </span>
                                    <span className="badge bg-secondary mr-1">Koramangala </span>
                                    <span className="badge bg-secondary mr-1">Audugodi </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <Modal
                        isOpen={this.state.editCoupon}
                        fade={false}
                        toggle={this.toggleEditCouponModal}
                        className={this.props.className}
                    >
                        <ModalHeader>Toggle Coupon</ModalHeader>
                        <ModalBody className="modal-height">
                            <FormGroup>
                                <div className="form-check form-switch">
                                    <div className="can-toggle can-toggle--size-small">
                                        <input id="1" type="checkbox"/>
                                        <label htmlFor="1">
                                            <div className="can-toggle__switch" data-checked="On"
                                                 data-unchecked="Off"></div>
                                            <div className="can-toggle__label-text"><strong>Whitefield</strong></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-check form-switch">
                                    <div className="can-toggle can-toggle--size-small">
                                        <input id="2" type="checkbox"/>
                                        <label htmlFor="2">
                                            <div className="can-toggle__switch" data-checked="On"
                                                 data-unchecked="Off"></div>
                                            <div className="can-toggle__label-text"><strong>HSR layout</strong></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-check form-switch">
                                    <div className="can-toggle can-toggle--size-small">
                                        <input id="3" type="checkbox"/>
                                        <label htmlFor="3">
                                            <div className="can-toggle__switch" data-checked="On"
                                                 data-unchecked="Off"></div>
                                            <div className="can-toggle__label-text"><strong>Indranagar</strong></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-check form-switch">
                                    <div className="can-toggle can-toggle--size-small">
                                        <input id="5" type="checkbox"/>
                                        <label htmlFor="5">
                                            <div className="can-toggle__switch" data-checked="On"
                                                 data-unchecked="Off"></div>
                                            <div className="can-toggle__label-text"><strong>Domlur</strong></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-check form-switch">
                                    <div className="can-toggle can-toggle--size-small">
                                        <input id="6" type="checkbox"/>
                                        <label htmlFor="6">
                                            <div className="can-toggle__switch" data-checked="On"
                                                 data-unchecked="Off"></div>
                                            <div className="can-toggle__label-text"><strong>Sanjay Nagar</strong></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-check form-switch">
                                    <div className="can-toggle can-toggle--size-small">
                                        <input id="7" type="checkbox"/>
                                        <label htmlFor="7">
                                            <div className="can-toggle__switch" data-checked="On"
                                                 data-unchecked="Off"></div>
                                            <div className="can-toggle__label-text"><strong>Yalahanka</strong></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-check form-switch">
                                    <div className="can-toggle can-toggle--size-small">
                                        <input id="8" type="checkbox"/>
                                        <label htmlFor="8">
                                            <div className="can-toggle__switch" data-checked="On"
                                                 data-unchecked="Off"></div>
                                            <div className="can-toggle__label-text"><strong>Bell Road</strong></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-check form-switch">
                                    <div className="can-toggle can-toggle--size-small">
                                        <input id="9" type="checkbox"/>
                                        <label htmlFor="9">
                                            <div className="can-toggle__switch" data-checked="On"
                                                 data-unchecked="Off"></div>
                                            <div className="can-toggle__label-text"><strong>Whitefield</strong></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-check form-switch">
                                    <div className="can-toggle can-toggle--size-small">
                                        <input id="10" type="checkbox"/>
                                        <label htmlFor="10">
                                            <div className="can-toggle__switch" data-checked="On"
                                                 data-unchecked="Off"></div>
                                            <div className="can-toggle__label-text"><strong>HSR layout</strong></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-check form-switch">
                                    <div className="can-toggle can-toggle--size-small">
                                        <input id="11" type="checkbox"/>
                                        <label htmlFor="11">
                                            <div className="can-toggle__switch" data-checked="On"
                                                 data-unchecked="Off"></div>
                                            <div className="can-toggle__label-text"><strong>Indranagar</strong></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-check form-switch">
                                    <div className="can-toggle can-toggle--size-small">
                                        <input id="12" type="checkbox"/>
                                        <label htmlFor="12">
                                            <div className="can-toggle__switch" data-checked="On"
                                                 data-unchecked="Off"></div>
                                            <div className="can-toggle__label-text"><strong>Domlur</strong></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-check form-switch">
                                    <div className="can-toggle can-toggle--size-small">
                                        <input id="6" type="checkbox"/>
                                        <label htmlFor="6">
                                            <div className="can-toggle__switch" data-checked="On"
                                                 data-unchecked="Off"></div>
                                            <div className="can-toggle__label-text"><strong>Sanjay Nagar</strong></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-check form-switch">
                                    <div className="can-toggle can-toggle--size-small">
                                        <input id="7" type="checkbox"/>
                                        <label htmlFor="7">
                                            <div className="can-toggle__switch" data-checked="On"
                                                 data-unchecked="Off"></div>
                                            <div className="can-toggle__label-text"><strong>Yalahanka</strong></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-check form-switch">
                                    <div className="can-toggle can-toggle--size-small">
                                        <input id="8" type="checkbox"/>
                                        <label htmlFor="8">
                                            <div className="can-toggle__switch" data-checked="On"
                                                 data-unchecked="Off"></div>
                                            <div className="can-toggle__label-text"><strong>Bell Road</strong></div>
                                        </label>
                                    </div>
                                </div>
                                {/*<p>Multiselect</p>*/}
                                {/*<MultiSelect options={} value={} labelledBy={}*/}
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" outline onClick={this.toggleEditCouponModal}>
                                Cancel
                            </Button>

                            <Button
                                color="primary"
                            >
                                Save
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        );
    }
}

function bindActions(dispatch) {
    return {
        getCouponsList: branch_id => dispatch(getCouponsList(branch_id)),
        getCouponTypes: () => dispatch(getCouponTypes())
    };
}

function mapStateToProps(state) {
    return {
        couponsList: state.branch.couponsList,
        couponTypes: state.branch.couponTypes
    };
}

export default connect(
    mapStateToProps,
    bindActions
)(Coupons);