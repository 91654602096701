import { SET_DASHBOARD_DATA } from "../actions/dashboard";

const initialState = {
  dashboardData: []
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_DATA:
      return { ...state, dashboardData: action.payload };
    default:
      return state;
  }
};

export default dashboard;
