import config from "../config";
import { getToken, setToken, clearToken } from "../utils";
import { setMessage, setMessageType, setAuthToken } from "./commonData";
import { isEmpty } from "lodash";
import axios from "axios";
import { func } from "prop-types";
import moment from "moment";
import { setSpinner } from "./commonData";
import { SET_CURRENT_ORDER } from "./orders";

export const SET_CASH_LIST = "SET_CASH_LIST";
export const SET_CASH_SUMMARY_LIST = "SET_CASH_SUMMARY_LIST";
export const SET_PAYMENT_MODES = "SET_PAYMENT_MODES";

let axiosInstance = axios.create({
  baseURL: config.baseUrl,
  timeout: 150000,
  headers: {
    "Content-Type": "application/json",
    version: "2.0",
    Authorization: `Token=${getToken()}`,
  },
});

function setCashList(payload) {
  return { type: SET_CASH_LIST, payload: payload };
}
function setCashSummaryList(payload) {
  return { type: SET_CASH_SUMMARY_LIST, payload: payload };
}

function setPaymentModes(payload) {
  return { type: SET_PAYMENT_MODES, payload: payload };
}
export function getCashList(params) {
  let default_params = {
    branch_id: 0,
    from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    searchText: "",
    offset: 0,
    perPage: 10,
    sort: "ASC",
  };
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `admin/getFieldCashList?branch_id=${params.branch_id}&city_id=${params.city_id}&from_date=${params.startDate}&to_date=${params.endDate}&sort=${params.sort}&length=${params.perPage}&search=${params.searchText}&start=${params.offset}`
      )
      .then((response) => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setCashList(response.data.data));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        dispatch(setMessage("Oops!! Something went wrong"));
        dispatch(setMessageType("danger"));
      });
  };
}

export function getPaymentModes() {
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(`/pos/getAvailPayModeForDelivery`)
      .then((response) => {
        dispatch(setSpinner(false));
        if (response.data.data) {
          dispatch(setPaymentModes(response.data.data));
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        dispatch(setMessage("Oops!! Something went wrong"));
        dispatch(setMessageType("danger"));
      });
  };
}

export function updatePaymentMode({ paymentModeId, billId, params }) {
  return function (dispatch) {
    console.log(params);
    dispatch(setSpinner(true));
    axiosInstance
      .put(`admin/updatePaymentModeOfBill`, {
        payment_mode_id: paymentModeId,
        bill_id: billId,
      })
      .then((response) => {
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(getCashList(params));
          dispatch(setMessage("Updated payment mode successfully."));
          dispatch(setMessageType("success"));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(setSpinner(false));
        dispatch(setMessage("Oops!! Something went wrong"));
        dispatch(setMessageType("danger"));
      });
  };
}

export function getCashSummary(params) {
  let default_params = {
    branch_id: 0,
    from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    searchText: "",
    offset: 0,
    perPage: 10,
    sort: "ASC",
  };
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `analytics/getRevenueByPaymentSource?from_date=${params.startDate}&to_date=${params.endDate}&branch_id=${params.branch_id}`
        // `admin/getFieldCashList?branch_id=${params.branch_id}&from_date=${params.startDate}&to_date=${
        //     params.endDate
        //     }&sort=${params.sort}&length=${
        //     params.perPage
        //     }&search=${params.searchText}&start=${params.offset}`
      )
      .then((response) => {
        console.log("responseeeeee", response);
        dispatch(setSpinner(false));
        if (response.data.data) {
          dispatch(setCashSummaryList(response.data.data));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        dispatch(setMessage("Oops!! Something went wrong"));
        dispatch(setMessageType("danger"));
      });
  };
}

export function markPaymentCollected(billId, params) {
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .put(`admin/markPaymentCollected/${billId}`)
      .then((response) => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(getCashList(params));
          dispatch(setMessage("Cash collected successfully."));
          dispatch(setMessageType("success"));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        dispatch(setMessage("Oops!! Something went wrong"));
        dispatch(setMessageType("danger"));
      });
  };
}
