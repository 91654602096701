import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import Barcode from "react-barcode";
import QRCode from 'qrcode.react';
import {getSettlement} from "../../actions/orders";

let options = {
    width: 2,
    height: 100,
    format: "CODE128",
    displayValue: true,
    fontOptions: "",
    font: "monospace",
    textAlign: "center",
    textPosition: "bottom",
    textMargin: 2,
    fontSize: 20,
    background: "#ffffff",
    lineColor: "#000000",
    margin: 10,
    marginTop: undefined,
    marginBottom: undefined,
    marginLeft: undefined,
    marginRight: undefined
}

class PrintSettlement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().subtract(7, "d"),
            endDate: moment(),
            orderStatus: 0,
            perPage: 10,
            offset: 0,
            searchText: "",
            preset: "",
            focusedInput: false,
            dropdownOpen: false,
            branch_id: 0,
            branch_name: "All branch",
            chartMode: "quantity",
        };

        let orderId = this.props.match.params.orderId;
        let from_date = this.props.location.from_date;
        let to_date = this.props.location.to_date;
        let branch_id = this.props.location.branch_id;
        this.props.getSettlement(orderId, from_date, to_date, branch_id);

    }

    branchtoggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    componentDidUpdate(prevProps) {
        if (
            Object.keys(this.props.SettlementReport).length != 0 &&
            this.props.SettlementReport != prevProps.SettlementReport
        ) {
            window.print();
            // window.location.reload();
        }
        // window.onafterprint = window.close();
    }

    render() {
        const {SettlementReport} = this.props;
        return (
            <div className="animated fadeIn print-order">
                <div class="bill">
                    {/*<div className="text-center">*/}
                    {/*<Barcode value={`${SettlementReport.bill_id}`}/>*/}
                    {/*</div>*/}

                    {/*<div class="space"/>*/}
                    <p className="pt-4"></p>
                    <p className="fs-16 text-center mb-2">Settlement Report</p>
                    <h1 className="fs-16 mb-2">Branch Name</h1>
                    <p className="text-center mb-0">Settled By: {SettlementReport.settlement_by}</p>
                    <p className="text-center">{SettlementReport.settlement_time}</p>


                    {/*<br/>*/}
                    {/*<p className="dot-border"/>*/}
                    {/*{console.log(SettlementReport.break_up && SettlementReport.break_up.payment_mode_value, "data")}*/}
                    <p className="mt-4"></p>
                    <p className="dot-border mb-0"/>
                    <p className="mb-1 mt-1">Overview</p>
                    <table className="">
                        <tbody>
                                <tr>
                                    <td>
                                        <span>Pay on Delivery Orders</span>
                                    </td>
                                    <td className="text-right">{SettlementReport.pod_data && SettlementReport.pod_data.count}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Pay on Delivery Total</span>
                                    </td>
                                    <td className="text-right">{SettlementReport.pod_data && SettlementReport.pod_data.value}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Total Orders Count</span>
                                    </td>
                                    <td className="text-right">{SettlementReport.total && SettlementReport.total.total_count}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Total Order Value</span>
                                    </td>
                                    <td className="text-right">{SettlementReport.total && SettlementReport.total.total_value}</td>
                                </tr>

                        </tbody>
                    </table>
                    <p className="mt-4"></p>
                    <p className="mb-1 mt-1">Payment Brake Down</p>
                    <table>
                        <thead>
                        <tr>
                            <th>Payment Mode</th>
                            <th className="text-right">Bill Count</th>
                            <th className="text-right">Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {SettlementReport.break_up &&
                        SettlementReport.break_up.map((obj, index) => {
                            return (
                            <tr>
                                <td>
                                    <span>{obj.payment_mode_value}</span>
                                </td>
                                <td className="text-right">{obj.bill_count}</td>
                                <td className="text-right">{obj.total}</td>
                            </tr>
                        );
                        })}
                        </tbody>
                    </table>
                    <p className="mt-4"></p>
                    <p className=" mb-1 mt-1">Rider List</p>
                    <table>
                        <thead>
                        <tr>
                            <th>Rider Name</th>
                            <th className="text-right">Bill Count</th>
                            <th className="text-right">Total</th>
                            <th className="text-right">in Hand</th>
                        </tr>
                        </thead>
                        <tbody>
                        {SettlementReport.executives_break_up &&
                        SettlementReport.executives_break_up.map((obj, index) => {
                            return (
                                <tr>
                                    <td>
                                        <span>{obj.user_first_name + obj.user_second_name }</span>
                                    </td>
                                    <td className="text-right">{obj.total_count}</td>
                                    <td className="text-right">{obj.value}</td>
                                    <td className="text-right">{obj.cash_in_hand}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    <p className="dot-border mb-0"/>
                    <p className="mt-4"></p>
                    <p>{SettlementReport.settlement_by}</p>
                    <p className="mt-4"></p>
                    <p>Signature</p>
                    <p className="pb-4"></p>

                    {/*<p class="space"/>*/}
                </div>
            </div>
        );
    }
}

function bindActions(dispatch) {
    return {
        getSettlement: params => dispatch(getSettlement(params))
    };
}

function mapStateToProps(state) {
    return {
        SettlementReport: state.orders.SettlementReport
    };
}

export default connect(
    mapStateToProps,
    bindActions
)(PrintSettlement);
