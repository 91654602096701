import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { AppHeader, AppSidebar, AppSidebarNav } from "@coreui/react";
import MDSpinner from "react-md-spinner";
import config from "../../config/index"

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Button,
  Alert
} from "reactstrap";
// sidebar nav config
import navigation from "../../_nav";
// routes config
import routes from "../../routes";

import {
  getLoggedInUser,
  getMenuList,
  clearMessage
} from "../../actions/commonData";
import { logout } from "../../actions/auth";
import { getToken, clearToken } from "../../utils";

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false
    };
    this.profileToggle = this.profileToggle.bind(this);

    this.props.getLoggedInUser();
    this.props.getMenuList();
    this.onDismiss = this.onDismiss.bind(this);
  }
  componentDidUpdate() {
    setTimeout(
      function () {
        this.props.clearMessage();
      }.bind(this),
      15000
    );
    document.body.classList.add("sidebar-minimized");
  }

  onDismiss() {
    this.props.clearMessage();
  }

  profileToggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  handleLogout() {
    clearToken();
    this.props.logout();
    this.props.history.push("/login");
  }

  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle("sidebar-hidden");
  }

  sidebarMinimize(e) {
    e.preventDefault();
    document.body.classList.toggle("sidebar-minimized");
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle("sidebar-mobile-show");
  }

  asideToggle(e) {
    e.preventDefault();
    document.body.classList.toggle("aside-menu-hidden");
  }

  render() {
    const {
      loggedInUser,
      tokenStatus,
      spinnerActive,
      message,
      alertClass
    } = this.props;
    let filteredNavigation = {};

    // console.log(' the fixed nav is ', navigation, ' and the data from menulist is ', this.props.menuList);
    if(this.props.menuList && this.props.menuList.length){
      let items = navigation.items.filter(item => this.props.menuList.includes(item.name));
      filteredNavigation = {
        items
      }
      // console.log('filtered Navigation ', filteredNavigation);
    }

    return (
      <div className="app">
        {spinnerActive && (
          <div className="h-100 row align-items-center spinner-overlay">
            <MDSpinner
              singleColor="#ee5a36"
              size="75"
              borderSize={10}
              style={{ margin: "0 auto" }}
              className=""
            />
          </div>
        )}
        {message && (
          <Alert
            className="m-5 fixed-top"
            color={alertClass}
            isOpen={message !== "" ? true : false}
            toggle={this.onDismiss}
          >
            {message}
          </Alert>
        )}

        <header className={`app-header navbar + ${config.brand}`}>
          <div>
            <div className="brand-logo d-inline-block">
              <img className="mr-2" src={`/images/${config.brand}/logo2.svg`} alt="" />
              <img src={`/images/${config.brand}/logo1.svg`} alt="" />
            </div>
            <NavbarToggler
              className="d-lg-none"
              // id="myButton"
              onClick={this.mobileSidebarToggle}
            >
              <span className="navbar-toggler-icon" />
            </NavbarToggler>
            <NavbarToggler
              className="d-md-down-none"
              id="myButton"
              onClick={this.sidebarMinimize}
            >
              <span className="navbar-toggler-icon" />
            </NavbarToggler>
          </div>

          <div className="header-right d-none d-md-block">

            <UncontrolledButtonDropdown className="branch-dropdown d-inline-block dropdown">
              <DropdownToggle caret>
                <i className="material-icons fs-20px align-middle mt--3px mr-1">
                  live_tv </i> Screens
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag={Link} Link to="/escalationScreen">Escalation Order Screen</DropdownItem>
                <DropdownItem tag={Link} Link to="/orderScreens" >Order Screen</DropdownItem>
                {/*<DropdownItem tag={Link} Link to="/" >KOT Display</DropdownItem>*/}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
            <Link to={`/billing`} className="pos-link">
              {" "}
              <Button color="danger"><img className="mr-1" src="images/rupee.svg" alt="" /> Open Point of Sale</Button>{" "}
            </Link>
          </div>
        </header>

        <div className="app-body">
          <AppSidebar display="lg" className={`${config.brand}`}>

            <div className="profile-wrap p-3">
              <div className="shape-name">
                <div className="shape-left img d-none d-sm-block" style={{ backgroundImage: `url(/images/${config.brand}.png)` }}></div>
                <div className="shape-right">
                  <h6 className="heading">
                    {this.props.loggedInUser &&
                      `${this.props.loggedInUser.user_first_name}`}
                  </h6>
                  <p className="desc">
                    {this.props.loggedInUser &&
                    `${this.props.loggedInUser.user_phone}`}
                  </p>
                  <p className="desc cursor-pointer"
                    onClick={() => {
                      this.handleLogout();
                    }}
                  >Logout</p>
                </div>
              </div>
            </div>
            <AppSidebarNav navConfig={filteredNavigation && filteredNavigation.items && filteredNavigation.items.length && filteredNavigation} {...this.props} />
          </AppSidebar>
          <main className="main">
            {/* <AppBreadcrumb appRoutes={routes}/> */}
            <div>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => <route.component {...props} />}
                    />
                  ) : null;
                })}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    getLoggedInUser: () => dispatch(getLoggedInUser()),
    getMenuList: () => dispatch(getMenuList()),
    logout: () => dispatch(logout()),
    clearMessage: () => dispatch(clearMessage())
  };
}

function mapStateToProps(state) {
  return {
    loggedInUser: state.commonData.loggedInUser,
    menuList: state.commonData.menu_list,
    tokenStatus: state.commonData.tokenStatus,
    spinnerActive: state.commonData.spinnerActive,
    message: state.commonData.message,
    alertClass: state.commonData.messageType
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(DefaultLayout);
