import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import config from "../../config";
import {
    Button,
    Col,
    Container,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Media,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";

// import moment from "moment";
import ProductTabs from "./ProductTabs";
import {getProduct, uploadProductImage} from "../../actions/products";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const options = {
    autoClose: 2000,
    // closeButton:,
    type: toast.TYPE.INFO,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_LEFT,
    pauseOnHover: true
    // progress: 0.2
};

class ProductView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgModal: false,
            infoDropdownOpen: false,
            productId: "",
            imageError: "",
            image: null
        };
        this.toggleImgModal = this.toggleImgModal.bind(this);
        this.renderVegNonVeg = this.renderVegNonVeg.bind(this);
        this.onImageUploaded = this.onImageUploaded.bind(this);
        this.infoToggle = this.infoToggle.bind(this);
        this.saveImage = this.saveImage.bind(this);
        let productId = this.props.match.params.productId;
        this.setState({productId: productId});
        this.props.getProduct(productId);
    }

    toggleImgModal() {
        this.setState(prevState => ({
            imgModal: !prevState.imgModal
        }));
    }

    infoToggle() {
        this.setState(prevState => ({
            infoDropdownOpen: !prevState.infoDropdownOpen
        }));
    }

    onImageUploaded(image) {
        if (!image) {
            this.setState({imageError: 'Please select image.', image: null});
            return false;
        }
        if (!image.type.match(/jpg|jpeg/)) {
            this.setState({imageError: 'Please select valid image.', image: null});
            return false;
        }
        if (image.size > 2000000) {
            this.setState({imageError: 'Image size too big', image: null});
            return false;
        }
        this.setState({
            image: image,
            imageError: ''
        });
    }

    saveImage() {
        if (this.state.image === null)
            toast.error("Please choose a image", options)
        else {
            this.props.uploadProductImage(this.props.match.params.productId, this.state.image);
            this.toggleImgModal()
        }
    }

    renderVegNonVeg(isVeg) {
        isVeg = parseInt(isVeg);
        switch (isVeg) {
            case 0:
                return "Non Veg";
            case 1:
                return "Veg";
            case 2:
                return "Egg";
            default:
                return "";
        }
    }

    render() {
        const productData = this.props.currentProduct;
        // console.log(productData, "current product");
        return (
            <div className="animated product-view  fadeIn">
                <Container>

                    <Row className="mt-2 product-details bg-white">
                        <Col sm="12">
                            <Link to="/products" className="mb-2 mt-2 d-block"><i
                                className="fas fa-arrow-circle-left mr-2"></i> Back to Product Listing</Link>
                        </Col>
                        <Col sm="9" className="p-2">
                            <Media className="mt-1">
                                <Media left middle>
                                    <Media
                                        object
                                        src={`${productData.product_image_url}`}
                                        alt={productData.product_name}
                                        className="product-image mr-2"
                                    />
                                </Media>
                                <Media body className="ml-2">
                                    <Media heading className="font-weight-bold">{productData.product_name}</Media>
                                    {productData.product_desc}
                                </Media>
                            </Media>
                        </Col>
                        <Col sm="3" className="p-4 align-self-center">
                            <Button
                                color="primary"
                                className="d-inline-block mr-2"
                                onClick={this.toggleImgModal}
                                outline
                            >
                                Update Image
                            </Button>
                            <Dropdown
                                isOpen={this.state.infoDropdownOpen}
                                toggle={this.infoToggle}
                                className="d-inline-block"
                            >
                                <DropdownToggle className="" color="primary" outline>
                                    Info
                                </DropdownToggle>
                                <DropdownMenu className="product-info">
                                    <div className="product-info-list">
                                        <span className="product-info-title">Category</span>
                                        <br/>
                                        <span className="product-info-desc">{productData.category_name}</span>
                                    </div>
                                    <div className="product-info-list">
                                        <span className="product-info-title">Taxable?</span>
                                        <br/>
                                        <span
                                            className="product-info-desc">{productData.is_taxable ? "Yes" : "No"}</span>
                                    </div>
                                    <div className="product-info-list">
                                        <span className="product-info-title">Veg/Non-Veg</span>
                                        <br/>
                                        <span
                                            className="product-info-desc">{this.renderVegNonVeg(productData.is_veg)}</span>
                                    </div>
                                    <div className="product-info-list">
                                        <span className="product-info-title">Packing Charges</span>
                                        <br/>
                                        <span
                                            className="product-info-desc">{productData.packing_charge_applies == 0 ? "No" : "Yes"}</span>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                            {/* <Link to={`/product/${productData.product_id}/edit`}> */}
                            {/*<Button color="primary" className="ml-2" disabled>*/}
                            {/*  Edit*/}
                            {/*  </Button>*/}
                            {/* </Link> */}
                        </Col>

                        <Modal
                            isOpen={this.state.imgModal}
                            fade={false}
                            toggle={this.toggleImgModal}
                            className={this.props.className}
                        >
                            <ModalHeader>Upload Product Image</ModalHeader>
                            <ModalBody>
                                <FormGroup>

                                    <Label>Upload Image</Label>
                                    <Input
                                        type="file"
                                        className="mb-3"
                                        onChange={(e) => this.onImageUploaded(e.target.files[0])}
                                    />
                                </FormGroup>
                                <Label>{this.state.imageError}</Label>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" outline onClick={this.toggleImgModal}>
                                    Cancel
                                </Button>

                                <Button
                                    color="primary"
                                    onClick={this.saveImage}
                                >
                                    Save
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Row>
                    <ProductTabs currentProduct={productData}/>
                </Container>
            </div>
        );
    }
}

function bindActions(dispatch) {
    return {
        getProduct: productId => dispatch(getProduct(productId)),
        uploadProductImage: (productId, image) => dispatch(uploadProductImage(productId, image))
    };
}

function mapStateToProps(state) {
    return {
        currentProduct: state.products.currentProduct,
    };
}

export default connect(
    mapStateToProps,
    bindActions
)(ProductView);
