import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { Link } from "react-router-dom";
import React, { Component } from "react";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";

// import {
//   Col,
//   Row, Container, Media, CardHeader, Badge,
//   Card, Button, CardTitle, CardText
// } from "reactstrap";
import moment from "moment";
import EachOrder from "./OrderScreenOrders";

const { SearchBar } = Search;

const RemotePagination = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  columns
}) => (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page,
          sizePerPage,
          totalSize
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <div>
            <BootstrapTable
              remote
              keyField="bill_id"
              data={data}
              columns={columns}
              onTableChange={onTableChange}
              {...paginationTableProps}
            />
            <div className="float-left text-muted pt-2">
              <p>
                Showing:{" "}
                {(paginationProps.page - 1) * paginationProps.sizePerPage + 1} to{" "}
                {paginationProps.page * paginationProps.sizePerPage <
                  paginationProps.totalSize
                  ? paginationProps.page * paginationProps.sizePerPage
                  : paginationProps.totalSize}{" "}
                of {paginationProps.totalSize} entries
            </p>
            </div>
            <div className="float-right">
              <PaginationListStandalone {...paginationProps} />
            </div>
          </div>
        )}
      </PaginationProvider>
    </div>
  );

class OrderScreenTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: this.props.orders,
      sizePerPage: 10
    };
    this.orderFormatter = this.orderFormatter.bind(this);
  }
  orderFormatter = function (cell, row, rowIndex, formatExtraData) {
    return (
      <EachOrder
        key={cell}
        order={row}
        // liveOrderMeta={this.props.liveOrderMeta}
        // deliveryGuys={this.props.deliveryGuys}
        filterParams={this.props.filterParams}
      />
    );
  };

  handleTableChange = (type, { page, sizePerPage }) => {
    this.setState(() => ({
      page
    }));
    this.props.handlePagination((page - 1) * sizePerPage);
  };

  render() {
    const { data, sizePerPage, page } = this.state;
    const columns = [
      {
        dataField: "bill_id",
        text: "#",
        sort: true,
        formatter: this.orderFormatter
      }
    ];
    return (
      <div className="w-100 orders-table custom-table-no-border">
        {console.log(this.props.totalSize)}
        <RemotePagination
          data={this.props.orders}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={this.props.totalData}
          onTableChange={this.handleTableChange}
          columns={columns}
        />
      </div>
    );
  }
}

export default OrderScreenTable;
