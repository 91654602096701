import React from "react";
import { connect } from "react-redux";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import classnames from "classnames";

import ServiceCharge from "./ServiceCharge";
import PackingCharge from "./PackingCharge";
import Delivery from "./Delivery";

class ProductTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      dropdownOpen: false
    };

    this.branchtoggle = this.branchtoggle.bind(this);
    this.tabToggle = this.tabToggle.bind(this);
    // this.props.getProduct(this.props.currentProduct.product_id, this.state.branch_id);
  }

  branchtoggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }


  tabToggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {

    return (
      <div className="animated fadeIn other-tabs">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "1" })}
              onClick={() => {
                this.tabToggle("1");
              }}
            >
              Packing Charge
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "2" })}
              onClick={() => { this.tabToggle('2'); }}

            >
              Delivery Charge
            </NavLink>
          </NavItem>
          <NavItem className="d-none">
            <NavLink
              className={classnames({ active: this.state.activeTab === "3" })}
              // onClick={() => { this.tabToggle('3'); }}
              disabled
            >
              Service Charge
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab} className="w-100">
          <TabPane tabId="1">
            <Row>
              <PackingCharge branch_id={this.props.branch_id} />
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Delivery branch_id={this.props.branch_id} />
          </TabPane>
          <TabPane tabId="3" className="d-none">
            <ServiceCharge branch_id={this.props.branch_id} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    // getProduct: (product_id, branch_id) => dispatch(getProduct(product_id, branch_id))
  };
}

function mapStateToProps(state) {
  return {
    loggedInUser: state.commonData.loggedInUser
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(ProductTabs);
