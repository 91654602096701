import React, {Component} from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import moment from "moment";
import {DateRangePicker} from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import {
    Badge,
    Button,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    Row
} from "reactstrap";

import OrdersSummary from "./AnalyticsPaymentModeSummary";
import OrdersTable from "./AnalyticsPaymentModeTable";
import OrdersSource from "./AnalyticsPaymentMode";
import {getAnalyticsPaymentMode} from "../../actions/orders";

class PaymentModeAnalytics extends Component {
    constructor(props) {
        super(props);
        // let branchId = this.props.loggedInUser.branch_list[0].branch_id;
        this.state = {
            // date default is -7 days
            startDate: moment().subtract(7, "d"),
            endDate: moment(),
            orderStatus: 0, // default all orders
            perPage: 10,
            offset: 0,
            searchText: "",
            preset: "",
            focusedInput: false,
            dropdownOpen: false,
            branch_id: 0,
            branch_name: "All branch"
        };

        this.filterOrders = this.filterOrders.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.renderDatePresets = this.renderDatePresets.bind(this);
        this.branchtoggle = this.branchtoggle.bind(this);
        this.handleBranchChange = this.handleBranchChange.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        const {
            branch_id,
            startDate,
            endDate,
            perPage,
            offset,
            searchText,
            orderStatus
        } = this.state;
        let default_params = {
            branch_id: branch_id,
            status: orderStatus,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC"
        };
        this.props.getAnalyticsPaymentMode(default_params);
        // console.log(this.props.getAnalyticsPaymentMode.data + 'main data');
    }

    branchtoggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    componentDidMount() {
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let firstBranchName = branch_list[0].branch_name;
                let firstBranchId = branch_list[0].branch_id;
                this.handleBranchChange(firstBranchId, firstBranchName);
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.loggedInUser && (this.props.loggedInUser.branch_list != nextProps.loggedInUser.branch_list)) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let firstBranchName = branch_list[0].branch_name;
                let firstBranchId = branch_list[0].branch_id;
                this.handleBranchChange(firstBranchId, firstBranchName);
            }
        }
    }


    handleDateChange(startDate, endDate) {
        const {branch_id, orderStatus, perPage, offset, searchText} = this.state;
        this.setState({startDate: startDate, endDate: endDate});
        // console.log("start date ", startDate, ' and the end date is ', endDate);
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC"
        };
        this.props.getAnalyticsPaymentMode(params);
        // console.log(this.props.getAnalyticsCategory);

    }

    handleBranchChange(branch_id, branch_name) {
        const {
            orderStatus,
            perPage,
            offset,
            searchText,
            startDate,
            endDate
        } = this.state;
        this.setState({branch_id: branch_id, branch_name: branch_name});
        // console.log("branch id ", branch_id);
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC"
        };
        this.props.getAnalyticsPaymentMode(params);
        // console.log(this.props);
    }

    filterOrders(bill_status) {
        const {
            branch_id,
            startDate,
            endDate,
            perPage,
            offset,
            searchText
        } = this.state;
        this.setState({orderStatus: bill_status});
        // console.log("order status ", this.state.orderStatus);
        let params = {
            branch_id: branch_id,
            status: bill_status,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC"
        };
        this.props.getAnalyticsPaymentMode(params);
        // console.log(this.props.getAnalyticsPaymentMode(params));
    }

    handlePagination(offset) {
        const {
            branch_id,
            startDate,
            endDate,
            orderStatus,
            searchText,
            perPage
        } = this.state;
        this.setState({offset: offset});
        // console.log("offset", offset);
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: offset,
            perPage: perPage,
            sort: "ASC"
        };
        this.props.getAnalyticsPaymentMode(params);
        // console.log(this.state);
    }

    handleSearch(searchText) {
        const {
            branch_id,
            startDate,
            endDate,
            orderStatus,
            offset,
            perPage
        } = this.state;
        this.setState({searchText: searchText});
        // console.log("searchText", searchText);
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: offset,
            perPage: perPage,
            sort: "ASC"
        };
        this.props.getAnalyticsPaymentMode(params);
        // console.log(this.props.getAnalyticsCategory.category_name);
    }

    renderDatePresets() {
        // console.log(this.props.AnalyticsCategory.data);
        return (
            <div className="date_custom_presets">
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment(), moment());
                    }}
                >
                    Today
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().subtract(1, "d"), moment().subtract(1, "d"));
                        this.setState({focusedInput: false});
                    }}
                >
                    Yesterday
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().subtract(7, "d"), moment());
                        this.setState({focusedInput: false});
                    }}
                >
                    Last 7 days
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().subtract(30, "d"), moment());
                        this.setState({focusedInput: false});
                    }}
                >
                    Last 30 days
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().startOf("month"), moment());
                        this.setState({focusedInput: false});
                    }}
                >
                    This month
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(
                            moment()
                                .subtract(1, "months")
                                .startOf("month"),
                            moment()
                                .subtract(1, "months")
                                .endOf("month")
                        );
                        this.setState({focusedInput: false});
                    }}
                >
                    Last month
                </Button>
                <Button className="w-100  mb-2" color="info">
                    Custom Range
                </Button>
            </div>
        );
    }

    render() {
        const {
            startDate,
            endDate,
            branch_name,
            orderStatus,
            searchText
        } = this.state;

        let orderStatusList = [];
        if (this.props.PaymentModeAnalytics && this.props.PaymentModeAnalytics.meta) {
            orderStatusList = this.props.PaymentModeAnalytics.meta;


        }
        let statusList = {
            ORDER_TEMP: "Temp",
            ORDER_ONLINE_PAYMENT_PROCESSING: "Payment Pending",
            ORDER_PLACED: "New Order",
            ORDER_ACCEPTED: "In Process",
            ORDER_PACKED: "Ready",
            ORDER_ASSIGNED: "Assigned",
            ORDER_DISPATCHED: "Dispatched",
            ORDER_DELIVERED: "Delivered",
            ORDER_REJECTED: "Rejected",
            ORDER_CANCELLED: "Canceled"
        };
        let branch_list = [];
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            branch_list = this.props.loggedInUser.branch_list;
        }
        let all_branch_outline = true;
        if (orderStatus === 0) {
            all_branch_outline = false;
        }
        return (
            <div className="animated fadeIn">
                <Row className="ml-2 mr-2 mt-2 orders">
                    <Col md="3" lg="3" xl="2" className="p-2 orders-side d-none">

                        <hr/>
                        <label className="filter-label">Order History</label>
                        <Button
                            color="primary"
                            className="w-100 text-left orderStatusBtn"
                            outline={all_branch_outline}
                            onClick={() => {
                                this.filterOrders(0);
                            }}
                        >
                            All Orders
                            <Badge className="float-right ml-1 p-1 orderCount">
                                {this.props.PaymentModeAnalytics &&
                                this.props.PaymentModeAnalytics.order_list &&
                                this.props.PaymentModeAnalytics.order_list.iTotalRecords}
                            </Badge>
                        </Button>
                        {orderStatusList &&
                        orderStatusList.length > 0 &&
                        orderStatusList.map((element, index) => {
                            // console.log(element);
                            let outline =
                                element.bill_status !== this.state.orderStatus ? true : false;
                            return (
                                <Button
                                    color="primary"
                                    className="w-100 text-left orderStatusBtn"
                                    outline={outline}
                                    onClick={() => {
                                        // console.log(element.bill_status);
                                        this.filterOrders(element.bill_status);
                                    }}
                                >
                                    {statusList[element.order_status_value]}
                                    <Badge className="float-right ml-1 p-1 orderCount">
                                        {element.bill_count}
                                    </Badge>
                                </Button>
                            );
                        })}
                    </Col>

                    <Col className="orders-main px-3">
                        <Row className="">
                            <div class="p-3 today-side col-sm-4 col-md-3 col-lg-2 border-right">
                                <Dropdown
                                    isOpen={this.state.dropdownOpen}
                                    toggle={this.branchtoggle}
                                    className="place-order w-100 mb-3"
                                >
                                    <DropdownToggle caret className="w-100" color="primary">
                                        {branch_name}
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        {branch_list &&
                                        branch_list.map((row, index) => {
                                            return (
                                                <DropdownItem
                                                    onClick={() => {
                                                        this.handleBranchChange(
                                                            row.branch_id,
                                                            row.branch_name
                                                        );
                                                    }}
                                                >
                                                    {row.branch_name}
                                                </DropdownItem>
                                            );
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                                <ul className="nav flex-column ul-hover-active">
                                    <li className="nav-link px-0"><NavLink to="/analytics">Product Analytics</NavLink>
                                    </li>
                                    <li className="nav-link px-0"><NavLink to="/category_analytics">By Payment
                                        Source</NavLink></li>
                                    {/*<li className="nav-link px-0"><NavLink to="/time_analytics">By Time</NavLink></li>*/}
                                </ul>
                            </div>
                            <div class="today-main py-3 col-sm-8 col-md-9 col-lg-10">
                                <Row>
                                    <Col lg="4" md="6" className="px-3 mb-3">
                                        <DateRangePicker
                                            calendarInfoPosition="before"
                                            renderCalendarInfo={this.renderDatePresets}
                                            startDate={startDate}
                                            startDateId="FromstartDate"
                                            endDate={endDate}
                                            endDateId="FromendDate"
                                            onDatesChange={({startDate, endDate}) => {
                                                // console.log({ startDate, endDate })

                                                if (!startDate) {
                                                    startDate = endDate
                                                }

                                                if (!endDate) {
                                                    endDate = startDate;
                                                }
                                                return this.handleDateChange(startDate, endDate)
                                            }
                                            }
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={focusedInput =>
                                                this.setState({focusedInput})
                                            }
                                            orientation={this.state.orientation}
                                            openDirection={this.state.openDirection}
                                            isOutsideRange={() => false}
                                            anchorDirection={"left"}
                                        />
                                    </Col>
                                    {/* <Col md="2" sm="3" className="px-0 px-md-3">
                                        <a
                                            href={`${config.baseUrl}admin/exportBills?from_date=${startDate.format(
                                                "YYYY-MM-DD"
                                            )}&to_date=${endDate.format(
                                                "YYYY-MM-DD"
                                            )}&access_token=${`${Cookies.get('token_dashboard')}`}`}
                                            target="_blank"
                                        >
                                            <Button className="w-100  mb-2" color="primary">
                                                Download
                                    </Button>
                                        </a>
                                    </Col> */}
                                    {this.props.PaymentModeAnalytics && (
                                        <OrdersSummary numbers={this.props.PaymentModeAnalytics.numbers}/>
                                    )}
                                    {this.props.PaymentModeAnalytics && (
                                        <OrdersSource
                                            sources={this.props.PaymentModeAnalytics.data_by_order_source}
                                            in_house_data={this.props.PaymentModeAnalytics.in_house}
                                            // adt={this.props.ordersList.ADT}
                                        />
                                    )}
                                    <Col lg="3" md="6" className="offset-lg-9 offset-md-6">
                                        <InputGroup className="mb-3 w-100">
                                            <Input
                                                type="text"
                                                placeholder="Search"
                                                autoComplete="searchText"
                                                className="w-100"
                                                onChange={e => {
                                                    this.handleSearch(e.target.value);
                                                }}
                                                value={searchText}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                {/*{console.log(this.props.PaymentModeAnalytics, "new")}*/}
                                {this.props.PaymentModeAnalytics &&
                                this.props.PaymentModeAnalytics.data &&
                                this.props.PaymentModeAnalytics.data && (
                                    <OrdersTable
                                        orders={this.props.PaymentModeAnalytics.data}

                                        totalData={
                                            this.props.PaymentModeAnalytics.data &&
                                            this.props.PaymentModeAnalytics.data.iTotalRecords

                                        }
                                        handlePagination={this.handlePagination}
                                    />

                                )}
                                <div className="alert alert-primary new-alert mt-3">
                                    <strong className="mr-1">Note: {" "}</strong>{" "}
                                    <span> Only Completed orders are visible here</span>
                                </div>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

function bindActions(dispatch) {
    return {
        getAnalyticsPaymentMode: params => dispatch(getAnalyticsPaymentMode(params))
    };
}

function mapStateToProps(state) {
    return {
        PaymentModeAnalytics: state.orders.PaymentModeAnalytics,
        loggedInUser: state.commonData.loggedInUser
        // ordersList

    };

}

export default connect(
    mapStateToProps,
    bindActions
)(PaymentModeAnalytics);
