import {
  SET_DELIVERY_CHARGE_LIST,
  SET_PACKING_CHARGE_LIST,
  SET_COUPONS_LIST,
  SET_COUPON_TYPES,
  SET_SERVICE_AREA
} from "../actions/branch";

const initialState = {
  deliveryChargeList: [],
  packingChargeList: [],
  couponsList: [],
  couponTypes: {},
  serviceArea: {}
};

const branch = (state = initialState, action) => {
  switch (action.type) {
    case SET_DELIVERY_CHARGE_LIST:
      return { ...state, deliveryChargeList: action.payload };
    case SET_PACKING_CHARGE_LIST:
      return { ...state, packingChargeList: action.payload };
    case SET_COUPONS_LIST:
      return { ...state, couponsList: action.payload };
    case SET_COUPON_TYPES:
      return { ...state, couponTypes: action.payload };
    case SET_SERVICE_AREA:
      return { ...state, serviceArea: action.payload };
    default:
      return state;
  }
};

export default branch;
