import React, { Component } from "react";
import { connect } from "react-redux";
import { checkLatLng } from "../../utils";
import { Col, Modal, ModalBody, ModalHeader, Button } from "reactstrap";

import Map from "../Branch/CreateBranchMap";

class ProductPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNoBranch: false,
      selectedBranch: "",
      selectedAddress: ""
    };
    this.handleMapChange = this.handleMapChange.bind(this);
  }

  // componentDidUpdate(previousProps, previousState) {
  //   const { productData, branch_id, showModal } = this.props;
  //   if (showModal && showModal !== previousProps.showModal) {
  //     this.props.getProduct(productData.product_id, branch_id);
  //   }
  // }

  handleMapChange(latValue, lngValue, address) {
    const { posMeta } = this.props;
    this.setState({
      selectedAddress: address,
      lat: latValue,
      lng: lngValue
    });
    let branch_list = posMeta.branch_list;
    console.log("Check ", latValue, lngValue, " exists in any branch");
    let branch_found = false;
    let branch_id = "";
    this.props.handleBranchChange("", {
      selectedAddress: "",
      lat: "",
      lng: ""
    });
    this.setState({ selectedBranch: "", showNoBranch: false });
    branch_list.map((row, index) => {
      let pointArray = [];
      let serving_area = row.branch_serving_area.serving_area;
      serving_area.map((point, index) => {
        let tmpArray = [];
        tmpArray.push(point.lat);
        tmpArray.push(point.lng);
        pointArray.push(tmpArray);
      });
      // debugger;
      branch_found = checkLatLng({ latValue, lngValue }, pointArray);
      branch_id = row.branch_id;
      if (branch_found) {
        console.log("customer address found in branch ", branch_id);
        this.props.handleBranchChange(branch_id, {
          selectedAddress: address,
          lat: latValue,
          lng: lngValue
        });
        this.setState({ selectedBranch: row.branch_name });
      } else {
        this.setState({ showNoBranch: true });
        console.log("customer address not servicable");
      }
    });
  }

  render() {
    // const productData = this.props.currentProduct;
    const { showNoBranch, selectedAddress, selectedBranch } = this.state;
    return (
      <Modal
        isOpen={this.props.showModal}
        fade={false}
        toggle={this.props.toggleModal}
        className="p-0 branch-selection-modal"
      >
        <ModalHeader toggle={this.toggleModal}>
          Enter Customer location
        </ModalHeader>
        <ModalBody className="p-4">
          <Map
            google={this.props.google}
            center={{ lat: 12.9716, lng: 77.5946 }}
            height="400px"
            zoom={13}
            handleMapChange={this.handleMapChange}
            polyCords={[
              ["12.928251", "77.617625"],
              ["12.931723", "77.643374"],
              ["12.945735", "77.654232"],
              ["12.949541", "77.662042"],
              ["12.946404", "77.665003"],
              ["12.939294", "77.660454"],
              ["12.942221", "77.669466"],
              ["12.952678", "77.669381"],
              ["12.957445", "77.676462"],
              ["12.967859", "77.672685"],
              ["12.970995", "77.675088"],
              ["12.984879", "77.666806"],
              ["12.983332", "77.651828"],
              ["12.987263", "77.623204"],
              ["12.981659", "77.613076"],
              ["12.964639", "77.608441"],
              ["12.962255", "77.622002"],
              ["12.950795", "77.606638"]
            ]}
          />
          {selectedAddress && (
            <Col sm="12" className="p-2">
              <p className="text-muted">Ordering for</p>
              {selectedAddress}
            </Col>
          )}
          {showNoBranch && !selectedBranch && (
            <div className="alert alert-danger w-100">
              <strong>Note:</strong> This customer address is not currently
              servicable
            </div>
          )}
          {selectedBranch && (
            <div className="alert alert-success w-100">
              <strong>
                {" "}
                This customer address will be served by {selectedBranch}{" "}
                branch
              </strong>
            </div>
          )}
          <Button
            color="primary"
            className="w-100 add-address mt-4"
            onClick={() => {
              this.props.toggleModal();
            }}
          >
            Okay
          </Button>
        </ModalBody>
      </Modal>
    );
  }
}
function bindActions(dispatch) {
  return {};
}

function mapStateToProps(state) {
  return {};
}
export default connect(
  mapStateToProps,
  bindActions
)(ProductPrice);
