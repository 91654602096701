import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";

import {Link} from "react-router-dom";
import React, {Component} from "react";

import {
    Col,
    Row,
    Container,
    Media,
    CardHeader,
    Badge,
    Card,
    Button,
    CardTitle,
    CardText
} from "reactstrap";
import moment from "moment";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone
} from "react-bootstrap-table2-paginator";

const {SearchBar} = Search;

// Custom column formatter
function billIdFormatter(cell, row, rowIndex, formatExtraData) {
    return (
        <Link to={`/order/${cell}`}>
            <span
                className="text-blue-brand font-weight-bolder"
            >
                #{cell}
            </span>
        </Link>
    );
}

function sourceFormatter(cell, row, rowIndex, formatExtraData) {
    return (
        <Badge className={`p-1 badge-${cell.split(' ').join('-')}`} color="info">
            {cell}
        </Badge>
    );
}

function customerFormatter(cell, row, rowIndex, formatExtraData) {
    return (
        <div>
      <span>
        <Link
            to={`/customer/${row.customer_id}`}
            className="text-blue-brand font-weight-bolder"
        >{`${row.customer_first_name} ${row.customer_second_name}`}</Link>
      </span>
            <br/>
            <span>{row.customer_phone}</span>
        </div>
    );
}

function dateFormatter(cell, row, rowIndex, formatExtraData) {
    return moment(cell).format("MMM DD, YYYY hh:mm a");
}

function amtFormatter(cell, row, rowIndex, formatExtraData) {
    return `${Math.round(row.bill_subtotal)} / ${Math.round(row.bill_total)}`;
}

function paymentFormatter(cell, row, rowIndex, formatExtraData) {
    return (
        <Badge className="p-2 border-primary" color="dark" outline>
            {cell}
        </Badge>
    );
}

function referenceFormatter(cell, row, rowIndex, formatExtraData) {
    return (
        <Badge className="p-2" color="secondary">
            {cell}
        </Badge>
    );
}
function referenceFormatterOne(cell, row, rowIndex, formatExtraData) {
    return (
        <span className="text-capitalize">
            {cell}
        </span>
    );
}

// Table columns
const columns = [
    {
        dataField: "bill_id",
        text: "Bill id",
        sort: true,
        formatter: billIdFormatter
    },
    {
        dataField: "reference_number",
        text: "Reference ID",
        sort: true,
        formatter: referenceFormatter
    },
    {
        dataField: "reason",
        text: "Reason",
        sort: true,
        formatter: referenceFormatter
    },
    {
        dataField: "order_source_name",
        text: "Source",
        sort: true,
        formatter: referenceFormatterOne
    },
    {
        dataField: "customer_first_name",
        text: "Customer Details",
        sort: true,
        formatter: customerFormatter
    },
    {
        dataField: "date_time",
        text: "Order date",
        sort: true,
        formatter: dateFormatter
    },
    {
        dataField: "bill_total",
        text: "Amount",
        sort: true,
        formatter: amtFormatter
    },
    {
        dataField: "payment_mode_value",
        text: "Payment Status",
        sort: true,
        formatter: paymentFormatter
    }
];

const RemotePagination = ({
                              data,
                              page,
                              sizePerPage,
                              onTableChange,
                              totalSize
                          }) => (
    <div>
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize
            })}
        >
            {({paginationProps, paginationTableProps}) => (
                <div className="table-responsive new-table-css last-child-right table-margin-minus">
                    <BootstrapTable
                        remote
                        keyField="bill_id"
                        data={data}
                        columns={columns}
                        onTableChange={onTableChange}
                        {...paginationTableProps}
                    />
                    <div className="float-left text-muted pt-2">
                        <p>
                            Showing:{" "}
                            {(paginationProps.page - 1) * paginationProps.sizePerPage + 1} to{" "}
                            {paginationProps.page * paginationProps.sizePerPage <
                            paginationProps.totalSize
                                ? paginationProps.page * paginationProps.sizePerPage
                                : paginationProps.totalSize}{" "}
                            of {paginationProps.totalSize} entries
                        </p>
                    </div>
                    <div className="float-right mr-3">
                        <PaginationListStandalone {...paginationProps} />
                    </div>
                </div>
            )}
        </PaginationProvider>
    </div>
);

class OrdersTableContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            data: this.props.orders,
            sizePerPage: 10
        };
    }

    handleTableChange = (type, {page, sizePerPage}) => {
        this.setState(() => ({
            page
        }));
        this.props.handlePagination((page - 1) * sizePerPage);
    };

    render() {
        const {data, sizePerPage, page} = this.state;
        return (
            <div className="col-12 orders-table custom-table-no-border">
                {console.log(this.props.totalSize)}
                <RemotePagination
                    data={this.props.orders}
                    page={page}
                    sizePerPage={sizePerPage}
                    totalSize={this.props.totalData}
                    onTableChange={this.handleTableChange}
                />
            </div>
        );
    }
}

export default OrdersTableContainer;
