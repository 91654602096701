import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import Griddle, { plugins, RowDefinition, ColumnDefinition} from 'griddle-react';
import {Link} from "react-router-dom";
import React, {Component} from "react";
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import {
    Col,
    Row,
    Container,
    Media,
    CardHeader,
    Badge,
    Card,
    Button,
    CardTitle,
    CardText
} from "reactstrap";
import moment from "moment";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone
} from "react-bootstrap-table2-paginator";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Categories from "../Products/Categories";
import NewCategory from "../Products/NewCategory";
import NewProduct from "../Products/NewProduct";

const {SearchBar} = Search;
// Custom column formatter
function billIdFormatter(cell, row, rowIndex, formatExtraData) {
    return (
        <Link to={`/order/${cell}`}>
            <Button
                className="p-1 pl-2 pr-2 border-primary text-bolder"
                color="primary"
                outline
            >
                #{cell}
            </Button>
        </Link>
    );
}

function sourceFormatter(cell, row, rowIndex, formatExtraData) {
    return (
        <Badge className={`p-1 badge-${cell.split(' ').join('-')}`} color="info">
            {cell}
        </Badge>
    );
}

function customerFormatter(cell, row, rowIndex, formatExtraData) {
    return (
        <div>
      <span>
        <Link
            to={`/customer/${row.customer_id}`}
            className="text-danger font-weight-bolder"
        >{`${row.customer_first_name} ${row.customer_second_name}`}</Link>
      </span>
            <br/>
            <span>{row.customer_phone}</span>
        </div>
    );
}

function dateFormatter(cell, row, rowIndex, formatExtraData) {
    return moment(cell).format("MMM DD, YYYY hh:mm a");
}

function amtFormatter(cell, row, rowIndex, formatExtraData) {
    return (
        <span className="font-weight-bold">
            {Math.round(cell)}
        </span>
    );
}

function paymentFormatter(cell, row, rowIndex, formatExtraData) {
    return (
        <Badge className="p-2 border-primary" color="dark" outline>
            {cell}
        </Badge>
    );
}

function referenceFormatter(cell, row, rowIndex, formatExtraData) {
    return (
        <span className="font-weight-bold">
            {cell}
        </span>
    );
}
// total: "6859.25"
// discount_total: "50.00"
// subtotal: "6260.00"
// bill_count: "10"
// payment_mode_value: "CASH"
// Table columns
const columns = [
    {
        dataField: "payment_mode_value",
        text: "Payment Mode",
        sort: true,
        formatter: referenceFormatter
    },

    {
        dataField: "bill_count",
        text: "Bill Count",
        sort: true,
        formatter: referenceFormatter
    },
    {
        dataField: "subtotal",
        text: "Sub Total",
        sort: true,
        formatter: amtFormatter
    },
    {
        dataField: "discount_total",
        text: "Discount Total",
        sort: true,
        formatter: amtFormatter
    },
    {
        dataField: "total",
        text: "Total",
        sort: true,
        formatter: amtFormatter
    },
    // {
    //     dataField: "serving_size.1.product_price",
    //     text: "Large",
    //     sort: true,
    //     formatter: referenceFormatter
    // },
    // {
    //     dataField: "customer_first_name",
    //     text: "Customer Details",
    //     sort: true,
    //     formatter: customerFormatter
    // },
    // {
    //     dataField: "date_time",
    //     text: "Order date",
    //     sort: true,
    //     formatter: dateFormatter
    // },
    // {
    //     dataField: "bill_total",
    //     text: "Amount",
    //     sort: true,
    //     formatter: amtFormatter
    // },
    // {
    //     dataField: "payment_mode_value",
    //     text: "Payment Status",
    //     sort: true,
    //     formatter: paymentFormatter
    // }
];

const RemotePagination = ({
                              data,
                              page,
                              sizePerPage,
                              onTableChange,
                              totalSize
                          }) => (
    <div>

        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize
            })}
        >

            {({paginationProps, paginationTableProps}) => (
                <div className="table-responsive  new-table-css last-child-right table-margin-minus">
                    <BootstrapTable
                        remote
                        keyField="category_name"
                        data={data}
                        columns={columns}
                        onTableChange={onTableChange}
                        {...paginationTableProps}
                    />
                    <div className="float-left text-muted pt-2">
                        <p>
                            Showing:{" "}
                            {(paginationProps.page - 1) * paginationProps.sizePerPage + 1} to{" "}
                            {paginationProps.page * paginationProps.sizePerPage <
                            paginationProps.totalSize
                                ? paginationProps.page * paginationProps.sizePerPage
                                : paginationProps.totalSize}{" "}
                            of {paginationProps.totalSize} entries
                        </p>
                    </div>
                    <div className="float-right mr-3">
                        {/*<PaginationListStandalone {...paginationProps} />*/}
                    </div>
                </div>
            )}
        </PaginationProvider>
    </div>
);

class AnalyticsTableContainer extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props.orders);
        this.state = {
            page: 1,
            data: this.props.orders,
            sizePerPage: 10,
        };

    }

    handleTableChange = (type, {page, sizePerPage}) => {
        this.setState(() => ({
            page
        }));
        this.props.handlePagination((page - 1) * sizePerPage);
    };

    render() {
        const {data, sizePerPage, page} = this.state;
        return (
            <div>
                {/*<Griddle*/}
                    {/*data={data}*/}
                    {/*columnMetadata={data}*/}
                    {/*results={data}*/}
                    {/*plugins={[plugins.LocalPlugin]}*/}
                    {/*className="w-100 table"*/}
                    {/*resultsPerPage={200}*/}
                    {/*enableInfiniteScroll={true}*/}
                    {/*bodyHeight={400}*/}
                {/*>*/}

                {/*<RowDefinition cssClassName="table">*/}
                    {/*<ColumnDefinition id="category_name" title="Category Name" />*/}
                    {/*<ColumnDefinition id="product_name" title="Product Name" />*/}
                    {/*<ColumnDefinition id="total_qty" title="Quantity" />*/}
                    {/*<ColumnDefinition id="sales_value" title="Sales Value" />*/}
                    {/*<ColumnDefinition id="serving_size.0.product_price" title="Size 1" />*/}
                    {/*<ColumnDefinition id="serving_size.0.units" title="Qty" />*/}
                    {/*<ColumnDefinition id="serving_size.1.product_price" title="Size 2" />*/}
                    {/*<ColumnDefinition id="serving_size.1.units" title="Qty" />*/}
                {/*</RowDefinition>*/}

                {/*</Griddle>*/}

            <div className="w-100 orders-table custom-table-no-border">
                <RemotePagination
                    data={this.props.orders}
                    page={page}
                    sizePerPage={sizePerPage}
                    totalSize={this.props.totalData}
                    onTableChange={this.handleTableChange}
                />
            </div>
            </div>
        );
    }
}

export default AnalyticsTableContainer;
