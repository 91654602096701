import React, { Component, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import * as scroll from "react-scroll";

import { Col, Row, ListGroup, ListGroupItem, Media } from "reactstrap";
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable, Draggable } from "react-beautiful-dnd";

import NewProduct from "./NewProduct";
import NewCategory from "./NewCategory";

import {
  getProductList,
  getCategoryList,
  sortCategories
} from "../../actions/products";

class Products extends Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.state = {
      index: 0,
      categories: []
    };
    this.props.getCategoryList();

    this.onDragEnd = this.onDragEnd.bind(this);
    this.reorder = this.reorder.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { categories } = this.state;
    if (nextProps.categoryList.length != categories.length)
      this.setState({ categories: this.props.categoryList });
  }
  handleShow(i) {
    this.setState({ index: i });
    this.refs[i].scrollIntoView({ block: "end", behavior: "smooth" });
  }

  onDragEnd({ source, destination }) {
    if (!destination) {
      return;
    }

    const categories = this.reorder(
      this.state.categories,
      source.index,
      destination.index
    );

    this.setState({
      categories
    });

    const sortOrder = [];
    categories.map((row, index) => {
      sortOrder.push(parseInt(row.category_id));
    });
    // console.log(sortOrder);

    this.props.sortCategories(sortOrder);
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  render() {
    const { categories } = this.state;

    return (
        <div></div>

    );
  }
}

function bindActions(dispatch) {
  return {
    getCategoryList: () => dispatch(getCategoryList()),
    sortCategories: order => dispatch(sortCategories(order))
  };
}

function mapStateToProps(state) {
  return {
    categoryList: state.products.categoryList
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(Products);
