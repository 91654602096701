export default {
  items: [
    {
      name: 'Home',
      url: '/dashboard',
      icon: 'fa fa-bookmark'
    },
    {
      name: `Today's Order`,
      url: '/today',
      icon: 'fa fa-circle-notch',
    },
    {
      name: `Pre Orders`,
      url: '/preorders',
      icon: 'far fa-clock',
    },
    {
      name: `Escalations`,
      url: '/escalations',
      icon: 'fas fa-exclamation-triangle',
    },
    {
      name: 'Order History',
      url: '/orders',
      icon: 'fa fa-circle',
    },
    // {
    //   name: 'Billing',
    //   url: '/billing',
    //   icon: 'fa fa-keyboard',
    // },
    {
      name: 'Customers',
      url: '/customers',
      icon: 'fa fa-address-book',
    },
    {
      name: 'Products',
      url: '/products',
      icon: 'fa fa-utensils',
    },
    {
      name: 'Manage Branch',
      url: '/branch',
      icon: 'fa fa-cog',
    },
    {
      name: 'Manage Staff',
      url: '/staff',
      icon: 'fa fa-users',
    },
    {
      name: 'Field Cash',
      url: '/cash',
      icon: 'fa fa-rupee-sign',
    },
    {
      name: 'Manage Credits',
      url: '/wallets',
      icon: 'fas fa-wallet',
    },
    {
      name: 'Analytics',
      url: '/analytics',
      icon: 'fas fa-chart-pie',
    }
  ]
};
