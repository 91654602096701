import config from "../config";
import { getToken } from "../utils";
import { setMessage, setMessageType } from "./commonData";
import { isEmpty } from "lodash";
import axios from "axios";
import moment from "moment";
import { setSpinner } from "./commonData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const SET_LIVE_ORDERS_LIST = "SET_LIVE_ORDERS_LIST";
export const SET_ALL_LIVE_ORDERS_LIST = "SET_ALL_LIVE_ORDERS_LIST";
export const SET_LIVE_ESCALATION_ORDERS_LIST =
  "SET_LIVE_ESCALATION_ORDERS_LIST";
export const SET_LIVE_PRE_ORDER_ORDERS_LIST = "SET_LIVE_PRE_ORDER_ORDERS_LIST";
export const SET_CURRENT_ORDER = "SET_CURRENT_ORDER";
export const SET_ORDERS_LIST = "SET_ORDERS_LIST";
export const SET_DELIVERY_GUYS_LIST = "SET_DELIVERY_GUYS_LIST";
export const SET_LIVE_ORDER_META = "SET_LIVE_ORDER_META";
export const SET_ANALYTICS = "SET_ANALYTICS";
export const SET_ANALYTICS_CATEGORY = "SET_ANALYTICS_CATEGORY";
export const SET_ANALYTICS_BY_TIME = "SET_ANALYTICS_BY_TIME";
export const SET_DISPATCH_ORDER = "SET_DISPATCH_ORDER";
export const SET_ANALYTICS_PARTNER_REPORT = "SET_ANALYTICS_PARTNER_REPORT";
export const SET_ANALYTICS_PAYMENT_MODE = "SET_ANALYTICS_PAYMENT_MODE";
export const SET_ANALYTICS_TRENDS = "SET_ANALYTICS_TRENDS";
export const SET_ORDER_SOURCE = "SET_ORDER_SOURCE";
export const SET_ANALYTICS_CUSTOMER = "SET_ANALYTICS_CUSTOMER";
export const SET_UNDISPATCHED = "SET_UNDISPATCHED";
export const SET_SETTLEMENT = "SET_SETTLEMENT";
export const SET_PAYMENT_RECIVED = "SET_PAYMENT_RECIVED";

  var axiosInstance = axios.create({
  baseURL: config.baseUrl,
  timeout: 150000,
  headers: {
    "Content-Type": "application/json",
    version: "2.0",
    Authorization: `Token=${getToken()}`,
  },
});

const options = {
  autoClose: 2000,
  // closeButton:,
  type: toast.TYPE.INFO,
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_LEFT,
  pauseOnHover: true,
  // progress: 0.2
};

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // console.log("interceptors.response");
    return response;
  },
  function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      // console.log("Error", error.message);
    }
    // console.log(error.config);
    return Promise.reject(error);
  }
);

function setAllLiveOrdersList(payload) {
  return { type: SET_ALL_LIVE_ORDERS_LIST, payload: payload };
}

function setLiveOrdersList(payload) {
  return { type: SET_LIVE_ORDERS_LIST, payload: payload };
}

function setLiveEscalationOrdersList(payload) {
  return { type: SET_LIVE_ESCALATION_ORDERS_LIST, payload: payload };
}

function setLivePreOrderOrdersList(payload) {
  return { type: SET_LIVE_PRE_ORDER_ORDERS_LIST, payload: payload };
}

function setCurrentOrder(payload) {
  return { type: SET_CURRENT_ORDER, payload: payload };
}

function setOrdersList(payload) {
  return { type: SET_ORDERS_LIST, payload: payload };
}
function setPaymentRecived(payload) {
    return { type: SET_PAYMENT_RECIVED, payload: payload };
}
function setUnDispatched(payload) {
  return { type: SET_UNDISPATCHED, payload: payload };
}
function setAnalytics(payload) {
  return { type: SET_ANALYTICS, payload: payload };
}
function setAnalyticsTrends(payload) {
  return { type: SET_ANALYTICS_TRENDS, payload: payload };
}

function setOrderSource(payload) {
  return { type: SET_ORDER_SOURCE, payload: payload };
}

function setAnalyticsCategory(payload) {
  return { type: SET_ANALYTICS_CATEGORY, payload: payload };
}
function setSettlement(payload) {
    return { type: SET_SETTLEMENT, payload: payload };
}
function setAnalyticsPaymentMode(payload) {
  return { type: SET_ANALYTICS_PAYMENT_MODE, payload: payload };
}

function setAnalyticsByTime(payload) {
  return { type: SET_ANALYTICS_BY_TIME, payload: payload };
}
function setAnalyticsCustomer(payload) {
  return { type: SET_ANALYTICS_CUSTOMER, payload: payload };
}
function setAnalyticsPartnerReport(payload) {
  return { type: SET_ANALYTICS_PARTNER_REPORT, payload: payload };
}
function setDeliveryGuys(payload) {
  return { type: SET_DELIVERY_GUYS_LIST, payload: payload };
}

function setLiveOrderMeta(payload) {
  return { type: SET_LIVE_ORDER_META, payload: payload };
}

function setDispatchOrder(payload) {
  return { type: SET_DISPATCH_ORDER, payload: payload };
}

export function getAllLiveOrdersList(params) {
  let branchID = params.branch_id;
  let token = getToken();
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `pos/getTodaysOrder?branch_id=${branchID}&order_state=${params.status}&length=1000&sort=${params.sort}&search=${params.searchText}&start=${params.offset}&Token=${token}`
      )
      .then((response) => {
        dispatch(setSpinner(false));
        // console.log("responseeeeee", response)
        if (response.data) {
          dispatch(setAllLiveOrdersList(response.data.data));
        } else {
          toast.error(response.errors, options);
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        // console.log(err);
        toast.error("Oops!! Something went wrong", options);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
      });
  };
}
export function getUnDispatched(params) {
  let branchID = params.branch_id;
  let token = getToken();
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `/pos/getUnDispatchedOrders?branch_id=${branchID}&order_state=${params.status}&Token=${token}`
      )
      .then((response) => {
        dispatch(setSpinner(false));
        // console.log("responseeeeee", response)
        if (response.data) {
          dispatch(setUnDispatched(response.data));
        } else {
          toast.error(response.errors, options);
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        // console.log(err);
        toast.success("Cook Time updated Successfully", options);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
      });
  };
}
export function getLiveOrdersList(params) {
  return function (dispatch) {
    let branchID = params.branch_id;
    let token = getToken();
    dispatch(setSpinner(true));
    axiosInstance

      .get(
        `pos/getTodaysOrder?branch_id=${branchID}&order_state=${params.status}&length=${params.perPage}&sort=${params.sort}&search=${params.searchText}&start=${params.offset}&Token=${token}&order_source=${params.orderSource}&city_id=${params.city_id}`
      )
      .then((response) => {
        dispatch(setSpinner(false));
        // console.log("responseeeeee", response)
        if (response.data) {
          dispatch(setLiveOrdersList(response.data.data));
        } else {
          toast.error(response.errors, options);
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        // console.log(err, "this is fucking up");
        toast.error(" Oops!! Something went wrong", options);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
      });
  };
}

export function getLiveEscalationOrdersList(params) {
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      // `pos/getTodaysOrder?branch_id=${params.branch_id}&order_state=${params.status}&length=${params.perPage}&sort=${params.sort}&search=${params.searchText}&start=${params.offset}&pre_order=true`
      .get(
        `pos/getTodaysOrder?branch_id=${params.branch_id}&city_id=${params.city_id}&order_state=${params.status}&length=${params.perPage}&sort=${params.sort}&search=${params.searchText}&start=${params.offset}&time_priority=1`
      )
      .then((response) => {
        dispatch(setSpinner(false));

        if (response.data) {
          dispatch(setLiveEscalationOrdersList(response.data.data));
        } else {
          toast.error(response.errors, options);
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        // console.log(err);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
        toast.error("Oops!! Something went wrong", options);
      });
  };
}

export function getLivePreOrderOrdersList(params) {
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `pos/getTodaysOrder?branch_id=${params.branch_id}&city_id=${params.city_id}&order_state=${params.status}&length=${params.perPage}&sort=${params.sort}&search=${params.searchText}&start=${params.offset}&pre_order=true`
        // `pos/getTodaysOrder?branch_id=${params.branch_id}&order_state=${params.status}&length=${params.perPage}&sort=${params.sort}&search=${params.searchText}&start=${params.offset}&time_priority=1`
      )
      .then((response) => {
        dispatch(setSpinner(false));
        // console.log("responseeeeee", response)
        if (response.data) {
          dispatch(setLivePreOrderOrdersList(response.data.data));
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
          toast.error(response.errors, options);
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        // console.log(err);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
        toast.error("Oops!! Something went wrong", options);
      });
  };
}

export function getOrder(orderId) {
  // console.log(orderId);
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(`pos/getBillForPrint/${orderId}`)
      .then((response) => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setCurrentOrder(response.data.data));
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
          toast.error(response.errors, options);
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        // console.log(err);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
        toast.error("Oops!! Something went wrong", options);
      });
  };
}

export function getOrderTimeline(orderId) {
  console.log(orderId);
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(`pos/getBill/${orderId}`)
      .then((response) => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setCurrentOrder(response.data.data));
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
          toast.error(response.errors, options);
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        // console.log(err);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
        toast.error("Oops!! Something went wrong", options);
      });
  };
}

export function getOrderHistory(params) {
  let default_params = {
    branch_id: 0,
    status: 0,
    from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    searchText: "",
    offset: 0,
    perPage: 10,
    sort: "ASC",
  };
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `pos/orderHistoryData?from_date=${params.from_date}&to_date=${params.to_date}&branch_id=${params.branch_id}&sort=${params.sort}&length=${params.perPage}&search=${params.searchText}&start=${params.offset}&status=${params.status}&city_id=${params.city_id}`
      )
      .then((response) => {
        dispatch(setSpinner(false));
        // console.log("responseeeeee", response)
        if (response.data) {
          dispatch(setOrdersList(response.data.data));
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
          toast.error(response.errors, options);
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        // console.log(err);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
        toast.error("Oops!! Something went wrong", options);
      });
  };
}

export function getAnalytics(params) {
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `analytics/getProductSalesReport?from_date=${params.from_date}&to_date=${params.to_date}&branch_id=${params.branch_id}&city_id=${params.city_id}&order_source=${params.order_source}`
      )
      .then((response) => {
        dispatch(setSpinner(false));

        if (response.data) {
          dispatch(setAnalytics(response.data));
        } else {
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
      });
  };
}

export function getOrderSourceForAnalyticsTrends() {
  return function (dispatch) {
    axiosInstance
      .get(`/basics/loadOrderSource`)
      .then((response) => {
        dispatch(setSpinner(false));
        dispatch(setOrderSource(response.data));
      })
      .catch((err) => {});
  };
}

export function getAnalyticsTrends(params) {
  let default_params = {
    branch_id: 0,
    status: 0,
    from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    searchText: "",
    offset: 0,
    perPage: 10,
    sort: "ASC",
  };
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `analytics/getSalesTrendByValue?from_date=${params.from_date}&to_date=${params.to_date}&branch_id=${params.branch_id}&city_id=${params.city_id}&order_source=${params.order_source}`
        // `analytics/getProductSalesReport?from_date=${params.from_date}&to_date=${params.to_date}&branch_id=${params.branch_id}&sort=${params.sort}&length=${params.perPage}`
      )
      .then((response) => {
        dispatch(setSpinner(false));

        if (response.data) {
          dispatch(setAnalyticsTrends(response.data));
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
          // toast.error(response.errors, options); //ToDo Must clear double API ping issue
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        // console.log(err);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
        // toast.error("Oops!! Something went wrong", options) //ToDo Must clear double API ping issue
      });
  };
}

export function getAnalyticsCustomer(params) {
  let default_params = {
    branch_id: 0,
    status: 0,
    from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    searchText: "",
    offset: 0,
    perPage: 10,
    sort: "ASC",
  };
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `analytics/getCustomerReport?from_date=${params.from_date}&to_date=${params.to_date}&branch_id=${params.branch_id}&city_id=${params.city_id}&order_source=0`
        // `analytics/getProductSalesReport?from_date=${params.from_date}&to_date=${params.to_date}&branch_id=${params.branch_id}&sort=${params.sort}&length=${params.perPage}`
      )
      .then((response) => {
        dispatch(setSpinner(false));

        if (response.data) {
          dispatch(setAnalyticsCustomer(response.data));
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
          // toast.error(response.errors, options); //ToDo Must clear double API ping issue
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        // console.log(err);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
        // toast.error("Oops!! Something went wrong", options) //ToDo Must clear double API ping issue
      });
  };
}
export function getAnalyticsByTime(params) {
  let default_params = {
    branch_id: 0,
    status: 0,
    from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    searchText: "",
    offset: 0,
    perPage: 10,
    sort: "ASC",
  };
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `analytics/getProductSalesBreakUpByTimeRange?from_date=${params.from_date}&to_date=${params.to_date}&category_id=0&branch_id=${params.branch_id}&city_id=${params.city_id}&day_of_the_week=${params.day_of_the_week}`
        // `analytics/getProductSalesReport?from_date=${params.from_date}&to_date=${params.to_date}&branch_id=${params.branch_id}`
        // `analytics/getProductSalesReport?from_date=${params.from_date}&to_date=${params.to_date}&branch_id=${params.branch_id}&sort=${params.sort}&length=${params.perPage}`
      )
      .then((response) => {
        dispatch(setSpinner(false));
        console.log("by time", response);
        if (response.data) {
          dispatch(setAnalyticsByTime(response.data));
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
          // toast.error(response.errors, options); //ToDo Must clear double API ping issue
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        // console.log(err);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
        // toast.error("Oops!! Something went wrong", options) //ToDo Must clear double API ping issue
      });
  };
}
export function getSettlement(params) {
    let default_params = {
        branch_id: 0,
        status: 0,
        from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        searchText: "",
        offset: 0,
        perPage: 10,
        sort: "ASC",
    };
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .get(
                `/report/getSettlementReport`
                // `analytics/getProductSalesReport?from_date=${params.from_date}&to_date=${params.to_date}&branch_id=${params.branch_id}`
                // `analytics/getProductSalesReport?from_date=${params.from_date}&to_date=${params.to_date}&branch_id=${params.branch_id}&sort=${params.sort}&length=${params.perPage}`
            )
            .then((response) => {
                dispatch(setSpinner(false));
                console.log("by time", response);
                if (response.data.data) {
                    dispatch(setSettlement(response.data.data));
                } else {
                    // dispatch(setMessage(response.errors));
                    // dispatch(setMessageType("danger"));
                    // toast.error(response.errors, options); //ToDo Must clear double API ping issue
                }
            })
            .catch((err) => {
                dispatch(setSpinner(false));
                // console.log(err);
                // dispatch(setMessage("Oops!! Something went wrong"));
                // dispatch(setMessageType("danger"));
                // toast.error("Oops!! Something went wrong", options) //ToDo Must clear double API ping issue
            });
    };
}
export function getAnalyticsPartnerReport(params) {
  return function (dispatch) {
    dispatch(setSpinner(true));
    // &branch_id=${params.branch_id}
    axiosInstance
      .get(
        `/analytics/getDelPartnerReport?year=${params.year}&month=${params.month}&branch_id=${params.branch_id}&city_id=${params.city_id}`
     )
      .then((response) => {
        dispatch(setSpinner(false));
        console.log("by time", response);
        if (response.data) {
          dispatch(setAnalyticsPartnerReport(response.data));
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
          // toast.error(response.errors, options); //ToDo Must clear double API ping issue
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        // console.log(err);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
        // toast.error("Oops!! Something went wrong", options) //ToDo Must clear double API ping issue
      });
  };
}
export function getAnalyticsCategory(params) {
  let default_params = {
    branch_id: params.branch_id,
    status: 0,
    from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    searchText: "",
    offset: 0,
    perPage: 10,
    sort: "ASC",
  };
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `analytics/getRevenueByPaymentSource?from_date=${params.from_date}&to_date=${params.to_date}&branch_id=${params.branch_id}&city_id=${params.city_id}`
        // `analytics/getProductSalesReport?from_date=${params.from_date}&to_date=${params.to_date}&branch_id=${params.branch_id}&sort=${params.sort}&length=${params.perPage}`
      )
      .then((response) => {
        dispatch(setSpinner(false));

        if (response.data.data) {
          dispatch(setAnalyticsCategory(response.data.data));
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
          // toast.error(response.errors, options); //ToDo Must clear double API ping issue
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        // console.log(err);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
        // toast.error("Oops!! Something went wrong", options) //ToDo Must clear double API ping issue
      });
  };
}
export function getAnalyticsPaymentMode(params) {
  console.log(params.branch_id);
  let default_params = {
    branch_id: params.branch_id,
    status: 0,
    from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    searchText: "",
    offset: 0,
    perPage: 10,
    sort: "ASC",
  };
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `analytics/getRevenueByPaymentSource?from_date=${params.from_date}&to_date=${params.to_date}&branch_id=${params.branch_id}`
        // `analytics/getProductSalesReport?from_date=${params.from_date}&to_date=${params.to_date}&branch_id=${params.branch_id}&sort=${params.sort}&length=${params.perPage}`
      )
      .then((response) => {
        dispatch(setSpinner(false));

        if (response.data) {
          dispatch(setAnalyticsPaymentMode(response.data));
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
          // toast.error(response.errors, options); //ToDo Must clear double API ping issue
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        // console.log(err);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
        // toast.error("Oops!! Something went wrong", options) //ToDo Must clear double API ping issue
      });
  };
}
export function getDeliveryGuys(branch) {
  return function (dispatch) {
    if (isEmpty(branch)) {
      dispatch(setMessage("Oops!! Please select a branch"));
      dispatch(setMessageType("danger"));
      return;
    }
    axiosInstance
      .get(`manage_branch/getBranchAvlDeliveryGuys?branch_id=${branch}`)
      .then((response) => {
        // console.log("responseeeeee", response)
        if (response.data) {
          dispatch(setDeliveryGuys(response.data.data));
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
          toast.error(response.errors, options);
        }
      })
      .catch((err) => {
        // console.log(err);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
        toast.error("Oops!! Something went wrong", options);
      });
  };
}

export function getLiveOrderMeta() {
  return function (dispatch) {
    axiosInstance
      .get(`pos/getLiveOrderMeta`)
      .then((response) => {
        // console.log("responseeeeee", response)
        if (response.data) {
          dispatch(setLiveOrderMeta(response.data.data));
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
          toast.error(response.errors, options);
        }
      })
      .catch((err) => {
        // console.log(err);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
        toast.error("Oops!! Something went wrong", options);
      });
  };
}

export function updateOrder(
  status,
  order_id,
  reason,
  assigned_to,
  params,
  delivered_by_partner = 0,
  is_full_time
) {
  return function (dispatch) {
    if (isEmpty(status) && isEmpty(order_id)) {
      dispatch(setMessage("Invalid Params"));
      dispatch(setMessageType("danger"));
      return;
    }
    var service_id = 0;
    if (delivered_by_partner == 1) {
      service_id = assigned_to;
      assigned_to = 0;
    }
    var postdata = {
      order_id: order_id,
      order_status: status,
      assigned_to: assigned_to,
      reason: reason,
      service_id: service_id,
      is_full_time: is_full_time,
    };

    console.log(params);
    dispatch(setSpinner(true));
    axiosInstance
      .post(`pos/updateOrder`, postdata)
      .then((response) => {
        dispatch(setSpinner(false));
        // console.log("responseeeeee", response)
        if (response.data) {
          dispatch(getLiveOrdersList(params));
          // dispatch(setMessage("Order successfully updated"));
          // dispatch(setMessageType("success"));
          toast.success(
            "Order id #" + order_id + " has been updated successfully",
            options
          );
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
          toast.error(response.errors, options);
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        // console.log(err);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
        //   toast.error("Looks like s", options)
        toast.success(
          "Order id #" + order_id + " Has been Cancelled successfully",
          options
        );
        //ToDO this is wrong, need to be fixed
      });
  };
}
export function updateOrderPreTime(order_id, is_full_time, params) {
  return function (dispatch) {
    var postdata = {
      order_id: order_id,
      is_full_time: is_full_time,
    };

    console.log(postdata, "postdata");
    dispatch(setSpinner(true));
    axiosInstance
      .post(`pos/updateOrderPreTime/${order_id}`, postdata)
      .then((response) => {
        dispatch(setSpinner(false));
        // console.log("responseeeeee", response)
        if (response.data) {
          dispatch(getLiveOrdersList(params));
          // dispatch(setMessage("Order successfully updated"));
          // dispatch(setMessageType("success"));
          toast.success(
            "Order id #" + order_id + " has been updated successfully",
            options
          );
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
          toast.error(response.errors, options);
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        // console.log(err);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
        //   toast.error("Looks like s", options)
        // toast.success("Order id #" + order_id + " updated successfully", options);
        //ToDO this is wrong, need to be fixed
      });
  };
}
export function getPaymentRecived() {
    return function (dispatch) {
        axiosInstance
            .get(`/pos/getAvailPayModeForDelivery`)
            .then((response) => {
                // console.log("responseeeeee", response)
                if (response.data) {
                    dispatch(setPaymentRecived(response.data));
                } else {
                    // dispatch(setMessage(response.errors));
                    // dispatch(setMessageType("danger"));
                    toast.error(response.errors, options);
                }
            })
            .catch((err) => {
                // console.log(err);
                // dispatch(setMessage("Oops!! Something went wrong"));
                // dispatch(setMessageType("danger"));
                toast.error("Oops!! Something went wrong", options);
            });
    };
}
export function updatePaymentRecived(order_id, is_full_time, params) {
    return function (dispatch) {
        var postdata = {
            order_id: order_id,
            is_full_time: is_full_time,
        };

        console.log(postdata, "postdata");
        dispatch(setSpinner(true));
        axiosInstance
            .post(`pos/updateOrderPreTime/${order_id}`, postdata)
            .then((response) => {
                dispatch(setSpinner(false));
                // console.log("responseeeeee", response)
                if (response.data) {
                    dispatch(setPaymentRecived(params));
                    // dispatch(setMessage("Order successfully updated"));
                    // dispatch(setMessageType("success"));
                    toast.success(
                        "Order id #" + order_id + " has been updated successfully",
                        options
                    );
                } else {
                    // dispatch(setMessage(response.errors));
                    // dispatch(setMessageType("danger"));
                    toast.error(response.errors, options);
                }
            })
            .catch((err) => {
                dispatch(setSpinner(false));
                // console.log(err);
                // dispatch(setMessage("Oops!! Something went wrong"));
                // dispatch(setMessageType("danger"));
                //   toast.error("Looks like s", options)
                // toast.success("Order id #" + order_id + " updated successfully", options);
                //ToDO this is wrong, need to be fixed
            });
    };
}
export function dispatchOrder(status, order_id, reason, assigned_to, params) {
  return function (dispatch) {
    if (isEmpty(status) && isEmpty(order_id)) {
      dispatch(setMessage("Invalid Params"));
      dispatch(setMessageType("danger"));
      return;
    }
    console.log(params);
    dispatch(setSpinner(true));
    axiosInstance
      .post(`pos/updateOrder`, {
        order_id: order_id,
        order_status: status,
        assigned_to: assigned_to,
        reason: reason,
      })
      .then((response) => {
        dispatch(setSpinner(false));
        // console.log("responseeeeee", response)
        if (response.data) {
          dispatch(setDispatchOrder(params));
          // dispatch(setMessage("Order successfully updated"));
          // dispatch(setMessageType("success"));
          toast.success(
            "Order id #" + order_id + " has been updated successfully",
            options
          );
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
          toast.error(response.errors, options);
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        // console.log(err);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
        //   toast.error("Looks like s", options)
        toast.success(
          "Order id #" + order_id + " Has been Cancelled successfully",
          options
        );
        //ToDO this is wrong, need to be fixed
      });
  };
}

export function cancelOrder(order_id, reason) {
  return function (dispatch) {
    if (isEmpty(reason) && isEmpty(order_id)) {
      dispatch(setMessage("Invalid Params"));
      dispatch(setMessageType("danger"));
      return;
    }
    dispatch(setSpinner(true));
    axiosInstance
      .post(`pos/updateOrder`, {
        order_id: order_id,
        order_status: 9,
        assigned_to: "",
        reason: reason,
      })
      .then((response) => {
        console.log("error response", response);
        dispatch(setSpinner(false));
        if (response.data.status) {
          // dispatch(setMessage("Order successfully canceled"));
          // dispatch(setMessageType("success"));
          toast.success(
            "Order id #" + order_id + " has been updated successfully",
            options
          );
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
          toast.error(response.data.errors, options);
        }
      })
      .catch((err) => {
        dispatch(setSpinner(false));
        // console.log(err);
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
        //   toast.error("Looks like something went wrong", options)
        toast.success("Order id #" + order_id + " is cancelled", options);
        //ToDO this is wrong, need to be fixed
      });
  };
}
