import React, { Component } from "react";
import { connect } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";

import {
  Row,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Input
} from "reactstrap";
import SelectBranch from "./SelectBranch";
import moment from "moment";
import { getDeliveryChargeList, addDeliveryCharge } from "../../actions/branch"

function amtFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div>
      {Math.round(cell)}
    </div>
  );
}

function statusFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div>
      {/* @TODO - what are the values? */}
      {cell === 1 ? "Active" : "Inactive"}
    </div>
  );
}

const columns = [
  {
    dataField: "min_bill_amount",
    text: "From Bill Amount",
    sort: true,
    formatter: amtFormatter
  },
  {
    dataField: "max_bill_amount",
    text: "To Bill Amount",
    sort: true,
    formatter: amtFormatter
  },
  {
    dataField: "delivery_charge_value",
    text: "Delivery Charge",
    sort: true,
    formatter: amtFormatter
  },
  {
    dataField: "bdc_status",
    text: "Status",
    sort: true,
    formatter: statusFormatter
  }
];

class Delivery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formModal: false,
      minBillAmt: "",
      maxBillAmt: "",
      deliveryCharge: "",
      branch_id: this.props.branch_id
    };

    this.toggleFormModal = this.toggleFormModal.bind(this);
    this.submitDelivery = this.submitDelivery.bind(this);
    if (this.props.branch_id) {
      this.props.getDeliveryChargeList(this.state.branch_id);
    }
  }

  componentDidUpdate(previousProps, previousState) {
    const { branch_id } = this.props;
    if (branch_id !== previousProps.branch_id) {
      if (branch_id) {
        this.props.getDeliveryChargeList(branch_id);
      }
      this.setState({ branch_id: branch_id });
    }
  }

  toggleFormModal() {
    this.setState(prevState => ({
      formModal: !prevState.formModal
    }));
  };

  submitDelivery() {
    let data = {};
    const {
      minBillAmt,
      maxBillAmt,
      deliveryCharge,
      branch_id,
    } = this.state;
    data = {
      minBillAmt: minBillAmt,
      maxBillAmt: maxBillAmt,
      deliveryCharge: deliveryCharge,
      branch_id: branch_id
    };
    this.props.addDeliveryCharge(data);

    this.setState({
      formModal: false,
      minBillAmt: "",
      maxBillAmt: "",
      deliveryCharge: ""
    });
  }


  render() {
    const {
      formModal,
      minBillAmt,
      maxBillAmt,
      deliveryCharge,
      branch_id
    } = this.state;
    let data = this.props.deliveryChargeList;
    return (
      <div className="animated fadeIn dashboard">
        <Row>
          <Col sm="12" className="">
            <Row>
              <Col sm="9" className="m-0 mb-2">

                <div className="mb-2">DELIVERY CHARGES</div>
                <div className="">
                  Add Delivery charge slabs here
          </div>
              </Col>
              {branch_id == 0 ? <SelectBranch /> : " "}
              {branch_id != 0 &&
                <Col sm="3" className="m-0 pl-0">
                  <Button
                    color="primary"
                    className="place-order w-100"
                    onClick={this.toggleFormModal}
                  >Add Slab</Button>
                  <Modal
                    isOpen={this.state.formModal}
                    fade={false}
                    toggle={this.toggleFormModal}
                    className={this.props.className}
                  >
                    <ModalHeader toggle={this.toggleFormModal}>Add Delivery Slab</ModalHeader>
                    <ModalBody>
                      <FormGroup>
                        <Input
                          type="text"
                          className="mb-3"
                          placeholder="From Bill Amount"
                          autoComplete="minBillAmt"
                          onChange={e => {
                            this.setState({ minBillAmt: e.target.value });
                          }}
                          value={minBillAmt}
                        />

                        <Input
                          type="text"
                          className="mb-3"
                          placeholder="To Bill Amount"
                          autoComplete="maxBillAmt"
                          onChange={e => {
                            this.setState({ maxBillAmt: e.target.value });
                          }}
                          value={maxBillAmt}
                        />

                        <Input
                          type="text"
                          className="mb-3"
                          placeholder="Delivery Charge"
                          autoComplete="deliveryCharge"
                          onChange={e => {
                            this.setState({ deliveryCharge: e.target.value });
                          }}
                          value={deliveryCharge}
                        />

                      </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        outline
                        onClick={this.toggleFormModal}
                      >
                        Cancel
          </Button>{" "}
                      <Button
                        color="primary"
                        onClick={() => {
                          this.submitDelivery();
                        }}
                      >
                        Confirm and Add
          </Button>
                    </ModalFooter>
                  </Modal>
                </Col>

              }
            </Row>
          </Col>


          <Col sm="12" className="">
            {branch_id != 0 &&
              <div className="custom-table-no-border">
                <div className="table-responsive new-table-css last-child-right table-margin-minus">
                  <BootstrapTable
                    remote
                    keyField="delivery_charges_id"
                    data={data}
                    columns={columns}
                  />
                </div>
              </div>
            }
          </Col>
        </Row>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    getDeliveryChargeList: branch_id => dispatch(getDeliveryChargeList(branch_id)),
    addDeliveryCharge: params => dispatch(addDeliveryCharge(params)),
  };
}

function mapStateToProps(state) {
  return {
    deliveryChargeList: state.branch.deliveryChargeList,
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(Delivery);