import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Badge, Media, Row } from "reactstrap";
import moment from "moment";
import config from "../../config";

import OrderActions from "./PreOrderActions";

class PreOrderOrders extends Component {
  render() {
    const order = this.props.order;
    return (
      <div>
        <Row className={`each-order order_${order.priority}`} >
          <Col xs="12" sm="12" md="3" className="mb-3 mb-md-0 border-b pb-3 pb-md-0">
            <Media>
              <Media left href="#" className="customer_details">
                <Media
                  object
                  src={`${config.imgUrl}frontend/images/order_source/${
                    order.order_source_id
                    }.jpg`}
                  alt={order.order_source_name}
                  className="source_img"
                />
              </Media>
              <Media body className="ml-3">
                <Media heading className="customer_name">
                  <Link to={`/customer/${order.customer_id}`}>
                    {`${
                      order.customer_first_name
                      } ${order.customer_second_name}`}
                  </Link>
                </Media>
                <span className="opacity-less">{order.customer_phone}</span>
              </Media>
            </Media>

            <p className="mt-md-3 mb-0">
              <Link to={`/order/${order.bill_id}`}>
                #{order.bill_id}{" "}{order.branch_name}
                <br />
                {order.reference_number && (
                  <Badge color="info" className="mt-1">
                    Ref: {order.reference_number}{" "}
                  </Badge>
                )}
              </Link>
            </p>
            <div className="clearfix" />
          </Col>
          <Col xs="12" sm="12" md="2" className="mb-3 mb-md-0 border-b pb-3 pb-md-0">
            <div className="new_order_pricing">
              <div className="fs-16 mt--3px">
                <span>{Math.round(order.bill_subtotal)}</span> /{" "}
                <span>{Math.round(order.bill_total)}</span>
                <span className="fa fa-rupee hidden" />
              </div>
              <div className="badge badge-secondary badge-payemnt">
                {order.payment_mode_value}
              </div>
            </div>
          </Col>
          <Col xs="12" sm="12" md="3" className="mb-3 mb-md-0 border-b pb-3 pb-md-0">
            <div className="new_order_status">
              <div className="new_order_status_text">
                <h5 className="fs-16">{order.current_state_name} {order.pre_order == true && <span className="small-font">(Pre-order)</span>}</h5>
                {moment(order.date_time).format("MMMM Do YYYY, h:mm:ss a")}
                {order.mins_left && <p className={`fa-1x mb-0 order_count_${order.priority}`}>{order.mins_left}</p>}
                {/*{order.priority == 0}*/}
                {/*{order.priority == 1 && <p className="badge badge-high fa-1x mb-0">Critical</p>}*/}
                {/*{order.priority == 2 && <p className="badge badge-high fa-1x mb-0">High</p>}*/}
                {/*{order.priority == 3 && <p className="badge badge-mediem fa-1x mb-0">Medium</p>}*/}
                {/*{order.priority == 4 && <p className="badge badge-low fa-1x mb-0">Low</p>}*/}
                {/*{order.priority == 5 && <p className="badge badge-low fa-1x mb-0">Low</p>}*/}
                {/*{order.priority == 6 && <p className="badge badge-low fa-1x mb-0">Low</p>}*/}
                {/*{order.priority == 7 && <p className="badge badge-low fa-1x mb-0">Low</p>}*/}
                {/*{order.priority == 8 && <p className="badge badge-low fa-1x mb-0">Low</p>}*/}

              </div>
            </div>
          </Col>
          <Col sm="12" md="4">
            <div className="action_container ">
              <OrderActions
                liveOrderMeta={this.props.liveOrderMeta}
                deliveryGuys={this.props.deliveryGuys}
                order={order}
                filterParams={this.props.filterParams}
              />

              <div className="d-inline-block">
                <Link to={`/print-order/${order.bill_id}`} target="_blank" className="btn btn-secondary ml-3 action">
                  <i className="fa fa-print" />
                </Link>
                <Link
                  to={`/order/${order.bill_id}`}
                  className="btn btn-secondary ml-3 action"
                >
                  <i className="fa fa-bars" />
                </Link>
              </div>
            </div>
            <div className="clearfix" />
            {/*<p className=" mb-0">*/}
            {/*  <strong>Order Placed on</strong><br />*/}
            {/*  {moment(order.date_time).format("MMMM Do YYYY, h:mm:ss a")}*/}
            {/*</p>*/}
          </Col>
        </Row>
      </div>
    );
  }
}

export default PreOrderOrders;
