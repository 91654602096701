import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import config from "../../config";
import { Link } from "react-router-dom";
import React, { Component } from "react";

import {
  Col,
  Row,
  Container,
  Media,
  CardHeader,
  Badge,
  Card,
  Button,
  CardTitle,
  CardText,
  Label,
  Input
} from "reactstrap";
import moment from "moment";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";

const RemotePagination = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  columns
}) => (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page,
          sizePerPage,
          totalSize
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <div className="table-responsive new-table-css last-child-right table-margin-minus">
            <BootstrapTable
              remote
              keyField="bill_id"
              data={data}
              columns={columns}
              onTableChange={onTableChange}
              {...paginationTableProps}
            />
            <div className="float-left text-muted pt-2">
              <p>
                Showing:{" "}
                {(paginationProps.page - 1) * paginationProps.sizePerPage + 1} to{" "}
                {paginationProps.page * paginationProps.sizePerPage <
                  paginationProps.totalSize
                  ? paginationProps.page * paginationProps.sizePerPage
                  : paginationProps.totalSize}{" "}
                of {paginationProps.totalSize} entries
            </p>
            </div>
            <div className="float-right mr-3">
              <PaginationListStandalone {...paginationProps} />
            </div>
          </div>
        )}
      </PaginationProvider>
    </div>
  );

class CustomersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: this.props.orders,
      sizePerPage: 10
    };
    this.handleSMS = this.handleSMS.bind(this);
    this.smsFormatter = this.smsFormatter.bind(this);
  }

  handleSMS(customer_id, sms_opt_in) {
    this.props.toggleSMS(customer_id, sms_opt_in);
  }

  // Custom column formatter
  userFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <Media
        object
        src={`/images/restro.png`}
        alt=""
        className="profile-image"
      />
    );
  }

  // Custom column formatter
  nameFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <Link to={`/customer/${row.customer_id}`}>
        <span className="text-blue-brand">{`${row.customer_first_name} ${row.customer_second_name}`}</span>
        <br />
        {row.customer_phone}
      </Link>
    );
  }

  // Custom column formatter
  dateJoinedFormatter(cell, row, rowIndex, formatExtraData) {
    if (cell) return moment(cell).format("MMM DD, YYYY");
    return "";
  }

  // Custom column formatter
  revenueFormatter(cell, row, rowIndex, formatExtraData) {
    return <div>{Math.round(cell)}</div>;
  }

  // Custom column formatter
  smsFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <label className="switch-container">
        <Input
          type="checkbox"
          className={cell == 1 ? "checkedSwitch" : ""}
          checked={cell == 1 ? "true" : "false"}
          value={cell}
          onChange={e => {
            this.handleSMS(row.customer_id, cell == 1 ? 0 : 1);
          }}
        />
        <span className="slider round"></span>
      </label>
    );
  }

  handleTableChange = (type, { page, sizePerPage }) => {
    this.setState(() => ({
      page
    }));
    this.props.handlePagination((page - 1) * sizePerPage);
  };
    // bill_count: "2"
    // customer_first_name: ""
    // customer_id: "91779"
    // customer_phone: "8660900679"
    // customer_second_name: ""
    // date_time: "2019-09-15 21:44:07"
    // discount_value: "0.00"
    // first_order_date: null
    // first_order_source_name: null
    // last_order_date: null
    // last_order_source_name: null
    // sms_opt_in: "1"
    // total: "585.80"
  render() {
    const columns = [
      {
        dataField: "customer_id",
        text: "User",
        sort: true,
        formatter: this.userFormatter
      },
      {
        dataField: "customer_first_name",
        text: "Full Name",
        sort: true,
        formatter: this.nameFormatter
      },
      {
        dataField: "first_order_date",
        text: "First order",
        sort: true,
        // formatter: this.dateJoinedFormatter
      },
      {
        dataField: "first_order_source_name",
        text: "First Source",
        sort: true,
      },
        {
            dataField: "last_order_date",
            text: "Last order",
            sort: true,
            // formatter: this.dateJoinedFormatter
        },
        {
            dataField: "last_order_source_name",
            text: "Last Source",
            sort: true,
        },
      {
        dataField: "bill_count",
        text: "Bills",
        sort: true
      },
      {
        dataField: "total",
        text: "Revenue",
        sort: true,
        formatter: this.revenueFormatter
      },
      {
        dataField: "discount_value",
        text: "Discount",
        sort: true,
        formatter: this.revenueFormatter
      },
      // {
      //   dataField: "total",
      //   text: "Credits",
      //   sort: true,
      //   formatter: this.revenueFormatter
      // },
      {
        dataField: "sms_opt_in",
        text: "SMS",
        sort: true,
        formatter: this.smsFormatter
      }
    ];

    const { data, sizePerPage, page } = this.state;
    return (
      <div className="w-100 orders-table custom-table-no-border">
        <RemotePagination
          data={this.props.orders}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={this.props.totalData}
          onTableChange={this.handleTableChange}
          columns={columns}
        />
      </div>
    );
  }
}

export default CustomersTable;
