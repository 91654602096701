import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import {DateRangePicker} from "react-dates";
import config from "../../config";
import {getCashList, getPaymentModes, markPaymentCollected, updatePaymentMode,} from "../../actions/cash";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import {Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Row,} from "reactstrap";
import CashTable from "./CashTable";
import {formatNumbersWithThousandSeparator} from "../../utils";
import Cookies from "js-cookie";

import {VirtualTable} from "@devexpress/dx-react-grid-bootstrap4";

import {HighlightedCell} from "../../theme-sources/highlighted-cell";
import {Link} from "react-router-dom";
import {debounce} from "throttle-debounce";


class Cash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // date default is -7 days
            startDate: moment().subtract(7, "d"),
            endDate: moment(),
            perPage: 10,
            offset: 0,
            searchText: "",
            preset: "",
            focusedInput: false,
            dropdownOpen: false,
            cityDropdown: false,

            branch_id: 0,
            branch_name: "All branch",
            city_id: 0,
            city_name: "All Cities",
        };
        this.handleDateChange = this.handleDateChange.bind(this);
        this.renderDatePresets = this.renderDatePresets.bind(this);
        this.branchtoggle = this.branchtoggle.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleBranchChange = this.handleBranchChange.bind(this);
        this.markPaymentCollected = this.markPaymentCollected.bind(this);
        this.updatePaymentMode = this.updatePaymentMode.bind(this);
        this.citytoggle = this.citytoggle.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);

        this.autocompleteSearchDebounced = debounce(700, this.autocompleteSearch);


        const {
            startDate,
            endDate,
            perPage,
            offset,
            searchText,
            branch_id,
        } = this.state;

        let default_params = {
            from_date: startDate.format("YYYY-MM-DD"),
            to_date: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            branch_id: branch_id,
        };

        this.props.getPaymentModes();
        // this.props.getCashList(default_params);
        // console.log(default_params);
    }

    citytoggle() {
        this.setState(prevState => ({
            cityDropdown: !prevState.cityDropdown
        }));
    }


    branchtoggle() {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }

    changeQuery = event => {
        this.setState({q: event.target.value}, () => {
            this.autocompleteSearchDebounced(this.state.q);
        });
    };

    componentDidMount() {
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let firstCityName = branch_list[0].city_name;
                let firstCityId = branch_list[0].city_id;
                this.handleCityChange(firstCityName, firstCityId);
            }
        }
    }

    autocompleteSearch = query => {
        this.handleSearch(query);
    };

    componentWillReceiveProps(nextProps) {
        if (
            this.props.loggedInUser &&
            this.props.loggedInUser.branch_list != nextProps.loggedInUser.branch_list
        ) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let firstCityName = branch_list[0].city_name;
                let firstCityId = branch_list[0].city_id;
                this.handleCityChange(firstCityName, firstCityId);
            }
        }
    }

    handleCityChange(city_name, city_id) {
        this.setState({city_id: city_id, city_name: city_name}, () => {
            this.props.loggedInUser.branch_list.map((value) => {
                if (city_id === value.city_id)
                    this.handleBranchChange(value.branch_list[0].branch_id, value.branch_list[0].branch_name);
            });
        });
    }

    handleBranchChange(branch_id, branch_name) {
        const {startDate, endDate, perPage, offset, searchText,city_id} = this.state;
        this.setState({branch_id: branch_id, branch_name: branch_name});
        // console.log("the state before changin ", this.state);
        let params = {
            branch_id: branch_id,
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id:city_id
        };
        // console.log("the state before changin ", params);
        this.props.getCashList(params);
    }

    handlePagination(offset) {
        const {branch_id, startDate, endDate, searchText, perPage,city_id} = this.state;
        this.setState({offset: offset});
        // console.log("offset", offset);
        let params = {
            branch_id: branch_id,
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: offset,
            perPage: perPage,
            sort: "ASC",
            city_id:city_id
        };
        this.props.getCashList(params);
    }

    handleSearch(searchText) {
        const {startDate, endDate, offset, perPage, branch_id,city_id} = this.state;
        this.setState({searchText: searchText});
        let params = {
            branch_id: branch_id,
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id:city_id
        };
        this.props.getCashList(params);
    }

    handleDateChange(startDate, endDate) {
        const {date_type, perPage, offset, searchText, branch_id,city_id} = this.state;
        this.setState({startDate: startDate, endDate: endDate});
        // console.log("order status ", this.state.orderStatus);
        let params = {
            branch_id: branch_id,
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id:city_id
        };
        this.props.getCashList(params);
    }

    markPaymentCollected(billId) {
        const {
            startDate,
            endDate,
            perPage,
            offset,
            searchText,
            branch_id,
        } = this.state;
        let params = {
            branch_id: branch_id,
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
        };
        this.props.markPaymentCollected(billId, params);
    }

    updatePaymentMode(paymentModeId, billId) {
        const {startDate, endDate, perPage, searchText, branch_id} = this.state;
        let params = {
            branch_id: branch_id,
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
        };
        this.props.updatePaymentMode({paymentModeId, billId, params});
    }

    renderDatePresets() {
        return (
            <div className="date_custom_presets">
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment(), moment());
                    }}
                >
                    Today
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().subtract(1, "d"), moment().subtract(1, "d"));
                        this.setState({focusedInput: false});
                    }}
                >
                    Yesterday
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().subtract(7, "d"), moment());
                        this.setState({focusedInput: false});
                    }}
                >
                    Last 7 days
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().subtract(30, "d"), moment());
                        this.setState({focusedInput: false});
                    }}
                >
                    Last 30 days
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(moment().startOf("month"), moment());
                        this.setState({focusedInput: false});
                    }}
                >
                    This month
                </Button>
                <Button
                    className="w-100  mb-2"
                    color="info"
                    onClick={() => {
                        this.handleDateChange(
                            moment().subtract(1, "months").startOf("month"),
                            moment().subtract(1, "months").endOf("month")
                        );
                        this.setState({focusedInput: false});
                    }}
                >
                    Last month
                </Button>
                <Button className="w-100  mb-2" color="info">
                    Custom Range
                </Button>
            </div>
        );
    }

    render() {
        const Cell = (props) => {
            const {column} = props;

            // if (column.title === 'Share_Category') {
            //     return <ProgressBarCell {...props} />;
            // }
            if (column.name === "data[1100]") {
                return <HighlightedCell {...props} />;
            }
            return <VirtualTable.Cell {...props} />;
        };

        const getRowId = (row) => row.id;
        const columns = [
            {name: "payment_mode_value", title: "Payment Mode"},
            {name: "bill_count", title: "Bill Count"},
            {name: "total", title: "Total"},
        ];
        // console.log("row", rows);

        // break_up
        const mydate = this.props.cashSummary;
        // console.log(mydate, "mydata");
        const rows = mydate;
        // console.log(rows, "rows")
        // const rows = {
        //     columnValues: { id: ({ index }) => index, ...this.props.Analytics.data },
        //     length: 8,
        // };
        const tableColumnExtensions = [
            // { columnName: 'data[1]', align: 'right' },
            {columnName: "total", align: "right"},
            {columnName: "bill_count", align: "right"},
            // { columnName: 'units', align: 'right' },
        ];

        const currencyColumns = ["total", "bill_count"];
        const percentColumns = ["%_share_cat"];
        const booleanColumns = ["shipped"];
        const [leftColumns] = ["product_name"];
        const totalSummaryItems = [
            {columnName: "bill_count", type: "count"},
            {columnName: "bill_count", type: "sum"},
            {columnName: "total", type: "sum"},
            {columnName: "total", type: "max"},
            {columnName: "total", type: "avg"},
            {columnName: "total", type: "min"},
            {columnName: "total", type: "count"},
        ];
        const groupSummaryItems = [];
        const {
            branch_name,
            branch_id,
            userType,
            searchText,
            perPage,
            startDate,
            endDate,
            city_id, city_name
        } = this.state;

        let city_list = [];
        let branch_list = [];
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {

            this.props.loggedInUser.branch_list.map((item) => {
                if (city_id === item.city_id)
                    branch_list = item.branch_list;
                city_list.push(item);
            });
        }
        return (
            <div className="animated fadeIn">
                <div className="ml-2 mr-2 mt-2 cash p-3">
                    <Row className="">
                        <Col md="3" lg="2" className="mb-3">
                            <Dropdown
                                isOpen={this.state.cityDropdown}
                                toggle={this.citytoggle}
                                className="place-order w-100 mb-2"
                            >
                                <DropdownToggle caret className="w-100" color="primary">
                                    {city_name}
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    {city_list &&
                                    city_list.map((row, index) => {
                                        return (
                                            <DropdownItem
                                                onClick={() => {
                                                    this.handleCityChange(
                                                        row.city_name,
                                                        row.city_id
                                                    );
                                                }}
                                            >
                                                {row.city_name}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        <Col md="3" lg="2" className="mb-3">
                            <Dropdown
                                isOpen={this.state.dropdownOpen}
                                toggle={this.branchtoggle}
                                className="place-order w-100 mb-2"
                            >
                                <DropdownToggle caret className="w-100" color="primary">
                                    {branch_name}
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    {branch_list &&
                                    branch_list.map((row, index) => {
                                        return (
                                            <DropdownItem
                                                onClick={() => {
                                                    this.handleBranchChange(
                                                        row.branch_id,
                                                        row.branch_name
                                                    );
                                                }}
                                            >
                                                {row.branch_name}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        <Col md="4" sm="6" className="mb-3">
                            <DateRangePicker
                                calendarInfoPosition="before"
                                renderCalendarInfo={this.renderDatePresets}
                                startDate={startDate}
                                startDateId="FromstartDate"
                                endDate={endDate}
                                endDateId="FromendDate"
                                onDatesChange={({startDate, endDate}) =>
                                    this.handleDateChange(startDate, endDate)
                                }
                                focusedInput={this.state.focusedInput}
                                onFocusChange={(focusedInput) =>
                                    this.setState({focusedInput})
                                }
                                orientation={this.state.orientation}
                                openDirection={this.state.openDirection}
                                isOutsideRange={() => false}
                                anchorDirection={"left"}
                            />
                        </Col>
                        <Col md="3" lg="2">
                            <a
                                target="_blank"
                                href={`${
                                    config.baseUrl
                                }admin/exportDeliveryReport?branch_id=${branch_id}&city_id=${city_id}&from_date=${startDate.format(
                                    "YYYY-MM-DD"
                                )}&to_date=${endDate.format(
                                    "YYYY-MM-DD"
                                )}&access_token=${`${Cookies.get("token_dashboard")}`}`}
                            >
                                <Button className="w-100  mb-2" color="primary">
                                    Download
                                </Button>
                            </a>
                        </Col>
                        <Col md="3" lg="2">
                            <Link to={`/print-settlement/${branch_id}`} target="_blank"
                                  className="btn btn-secondary ml-3 action">
                                <i className="fa fa-print"/> Settlement Report
                            </Link>
                        </Col>
                    </Row>

                    <Row className="mb-3 mt-3 cash-summary">
                        <Col sm="3" className="text-center mb-3">
                            <div className="bg-grey p-4 ">
                <span className="summary-title">
                  {formatNumbersWithThousandSeparator(
                      Math.round(this.props.cashList.iTotalRecords)
                  ) || 0}
                </span>
                                <br/>
                                Orders
                            </div>
                        </Col>
                        <Col sm="3" className="text-center mb-3">
                            <div className="bg-grey p-4 ">
                <span className="summary-title">
                  {formatNumbersWithThousandSeparator(
                      Math.round(this.props.cashList.pod && this.props.cashList.pod.collected_amount)
                  ) || 0}
                </span>
                                <br/>
                                Collected Amount
                            </div>
                        </Col>
                        <Col sm="3" className="text-center mb-3">
                            <div className="bg-grey p-4 ">
                <span className="summary-title">
                  {formatNumbersWithThousandSeparator(
                      Math.round(this.props.cashList.pod && this.props.cashList.pod.uncollected_amount)
                  ) || 0}
                </span>
                                <br/>
                                Uncollected Amount
                            </div>
                        </Col>
                        <Col sm="3" className="text-center mb-3">
                            <div className="bg-grey p-4 ">
                <span className="summary-title">
                  {formatNumbersWithThousandSeparator(
                      Math.round(this.props.cashList.pod && this.props.cashList.pod.banking_amount)
                  ) || 0}
                </span>
                                <br/>
                                Banking Amount (POD)
                            </div>
                        </Col>
                        {/*{this.props.cashList.total_value && (*/}
                        {/*  <Col sm="6" className="text-center">*/}
                        {/*    <div className="bg-grey p-4">*/}
                        {/*      <span className="summary-title">*/}
                        {/*        {" "}*/}
                        {/*        {formatNumbersWithThousandSeparator(*/}
                        {/*          Math.round(this.props.cashList.total_value.total) || 0*/}
                        {/*        )}*/}
                        {/*      </span>*/}
                        {/*      <br />*/}
                        {/*      Total Cash to be collected*/}
                        {/*    </div>*/}
                        {/*  </Col>*/}
                        {/*)}*/}
                    </Row>
                    <Row>
                        <Col>
                            {/*<h4>Field Cash Summary</h4>*/}
                            {/*<CustomersSummaryTable*/}
                            {/*orders={this.props.cashSummary}*/}
                            {/*totalData={this.props.cashSummary.iTotalRecords}*/}
                            {/*handlePagination={this.handlePagination}*/}
                            {/*markPaymentCollected={this.markPaymentCollected}*/}
                            {/*/>*/}
                        </Col>
                    </Row>
                    <Row className="">
                        <Col
                            sm={{size: "6", offset: 6}}
                            md={{size: "3", offset: 9}}
                            className="float-right p-0 pr-2 border-0"
                        >
                            <InputGroup className="mb-3 w-100">
                                <Input
                                    type="text"
                                    placeholder="Search"
                                    autoComplete="searchText"
                                    className="w-100"
                                    onChange={this.changeQuery}
                                    value={this.state.q}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    {this.props.cashList && this.props.cashList.aaData && (
                        <CashTable
                            orders={this.props.cashList.aaData}
                            totalData={this.props.cashList.iTotalDisplayRecords}
                            paymentModes={this.props.paymentModes}
                            handlePagination={this.handlePagination}
                            markPaymentCollected={this.markPaymentCollected}
                            updatePaymentMode={this.updatePaymentMode}
                            refreshCashList={this.refreshCashList}
                        />
                    )}
                </div>
            </div>
        );
    }
}

function bindActions(dispatch) {
    return {
        getCashList: (params) => dispatch(getCashList(params)),
        markPaymentCollected: (billId, params) =>
            dispatch(markPaymentCollected(billId, params)),
        updatePaymentMode: (paymentModeId, billId) =>
            dispatch(updatePaymentMode(paymentModeId, billId)),
        getPaymentModes: (params) => dispatch(getPaymentModes(params)),
    };
}

function mapStateToProps(state) {
    return {
        cashList: state.cash.cashList,
        cashSummary: state.cash.cashSummary,
        paymentModes: state.cash.paymentModes,
        loggedInUser: state.commonData.loggedInUser,
    };
}

export default connect(mapStateToProps, bindActions)(Cash);
