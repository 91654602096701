import config from "../config";
import { getToken, setToken, clearToken } from "../utils";
import { setMessage, setMessageType, setAuthToken } from "./commonData";
import { isEmpty } from "lodash";
import axios from "axios";
import { func } from "prop-types";
import moment from "moment";
import { setSpinner } from "./commonData";

export const SET_WALLET_LIST = "SET_WALLET_LIST";
export const SET_WALLET_DATA = "SET_WALLET_DATA";
export const SET_WALLET_REQUEST_LIST = "SET_WALLET_REQUEST_LIST";
export const SET_WALLET_CONSTANTS = "SET_WALLET_CONSTANTS";

var axiosInstance = axios.create({
  baseURL: config.baseUrl,
  timeout: 150000,
  headers: {
    "Content-Type": "application/json",
    version: "2.0",
    Authorization: `Token=${getToken()}`
  }
});

function setWalletList(payload) {
  return { type: SET_WALLET_LIST, payload: payload };
}

function setWalletData(payload) {
  return { type: SET_WALLET_DATA, payload: payload };
}

function setWalletRequestList(payload) {
  return { type: SET_WALLET_REQUEST_LIST, payload: payload };
}

function setWalletsConstants(payload) {
  return { type: SET_WALLET_CONSTANTS, payload: payload };
}

export function getWalletData() {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(`wallets/loadHomePageData`)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setWalletData(response.data.data));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        console.log(err);
        dispatch(setMessage("Oops!! Something went wrong"));
        dispatch(setMessageType("danger"));
      });
  };
}

export function getWalletList(params) {
  let default_params = {
    branch_id: 0,
    from_date: moment()
      .subtract(7, "d")
      .format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    searchText: "",
    offset: 0,
    perPage: 10,
    sort: "ASC"
  };
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `wallets/loadWalletTransaction?transaction_type=${params.transaction_type}&length=${params.perPage}&start=${params.offset}&search=${params.searchText}&date_time=${params.from_date} to ${params.to_date}`
      )
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setWalletList(response.data.data));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        console.log(err);
        dispatch(setMessage("Oops!! Something went wrong"));
        dispatch(setMessageType("danger"));
      });
  };
}

export function addCredit(params) {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .post(`wallets/requestWalletsTransaction/`, params)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          console.log(params);
          dispatch(setMessage("Wallet Transaction Requested."));
          dispatch(setMessageType("success"));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        console.log(err);
        dispatch(setSpinner(false));
        if (err.response && err.response.data) {
          dispatch(setMessage(err.response.data.errors));
        } else {
          dispatch(setMessage("Oops!! Something went wrong"));
        }
        dispatch(setMessageType("danger"));
      });
  };
}

export function getWalletRequestList(params) {
  let default_params = {
    branch_id: 0,
    from_date: moment()
      .subtract(7, "d")
      .format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    searchText: "",
    offset: 0,
    perPage: 10,
    sort: "ASC"
  };
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `wallets/loadWalletRequests?transaction_type=${params.transaction_type}&length=${params.perPage}&start=${params.offset}&search=${params.searchText}&date_time=${params.from_date} to ${params.to_date}`
      )
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setWalletRequestList(response.data.data));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        console.log(err);
        dispatch(setMessage("Oops!! Something went wrong"));
        dispatch(setMessageType("danger"));
      });
  };
}

export function loadWalletsConstants() {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(`wallets/loadWalletsConstants`)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setWalletsConstants(response.data.data));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        console.log(err);
        dispatch(setMessage("Oops!! Something went wrong"));
        dispatch(setMessageType("danger"));
      });
  };
}

export function updateTransactionRequest(request_id, params, filterParams) {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .put(`wallets/updateTransactionRequest/${request_id}`, params)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          console.log(params);
          dispatch(getWalletRequestList(filterParams));
          dispatch(setMessage("Wallet Transaction Requested."));
          dispatch(setMessageType("success"));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        console.log(err);
        dispatch(setSpinner(false));
        if (err.response && err.response.data) {
          dispatch(setMessage(err.response.data.errors));
        } else {
          dispatch(setMessage("Oops!! Something went wrong"));
        }
        dispatch(setMessageType("danger"));
      });
  };
}
