import React, { Component, lazy, Suspense } from "react";
import {
  Col,
} from "reactstrap";


class SelectBranch extends Component {
  

  render() {
    return (
      <Col sm="12">
      <div className="alert alert-danger">
      <i class="fas fa-exclamation-triangle mr-2"></i>
      Please choose a branch.
      </div>
      </Col>
    );
  }
}

export default SelectBranch;
