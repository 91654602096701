import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import { Link } from "react-router-dom";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import {
  Container,
  Col,
  Row,
  Button,
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Input
} from "reactstrap";
import CustomersTable from "./CustomersTable";

import { getCustomersList, toggleSMSAction } from "../../actions/customers";
import config from "../../config";
import Cookies from "js-cookie";
import {debounce} from "throttle-debounce";

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // date default is -7 days
      startDate: moment().subtract(30, "d"),
      endDate: moment(),
      perPage: 10,
      offset: 0,
      searchText: "",
      preset: "",
      focusedInput: false,
      dropdownOpen: false,
      date_type: 1,
      date_type_name: "Ordered Date"
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.renderDatePresets = this.renderDatePresets.bind(this);
    this.date_typeToggle = this.date_typeToggle.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.toggleSMS = this.toggleSMS.bind(this);

    const {
      startDate,
      endDate,
      perPage,
      offset,
      searchText,
      date_type
    } = this.state;
    let default_params = {
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: 0,
      perPage: perPage,
      sort: "ASC",
      date_type: date_type
    };
    this.props.getCustomersList(default_params);
    console.log(default_params);
    this.autocompleteSearchDebounced = debounce(300, this.autocompleteSearch);
  }

  changeQuery = event => {
    this.setState({ q: event.target.value }, () => {
      this.autocompleteSearchDebounced(this.state.q);
    });
  };

  date_typeToggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  autocompleteSearch = query => {
    this.handleSearch(query);
  };

  handlePagination(offset) {
    const {
      date_type,
      startDate,
      endDate,
      orderStatus,
      searchText,
      perPage
    } = this.state;
    this.setState({ offset: offset });
    console.log("offset", offset);
    let params = {
      date_type: date_type,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: offset,
      perPage: perPage,
      sort: "ASC"
    };
    this.props.getCustomersList(params);
    console.log(this.state);
  }
  handleSearch(searchText) {
    const { startDate, endDate, offset, perPage, date_type } = this.state;
    this.setState({ searchText: searchText });
    console.log("searchText", searchText);
    let params = {
      date_type: date_type,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: offset,
      perPage: perPage,
      sort: "ASC"
    };
    this.props.getCustomersList(params);
    console.log(this.state);
  }
  handleDateChange(startDate, endDate) {
    const { date_type, perPage, offset, searchText } = this.state;
    this.setState({ startDate: startDate, endDate: endDate });
    console.log("order status ", this.state.orderStatus);
    let params = {
      date_type: date_type,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: 0,
      perPage: perPage,
      sort: "ASC"
    };
    this.props.getCustomersList(params);
    console.log(this.state);
  }

  handledate_typeChange(date_type, date_type_name) {
    const { perPage, offset, searchText, startDate, endDate } = this.state;
    this.setState({
      date_type: date_type,
      date_type_name: date_type_name,
      dropdownOpen: false
    });
    console.log("date_type ", date_type);
    let params = {
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: 0,
      perPage: perPage,
      sort: "ASC",
      date_type: date_type
    };
    this.props.getCustomersList(params);
    console.log(this.state);
  }

  toggleSMS(customer_id, sms_opt_in) {
    const {
      perPage,
      offset,
      searchText,
      startDate,
      endDate,
      date_type
    } = this.state;

    let params = {
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: 0,
      perPage: perPage,
      sort: "ASC",
      date_type: date_type
    };
    this.props.toggleSMSAction(customer_id, sms_opt_in, params);
  }
  renderDatePresets() {
    return (
      <div className="date_custom_presets">
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment(), moment());
          }}
        >
          Today
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().subtract(1, "d"), moment().subtract(1, "d"));
            this.setState({ focusedInput: false });
          }}
        >
          Yesterday
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().subtract(7, "d"), moment());
            this.setState({ focusedInput: false });
          }}
        >
          Last 7 days
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().subtract(30, "d"), moment());
            this.setState({ focusedInput: false });
          }}
        >
          Last 30 days
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().startOf("month"), moment());
            this.setState({ focusedInput: false });
          }}
        >
          This month
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(
              moment()
                .subtract(1, "months")
                .startOf("month"),
              moment()
                .subtract(1, "months")
                .endOf("month")
            );
            this.setState({ focusedInput: false });
          }}
        >
          Last month
        </Button>
        <Button className="w-100  mb-2" color="info">
          Custom Range
        </Button>
      </div>
    );
  }

  render() {
    const {
      startDate,
      endDate,
      searchText,
      date_type,
      date_type_name
    } = this.state;

    return (
      <div className="animated fadeIn dashboard">
        <div className="ml-2 mr-2 mt-2 customers p-3">
          <Row>
            <Col md="2" sm="3" className="">
              <Dropdown
                isOpen={this.state.dropdownOpen}
                toggle={this.date_typeToggle}
                className="mb-2 w-100"
              >
                <DropdownToggle caret className="w-100" color="primary">
                  {date_type_name}
                </DropdownToggle>
                <DropdownMenu className="w-100">
                  <DropdownItem
                    onClick={() => {
                      this.handledate_typeChange(1, "Ordered Date");
                      this.date_typeToggle();
                    }}
                  >
                    Ordered Date
                    </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      this.handledate_typeChange(2, "Sign Up Date");
                      this.date_typeToggle();
                    }}
                  >
                    Sign Up Date
                    </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
            <Col md="4" sm="6" className="mb-3">
              <DateRangePicker
                calendarInfoPosition="before"
                renderCalendarInfo={this.renderDatePresets}
                startDate={startDate}
                startDateId="FromstartDate"
                endDate={endDate}
                endDateId="FromendDate"
                onDatesChange={({ startDate, endDate }) =>
                  this.handleDateChange(startDate, endDate)
                }
                focusedInput={this.state.focusedInput}
                onFocusChange={focusedInput =>
                  this.setState({ focusedInput })
                }
                orientation={this.state.orientation}
                openDirection={this.state.openDirection}
                isOutsideRange={() => false}
                anchorDirection={"left"}
              />
            </Col>
            <Col md="2" sm="3" className="">
              <a
                href={`${config.baseUrl}customers/downloadCustomerList?from_date=${startDate.format(
                  "YYYY-MM-DD"
                )}&to_date=${endDate.format(
                  "YYYY-MM-DD"
                )}&date_type=${date_type}&access_token=${`${Cookies.get('token_dashboard')}`}`}
                target="_blank"
              >
                <Button className="w-100  mb-2" color="primary">
                  Download
                  </Button>
              </a>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: '6', offset: 6 }} md={{ size: '3', offset: 9 }} className="float-right p-0 pr-2 border-0">
              <InputGroup className="mb-3 w-100">
                <Input
                  type="text"
                  placeholder="Search"
                  autoComplete="searchText"
                  className="w-100"
                  onChange={this.changeQuery}
                  value={this.state.q}
                />
              </InputGroup>
            </Col>
          </Row>

          {this.props.customersList && this.props.customersList.aaData && (
            <CustomersTable
              orders={this.props.customersList.aaData}
              totalData={this.props.customersList.iTotalDisplayRecords}
              handlePagination={this.handlePagination}
              toggleSMS={this.toggleSMS}
            />
          )}
        </div>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    getCustomersList: params => dispatch(getCustomersList(params)),
    toggleSMSAction: (customer_id, sms_opt_in, listParams) =>
      dispatch(toggleSMSAction(customer_id, sms_opt_in, listParams))
  };
}

function mapStateToProps(state) {
  return {
    customersList: state.customers.customersList
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(Customers);
