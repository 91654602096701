import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Button,
    Col,
    Badge,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";
import TodayTable from "./PreOrderTable";

import {
    getLivePreOrderOrdersList,
    getLiveOrderMeta,
} from "../../actions/orders";

class PreOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // date default is -7 days
            orderStatus: 0, // default all orders
            perPage: 10,
            offset: 0,
            searchText: "",
            dropdownOpen: false,
            citydropdownOpen: false,
            branch_id: 0,
            branch_name: "All branch",
            city_name: "All Cities",
            city_id: "0"
        };
        this.filterOrders = this.filterOrders.bind(this);
        this.branchtoggle = this.branchtoggle.bind(this);
        this.handleBranchChange = this.handleBranchChange.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.citytoggle = this.citytoggle.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);


        const { branch_id, orderStatus, perPage, offset, searchText,city_id } = this.state;
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id:city_id
        };
        this.props.getLivePreOrderOrdersList(params);
        this.props.getLiveOrderMeta();
    }

      componentDidMount(){
          if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
              let branch_list = [];
              branch_list = this.props.loggedInUser.branch_list;

              if (branch_list.length > 0) {
                  let firstCityName = branch_list[0].city_name;
                  let firstCityId = branch_list[0].city_id;
                  this.handleCityChange(firstCityName, firstCityId);
              }
          }
      }

    branchtoggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    citytoggle() {
        this.setState(prevState => ({
            citydropdownOpen: !prevState.citydropdownOpen
        }));
    }

    filterOrders(bill_status) {
        const { branch_id, perPage, searchText,city_id } = this.state;
        this.setState({ orderStatus: bill_status });
        let params = {
            branch_id: branch_id,
            status: bill_status,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id:city_id
        };
        this.props.getLivePreOrderOrdersList(params);
    }

    handleCityChange(city_name, city_id) {
        this.setState({city_id: city_id, city_name: city_name},()=>{
            this.props.loggedInUser.branch_list.map((value) => {
                if (city_id === value.city_id)
                    this.handleBranchChange(value.branch_list[0].branch_id, value.branch_list[0].branch_name);
            });
        });
    }

    handleBranchChange(branch_id, branch_name) {
        const { orderStatus, perPage, searchText,city_id } = this.state;
        this.setState({ branch_id: branch_id, branch_name: branch_name });
        console.log("branch id ", branch_id);
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id:city_id
        };
        this.props.getLivePreOrderOrdersList(params);
    }

    handleSearch(searchText) {
        const { branch_id, perPage, bill_status,city_id } = this.state;
        this.setState({ searchText: searchText });
        let params = {
            branch_id: branch_id,
            status: bill_status,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC",
            city_id:city_id
        };
        this.props.getLivePreOrderOrdersList(params);
    }

    handlePagination(offset) {
        const { orderStatus, perPage, branch_id, searchText,city_id } = this.state;
        this.setState({ offset: offset });
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            searchText: searchText,
            offset: offset,
            perPage: perPage,
            sort: "ASC",
            city_id:city_id
        };

        this.props.getLivePreOrderOrdersList(params);
    }

    render() {
        const {
            orderStatus,
            perPage,
            branch_id,
            searchText,
            offset,
            branch_name,
            city_id,
            city_name
        } = this.state;
        let products = [];

        if (
            this.props.liveOrders &&
            this.props.liveOrders.aaData &&
            this.props.liveOrders.aaData.length > 0
        ) {
            products = this.props.liveOrders.aaData;
        }
        let columns = [
            {
                dataField: "bill_id",
                text: "Product ID",
                formatter: this.orderFormatter
            }
        ];
        let orderStatusList = this.props.liveOrders.order_meta;
        let statusList = {
            ORDER_TEMP: "Temp",
            ORDER_ONLINE_PAYMENT_PROCESSING: "Payment Pending",
            ORDER_PLACED: "New Order",
            ORDER_ACCEPTED: "In Process",
            ORDER_PACKED: "Ready",
            ORDER_ASSIGNED: "Assigned",
            ORDER_DISPATCHED: "Dispatched",
            ORDER_DELIVERED: "Delivered",
            ORDER_REJECTED: "Rejected",
            ORDER_CANCELLED: "Canceled"
        };

        let city_list = [];
        let branch_list = [];
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {

            this.props.loggedInUser.branch_list.map((item)=>{
                if(city_id===item.city_id)
                    branch_list = item.branch_list;
                city_list.push(item);
            });
        }
        let all_branch_outline = true;
        if (orderStatus === 0) {
            all_branch_outline = false;
        }

        let filterParams = {
            orderStatus: orderStatus,
            perPage: perPage,
            branch_id: branch_id,
            searchText: searchText,
            offset: offset,
            city_id:city_id
        };

        return (
            <div className="animated fadeIn">
                <Row className="ml-2 mr-2 mt-2 today">
                    {/* <Col sm="4" md="3" lg="2" className="p-md-3 p-2 today-side">

                        <hr />
                        <label className="filter-label">STATUS</label>
                        <Button
                            color="primary"
                            className="w-100 text-left orderStatusBtn"
                            outline={all_branch_outline}
                            onClick={() => {
                                this.filterOrders(0);
                            }}
                        >
                            All Orders
                            <Badge className="float-right ml-1 p-1 orderCount bg-white">
                                {this.props.liveOrders && this.props.liveOrders.iTotalRecords}
                            </Badge>
                        </Button>
                        {orderStatusList &&
                            orderStatusList.length > 0 &&
                            orderStatusList.map((element, index) => {
                                console.log(element);
                                let outline =
                                    element.bill_status !== this.state.orderStatus ? true : false;
                                return (
                                    <Button
                                        color="primary"
                                        className="w-100 text-left orderStatusBtn"
                                        outline={outline}
                                        onClick={() => {
                                            console.log(element.bill_status);
                                            this.filterOrders(element.bill_status);
                                        }}
                                    >
                                        {statusList[element.order_status_value]}
                                        <Badge className="float-right ml-1 p-1 orderCount bg-white">
                                            {element.bill_count}
                                        </Badge>
                                    </Button>
                                );
                            })}
                    </Col> */}

                    <Col sm="12" className="today-main p-4">
                        <Row className="custom-table-no-border today-table">
                            <Col sm="12" className="p-0">
                                <Row>
                                    {/*<Col sm="9">*/}
                                    {/*<h1 className="text-danger">Escalations</h1>*/}
                                    {/*</Col>*/}
                                    <Col sm="6">
                                        <h4 className="order_count_4 pt-2">Pre Orders</h4>
                                    </Col>
                                    <Col sm="3">
                                        <Dropdown
                                            isOpen={this.state.citydropdownOpen}
                                            toggle={this.citytoggle}
                                            className="w-100 mb-2"
                                        >
                                            <DropdownToggle caret color="primary" className="w-100">
                                                {city_name}
                                            </DropdownToggle>
                                            <DropdownMenu className="w-100">
                                                {city_list &&
                                                    city_list.map((row, index) => {
                                                        return (
                                                            <DropdownItem
                                                                onClick={() => {
                                                                    this.handleCityChange(
                                                                        row.city_name,
                                                                        row.city_id
                                                                    );
                                                                }}
                                                            >
                                                                {row.city_name}
                                                            </DropdownItem>
                                                        );
                                                    })}
                                            </DropdownMenu>
                                        </Dropdown>
                                        <Dropdown
                                            isOpen={this.state.dropdownOpen}
                                            toggle={this.branchtoggle}
                                            className="w-100 mb-2"
                                        >
                                            <DropdownToggle caret color="primary" className="w-100">
                                                {branch_name}
                                            </DropdownToggle>
                                            <DropdownMenu className="w-100">
                                                {branch_list &&
                                                branch_list.map((row, index) => {
                                                    return (
                                                        <DropdownItem
                                                            onClick={() => {
                                                                this.handleBranchChange(
                                                                    row.branch_id,
                                                                    row.branch_name
                                                                );
                                                            }}
                                                        >
                                                            {row.branch_name}
                                                        </DropdownItem>
                                                    );
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>
                                    <Col sm="3">
                                        <div className=" p-0 pr-2 border-0">
                                            <InputGroup className="mb-3 w-100">
                                                <Input
                                                    type="text"
                                                    placeholder="Search"
                                                    autoComplete="searchText"
                                                    className="w-100"
                                                    onChange={e => {
                                                        this.handleSearch(e.target.value);
                                                    }}
                                                    value={searchText}
                                                />
                                            </InputGroup>
                                        </div>

                                    </Col>
                                </Row>
                                <Col sm="3" className="w-100 p-0">
                                    {/*vishwa change*/}
                                </Col>
                            </Col>
                            <Col sm="12" className="p-0">
                                <TodayTable
                                    orders={products}
                                    totalData={
                                        this.props.liveOrders && this.props.liveOrders.iTotalRecords
                                    }
                                    filterParams={filterParams}
                                    handlePagination={this.handlePagination}
                                // liveOrderMeta={this.props.liveOrderMeta}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

function bindActions(dispatch) {
    return {
        getLivePreOrderOrdersList: params => dispatch(getLivePreOrderOrdersList(params)),
        getLiveOrderMeta: () => dispatch(getLiveOrderMeta())
    };
}

function mapStateToProps(state) {
    return {
        liveOrders: state.orders.livePreOrderOrdersList,
        liveOrderMeta: state.orders.liveOrderMeta,
        loggedInUser: state.commonData.loggedInUser
    };
}

export default connect(
    mapStateToProps,
    bindActions
)(PreOrders);
