import config from "../config";
import { getToken } from "../utils";
import { setMessage, setMessageType } from "./commonData";
import axios from "axios";
import { setSpinner } from "./commonData";
import branch from "../reducers/branch";

export const SET_DELIVERY_CHARGE_LIST = "SET_DELIVERY_CHARGE_LIST";
export const SET_PACKING_CHARGE_LIST = "SET_PACKING_CHARGE_LIST";
export const SET_COUPONS_LIST = "SET_COUPONS_LIST";
export const SET_COUPON_TYPES = "SET_COUPON_TYPES";
export const SET_SERVICE_AREA = "SET_SERVICE_AREA";

var axiosInstance = axios.create({
  baseURL: config.baseUrl,
  timeout: 150000,
  headers: {
    "Content-Type": "application/json",
    version: "2.0",
    Authorization: `Token=${getToken()}`
  }
});

function setDeliveryChargeList(payload) {
  return {
    type: SET_DELIVERY_CHARGE_LIST,
    payload: payload
  };
}

function setPackingChargeList(payload) {
  return {
    type: SET_PACKING_CHARGE_LIST,
    payload: payload
  };
}

function setCouponsList(payload) {
  return {
    type: SET_COUPONS_LIST,
    payload: payload
  };
}

function setCouponTypes(payload) {
  return {
    type: SET_COUPON_TYPES,
    payload: payload
  };
}

function setServiceArea(payload) {
  return {
    type: SET_SERVICE_AREA,
    payload: payload
  };
}

export function getDeliveryChargeList(branch_id) {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(`manage_branch/getBranchDeliveryCharges/${branch_id}`)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setDeliveryChargeList(response.data.data));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        if (err.response && err.response.data) {
          dispatch(setMessage(err.response.data.errors));
        } else {
          dispatch(setMessage("Oops!! Something went wrong"));
        }

        dispatch(setMessageType("danger"));
      });
  };
}

export function addDeliveryCharge(params) {
  return function(dispatch) {
    // if (isEmpty(status) && isEmpty(order_id)) {
    //   dispatch(setMessage("Invalid Params"));
    //   dispatch(setMessageType("danger"));
    //   return;
    // }
    dispatch(setSpinner(true));
    axiosInstance
      .post(`manage_branch/add_del_charge`, {
        min_bill: parseInt(params.minBillAmt),
        max_bill: parseInt(params.maxBillAmt),
        charge: parseInt(params.deliveryCharge),
        branch_id: parseInt(params.branch_id)
      })
      .then(response => {
        dispatch(setSpinner(false));
        // console.log("responseeeeee", response)
        if (response.data) {
          dispatch(getDeliveryChargeList(params.branch_id));
          dispatch(setMessage("Delivery charge successfully added"));
          dispatch(setMessageType("success"));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        if (err.response && err.response.data) {
          dispatch(setMessage(err.response.data.errors));
        } else {
          dispatch(setMessage("Oops!! Something went wrong"));
        }

        dispatch(setMessageType("danger"));
      });
  };
}

export function getPackingChargeList(branch_id) {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(`manage_branch/getBranchPackingCharge/${branch_id}`)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setPackingChargeList(response.data.data));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        if (err.response && err.response.data) {
          dispatch(setMessage(err.response.data.errors));
        } else {
          dispatch(setMessage("Oops!! Something went wrong"));
        }

        dispatch(setMessageType("danger"));
      });
  };
}

export function addPackingCharge(params) {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .post(`manage_branch/add_packing_charges`, {
        packing_charge_type: params.packingType,
        value: params.amount,
        branch_id: parseInt(params.branch_id)
      })
      .then(response => {
        dispatch(setSpinner(false));
        // console.log("responseeeeee", response)
        if (response.data) {
          dispatch(getPackingChargeList(params.branch_id));
          dispatch(setMessage("Delivery charge successfully added"));
          dispatch(setMessageType("success"));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        if (err.response && err.response.data) {
          dispatch(setMessage(err.response.data.errors));
        } else {
          dispatch(setMessage("Oops!! Something went wrong"));
        }

        dispatch(setMessageType("danger"));
      });
  };
}

export function getCouponsList(branch_id) {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(`manage_branch/listBranchCoupons?branch_id=${branch_id}`)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setCouponsList(response.data.data));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        if (err.response && err.response.data) {
          dispatch(setMessage(err.response.data.errors));
        } else {
          dispatch(setMessage("Oops!! Something went wrong"));
        }

        dispatch(setMessageType("danger"));
      });
  };
}

export function getCouponTypes() {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(`manage_branch/listCouponTypes`)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setCouponTypes(response.data.data));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        if (err.response && err.response.data) {
          dispatch(setMessage(err.response.data.errors));
        } else {
          dispatch(setMessage("Oops!! Something went wrong"));
        }

        dispatch(setMessageType("danger"));
      });
  };
}

export function addCoupon(params) {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .post(`manage_branch/addBranchCoupon`, params)
      .then(response => {
        dispatch(setSpinner(false));
        // console.log("responseeeeee", response)
        if (response.data) {
          dispatch(getCouponsList(params.branch_id));
          dispatch(setMessage("Coupon successfully created"));
          dispatch(setMessageType("success"));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        if (err.response && err.response.data) {
          dispatch(setMessage(err.response.data.errors));
        } else {
          dispatch(setMessage("Oops!! Something went wrong"));
        }

        dispatch(setMessageType("danger"));
      });
  };
}

export function getServiceArea(branch_id) {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(`/manage_branch/getBranchServingArea/${branch_id}`)
      .then(response => {
        dispatch(setSpinner(false));
        // console.log("response1",response);
        if (response.data) {
          dispatch(setServiceArea(response.data.data));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        if (err.response && err.response.data) {
          dispatch(setMessage(err.response.data.errors));
        } else {
          dispatch(setMessage("Oops!! Something went wrong"));
        }

        dispatch(setMessageType("danger"));
      });
  };
}

export function addServiceArea(params) {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .post(`/manage_branch/addBranchServingArea`, params)
      .then(response => {
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(getServiceArea(params.branch_id));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        if (err.response && err.response.data) {
          dispatch(setMessage(err.response.data.errors));
        } else {
          dispatch(setMessage("Oops!! Something went wrong"));
        }

        dispatch(setMessageType("danger"));
      });
  };
}

export function createNewBranch(params) {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .post(`/manage_branch/createNewBranch`, {
        branch_name: params.branch_name,
        branch_phone: params.branch_phone,
        branch_address: params.branch_address
      })
      .then(response => {
        dispatch(setSpinner(false));
        if (response.data) {
          updateBranchLocation(response.data.branch_id, params);
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        if (err.response && err.response.data) {
          dispatch(setMessage(err.response.data.errors));
        } else {
          dispatch(setMessage("Oops!! Something went wrong"));
        }
        dispatch(setMessageType("danger"));
      });
  };
}

export function updateBranchLocation(branch_id, params) {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .post(`/manage_branch/createNewBranch`, {
        lat: params.lat,
        lng: params.lng,
        branch_id: branch_id
      })
      .then(response => {
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(getServiceArea(params.branch_id));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        if (err.response && err.response.data) {
          dispatch(setMessage(err.response.data.errors));
        } else {
          dispatch(setMessage("Oops!! Something went wrong"));
        }
        dispatch(setMessageType("danger"));
      });
  };
}
