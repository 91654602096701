import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Col, FormGroup, Input } from "reactstrap";

import Map from "./CreateBranchMap";

import { createNewBranch } from "../../actions/branch";

class CreateBranch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branch_name: "",
      branch_phone: "",
      branch_email: "",
      branch_address: "",
      lat: "",
      lng: ""
    };
    this.handleMapChange = this.handleMapChange.bind(this);
    this.createBranch = this.createBranch.bind(this);
  }

  handleMapChange(latValue, lngValue, address) {
    this.setState({
      branch_address: address,
      lat: latValue,
      lng: lngValue
    });
  }

  createBranch() {
    const {
      branch_name,
      branch_phone,
      branch_email,
      branch_address,
      lat,
      lng
    } = this.state;

    let params = {
      branch_name,
      branch_phone,
      branch_address,
      branch_email,
      lat: lat,
      lng: lng
    };

    this.props.createNewBranch(params);
  }

  render() {
    const {
      branch_name,
      branch_phone,
      branch_email,
      branch_address,
      lat,
      lng
    } = this.state;
    return (
      <div className="animated fadeIn create-branch">
        <Col lg={{ size: '8', offset: 2 }}>
          <div className="mb-4">Create new Branch</div>
          <div className="mb-5">
            <Map
              // google={this.props.google}
              center={{ lat: 12.9716, lng: 77.5946 }}
              height="300px"
              zoom={15}
              handleMapChange={this.handleMapChange}
            />
          </div>
          <FormGroup>
            <Input
              type="text"
              className="mb-3"
              placeholder="Branch Name"
              autoComplete="branch_name"
              onChange={e => {
                this.setState({ branch_name: e.target.value });
              }}
              value={branch_name}
            />
            <Input
              type="text"
              className="mb-3"
              placeholder="Branch Phone"
              autoComplete="branch_phone"
              onChange={e => {
                this.setState({ branch_phone: e.target.value });
              }}
              value={branch_phone}
            />
            <Input
              type="text"
              className="mb-3"
              placeholder="Branch Email"
              autoComplete="branch_email"
              onChange={e => {
                this.setState({ branch_email: e.target.value });
              }}
              value={branch_email}
            />

            <Input
              type="textarea"
              className="mb-3"
              placeholder="Branch Address"
              autoComplete="branch_address"
              onChange={e => {
                this.setState({ branch_address: e.target.value });
              }}
              value={branch_address}
            />
          </FormGroup>

          <Button
            color="primary"
            onClick={() => {
              this.createBranch();
            }}
          >
            Save Changes
          </Button>
        </Col>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    createNewBranch: params => dispatch(createNewBranch(params))
  };
}

function mapStateToProps(state) {
  return {};
}
export default connect(
  mapStateToProps,
  bindActions
)(CreateBranch);
