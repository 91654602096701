import React, { Component } from "react";
// import { BarChart, Bar } from "recharts";
import {
  BarChart, Bar, LabelList, ResponsiveContainer, Legend, Tooltip
} from 'recharts';
import Chart from "react-apexcharts";
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { Badge, Col } from "reactstrap";
import { formatNumbersWithThousandSeparator } from "../../utils";

function NumberFormat(props) {
  const { value, type } = props;
  // if (type == "currency") {
  //   return value.toLocaleString("en-IN", {
  //     style: "currency",
  //     currency: "INR"
  //   });
  // }

  return value;
}

class EachChart extends Component {
  render() {
    const renderCustomizedLabel = (props) => {
      const { x, y, width, height, value } = props;
      const radius = 10;

      return (
        <g>
          {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
          {/* <text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
            {value.split(' ')[1]}
          </text> */}
        </g>
      );
    };
    return (
      <Col className={this.props.data.className} sm="3">
        <Col md="12" className="chart-title">
          <div className="title">{this.props.data.name}</div>
          <div className="value">
            <NumberFormat
              value={formatNumbersWithThousandSeparator(this.props.data.today)}
              type={this.props.data.format}
            />
          </div>
        </Col>

        <div className="mb-4" style={{ width: '100%', height: 150 }}>
          <ResponsiveContainer>
            <BarChart
              className="mt-3 chart"
              data={[this.props.data]}
            >
              <Legend />
              {/* <Tooltip /> */}
              <Bar isAnimationActive={false} dataKey="today" fill={this.props.data.color} minPointSize={20}>
                <LabelList fill="#ffffff" backgroundFill="#000" dataKey="today" position="middle" />
              </Bar>
              <Bar isAnimationActive={false} dataKey="last week" fill="#8c9798" minPointSize={20}>
                <LabelList fill="#ffffff" dataKey="last week" position="middle"  />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Col>
    );
  }
}

export default EachChart;
