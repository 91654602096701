import React from "react";
import { compose, withProps, withState } from "recompose";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
  Polygon
} from "react-google-maps";
const {
  DrawingManager
} = require("react-google-maps/lib/components/drawing/DrawingManager");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const google = (window.google = window.google ? window.google : {});

const MyMapComponent = compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100vh` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withState('showInfoBox', 'setShowInfoBox',
      ({otherBranchesLocations}) => otherBranchesLocations.reduce((res,{name}) => {
          res[name] = false;
          return res;
      } ,{})
  ),
  withGoogleMap

)(({lat, lng, isMarkerShown, mapElement, onPolygonComplete, coords, otherBranches, otherBranchesLocations, showInfoBox, setShowInfoBox, referenceRadius }) => {

    const handleMouseOver = (name,value) => {
        setShowInfoBox({
            ...showInfoBox,
            [name]: value
        });
    };

    return (
        <GoogleMap
            defaultOptions={{
                streetViewControl: false,
            }}
            defaultZoom={12}
            defaultCenter={{lat,lng }}  >

            {isMarkerShown && <Marker position={{lat,lng}} />}
            <DrawingManager
                defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
                defaultOptions={{
                    drawingControl: true,
                    drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [google.maps.drawing.OverlayType.POLYGON]
                    },
                    map: mapElement,
                    circleOptions: {
                        fillColor: `#ffff00`,
                        fillOpacity: 1,
                        strokeWeight: 5,
                        clickable: false,
                        editable: true,
                        zIndex: 1000
                    }
                }}
                onPolygonComplete={onPolygonComplete}
            />

            <Polygon
                path={coords}
                options={{
                    fillColor: "#000",
                    fillOpacity: 0.4,
                    strokeColor: "#000",
                    strokeOpacity: 1,
                    strokeWeight: 1
                }}
            />

            <Circle
                center={{lat, lng}}
                radius={referenceRadius}
                options={{
                fillColor: "#f59f9f",
                fillOpacity: 0,
                strokeColor: "#000",
                strokeOpacity: 0.8,
                strokeWeight: 0.4
            }} />

            {otherBranches.length && (
                otherBranches.map((servingArea, index) =>
                    <Polygon
                        key={index}
                        path={servingArea}
                        options={{
                            fillColor: "#f59f9f",
                            fillOpacity: 0.4,
                            strokeColor: "#000",
                            strokeOpacity: 1,
                            strokeWeight: 1
                        }}
                    />
                )
            )}
            {otherBranchesLocations.length && (
                otherBranchesLocations.map(({lat,lng, name}, index) => {
                    return <Marker key={index} position={{lat,lng}}  onMouseOver={() => handleMouseOver(name, true)} onMouseOut={() => handleMouseOver(name, false)}>
                        {showInfoBox[name] && <InfoBox>
                            <div style={{ background:'#fff', opacity: 0.75, padding: `4px`, borderRadius: '5px' }}>
                                <div style={{ fontSize: `16px`, color: 'black' }}>
                                    {name}
                                </div>
                            </div>
                        </InfoBox>}
                    </Marker>
                    }
                )
            )
            }
        </GoogleMap>
    );
});

export default MyMapComponent;
