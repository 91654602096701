import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import config from "../../config";
import {
  Card,
  Button,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Media
} from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import {
  getProductList,
  listProductSuggestions,
  addSuggestion,
  getProductServingList,
  updateProductSuggestion
} from "../../actions/products";
import MultiSelect from "react-multi-select-component";

class ProductTabs extends React.Component {
  constructor(props) {
    super(props);
    let productData = this.props.productData;
    this.state = {
      product_name: 0,
      product_id: productData.product_id,
      suggestionModal: false,
      serving_size_list: [],
      selected_product: "",
      suggestion_price_id: "",
      selectBranchList:[]
    };

    this.toggleSuggestionModal = this.toggleSuggestionModal.bind(this);
    this.submitSuggestion = this.submitSuggestion.bind(this);
    this.deleteSuggestion = this.deleteSuggestion.bind(this);
    this.selectBranchList = this.selectBranchList.bind(this);

    this.props.getProductList();
  }

  componentDidUpdate(previousProps, previousState) {
    const { productData, branch_id } = this.props;
    if (
      productData.product_id !== previousProps.productData.product_id ||
      branch_id !== previousProps.branch_id
    ) {
      if (branch_id != 0 && productData.product_id) {
        this.props.listProductSuggestions(productData.product_id, branch_id);
      }
    }
  }

  selectBranchList(val) {
    this.setState(prevState => ({
      selectBranchList: val
    }));
  }

  toggleSuggestionModal() {
    this.setState(prevState => ({
      suggestionModal: !prevState.suggestionModal
    }));
  }
  submitSuggestion() {
    const { selected_product, serving_size_id, product_id } = this.state;
    const productData = this.props.productData;
    const branch_list = this.state.selectBranchList.map((val,index)=>{
      return val.value;
    });
    if (selected_product && serving_size_id && productData.product_id) {
      this.setState({
        suggestionModal: false,
          selectBranchList:[]
      });

      this.props.addSuggestion({
        product_id: productData.product_id,
        suggestion_price_id: serving_size_id,
        branch_id: this.props.branch_id,
        branch_ids:branch_list
      });
    } else {
      //raise error
    }
  }
  selectProduct(product) {
    product = product[0];
    if (product && product.product_id) {
      this.setState({
        selected_product: product.product_id,
        serving_size_id: ""
      });
      this.props.getProductServingList(
        product.product_id,
        this.props.branch_id
      );
    } else {
      this.setState({
        selected_product: "",
        serving_size_id: ""
      });
    }
  }

  deleteSuggestion(suggestion_id, status) {
    const { branch_id, productData } = this.props;
    let params = {
      suggestion_id,
      status
    };
    this.props.updateProductSuggestion(
      productData.product_id,
      branch_id,
      params
    );
  }

  render() {
    const { productServingList, suggestionList, currentProduct, productsList } = this.props;
    const productSuggestions = suggestionList && suggestionList.data;
    const {selectBranchList} = this.state;
    const allProducts = productsList.reduce((res, { products }) => {
      if(products && products.length) {
        products.forEach(product => {
          if(product.product_id !== currentProduct.product_id) {
            res.push(product)
          }
        });
        return res;
      }
    }, []);

    let branch_list = [];
    if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
      for (let j = 0; j < this.props.loggedInUser.branch_list.length; j++) {
        let item = this.props.loggedInUser.branch_list[j];
        if (j === 0) {
          for (let k = 0; k < item.branch_list.length; k++) {
            let branch = item.branch_list[k];
            if(branch.branch_id==0)
              continue;
            branch_list.push({label:branch.branch_name,value:branch.branch_id})
          }
          break;
        }
      }
    }

    const _productServingList = productServingList.filter(serving => serving.product_size_status >= 0);

    return (
      <Card body className="border-0 product-suggestions p-0">
        <CardTitle>Add ons</CardTitle>
        <Button
          color="primary"
          className="w-100"
          onClick={this.toggleSuggestionModal}
          outline
        >
          Add Suggestion
        </Button>
        <Modal
          isOpen={this.state.suggestionModal}
          fade={false}
          toggle={this.toggleSuggestionModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleSuggestionModal}>
            Add Product Suggestion
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Product</Label>
              <Typeahead
                onChange={selected => {
                  this.selectProduct(selected);
                }}
                labelKey="product_name"
                className="mb-3"
                options={allProducts}
              />
              <Label>Size</Label>
              <Input
                type="select"
                className="mb-3"
                name="category"
                id="category"
                onChange={e => {
                  this.setState({ serving_size_id: e.target.value });
                }}
              >
                <option value={""}>Choose a serving size</option>
                {_productServingList &&
                _productServingList.length > 0 &&
                _productServingList.map((row, index) => {
                    return (
                      <option value={row.product_price_id}>
                        {row.serving_size_name}
                      </option>
                    );
                  })}
              </Input>
              <div id="checkboxes">
                <label>Choose Branch</label>
                <MultiSelect
                    options={branch_list}
                    value={selectBranchList}
                    onChange={(val)=>this.selectBranchList(val)}
                />
              </div>
              <div className="alert alert-primary new-alert"><strong className="mr-1">Note: </strong>
                <span>If a product is part of suggestion group it'll be overwritten</span></div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              outline
              onClick={this.toggleSuggestionModal}
            >
              Cancel
            </Button>{" "}
            <Button
              color="primary"
              onClick={() => {
                this.submitSuggestion();
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>

        {/* Suggestions listings */}
        <Col sm="12" className="p-0 pt-3">
          {productSuggestions &&
            productSuggestions.map((product, index) => {
              return (
                <Media className="mt-1 product-suggestion p-2">
                  <div className="col-md-2">
                  <Media className="img-fluid product-image">
                    <Media
                      object
                      src={`${config.imgUrl}frontend/images/products/${product.product_id}.jpg`}
                      alt={product.product_name}
                      className="product-image mr-2 w-100"
                    />
                  </Media>
                  </div>
                  <div className="col-md-8">
                  <Link to={`/product/${product.product_id}/`} target="_blank">
                    <Media body className="ml-2 w-75 product-details">
                      <Media heading>{product.product_name}</Media>
                      {product.serving_size_name} -
                      <span className="ml-2">
                        {product.product_price}
                      </span>
                    </Media>
                  </Link>
                  </div>
                  <div className="div col-md-2">
                  <Media right middle className="delete p-4">
                    <span
                      className="fas fa-trash"
                      onClick={() => {
                        this.deleteSuggestion(product.id, 1);
                      }}
                    ></span>
                  </Media>
                  </div>
                </Media>
              );
            })}
        </Col>
      </Card>
    );
  }
}

function bindActions(dispatch) {
  return {
    getProductList: () => dispatch(getProductList()),
    listProductSuggestions: (product_id, branch_id) =>
      dispatch(listProductSuggestions(product_id, branch_id)),
    addSuggestion: params => dispatch(addSuggestion(params)),
    getProductServingList: (product_id, branch_id) =>
      dispatch(getProductServingList(product_id, branch_id)),
    updateProductSuggestion: (product_id, branch_id, params) =>
      dispatch(updateProductSuggestion(product_id, branch_id, params))
  };
}

function mapStateToProps(state) {
  return {
    currentProduct: state.products.currentProduct,
    productsList: state.products.productsList,
    suggestionList: state.products.suggestionList,
    productServingList: state.products.productServingList,
    loggedInUser: state.commonData.loggedInUser

  };
}
export default connect(
  mapStateToProps,
  bindActions
)(ProductTabs);
