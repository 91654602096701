import React, { Component, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import * as scroll from "react-scroll";

import { Col, Row, ListGroup, ListGroupItem, Media } from "reactstrap";
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable, Draggable } from "react-beautiful-dnd";

import NewProduct from "./NewProduct";
import NewCategory from "./NewCategory";

import {
  getProductList,
  getCategoryList,
  sortCategories
} from "../../actions/products";

class Products extends Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.state = {
      index: 0,
      categories: []
    };
    this.props.getCategoryList();

    this.onDragEnd = this.onDragEnd.bind(this);
    this.reorder = this.reorder.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { categories } = this.state;
    if (nextProps.categoryList.length != categories.length)
      this.setState({ categories: this.props.categoryList });
  }
  handleShow(i) {
    this.setState({ index: i });
    this.refs[i].scrollIntoView({ block: "end", behavior: "smooth" });
  }

  onDragEnd({ source, destination }) {
    if (!destination) {
      return;
    }

    const categories = this.reorder(
      this.state.categories,
      source.index,
      destination.index
    );

    this.setState({
      categories
    });

    const sortOrder = [];
    categories.map((row, index) => {
      sortOrder.push(parseInt(row.category_id));
    });
    // console.log(sortOrder);

    this.props.sortCategories(sortOrder);
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  render() {
    const { categories } = this.state;

    return (
      <Col lg="2" md="3" sm="4" className="p-2 products-side">
        <div className="product-side-sticky">
          <div className="sidebar-title new-sidebar-title">Categories</div>
          <Droppable droppableId={"categories"} isDropDisabled={false}>
            {provided => {
              return (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <ListGroup flush className="categories-list px-1">
                    {categories &&
                      categories.length > 0 &&
                      categories.map((row, index) => {
                        // return <ListGroupItem className="pl-1 "  onClick={this.handleShow(row.category_id)}>{row.category_name}</ListGroupItem>;
                        return (
                          <Draggable
                            key={row.category_name}
                            draggableId={row.category_name}
                            index={index}
                          >
                            {provided => {
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <ListGroupItem className="pl-1 new-list-border-0">
                                    <scroll.Link
                                      activeClass="active"
                                      to={`category-section-${row.category_id}`}
                                      spy={true}
                                      smooth={true}
                                      offset={-70}
                                      duration={500}
                                    >
                                      {row.category_name}
                                    </scroll.Link>
                                  </ListGroupItem>
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                  </ListGroup>
                </div>
              );
            }}
          </Droppable>
        </div>
      </Col>
    );
  }
}

function bindActions(dispatch) {
  return {
    getCategoryList: () => dispatch(getCategoryList()),
    sortCategories: order => dispatch(sortCategories(order))
  };
}

function mapStateToProps(state) {
  return {
    categoryList: state.products.categoryList
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(Products);
