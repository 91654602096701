import React, { Component } from "react";
import Slider from 'rc-slider/lib/Slider';
import 'rc-slider/assets/index.css';

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  Col,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import AddServiceAreaMap from "./AddServiceAreaMap";

import { getServiceArea, addServiceArea } from "../../actions/branch";

class AddServiceArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      branch_id: this.props.match.params.branchId,
      referenceRadius: 1000,
      paths: []
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.props.getServiceArea(this.state.branch_id);
    this.onPolygonComplete = this.onPolygonComplete.bind(this);
    this.confirmServiceArea = this.confirmServiceArea.bind(this);
    this.setReferenceRadius = this.setReferenceRadius.bind(this);
  }

  toggleModal() {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  }

  confirmServiceArea(path) {
    const { branch_id, paths } = this.state;
    let params = {
      polygon: {
        serving_area: paths
      },
      branch_id: branch_id
    };

    this.props.addServiceArea(params);
    this.setState({ showModal: false });
  }
  onPolygonComplete(poly) {
    this.setState({ showModal: true });
    const polyArray = poly.getPath().getArray();
    let paths = [];
    polyArray.forEach(function(path) {
      paths.push({ lat: path.lat().toFixed(6), lng: path.lng().toFixed(6) });
    });
    this.setState({ paths: paths });
  }

  setReferenceRadius(step) {
    this.setState({referenceRadius: step * 1000})
  }

  render() {
    const { serviceArea } = this.props;
    let coords = [];
    let lat = 0;
    let lng = 0;
    let otherBranchesServingArea = [];
    let otherBranchesLocations = [];
    const {other_branch} = serviceArea;
    if(other_branch) {
      otherBranchesServingArea = other_branch.map(({branch_serving_area:{ serving_area }}) => serving_area.map(coords => ({lat: +coords.lat, lng:+coords.lng })));
      otherBranchesLocations = other_branch.map(branch => ({lat: +branch.lat, lng: +branch.lng, name: branch.branch_name }) );
    }

    if (serviceArea && serviceArea.branch_serving_area) {
      coords = serviceArea.branch_serving_area.serving_area;
      // console.log(coords);
      lat = serviceArea.lat;
      lng = serviceArea.lng;
      coords.push(coords[0]);
      if (coords.length)
        coords.map((ll, index) => {
          Object.keys(ll).forEach(function(key) {
            ll[key] = Number(ll[key]);
          });
        });
    }

    return (
        <Row className="p-0 flex-row align-items-center">
          <Row className="flex-grow-1 align-items-center bg-white p-3">
            <Col sm="7" >
              <Link to="/branch/service_area" className="ml-4 mb-2 mt-2 d-block">
                <i className="fas fa-arrow-circle-left mr-2"/> Back to Manage Branch
              </Link>
            </Col>
            <Col sm="4">
              <Slider value={this.state.referenceRadius / 1000} min={0} max={15} onChange={this.setReferenceRadius} />
            </Col>
            <Col sm="1">
              <span>
                {this.state.referenceRadius / 1000} Km
              </span>
            </Col>
          </Row>

          <Col sm="12" className="pl-2 pr-2">
            {coords.length && (
                <AddServiceAreaMap
                    isMarkerShown={true}
                    coords={coords}
                    referenceRadius={this.state.referenceRadius}
                    otherBranches={otherBranchesServingArea}
                    otherBranchesLocations={otherBranchesLocations}
                    lat={lat}
                    lng={lng}
                    onPolygonComplete={this.onPolygonComplete}
                />
            )}
          </Col>
          <Modal
              isOpen={this.state.showModal}
              fade={false}
              toggle={this.toggleModal}
              className={this.props.className}
          >
            <ModalBody className="text-center">
              <h3>
                <i class="far fa-question-circle"/>
              </h3>
              <h4>Are you sure?</h4>
              <h5>This will be your service location</h5>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" outline onClick={this.toggleModal}>
                Cancel
              </Button>{" "}
              <Button
                  color="primary"
                  onClick={() => {
                    this.confirmServiceArea();
                  }}
              >
                Save Changes
              </Button>
            </ModalFooter>
          </Modal>
        </Row>
    );
  }
}

function bindActions(dispatch) {
  return {
    getServiceArea: branch_id => dispatch(getServiceArea(branch_id)),
    addServiceArea: params => dispatch(addServiceArea(params))
  };
}

function mapStateToProps(state) {
  return {
    serviceArea: state.branch.serviceArea
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(AddServiceArea);
