import React, { Component, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import * as scroll from "react-scroll";

import { Col, Row, ListGroup, ListGroupItem, Media } from "reactstrap";
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable, Draggable } from "react-beautiful-dnd";

import NewProduct from "./NewProduct";
import NewCategory from "./NewCategory";

import {
  getAnalyticsCategoryList,
  getCategoryList,
  sortCategories,
  sortProducts
} from "../../actions/products";
import Categories from "./AnalyticsCategories";

class ProductsAnalytics extends Component {

  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      categories: [],
      products: []
    };
    this.props.getAnalyticsCategoryList();
    this.props.getCategoryList();

    this.onDragEnd = this.onDragEnd.bind(this);
    this.reorder = this.reorder.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { categories, products } = this.state;
    if (nextProps.categoryList.length != categories.length)
      this.setState({ categories: this.props.categoryList });
    if (nextProps.productaAnalyticsList.length != products.length)
      this.setState({ products: this.props.productaAnalyticsList });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.categoryList != this.props.categoryList)
      this.setState({ categories: this.props.categoryList });
    if (prevProps.productaAnalyticsList != this.props.productaAnalyticsList)
      this.setState({ products: this.props.productaAnalyticsList });
    }

    onDragEnd({ source, destination }) {
    if (!destination) {
      return;
    }
    console.log(source, destination);
    if (destination && destination.droppableId == "categories") {
      const categories = this.reorder(
        this.state.categories,
        source.index,
        destination.index
      );

      this.setState({
        categories
      });

      const sortOrder = [];
      categories.map((row, index) => {
        sortOrder.push(parseInt(row.category_id));
      });
      console.log(sortOrder);

      this.props.sortCategories(sortOrder);
    } else {
      if (destination.droppableId != source.droppableId) {
        return;
      }
      let catId = destination.droppableId.replace("prodCat-", "");
      catId = parseInt(catId);
      console.log(catId);
      let prodList = [];
      prodList = this.state.products.find(function (element) {
        return element.category_id == catId;
      });
      if (!prodList.products.length) {
        return;
      }
      const products = this.reorder(
        prodList.products,
        source.index,
        destination.index
      );

      this.setState({
        products
      });

      const sortOrder = [];
      products.map((row, index) => {
        sortOrder.push(parseInt(row.product_id));
      });
      console.log(sortOrder);

      this.props.sortProducts(sortOrder);
    }
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  render() {
    let products = this.props.productaAnalyticsList;
    const { categories } = this.state;

                                            return (
      <div className="animated fadeIn">
          <Row className="ml-2 mr-2 mt-2 products">
            <Categories />
            <Col md="9" sm="8" lg="10" className="products-main p-3">
              <Row className="mb-4">
                <Col md="3" xs="6">
                  <NewCategory className="mb-2" categories={categories} />
                </Col>
                <Col md="3" xs="6">
                  <NewProduct categories={categories} />
                </Col>
              </Row>
              <Row className="w-100 each-product p-2 pt-3 pb-3 mb-2">

                <Col md="5">Product Name</Col>
                <Col md="2" className="my-auto d-none d-md-block">
                  Category
                </Col>
                <Col md="3" className="my-auto d-none d-md-block">
                  Description
                </Col>
                <Col md="2" className="my-auto d-none d-md-block">
                  Created At
                </Col>
              </Row>
              {products &&
                products.length > 0 &&
                products.map((row, index) => {
                  return (
                    <section id={`category-section-${row.category_name}`}>
                      <Row className="p-2" ref={row.category_name}>
                        <Col
                          sm="12"
                          className="product-category"
                        >
                          {row.category_name}
                          {row.products.product_name}
                        </Col>
                                {row.products &&
                                  row.products.length > 0 &&
                                  row.products.map((eachProduct, index) => {
                                    return (
                                              <Row className="w-100 each-product p-2 pt-3 pb-3">
                                                <Col md="5" className="my-auto">
                                                  <i class="fas fa-grip-vertical d-inline-block ml-2" />

                                                  <Link
                                                    to={`/product/${eachProduct.product_name}`}
                                                  >
                                                    {/*<div className="product-image-holder d-inline-block ml-2">*/}
                                                    {/*<Media*/}
                                                    {/*object*/}
                                                    {/*src={`http://admin.potful.in/frontend/images/products/${eachProduct.product_id}.jpg`}*/}
                                                    {/*alt={*/}
                                                    {/*eachProduct.product_name*/}
                                                    {/*}*/}
                                                    {/*className="product-image"*/}
                                                    {/*/>*/}
                                                    {/*</div>*/}
                                                    <Media
                                                      body
                                                      className="ml-2 d-inline-block product-title"
                                                    >
                                                      {eachProduct.product_name}
                                                    </Media>
                                                  </Link>
                                                </Col>
                                                <Col sm="2" className="my-auto d-none d-md-block">
                                                  {row.category_name}
                                                </Col>
                                                <Col
                                                  sm="3"
                                                  className="my-auto product-desc d-none d-md-block"
                                                >
                                                  {eachProduct.total_qty}
                                                </Col>
                                                  <Col
                                                      sm="2"
                                                      className="my-auto product-desc d-none d-md-block"
                                                  >
                                                      {eachProduct.sales_value}
                                                  </Col>
                                                {/*<Col sm="1" className="my-auto d-none d-md-block">*/}
                                                  {/*{moment(*/}
                                                    {/*eachProduct.date_time*/}
                                                  {/*).format("DD/MM/YYYY")}*/}
                                                {/*</Col>*/}
                                              </Row>

                                          );

                                  })}

                      </Row>
                    </section>
                  );
                })}
            </Col>
          </Row>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    getAnalyticsCategoryList: () => dispatch(getAnalyticsCategoryList()),
    getCategoryList: () => dispatch(getCategoryList()),
    sortCategories: order => dispatch(sortCategories(order)),
    sortProducts: order => dispatch(sortProducts(order))
  };
}

function mapStateToProps(state) {
  return {
    productaAnalyticsList: state.products.productaAnalyticsList,
    categoryList: state.products.categoryList
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(ProductsAnalytics);
