import React, {Component} from "react";
import config from "../../config";
import {
    Button,
    Card,
    CardBody,
    CardGroup,
    Col,
    Container,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Media
} from "reactstrap";
import {formatNumbersWithThousandSeparator} from "../../utils";


class Orders extends Component {

    getTotalOfSources = (sources) => {
        let totalValue = 0;
        let totalOrders = 0;
        if (sources && sources.length) {
            let source1 = sources.find(source => source.order_source === '1');
            if (source1 != null) {

                totalValue += Number(source1.gross_value);
                totalOrders += Number(source1.bill_count);
            }
            let source2 = sources.find(source => source.order_source === '2');
            if (source2 != null) {
                totalValue += Number(source2.gross_value);
                totalOrders += Number(source2.bill_count)

            }
            let source3 = sources.find(source => source.order_source === '3');
            if (source3 != null) {
                totalValue += Number(source3.gross_value);
                totalOrders += Number(source3.bill_count)

            }
            let source4 = sources.find(source => source.order_source === '4');
            if (source4 != null) {
                totalValue += Number(source4.gross_value);
                totalOrders += Number(source4.bill_count)

            }
            let source8 = sources.find(source => source.order_source === '8');
            if (source8 != null) {
                totalValue += Number(source8.gross_value);
                totalOrders += Number(source8.bill_count)

            }
            let total = {
                totalValue: Math.floor(totalValue),
                totalOrders
            };
            // console.log(' the total object adding everything is ', total);
            return total;

        } else {
            return {
                totalValue: 0,
                totalOrders: 0
            };
        }

    }

    render() {
        const sources = this.props.sources;
        // const in_house_data = this.props.in_house_data;
        console.log(sources);
        return (
            <div className="col-12">
                <Row className="order-sources">
                    {sources &&
                    sources.map((row, index) => {
                        return (
                            <Col sm="6" md="2" className="order-sources-card">
                                <Card className="">
                                    <CardBody className="pt-2 pb-2 pl-2 pr-2">
                                        <div className="shape-name">
                                            <div className="shape-left">
                                                <Media
                                                    object
                                                    src={`${config.imgUrl}frontend/images/order_source/${row.order_source}.jpg`}
                                                    alt={row.order_source_name}
                                                    className="source_img img-fluid"
                                                />
                                            </div>
                                            <div className="shape-right">
                                                <h6 className="heading">{formatNumbersWithThousandSeparator(Math.round(row.gross_value))}</h6>
                                                <p className="desc">{formatNumbersWithThousandSeparator(row.bill_count)} orders</p>
                                            </div>
                                            <div className="clearfix"></div>
                                            <table className="mt-4 fw">
                                                <tr>
                                                    <th>Discount</th>
                                                    <td className="text-right">{formatNumbersWithThousandSeparator(Math.round(row.discount_value))}</td>
                                                </tr>
                                                <tr>
                                                    <th>Net Sales</th>
                                                    <td className="text-right">{formatNumbersWithThousandSeparator(Math.round(row.net_value))}</td>
                                                </tr>
                                                <tr>
                                                    <th>lost ({row.lost_bill_count})</th>
                                                    <td className="text-right">{formatNumbersWithThousandSeparator(Math.round(row.lost_revenue))}</td>
                                                </tr>
                                                <tr>
                                                    <th>ADT (Min)</th>
                                                    <td className="text-right">{formatNumbersWithThousandSeparator(Math.round(row.adt))}</td>
                                                </tr>
                                                <tr>
                                                    <th>ABV</th>
                                                    <td className="text-right">{formatNumbersWithThousandSeparator(Math.round(row.avg_value))}</td>
                                                </tr>

                                            </table>
                                            {/* <div className="shape-right">
                          <h6 className="heading">{row.total}</h6>
                          <p className="desc">{row.bill_count} orders</p>
                        </div> */}

                                        </div>

                                        <Row className="p-0 d-none">
                                            <Col sm="3">
                                                <Media
                                                    object
                                                    src={`${config.imgUrl}frontend/images/order_source/${row.order_source}.jpg`}
                                                    alt={row.order_source_name}
                                                    className="source_img"
                                                />
                                            </Col>
                                            <Col sm="9">
                                                <Media body className="ml-2 pt-2 text-left">
                                                    <Media heading className="text-left">
                                                        {formatNumbersWithThousandSeparator(row.total)}
                                                    </Media>
                                                    <span className="text-muted text-left">
                              {row.bill_count} orders
                            </span>

                                                </Media>

                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
                <Row>
                    {/*<Col sm="6" md="4" className="order-sources-card">*/}
                        {/*<Card className="">*/}
                            {/*<CardBody className="pt-2 pb-2 pl-2 pr-2">*/}
                                {/*<div className="shape-name">*/}
                                    {/*<div className="shape-left">*/}
                                        {/*<Media*/}
                                            {/*object*/}
                                            {/*src={`http://admin.potful.in/frontend/images/order_source/potful-sources.jpg`}*/}
                                            {/*alt="potful All"*/}
                                            {/*className="source_img img-fluid"*/}
                                        {/*/>*/}
                                    {/*</div>*/}
                                    {/*<div className="shape-right">*/}
                                        {/*<h6 className="heading">{formatNumbersWithThousandSeparator(this.getTotalOfSources(sources).totalValue)}</h6>*/}
                                        {/*<p className="desc"> {this.getTotalOfSources(sources).totalOrders} Orders</p>*/}
                                    {/*</div>*/}
                                    {/*<div className="clearfix"></div>*/}
                                {/*</div>*/}
                                {/*<Row className="p-0 d-none">*/}
                                    {/*<Col sm="3">*/}
                                        {/*<i className="adt-logo far fa-clock"></i>*/}
                                    {/*</Col>*/}
                                    {/*<Col sm="9">*/}
                                        {/*<Media body className="ml-2 pt-2">*/}
                      {/*<span className="text-left">*/}
                        {/*ADT*/}
                      {/*</span>*/}
                                            {/*<br/>*/}
                                            {/*<span className="text-muted text-left">*/}
                        {/*{this.props.adt && Math.round(this.props.adt)} mins*/}
                      {/*</span>*/}
                                        {/*</Media>*/}

                                    {/*</Col>*/}

                                {/*</Row>*/}

                            {/*</CardBody>*/}
                        {/*</Card>*/}
                    {/*</Col>*/}
                    {/*<Col sm="6" md="4" className="order-sources-card">*/}
                        {/*<Card className="">*/}
                            {/*<CardBody className="pt-2 pb-2 pl-2 pr-2">*/}
                                {/*<div className="shape-name">*/}
                                    {/*<div className="shape-left">*/}
                                        {/*<Media*/}
                                            {/*object*/}
                                            {/*src={`http://admin.potful.in/frontend/images/order_source/potful-sources.jpg`}*/}
                                            {/*alt="potful All"*/}
                                            {/*className="source_img img-fluid"*/}
                                        {/*/>*/}
                                    {/*</div>*/}
                                    {/*<div className="shape-right">*/}
                                        {/*<h6 className="heading">{this.props.in_house.gross_value}</h6>*/}
                                        {/*<p className="desc"> {this.props.in_house.bill_count} Orders</p>*/}
                                    {/*</div>*/}
                                    {/*<div className="clearfix"></div>*/}
                                {/*</div>*/}
                                {/*<Row className="p-0 d-none">*/}
                                    {/*<Col sm="3">*/}
                                        {/*<i className="adt-logo far fa-clock"></i>*/}
                                    {/*</Col>*/}
                                    {/*<Col sm="9">*/}
                                        {/*<Media body className="ml-2 pt-2">*/}
                      {/*<span className="text-left">*/}
                        {/*ADT*/}
                      {/*</span>*/}
                                            {/*<br/>*/}
                                            {/*<span className="text-muted text-left">*/}
                        {/*{this.props.adt && Math.round(this.props.adt)} mins*/}
                      {/*</span>*/}
                                        {/*</Media>*/}

                                    {/*</Col>*/}

                                {/*</Row>*/}

                            {/*</CardBody>*/}
                        {/*</Card>*/}
                    {/*</Col>*/}
                    {/*<Col sm="6" md="4" className="order-sources-card">*/}
                        {/*<Card className="">*/}
                            {/*<CardBody className="pt-2 pb-2 pl-2 pr-2">*/}
                                {/*<div className="shape-name">*/}
                                    {/*<div className="shape-left">*/}
                                        {/*<i className="adt-logo far fa-clock"></i>*/}
                                    {/*</div>*/}
                                    {/*<div className="shape-right">*/}
                                        {/*<h6 className="heading">ADT</h6>*/}
                                        {/*<p className="desc"> {this.props.adt && Math.round(this.props.adt)} mins</p>*/}
                                    {/*</div>*/}
                                    {/*<div className="clearfix"></div>*/}
                                {/*</div>*/}
                                {/*<Row className="p-0 d-none">*/}
                                    {/*<Col sm="3">*/}
                                        {/*<i className="adt-logo far fa-clock"></i>*/}
                                    {/*</Col>*/}
                                    {/*<Col sm="9">*/}
                                        {/*<Media body className="ml-2 pt-2">*/}
                      {/*<span className="text-left">*/}
                        {/*ADT*/}
                      {/*</span>*/}
                                            {/*<br/>*/}
                                            {/*<span className="text-muted text-left">*/}
                        {/*{this.props.adt && Math.round(this.props.adt)} mins*/}
                      {/*</span>*/}
                                        {/*</Media>*/}

                                    {/*</Col>*/}
                                {/*</Row>*/}
                            {/*</CardBody>*/}
                        {/*</Card>*/}
                    {/*</Col>*/}
                    {/*<Col sm="6" md="4" className="order-sources-card d-none">*/}
                        {/*<Card className="">*/}
                            {/*<CardBody className="pt-2 pb-2 pl-2 pr-2">*/}
                                {/*<div className="shape-name">*/}
                                    {/*<div className="shape-left">*/}
                                        {/*<i className="adt-logo far fa-clock"></i>*/}
                                    {/*</div>*/}
                                    {/*<div className="shape-right">*/}
                                        {/*<h6 className="heading">ABV</h6>*/}
                                        {/*<p className="desc"> {this.props.adt && Math.round(this.props.adt)} mins</p>*/}
                                    {/*</div>*/}
                                    {/*<div className="clearfix"></div>*/}
                                {/*</div>*/}
                                {/*<Row className="p-0 d-none">*/}
                                    {/*<Col sm="3">*/}
                                        {/*<i className="adt-logo far fa-clock"></i>*/}
                                    {/*</Col>*/}
                                    {/*<Col sm="9">*/}
                                        {/*<Media body className="ml-2 pt-2">*/}
                      {/*<span className="text-left">*/}
                        {/*ABV*/}
                      {/*</span>*/}
                                            {/*<br/>*/}
                                            {/*<span className="text-muted text-left">*/}
                        {/*{this.props.adt && Math.round(this.props.adt)} mins*/}
                      {/*</span>*/}
                                        {/*</Media>*/}
                                    {/*</Col>*/}
                                {/*</Row>*/}
                            {/*</CardBody>*/}
                        {/*</Card>*/}
                    {/*</Col>*/}
                </Row>
            </div>
        );
    }
}

export default Orders;
