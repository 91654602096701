import React, {Component} from "react";

import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {DateRangePicker} from "react-dates";
import moment from "moment";
import {toast} from "react-toastify";
import {createBanner, getBannerList, updateBannerItem, uploadBannerImage} from "../../actions/banners";
import {connect} from "react-redux";

const options = {
    autoClose: 2000,
    // closeButton:,
    type: toast.TYPE.INFO,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_LEFT,
    pauseOnHover: true
    // progress: 0.2
};

class Banners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            BannerModal: false,
            imageUploadModal: false,
            BannerEditModal: false,
            focusedInput: false,
            title: "",
            isAlways: true,
            minDate: moment(),
            startDate: moment(),
            endDate: moment().add(7, "d"),
            image: null,
            selectedBanner: null,
        }
        this.toggleBannerModal = this.toggleBannerModal.bind(this);
        this.toggleBannerEditModal = this.toggleBannerEditModal.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.onImageUploaded = this.onImageUploaded.bind(this);
        this.submitCreateBanner = this.submitCreateBanner.bind(this);
        this.handleAlwaysOnToggle = this.handleAlwaysOnToggle.bind(this);
        this.checkBranchExist = this.checkBranchExist.bind(this);
        this.submitImageUpload = this.submitImageUpload.bind(this);
        this.submitUpdateBannerItem = this.submitUpdateBannerItem.bind(this);

        this.props.getBannerList();
    }

    toggleBannerModal() {
        this.setState(prevState => ({
            BannerModal: !prevState.BannerModal
        }));
    }

    toggleBannerEditModal(banner) {
        this.setState(prevState => ({
            BannerEditModal: !prevState.BannerEditModal,
            selectedBanner: banner
        }));
    }

    toggleImageUploadModal(banner) {
        this.setState(prevState => ({
            imageUploadModal: !prevState.imageUploadModal,
            selectedBanner: banner
        }));
    }

    handleDateChange(startDate, endDate) {
        if (startDate < this.state.minDate) {
            toast.error("Please choose starting greater than today", options)
            return false;
        }
        this.setState({startDate: startDate, endDate: endDate});
    }

    handleAlwaysOnToggle() {
        this.setState(prevState => ({
            isAlways: !prevState.isAlways
        }));
    }

    submitCreateBanner() {
        if (this.state.title.length < 5)
            toast.error("Banner title too short", options)
        else {
            let params = {
                banner_title: this.state.title,
                banner_desc: ''
            }

            if (this.state.isAlways) {
                params.start_date = '';
                params.end_date = '';
            } else {
                params.start_date = this.state.startDate.format("YYYY-MM-DD");
                params.end_date = this.state.endDate.format("YYYY-MM-DD");
            }
            this.props.createBanner(params);
            this.toggleBannerModal();
        }
    }

    onImageUploaded(image) {
        if (!image) {
            this.setState({imageError: 'Please select image.', image: null});
            return false;
        }
        if (!image.type.match(/jpg|jpeg/)) {
            this.setState({imageError: 'Please select valid image.', image: null});
            return false;
        }
        if (image.size > 2000000) {
            this.setState({imageError: 'Image size too big', image: null});
            return false;
        }
        this.setState({
            image: image,
            imageError: ''
        });
    }

    submitUpdateBannerItem( branch_id,status) {
        let params = {
            banner_id: this.state.selectedBanner.banner_id,
            branch_id: branch_id,
            status: status ? 1 : 0
        };
        this.props.updateBannerItem(params);
    }

    checkBranchExist(branchId) {
        if (this.state.selectedBanner != null) {
            for (let i = 0; i <this.state.selectedBanner.branch_list.length; i++) {
                let item = this.state.selectedBanner.branch_list[i];
                if(item.branch_id===branchId)
                    return true;
            }
        }
    }

    submitImageUpload() {
        if (this.state.image === null)
            toast.error("Please choose a image", options)
        else {
            this.props.uploadBannerImage(this.state.selectedBanner.banner_id, this.state.image);
            this.toggleImageUploadModal(null);
        }
    }

    render() {

        const bannerList = this.props.bannerList;
        let branch_list = [];
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            for (let i = 0; i < this.props.loggedInUser.branch_list.length; i++) {
                if (i === 0) {
                    branch_list = this.props.loggedInUser.branch_list[i].branch_list;
                    if (branch_list[0].branch_id === 0)
                        branch_list.splice(0, 1);
                    break;
                }
            }
        }
        return (
            <div className="animated fadeIn dashboard">
                <Button
                    color="primary"
                    className="d-inline-block mr-2"
                    onClick={this.toggleBannerModal}
                    outline
                >
                    Upload Banner
                </Button>

                {bannerList && bannerList.map((item) => (
                    <div className="banner-row border-top pt-2 mt-2">
                        <div className="row ">
                            <div className="col-md-2">
                                <img className="img-fluid" src={`${item.banner_image_url}`} alt=""/>
                            </div>
                            <div className="col-md-8">
                                <h4>Assigned Branches</h4>
                                <p>
                                    {item.branch_list && item.branch_list.map((item1) =>(<span className="badge badge-secondary">{item1.branch_name}</span>))}
                                </p>
                            </div>
                            <div className="col-md-2">
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    <button type="button" className="btn btn-secondary" onClick={()=>{this.toggleImageUploadModal(item)}}>Update Banner Image</button>
                                    <button type="button" className="btn btn-warning"
                                            onClick={()=>{this.toggleBannerEditModal(item)}}>Manage Banner Branch
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

                <Modal
                    isOpen={this.state.BannerModal}
                    fade={false}
                    toggle={this.toggleBannerModal}
                    className={this.props.className}
                >
                    <ModalHeader>Upload Banner</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>Title</Label>
                            <Input
                                type="text"
                                className="mb-3"
                                placeholder="Title"
                                onChange={(e) => {
                                    this.setState({title: e.target.value})
                                }}
                            />

                            <Label>Banner Active range</Label>

                            <DateRangePicker
                                calendarInfoPosition="before"
                                startDate={this.state.startDate}
                                minDate={moment(this.props.minDate).toDate()}
                                startDateId="From Date"
                                endDate={this.state.endDate}
                                endDateId="To Date"
                                onDatesChange={({startDate, endDate}) => {
                                    if (!startDate)
                                        startDate = endDate
                                    if (!endDate)
                                        endDate = startDate;
                                    return this.handleDateChange(startDate, endDate)
                                }
                                }
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput =>
                                    this.setState({focusedInput})
                                }
                                orientation={this.state.orientation}
                                openDirection={this.state.openDirection}
                                isOutsideRange={() => false}
                                anchorDirection={"left"}
                            />
                            <div className="p-4">
                                <Input
                                    type="checkbox"
                                    defaultChecked={this.state.isAlways}
                                    onChange={(e) => this.handleAlwaysOnToggle()}

                                /> <Label>Always</Label>
                            </div>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" outline onClick={this.toggleBannerModal}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            onClick={this.submitCreateBanner}
                        >
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={this.state.BannerEditModal}
                    fade={false}
                    // toggle={this.toggleBannerEditModal}
                    className={this.props.className}
                >
                    <ModalHeader>Edit Banner</ModalHeader>
                    <ModalBody className="modal-height">
                        <FormGroup>
                            {
                                branch_list && branch_list.map((item1) => {


                                    return (<div className="form-check form-switch">
                                        <div className="can-toggle can-toggle--size-small">
                                            <input id={`${item1.branch_id}`} type="checkbox"
                                            defaultChecked={this.checkBranchExist(item1.branch_id)}
                                                   onChange={(e) => this.submitUpdateBannerItem(item1.branch_id,e.target.checked)}


                                            />
                                            <label htmlFor={`${item1.branch_id}`}>
                                                <div className="can-toggle__switch" data-checked="On"
                                                     data-unchecked="Off"></div>
                                                <div className="can-toggle__label-text">
                                                    <strong>{item1.branch_name}</strong></div>
                                            </label>
                                        </div>
                                    </div>)
                                })
                            }
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" outline onClick={() => {
                            this.toggleBannerEditModal(null)
                        }}>
                            Cancel
                        </Button>

                        <Button
                            color="primary"
                            onClick={() => {
                                this.toggleBannerEditModal(null)
                            }}
                        >Save
                        </Button>
                    </ModalFooter>
                </Modal>


                <Modal
                    isOpen={this.state.imageUploadModal}
                    fade={false}
                    className={this.props.className}
                >
                    <ModalHeader>Update Banner Image</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>Upload Image</Label>
                            <Input
                                type="file"
                                className="mb-3"
                                onChange={(e) => this.onImageUploaded(e.target.files[0])}
                            />
                            {/*<p>Multiselect</p>*/}
                            {/*<MultiSelect options={} value={} labelledBy={}*/}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" outline onClick={() => {
                            this.toggleImageUploadModal(null)
                        }}>
                            Cancel
                        </Button>

                        <Button
                            color="primary" onClick={() => {
                            this.submitImageUpload()
                        }}
                        >
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>

            </div>
        );
    }
}


function bindActions(dispatch) {
    return {
        getBannerList: () => dispatch(getBannerList()),
        createBanner: (params) => dispatch(createBanner(params)),
        updateBannerItem: (params) => dispatch(updateBannerItem(params)),
        uploadBannerImage: (banner_id, image) => dispatch(uploadBannerImage(banner_id, image))
    };
}

function mapStateToProps(state) {
    return {
        bannerList: state.banners.bannerList,
        loggedInUser: state.commonData.loggedInUser
    };
}

export default connect(
    mapStateToProps,
    bindActions
)(Banners);

