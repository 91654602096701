import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Col,
  Row, Container, Media, CardHeader, Badge,
  Card, Button, CardTitle, CardText
} from "reactstrap";
import OrderDetail from "./OrderDetail";
import OrderTimeline from "./OrderTimeline";

import moment from "moment";

import { getOrderTimeline } from "../../actions/orders";

class OrderView extends Component {
  constructor(props) {
    super(props);
    let orderId = this.props.match.params.orderId;
    this.props.getOrderTimeline(orderId);
    // console.log(this.props.currentOrder);
  }

  render() {
    const orderData = this.props.currentOrder;
    return (
      <div className="animated fadeIn">
        <Row className="ml-2 mr-2 mt-4 order-view">
          <Col md="6" className="order-details">
            <OrderDetail order={orderData} />
          </Col>
          <Col md="6" className="order-customer">
            <OrderTimeline order={orderData} />
          </Col>
        </Row>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
      getOrderTimeline: orderId => dispatch(getOrderTimeline(orderId))
  };
}

function mapStateToProps(state) {
  return {
    currentOrder: state.orders.currentOrder
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(OrderView);
