import BootstrapTable from "react-bootstrap-table-next";

import { Link } from "react-router-dom";
import React from "react";

import { Button } from "reactstrap";
import moment from "moment";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";

const RemotePagination = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  columns
}) => (
  <div>
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        page,
        sizePerPage,
        totalSize
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <div className="table-responsive align-m-table new-table-css last-child-right table-margin-minus">
          <BootstrapTable
            remote
            keyField="bill_id"
            data={data}
            columns={columns}
            onTableChange={onTableChange}
            {...paginationTableProps}
          />
          <div className="float-left text-muted pt-2">
            <p>
              Showing:{" "}
              {(paginationProps.page - 1) * paginationProps.sizePerPage + 1} to{" "}
              {paginationProps.page * paginationProps.sizePerPage <
              paginationProps.totalSize
                ? paginationProps.page * paginationProps.sizePerPage
                : paginationProps.totalSize}{" "}
              of {paginationProps.totalSize} entries
            </p>
          </div>
          <div className="float-right">
            <PaginationListStandalone {...paginationProps} />
          </div>
          <div className="clearfix"></div>
        </div>
      )}
    </PaginationProvider>
  </div>
);

class WalletTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: this.props.orders,
      sizePerPage: 10
    };
    this.actionFormatter = this.actionFormatter.bind(this);
    this.billFormatter = this.billFormatter.bind(this);
  }

  handleTableChange = (type, { page, sizePerPage }) => {
    this.setState(() => ({
      page
    }));
    this.props.handlePagination((page - 1) * sizePerPage);
  };

  actionFormatter = function(cell, row, rowIndex, formatExtraData) {
    return <div>NA</div>;
  };

  billFormatter = function(cell, row, rowIndex, formatExtraData) {
    return (
      <div>
        <span className="font-weight-bold"># {row.id}</span>
      </div>
    );
  };

  // Custom column formatter
  usererFormatter = function(cell, row, rowIndex, formatExtraData) {
    return (
      <div>
        <Link to={`/customer/${row.customer_id}`} target="_blank">
          <span className="text-danger font-weight-bold">{`${row.user_first_name} ${row.user_second_name}`}</span>
        </Link>
        <br />
        <span className="text-muted">{row.user_phone}</span>
      </div>
    );
  };

  dateFormatter = function(cell, row, rowIndex, formatExtraData) {
    return moment(cell).format("MMM DD, YYYY hh:mm a");
  };

  creditFormatter = function(cell, row, rowIndex, formatExtraData) {
    if (row.transaction_type == 1) {
      return (
        <div className="text-success font-weight-bold">
          +{" "}
          {cell}
        </div>
      );
    }
    return (
      <div className="text-danger font-weight-bold">
        -{" "}
        {cell}
      </div>
    );
  };

  balanceFormatter = function(cell, row, rowIndex, formatExtraData) {
    return (
      <div>
        {cell}
      </div>
    );
  };
  requestTypeFormatter = function(cell, row, rowIndex, formatExtraData) {
    switch (parseInt(cell)) {
      case 1:
        return "All Customers";
      default:
        return "";
    }
  };

  transactionTypeFormatter = function(cell, row, rowIndex, formatExtraData) {
    switch (parseInt(cell)) {
      case 1:
        return "Credit";
      case 2:
        return "Debit";
      default:
        return "";
    }
  };

  statusFormatter = function(cell, row, rowIndex, formatExtraData) {
    switch (parseInt(cell)) {
      case 1:
        return "Requested";
      case 2:
        return "Approved";
      case 3:
        return "Rejected";
      default:
        return "Requested";
    }
  };

  render() {
    const { data, sizePerPage, page } = this.state;

    const columns = [
      {
        dataField: "user_first_name",
        text: "Requested By",
        sort: true,
        formatter: this.usererFormatter
      },
      {
        dataField: "id",
        text: "Request ID",
        sort: true,
        formatter: this.billFormatter
      },
      {
        dataField: "request_date",
        text: "Date Time",
        sort: true,
        formatter: this.dateFormatter
      },
      {
        dataField: "credits",
        text: "credits",
        sort: true,
        formatter: this.creditFormatter
      },
      {
        dataField: "transaction_type",
        text: "Transaction type",
        sort: true,
        formatter: this.transactionTypeFormatter
      },
      {
        dataField: "request_type",
        text: "Request Type",
        sort: true,
        formatter: this.requestTypeFormatter
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
        formatter: this.statusFormatter
      }
    ];

    return (
      <div className="w-100 orders-table custom-table-no-border">
        <RemotePagination
          data={this.props.orders}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={this.props.totalData}
          onTableChange={this.handleTableChange}
          columns={columns}
        />
      </div>
    );
  }
}

export default WalletTable;
