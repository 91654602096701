import BootstrapTable from "react-bootstrap-table-next";

import React from "react";

import {
  Badge,
  Button,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";


const RemotePagination = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize, columns
}) => (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page,
          sizePerPage,
          totalSize
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <div className="table-responsive new-table-css last-child-right">
            <BootstrapTable
              remote
              keyField="bill_id"
              data={data}
              columns={columns}
              onTableChange={onTableChange}
              {...paginationTableProps}
            />
            <div className="float-left text-muted pt-2 ml-3">
              <p>
                Showing:{" "}
                {(paginationProps.page - 1) * paginationProps.sizePerPage + 1} to{" "}
                {paginationProps.page * paginationProps.sizePerPage <
                  paginationProps.totalSize
                  ? paginationProps.page * paginationProps.sizePerPage
                  : paginationProps.totalSize}{" "}
                of {paginationProps.totalSize} entries
            </p>
            </div>
            <div className="float-right mr-3">
              <PaginationListStandalone {...paginationProps} />
            </div>
          </div>
        )}
      </PaginationProvider>
    </div>
  );

class OrdersTableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: this.props.staff,
      sizePerPage: 10,
      dropdownOpen: false
    };
    this.actionToggle = this.actionToggle.bind(this);
    this.staffIdFormatter = this.staffIdFormatter.bind(this);
    this.userFormatter = this.userFormatter.bind(this);
    this.nameFormatter = this.nameFormatter.bind(this);
    this.designationFormatter = this.designationFormatter.bind(this);
    this.phoneFormatter = this.phoneFormatter.bind(this);
    this.branchFormatter = this.branchFormatter.bind(this);
    this.statusFormatter = this.statusFormatter.bind(this);
    this.actionsFormatter = this.actionsFormatter.bind(this);
  }

  actionToggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  handleTableChange = (type, { page, sizePerPage }) => {
    this.setState(() => ({
      page
    }));
    this.props.handlePagination((page - 1) * sizePerPage);
  };



  // Custom column formatter
  staffIdFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <span
        className="text-blue-brand font-weight-bold"
      >
        #{cell}
      </span>

    );
  }

  userFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <Badge className={`p-1 badge-${cell}`} color="info">
        {cell}.jpg
    </Badge>
    );
  }

  nameFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div>
        {row.user_first_name}{" "}{row.user_second_name}
      </div>
    );
  }
    nameFormatterOne(cell, row, rowIndex, formatExtraData) {
        return (
            <div>{cell}</div>
        );
    }
  designationFormatter(cell, row, rowIndex, formatExtraData) {
    // console.log(this.props);
    let typeData = this.props.userTypeList && this.props.userTypeList.filter(item => {
      return item.type_id == cell;
    });
    return (
      <div>
        {typeData[0] && typeData[0].type_name}
      </div>
    );
  }

  phoneFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div>{cell}</div>
    );
  }

  branchFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <Badge className="p-2 border-primary w-100" color="info" outline>
        {cell}
      </Badge>
    );
  }

  statusFormatter(cell, row, rowIndex, formatExtraData) {
    // console.log(this.props);
    let statusData = this.props.userStatusList && this.props.userStatusList.filter(item => {
      return item.status_id == cell;
    });
    // console.log(statusData);
    return (
      <Badge className={`status-badge p-2 ${statusData[0] && statusData[0].status_name.toLowerCase()}`}>{statusData[0] && statusData[0].status_name}</Badge>
    );
  }

  actionsFormatter(cell, row, rowIndex, formatExtraData) {
    if (row.user_status == 1) {
      return (
        <Button color="dark" outline
          onClick={() => {
            this.props.updateStaffStatus(row.user_id, 0);
          }} >Deactivate</Button>
      );
    } else {
      return (
        <Button color="success"
          onClick={() => {
            this.props.updateStaffStatus(row.user_id, 1);
          }} >Activate</Button>
      );
    }

  }

  render() {

    // Table columns
    const columns = [
      {
        dataField: "user_id",
        text: "#",
        sort: true,
        formatter: this.staffIdFormatter
      },
      // {
      //   dataField: "user_id",
      //   text: "User",
      //   sort: true,
      //   formatter: this.userFormatter
      // },
      {
        dataField: "user_first_name",
        text: "Full Name",
        sort: true,
        formatter: this.nameFormatter
      },
      {
        dataField: "user_first_name",
        text: "Join Date",
        sort: true,
        formatter: this.nameFormatter
      },
      {
        dataField: "user_type",
        text: "Designation",
        sort: true,
        formatter: this.designationFormatter
      },
      {
        dataField: "user_phone",
        text: "Phone",
        sort: true,
        formatter: this.phoneFormatter
      },
      {
        dataField: "branch_name",
        text: "Branch",
        sort: true,
        formatter: this.nameFormatterOne
      },
      {
        dataField: "user_status",
        text: "Status",
        sort: true,
        formatter: this.statusFormatter
      },
      {
        dataField: "actions",
        text: "Actions",
        sort: true,
        formatter: this.actionsFormatter
      }
      // {
      //   dataField: "actions",
      //   text: "Change Branch",
      //   sort: true,
      //   formatter: this.actionsFormatter
      // }
    ];
    const { data, sizePerPage, page } = this.state;
    // console.log(this.props);
    return (
      <div className="w-100 orders-table custom-table-no-border">
        <RemotePagination
          data={this.props.staff}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={this.props.totalData}
          onTableChange={this.handleTableChange}
          columns={columns}
        />
      </div>
    );
  }
}

export default OrdersTableContainer;
