import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Input
} from "reactstrap";

import { createCategory } from "../../actions/products";

class NewCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryModal: false,
      categoryName: "",
      parentCategory: 0
    };
    this.toggleCategoryModal = this.toggleCategoryModal.bind(this);
    this.submitCategory = this.submitCategory.bind(this);
  }


  toggleCategoryModal() {
    this.setState(prevState => ({
      categoryModal: !prevState.categoryModal
    }));
  }
  submitCategory() {
    let data = {};
    const { categoryName, parentCategory } = this.state;
    if (categoryName === "") {
      return;
    }
    data = { category_name: categoryName, parent_category: parentCategory };
    this.props.createCategory(data);
    this.setState({ categoryModal: false, categoryName: "", parentCategory: 0 });
  }
  render() {
    const { categoryName } = this.state;
    // console.log(this.props);
    return (
      <div className={`d-inline-block mr-3 animated fadeIn ${this.props.className}`}>
        <Button
          color="primary"
          className="place-order w-100 new-btn-css"
          onClick={this.toggleCategoryModal}
        >
          <span class="new-btn-span"><img src="/images/candy.svg" alt="" /></span>
          New Category
        </Button>
        <Modal
          isOpen={this.state.categoryModal}
          fade={false}
          toggle={this.toggleCategoryModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCategoryModal}>
            Create Category
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Input
                type="text"
                className="mb-3"
                placeholder="Category Name"
                autoComplete="categoryName"
                onChange={e => {
                  this.setState({ categoryName: e.target.value });
                }}
                value={categoryName}
              />
              <Input
                type="select"
                name="parentCategory"
                id="parentCategory"
                onChange={e => {
                  this.setState({ parentCategory: e.target.value });
                }}
              >
                <option value="0">Core Category</option>
                {this.props.categories &&
                  this.props.categories.length > 0 &&
                  this.props.categories.map((row, index) => {
                    return (
                      <option value={row.category_id}>
                        {row.category_name}
                      </option>
                    );
                  })}
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" outline onClick={this.toggleCategoryModal}>
              Cancel
            </Button>{" "}
            <Button
              color="primary"
              onClick={() => {
                this.submitCategory();
              }}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    createCategory: data => dispatch(createCategory(data))
  };
}
function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  bindActions
)(NewCategory);
