import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import _ from "lodash";
import "react-dates/lib/css/_datepicker.css";
import {
  Col,
  Row,
  Button,
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  getAnalyticsTrends,
  getOrderSourceForAnalyticsTrends,
} from "../../actions/orders";
import Highcharts from "highcharts";

class TrendsAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(7, "d"),
      endDate: moment(),
      orderStatus: 0, // default all orders
      perPage: 10,
      offset: 0,
      searchText: "",
      preset: "",
      focusedInput: false,
      dropdownOpen: false,
      branch_id: 0,
      orderSourceOpen: false,
      selectedOrderSource: "All Order Sources",
      selectedOrderSourceId: 0,
      branch_name: "All branch",
      cityDropdown: false,
      city_id: "0",
      city_name: "All Cities"
    };

    this.filterOrders = this.filterOrders.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.renderDatePresets = this.renderDatePresets.bind(this);
    this.branchtoggle = this.branchtoggle.bind(this);
    this.handleBranchChange = this.handleBranchChange.bind(this);
    this.handleOrderSourceChange = this.handleOrderSourceChange.bind(this);
    this.orderSourceToggle = this.orderSourceToggle.bind(this);
    this.handleChartDataForValue = this.handleChartDataForValue.bind(this);
    this.handleChartDataForOrders = this.handleChartDataForOrders.bind(this);
    this.citytoggle = this.citytoggle.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.props.getOrderSources();
    const {
      branch_id,
      startDate,
      endDate,
      perPage,
      searchText,
      orderStatus,
        city_id
    } = this.state;
    let default_params = {
      branch_id: branch_id,
      status: orderStatus,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: 0,
      order_source: 0,
      perPage: perPage,
      sort: "ASC",
      city_id:city_id
    };
    this.props.getAnalyticsTrends(default_params);
  }

  componentDidMount() {
    if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
      let branch_list = [];
      branch_list = this.props.loggedInUser.branch_list;

      if (branch_list.length > 0) {
        let firstCityName = branch_list[0].city_name;
        let firstCityId = branch_list[0].city_id;
        this.handleCityChange(firstCityName, firstCityId);
      }
    }
  }


  componentWillReceiveProps(nextProps) {
    if (this.props.loggedInUser && (this.props.loggedInUser.branch_list != nextProps.loggedInUser.branch_list)) {
      let branch_list = [];
      branch_list = this.props.loggedInUser.branch_list;

      if (branch_list.length > 0) {
        let firstCityName = branch_list[0].city_name;
        let firstCityId = branch_list[0].city_id;
        this.handleCityChange(firstCityName, firstCityId);
      }
    }
  }

  handleOrderSourceChange(id, name) {
    console.log(id, name);
    const {
      branch_id,
      startDate,
      endDate,
      orderStatus,
      offset,
      perPage,
        city_id
    } = this.state;
    this.setState({ selectedOrderSource: name, selectedOrderSourceId: id });
    let params = {
      branch_id: branch_id,
      status: orderStatus,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      order_source: id,
      offset: offset,
      perPage: perPage,
      sort: "ASC",
      city_id:city_id
    };
    this.props.getAnalyticsTrends(params);
  }

  citytoggle() {
    this.setState(prevState => ({
      cityDropdown: !prevState.cityDropdown
    }));
  }

  branchtoggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleDateChange(startDate, endDate) {
    const { branch_id, orderStatus, perPage, offset, searchText,city_id } = this.state;
    this.setState({ startDate: startDate, endDate: endDate });
    let params = {
      branch_id: branch_id,
      status: orderStatus,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: 0,
      perPage: perPage,
      sort: "ASC",
      city_id:city_id
    };
    this.props.getAnalyticsTrends(params);
  }

  handleCityChange(city_name, city_id) {
    this.setState({city_id: city_id, city_name: city_name},()=>{
      this.props.loggedInUser.branch_list.map((value) => {
        if (city_id === value.city_id)
          this.handleBranchChange(value.branch_list[0].branch_id, value.branch_list[0].branch_name);
      });
    });
  }

  handleBranchChange(branch_id, branch_name) {
    const {
      orderStatus,
      perPage,
      searchText,
      startDate,
      endDate,
      selectedOrderSourceId,
        city_id
    } = this.state;
    this.setState({ branch_id: branch_id, branch_name: branch_name });
    let params = {
      branch_id: branch_id,
      status: orderStatus,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      order_source: selectedOrderSourceId,
      offset: 0,
      perPage: perPage,
      sort: "ASC",
      city_id:city_id
    };
    this.props.getAnalyticsTrends(params);
  }

  filterOrders(bill_status) {
    const { branch_id, startDate, endDate, perPage, searchText,city_id } = this.state;
    this.setState({ orderStatus: bill_status });
    let params = {
      branch_id: branch_id,
      status: bill_status,
      from_date: startDate.format("YYYY-MM-DD"),
      to_date: endDate.format("YYYY-MM-DD"),
      searchText: searchText,
      offset: 0,
      perPage: perPage,
      sort: "ASC",
      city_id:city_id
    };
    this.props.getAnalyticsTrends(params);
  }

  renderDatePresets() {
    return (
      <div className="date_custom_presets">
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment(), moment());
          }}
        >
          Today
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().subtract(1, "d"), moment().subtract(1, "d"));
            this.setState({ focusedInput: false });
          }}
        >
          Yesterday
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().subtract(7, "d"), moment());
            this.setState({ focusedInput: false });
          }}
        >
          Last 7 days
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().subtract(30, "d"), moment());
            this.setState({ focusedInput: false });
          }}
        >
          Last 30 days
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(moment().startOf("month"), moment());
            this.setState({ focusedInput: false });
          }}
        >
          This month
        </Button>
        <Button
          className="w-100  mb-2"
          color="info"
          onClick={() => {
            this.handleDateChange(
              moment().subtract(1, "months").startOf("month"),
              moment().subtract(1, "months").endOf("month")
            );
            this.setState({ focusedInput: false });
          }}
        >
          Last month
        </Button>
        <Button className="w-100  mb-2" color="info">
          Custom Range
        </Button>
      </div>
    );
  }

  orderSourceToggle() {
    this.setState((prevState) => ({
      orderSourceOpen: !prevState.orderSourceOpen,
    }));
  }

  handleChartDataForValue() {
    const chartData = !_.isEmpty(this.props.AnalyticsTrends.data)
      ? _.map(this.props.AnalyticsTrends.data, (item) => {
          return {
            name: item.week_beginning,
            y: +item.total_order_value,
          };
        })
      : [];

    const chartConfig = {
      chart: {
        type: "column",
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                currency: "INR",
              });
            },
          },
        },
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      legend: {
        enabled: false,
      },
      title: {
        text: "By Value",
      },
      xAxis: {
        type: "category",
      },
      yAxis: {
        title: {
          text: "Value",
        },
          labels:{
            formatter: function() {
              return this.value +'L';
            }
          }
      },
      series: [
        {
          name: "Value",
          colorByPoint: true,
          data: chartData.map(function (item) {
            item.y=item.y/100000;
              return item;
          }),
        },
      ],
      credits: {
        enabled: false,
      },
    };

    if (!_.isEmpty(this.props.AnalyticsTrends.data) && this.chartElement)
      Highcharts.chart("trendsAnalyticsByValue", chartConfig);
  }

  handleChartDataForOrders() {
    const chartData = !_.isEmpty(this.props.AnalyticsTrends.data)
      ? _.map(this.props.AnalyticsTrends.data, (item) => {
          return {
            name: item.week_beginning,
            y: +item.total_orders,
          };
        })
      : [];
    const chartConfig = {
      chart: {
        type: "column",
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                currency: "INR",
              });
            },
          },
        },
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      legend: {
        enabled: false,
      },
      title: {
        text: "By Orders",
      },
      xAxis: {
        type: "category",
      },
      yAxis: {
        title: {
          text: "Orders",
        }
      },
      series: [
        {
          name: "Orders",
          colorByPoint: true,
          data: chartData,
        },
      ],
      credits: {
        enabled: false,
      },
    };

    if (!_.isEmpty(this.props.AnalyticsTrends.data) && this.chartElement)
      Highcharts.chart("trendsAnalyticsByOrders", chartConfig);
  }

  render() {
    const { startDate, endDate, branch_name, orderStatus,city_id,city_name } = this.state;

    let orderStatusList = [];
    if (this.props.AnalyticsTrends && this.props.AnalyticsTrends.meta) {
      orderStatusList = this.props.AnalyticsTrends.meta;
    }
    let statusList = {
      ORDER_TEMP: "Temp",
      ORDER_ONLINE_PAYMENT_PROCESSING: "Payment Pending",
      ORDER_PLACED: "New Order",
      ORDER_ACCEPTED: "In Process",
      ORDER_PACKED: "Ready",
      ORDER_ASSIGNED: "Assigned",
      ORDER_DISPATCHED: "Dispatched",
      ORDER_DELIVERED: "Delivered",
      ORDER_REJECTED: "Rejected",
      ORDER_CANCELLED: "Canceled",
    };
    let city_list = [];
    let branch_list = [];
    if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {

      this.props.loggedInUser.branch_list.map((item)=>{
        if(city_id===item.city_id)
          branch_list = item.branch_list;
        city_list.push(item);
      });
    }
    let all_branch_outline = true;
    if (orderStatus === 0) {
      all_branch_outline = false;
    }

    this.handleChartDataForOrders();
    this.handleChartDataForValue();

    return (
      <div className="animated fadeIn">
        <Row className="ml-2 mr-2 mt-2 orders">
          <Col md="3" lg="3" xl="2" className="p-2 orders-side d-none">
            <hr />
            <label className="filter-label">Order History</label>
            <Button
              color="primary"
              className="w-100 text-left orderStatusBtn"
              outline={all_branch_outline}
              onClick={() => {
                this.filterOrders(0);
              }}
            >
              All Orders
              <Badge className="float-right ml-1 p-1 orderCount">
                {this.props.AnalyticsTrends &&
                  this.props.AnalyticsTrends.order_list &&
                  this.props.AnalyticsTrends.order_list.iTotalRecords}
              </Badge>
            </Button>
            {orderStatusList &&
              orderStatusList.length > 0 &&
              orderStatusList.map((element, index) => {
                console.log(element);
                let outline =
                  element.bill_status !== this.state.orderStatus ? true : false;
                return (
                  <Button
                    color="primary"
                    className="w-100 text-left orderStatusBtn"
                    outline={outline}
                    onClick={() => {
                      console.log(element.bill_status);
                      this.filterOrders(element.bill_status);
                    }}
                  >
                    {statusList[element.order_status_value]}
                    <Badge className="float-right ml-1 p-1 orderCount">
                      {element.bill_count}
                    </Badge>
                  </Button>
                );
              })}
          </Col>

          <Col className="orders-main px-3">
            <Row className="">
              <div class="p-3 today-side col-sm-4 col-md-3 col-lg-2 border-right">

                <Dropdown
                    isOpen={this.state.cityDropdown}
                    toggle={this.citytoggle}
                    className="place-order w-100 mb-3"
                >
                  <DropdownToggle caret className="w-100" color="primary">
                    {city_name}
                  </DropdownToggle>
                  <DropdownMenu className="w-100">
                    {city_list &&
                    city_list.map((row, index) => {
                      return (
                          <DropdownItem
                              key={index}
                              onClick={() => {
                                this.handleCityChange(
                                    row.city_name,
                                    row.city_id
                                );
                              }}
                          >
                            {row.city_name}
                          </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>

                <Dropdown
                  isOpen={this.state.dropdownOpen}
                  toggle={this.branchtoggle}
                  className="place-order w-100 mb-3"
                >
                  <DropdownToggle caret className="w-100" color="primary">
                    {branch_name}
                  </DropdownToggle>
                  <DropdownMenu className="w-100">
                    {branch_list &&
                      branch_list.map((row, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() => {
                              this.handleBranchChange(
                                row.branch_id,
                                row.branch_name
                              );
                            }}
                          >
                            {row.branch_name}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
                  <h5 className="nav-header">Navigation</h5>
                  <ul className="nav flex-column ul-hover-active">
                      <li className="nav-link px-0">
                          <NavLink to="/analytics">Product Sales</NavLink>
                      </li>
                      <li className="nav-link px-0">
                          <NavLink to="/category_analytics">Payment Source</NavLink>
                      </li>
                      <li className="nav-link px-0">
                          <NavLink to="/time_analytics">Sales By Time</NavLink>
                      </li>
                      <li className="nav-link px-0">
                          <NavLink to="/partner_analytics">Partner Analytics</NavLink>
                      </li>
                      <li className="nav-link px-0">
                          <NavLink to="/trends_analytics">Sales Analytics</NavLink>
                      </li>
                      <li className="nav-link px-0">
                          <NavLink to="/customer_analytics">Customer Analytics</NavLink>
                      </li>
                  </ul>
              </div>
              <div class="today-main py-3 col-sm-8 col-md-9 col-lg-10">
                <Row>
                  <Col lg="4" md="6" className="px-3 mb-3">
                    <DateRangePicker
                      calendarInfoPosition="before"
                      renderCalendarInfo={this.renderDatePresets}
                      startDate={startDate}
                      startDateId="FromstartDate"
                      endDate={endDate}
                      endDateId="FromendDate"
                      onDatesChange={({ startDate, endDate }) => {
                        if (!startDate) {
                          startDate = endDate;
                        }

                        if (!endDate) {
                          endDate = startDate;
                        }
                        return this.handleDateChange(startDate, endDate);
                      }}
                      focusedInput={this.state.focusedInput}
                      onFocusChange={(focusedInput) =>
                        this.setState({ focusedInput })
                      }
                      orientation={this.state.orientation}
                      openDirection={this.state.openDirection}
                      isOutsideRange={() => false}
                      anchorDirection={"left"}
                    />
                  </Col>
                  <Col lg="4" md="6">
                    <Dropdown
                      isOpen={this.state.orderSourceOpen}
                      toggle={this.orderSourceToggle}
                      className="place-order w-100 mb-3"
                    >
                      <DropdownToggle caret className="w-100" color="primary">
                        {this.state.selectedOrderSource}
                      </DropdownToggle>

                      <DropdownMenu className="w-100">
                        <DropdownItem
                          key="0"
                          onClick={() => {
                            this.handleOrderSourceChange(
                              0,
                              "All Order Sources"
                            );
                          }}
                        >
                          All
                        </DropdownItem>
                        {!_.isEmpty(this.props.OrderSources) &&
                          !_.isEmpty(this.props.OrderSources.data) &&
                          this.props.OrderSources.data.map((row, index) => {
                            return (
                              <DropdownItem
                                key={index}
                                onClick={() => {
                                  this.handleOrderSourceChange(
                                    row.order_source_id,
                                    row.order_source_name
                                  );
                                }}
                              >
                                {row.order_source_name}
                              </DropdownItem>
                            );
                          })}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      id="trendsAnalyticsByValue"
                      style={{ width: "100%" }}
                      ref={(el) => (this.chartElement = el)}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div
                      id="trendsAnalyticsByOrders"
                      style={{ width: "100%" }}
                      ref={(el) => (this.chartElement = el)}
                    />
                  </Col>
                </Row>
                <div className="alert alert-primary new-alert mt-3">
                  <strong className="mr-1">Note: </strong>{" "}
                  <span> Only Completed orders are visible here</span>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    getAnalyticsTrends: (params) => dispatch(getAnalyticsTrends(params)),
    getOrderSources: (params) =>
      dispatch(getOrderSourceForAnalyticsTrends(params)),
  };
}

function mapStateToProps(state) {
  return {
    OrderSources: state.orders.OrderSources,
    AnalyticsTrends: state.orders.AnalyticsTrends,
    loggedInUser: state.commonData.loggedInUser,
  };
}

export default connect(mapStateToProps, bindActions)(TrendsAnalytics);
