import config from "../config";
import { getToken, setToken, clearToken } from "../utils";
import { setMessage, setMessageType, setAuthToken } from "./commonData";
import axios from "axios";
import { setSpinner } from "./commonData";
import { getCustomer } from "./customers";
import {toast} from "react-toastify";

export const SET_BILLING_CUSTOMER = "SET_BILLING_CUSTOMER";
export const SET_MENU = "SET_MENU";
export const SET_ORDER_META = "SET_ORDER_META";
export const SET_POS_META = "SET_POS_META";
export const SET_BILLING_CUSTOMER_ADDRESS = "SET_BILLING_CUSTOMER_ADDRESS";
export const SET_ORDER_SUCCESS = "SET_ORDER_SUCCESS";

var axiosInstance = axios.create({
  baseURL: config.baseUrl,
  timeout: 150000,
  headers: {
    "Content-Type": "application/json",
    version: "2.0",
    Authorization: `Token=${getToken()}`
  }
});
const options = {
    autoClose: 2000,
    // closeButton:,
    type: toast.TYPE.INFO,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_LEFT,
    pauseOnHover: true
    // progress: 0.2
};
function setBillingCustomer(payload) {
  return { type: SET_BILLING_CUSTOMER, payload: payload };
}

function setBillingCustomerAddress(payload) {
  return { type: SET_BILLING_CUSTOMER_ADDRESS, payload: payload };
}

function setOrderMeta(payload) {
  return { type: SET_ORDER_META, payload: payload };
}

function setPosMeta(payload) {
  return { type: SET_POS_META, payload: payload };
}

function setOrderSucess(payload) {
  return { type: SET_ORDER_SUCCESS, payload: payload };
}

function setMenu(payload) {
  return { type: SET_MENU, payload: payload };
}

export function getBillingCustomerByPhone(phone) {
  return function(dispatch) {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (!phone.match(phoneno)) {
      dispatch(setMessage("Enter a correct number"));
      dispatch(setMessageType("danger"));
      return;
    }
    dispatch(setSpinner(true));
    axiosInstance
      .get(`customers/findCustomerForCall?phone=${phone}`)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setBillingCustomer(response.data.data));
          dispatch(getCustomer(response.data.data.customer_id));
        } else {
            toast.error("oops, Something went wrong", options)
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
          toast.error("oops, Something went wrong", options)
      });
  };
}

export function getBillingCustomerAddress(customer_id, branch_id) {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(`customers/listCustomerAddress/${customer_id}/${branch_id}`)
      .then(response => {
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setBillingCustomerAddress(response.data.data));
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
            toast.error("oops, Something went wrong", options)
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
          toast.error("oops, Something went wrong", options)
      });
  };
}

export function updateCustomerName(customer_id, name, phone) {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .post(`customers/updateCustomerName/${customer_id}`, { name })
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(getBillingCustomerByPhone(phone));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
          toast.error("oops, Something went wrong", options)
      });
  };
}

export function getPosMeta() {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(`pos/preload`)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setPosMeta(response.data.data));
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
            toast.error("oops, Something went wrong", options)
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
          toast.error("oops, Something went wrong", options)
      });
  };
}

export function addAddress(params) {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .post(`customers/addAddress/`, params)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(getBillingCustomerAddress(params.customer_id, params.branch_id));
          dispatch(setMessage("Address successfully added"));
          dispatch(setMessageType("success"));
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
            toast.error("oops, Something went wrong", options)
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
          toast.error("oops, Something went wrong", options)
      });
  };
}

export function validatePricing(params) {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .post(`dashboard/posCheckout/`, params)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setOrderMeta(response.data.data));
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
            toast.error("oops, Something went wrong", options)
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
          toast.error("oops, Something went wrong", options)
      });
  };
}

export function placeOrder(params) {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .post(`pos/newBill/`, params)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setOrderSucess(response.data.data.bill_id));
          dispatch(setMessage("Order successfully placed"));
          dispatch(setMessageType("success"));
          // dispatch(setOrderMeta(response.data.data));
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
            toast.error("oops, Something went wrong", options)
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
          toast.error("oops, Something went wrong", options)
      });
  };
}

export function loadMenu(branch_id) {
  return function(dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(`products/loadPosMenu/${branch_id}`)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setMenu(response.data.data));
        } else {
          // dispatch(setMessage(response.errors));
          // dispatch(setMessageType("danger"));
            toast.error("oops, Something went wrong", options)
        }
      })
      .catch(err => {
        dispatch(setSpinner(false));
        // dispatch(setMessage("Oops!! Something went wrong"));
        // dispatch(setMessageType("danger"));
          toast.error("oops, Something went wrong", options)
      });
  };
}
