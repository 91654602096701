import config from "../config";
import { getToken, setToken, clearToken } from "../utils";
import { setMessage, setMessageType, setAuthToken } from "./commonData";
import { isEmpty } from "lodash";
import axios from "axios";
import { func } from "prop-types";
import moment from "moment";
import { setSpinner } from "./commonData";

export const SET_STAFF_LIST = "SET_STAFF_LIST";
export const SET_STAFF_META = "SET_STAFF_META";

var axiosInstance = axios.create({
  baseURL: config.baseUrl,
  timeout: 150000,
  headers: {
    "Content-Type": "application/json",
    version: "2.0",
    Authorization: `Token=${getToken()}`
  }
});


function setStaffList(payload) {
  return { type: SET_STAFF_LIST, payload: payload };
}
function setStaffMeta(payload) {
  return { type: SET_STAFF_META, payload: payload };
}

export function getStaffList(params) {
  let default_params = {
    branch_id: 0,
    userType: 0,
    searchText: "",
    offset: 0,
    perPage: 10,
    sort: "ASC"
  };
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .get(
        `users/getStaffList/${params.branch_id}/${params.userType}/?sort=${params.sort}&length=${
        params.perPage
        }&search=${params.searchText}&start=${params.offset}&city_id=${params.city_id}`)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setStaffList(response.data.data));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      }).catch(err => {
        dispatch(setSpinner(false));
        // console.log(err);
        dispatch(setMessage("Oops!! Something went wrong"));
        dispatch(setMessageType("danger"));
      });
  };
}


export function getStaffMeta() {

  return function (dispatch) {
    // dispatch(setSpinner(true));
    axiosInstance
      .get(`users/getUserMeta/`)
      .then(response => {
        // console.log("responseeeeee", response)
        // dispatch(setSpinner(false));
        if (response.data) {
          dispatch(setStaffMeta(response.data.data));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      }).catch(err => {
        // dispatch(setSpinner(false));
        // console.log(err);
        dispatch(setMessage("Oops!! Something went wrong"));
        dispatch(setMessageType("danger"));
      });
  };
}

export function createStaff(data, listParams) {
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .post(`admin/create_user`, data)
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          // console.log(listParams);
          dispatch(getStaffList(listParams));
          dispatch(setMessage("Staff created successfully."));
          dispatch(setMessageType("success"));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      }).catch(err => {
        dispatch(setSpinner(false));
        // console.log(err);
        dispatch(setMessage("Oops!! Something went wrong"));
        dispatch(setMessageType("danger"));
      });
  };
}

export function updateStatus(user_id, status, listParams) {
  return function (dispatch) {
    dispatch(setSpinner(true));
    axiosInstance
      .put(`users/updateUserStatus/`, {user_id: parseInt(user_id), user_status : status})
      .then(response => {
        // console.log("responseeeeee", response)
        dispatch(setSpinner(false));
        if (response.data) {
          // console.log(listParams);
          dispatch(getStaffList(listParams));
          dispatch(setMessage("Staff deactivated successfully."));
          dispatch(setMessageType("success"));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }
      }).catch(err => {
        dispatch(setSpinner(false));
        // console.log(err);
        dispatch(setMessage("Oops!! Something went wrong"));
        dispatch(setMessageType("danger"));
      });
  };
}
