import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import { SingleDatePicker } from "react-dates";
import moment from "moment";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { createStaff } from "../../actions/staff";

class StaffModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffModal: false,
      userType: 0, // default none
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      dateOfBirth: moment(),
      gender: 0,
      branch_id: 0
    };

    this.toggleStaffModal = this.toggleStaffModal.bind(this);
    this.submitStaff = this.submitStaff.bind(this);
  }

  toggleStaffModal() {
    this.setState(prevState => ({
      staffModal: !prevState.staffModal
    }));
  }
  submitStaff() {
    let data = {};
    const {
      userType,
      firstName,
      lastName,
      phone,
      email,
      dateOfBirth,
      gender,
      branch_id
    } = this.state;
    if (firstName === "") {
      return;
    }
    data = {
      fname: firstName,
      lname: lastName,
      phone: phone,
      email: email,
      dob: moment(dateOfBirth).format("YYYY-MM-DD"),
      gender: gender,
      branch_id: branch_id,
      user_type: userType
    };
    this.props.createStaff(data, this.props.listParams);
    this.setState({
      staffModal: false,
      userType: 0, // default none
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      dateOfBirth: "",
      gender: 0,
      branch_id: 0
    });
  }
  render() {
    const {
      userType,
      firstName,
      lastName,
      phone,
      email,
      dateOfBirth,
      gender,
      branch_id
    } = this.state;
    let branch_list = this.props.branch_list;
    let userTypeList = this.props.userTypeList;
    return (
      <div className={`animated fadeIn ${this.props.className}`}>
        <Button
          color="primary"
          className="create-staff w-100"
          onClick={this.toggleStaffModal}
        >
          Create Staff
        </Button>
        <Modal
          isOpen={this.state.staffModal}
          fade={false}
          toggle={this.toggleStaffModal}
          className={`${this.props.className} staff-modal`}
        >
          <ModalHeader toggle={this.toggleStaffModal}>Create Staff</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Input
                type="select"
                className="mb-3"
                name="userType"
                id="userType"
                onChange={e => {
                  this.setState({ userType: e.target.value });
                }}
              >
                <option value="">Choose a Role</option>
                {userTypeList &&
                  userTypeList.length > 0 &&
                  userTypeList.map((element, index) => {
                    return (
                      <option value={element.type_id}>
                        {element.type_name}
                      </option>
                    );
                  })}
              </Input>
              <Input
                type="text"
                className="mb-3"
                placeholder="First Name"
                autoComplete="firstName"
                onChange={e => {
                  this.setState({ firstName: e.target.value });
                }}
                value={firstName}
              />

              <Input
                type="text"
                className="mb-3"
                placeholder="Last Name"
                autoComplete="lastName"
                onChange={e => {
                  this.setState({ lastName: e.target.value });
                }}
                value={lastName}
              />

              <Input
                type="text"
                className="mb-3"
                placeholder="Phone"
                autoComplete="phone"
                onChange={e => {
                  this.setState({ phone: e.target.value });
                }}
                value={phone}
              />

              <Input
                type="text"
                className="mb-3"
                placeholder="Email"
                autoComplete="email"
                onChange={e => {
                  this.setState({ email: e.target.value });
                }}
                value={email}
              />

              <SingleDatePicker
                date={dateOfBirth} // momentPropTypes.momentObj or null
                onDateChange={date => this.setState({ dateOfBirth: date })} // PropTypes.func.isRequired
                focused={this.state.focused} // PropTypes.bool
                onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                id="dateOfBirth" // PropTypes.string.isRequired,
                className="mb-3"
              />
              <Input
                type="select"
                className="mb-3"
                name="gender"
                id="gender"
                onChange={e => {
                  this.setState({ gender: e.target.value });
                }}
              >
                <option value="">Choose Gender</option>
                <option value="m">Male</option>
                <option value="f">Female</option>
              </Input>

              <Input
                type="select"
                className="mb-3"
                name="branch_id"
                id="branch_id"
                onChange={e => {
                  this.setState({ branch_id: e.target.value });
                }}
              >
                <option value="">Choose Branch</option>
                {branch_list.length > 0 &&
                  branch_list.map((row, index) => {
                    return (
                      <option value={row.branch_id}>{row.branch_name}</option>
                    );
                  })}
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" outline onClick={this.toggleStaffModal}>
              Cancel
            </Button>{" "}
            <Button
              color="primary"
              onClick={() => {
                this.submitStaff();
              }}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    createStaff: (data, listParams) => dispatch(createStaff(data, listParams))
  };
}
function mapStateToProps(state) {
  return {
    // loggedInUser: state.commonData.loggedInUser
  };
}

export default connect(
  mapStateToProps,
  bindActions
)(StaffModal);
