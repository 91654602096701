import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

import {
    Col,
    Row,
    Container,
    Media,
    CardHeader,
    Badge,
    Card,
    Button,
    CardTitle,
    CardText,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    FormGroup,
    Input
} from "reactstrap";

import moment from "moment";

import {cancelOrder, getLiveOrderMeta, updateOrder} from "../../actions/orders";

class OrderDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignModal: false,
            rejectModal: false,
            orderStatus: "",
            rejectReason: 0,
            rejectError: false
        };

        this.toggleRejectModal = this.toggleRejectModal.bind(this);
        this.setRejectReason = this.setRejectReason.bind(this);
        this.props.getLiveOrderMeta();
    }

    toggleRejectModal() {
        this.setState(prevState => ({
            rejectModal: !prevState.rejectModal
        }));
    }

    setRejectReason() {
        this.props.cancelOrder(this.props.order.bill_id, this.state.rejectReason);
        this.setState({rejectModal: false});
    }

    setRejectReason() {
        if (!this.state.rejectReason) {
            this.setState({rejectError: true});
            return;
        }
        this.props.updateOrder(
            9,
            this.props.order.bill_id,
            this.state.rejectReason,
            "",
            this.props.filterParams
        );
        this.setState({rejectError: false, rejectModal: false});
    }

    render() {
        const orderData = this.props.order;
        let mapUrl = "";
        if (
            orderData &&
            orderData.customer_address &&
            orderData.customer_address.latlng
        ) {
            mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${
                orderData.customer_address.latlng
            }&zoom=13&size=600x300&maptype=roadmap&markers=color:red%7Clabel:S%7C${
                orderData.customer_address.latlng
            }&key=AIzaSyCDGzqs5nUncCrBHcmxUXN1OuQpWzIOpW4`;
        }
        let mapLink = "";
        if (
            orderData &&
            orderData.customer_address &&
            orderData.customer_address.latlng
        ) {
            mapLink = `https://www.google.com/maps/?api=1&map_action=map&center=${
                orderData.customer_address.latlng
            }&query=47.5951518,-122.3316393${orderData.customer_address.latlng}&markers=color:red%7Clabel:S%7C`;
        }
        return (

            <Card className="bg-white order_detail">
                <CardHeader className="p-3 bg-white">
                    {/*<Media*/}
                    {/*object*/}
                    {/*src={`http://admin.potful.in/frontend/images/order_source/${*/}
                    {/*orderData.order_source*/}
                    {/*}.jpg`}*/}
                    {/*alt={orderData.order_source_name}*/}
                    {/*className="source_img  w-100"*/}
                    {/*/>*/}
                    <div className="float-right mt-2">
                        <Link to={`/print-order/${orderData.bill_id}`} target="_blank"
                              className="btn btn-secondary ml-3 action">
                            <i className="fa fa-print"/>
                        </Link>
                    </div>
                    <div className="ml-2 d-inline-block">
                        <span className="font-weight-bold">#{orderData.bill_id} | {orderData.order_source}</span>
                        <br/>
                        <span className="text-muted">{orderData.date_time}</span>
                    </div>
                </CardHeader>
                <div class="px-4 pt-4">
                    <a href={`${mapLink}`} target="_blank">
                        <div className="row">
                            <div className="col-md-3">

                                {mapUrl && (
                                    <Media
                                        object
                                        src={mapUrl}
                                        alt={orderData.order_source_name}
                                        className="maps_img maps_img_new mb-4"
                                    />

                                )}

                            </div>
                            <div className="col-md-9">
                                {orderData.customer_address && orderData.customer_address.address_text && (
                                    <CardText className="text-left pt-2">
                                        <h4>Delivery Address</h4>
                                        <p>
                                            {orderData.customer_address.address_text},
                                            {orderData.customer_address.google_address}
                                        </p>
                                    </CardText>
                                )}
                            </div>
                        </div>
                    </a>
                </div>
                <CardText className="p-3 customer_detail">
                    <Row className="p-3">
                        <Col sm="8">
                            <b>{orderData.customer_first_name} {orderData.customer_second_name}</b>
                            <br/>
                            {orderData.customer_phone}
                        </Col>
                        <Col sm="4" className="text-right">
                            <Button>
                                <Link to={`/customer/${orderData.customer_id}`}> Profile </Link>
                            </Button>
                        </Col>
                    </Row>
                </CardText>

                <div className="p-2 price_table">
                    <table className="w-100">
                        <thead>
                        <tr>
                            <th>Item</th>
                            <th className="text-right">Qty</th>
                            <th className="text-right">Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        {orderData.bill_products &&
                        orderData.bill_products.length > 0 &&
                        orderData.bill_products.map((element, index) => {
                            return (
                                <tr key={index}>
                                    <td className={index === 0 ? "pt-3" : ""}>
                                        {element.product_name} {element.serving_size_name}
                                    </td>
                                    <td className={index === 0 ? "pt-3 text-right" : "text-right"}>{element.qty}</td>
                                    <td className={index === 0 ? "pt-3 text-right" : "text-right"}>{element.product_price}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td>Subtotal</td>
                            <td/>
                            <td className="text-right">{orderData.bill_subtotal}</td>
                        </tr>
                        {orderData.delivery_charge_value && (
                            <tr>
                                <td>Delivery Charge</td>
                                <td/>
                                <td className="text-right">{orderData.delivery_charge_value}</td>
                            </tr>

                        )}
                        {orderData.packing_charge_value && (
                            <tr>
                                <td>Packaging Charge</td>
                                <td/>
                                <td className="text-right">{orderData.packing_charge_value}</td>
                            </tr>

                        )}

                        {orderData.bill_taxes &&
                        orderData.bill_taxes.length > 0 &&
                        orderData.bill_taxes.map((element, index) => {
                            return (
                                <tr key={index}>
                                    <td className={index === 0 ? "pt-3" : ""}>
                                        {element.tax_type_name}
                                    </td>
                                    <td></td>
                                    <td className={index === 0 ? "pt-3 text-right" : "text-right"}>{element.amount}</td>
                                </tr>
                            );
                        })}

                        {orderData.credits_used && (
                            <tr>
                                <td> Potful Credits</td>
                                <td/>
                                <td className="text-right">-{orderData.credits_used}</td>
                            </tr>

                        )}
                        <tr>
                            <td>Total <Badge className="ml-2">
                                {orderData.payment_mode_value}
                            </Badge></td>
                            <td/>
                            <td className="text-right">{orderData.bill_total}</td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
                {orderData.current_status > 7 && (
                    <Button color="danger" className="border-radius-0" disabled>
                        Cancel Order
                    </Button>
                )}

                {orderData.current_status <= 7 && (
                    <Button color="danger" className="border-radius-0" onClick={this.toggleRejectModal}>
                        Cancel Order
                    </Button>
                )}
                <Modal
                    isOpen={this.state.rejectModal}
                    fade={false}
                    toggle={this.toggleRejectModal}
                    className={this.props.className}
                >
                    <ModalHeader toggle={this.toggleRejectModal}>
                        Cancel Order (Order ID: {orderData.bill_id})
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Input
                                type="select"
                                name="select"
                                id="exampleSelect"
                                onChange={e => {
                                    this.setState({rejectReason: e.target.value});
                                }}
                            >
                                <option value="">Choose a reason</option>
                                {this.props.liveOrderMeta &&
                                this.props.liveOrderMeta.reject_reason &&
                                this.props.liveOrderMeta.reject_reason.length > 0 &&
                                this.props.liveOrderMeta.reject_reason.map(
                                    (element, index) => {
                                        // console.log(element);
                                        return (
                                            <option value={element.id}>{element.reason}</option>
                                        );
                                    }
                                )}
                            </Input>
                        </FormGroup>
                        {this.state.rejectError && (
                            <div className="alert alert-danger w-100">
                                Chooose cancelation reason
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" outline onClick={this.toggleRejectModal}>
                            Cancel
                        </Button>{" "}
                        <Button
                            color="danger"
                            onClick={() => {
                                this.setRejectReason();
                            }}
                        >
                            Reject Order
                        </Button>
                    </ModalFooter>
                </Modal>
            </Card>
        );
    }
}

function bindActions(dispatch) {
    return {
        updateOrder: (status, order_id, reason, assigned_to, params) =>
            dispatch(updateOrder(status, order_id, reason, assigned_to, params)),
        getLiveOrderMeta: () => dispatch(getLiveOrderMeta()),
        cancelOrder: (order_id, reason) => dispatch(cancelOrder(order_id, reason))
    };
}

function mapStateToProps(state) {
    return {
        liveOrderMeta: state.orders.liveOrderMeta
    };
}

export default connect(
    mapStateToProps,
    bindActions
)(OrderDetail);
