import React, { Component } from "react";
import { connect } from "react-redux";
import { getLoggedInUser, clearMessage } from "../../actions/commonData";
import MDSpinner from "react-md-spinner";
import * as scroll from "react-scroll";
import config from "../../config";
import {
  Badge,
  Col,
  Row,
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button
} from "reactstrap";

import { getProductList, getCategoryList } from "../../actions/products";
import {
  validatePricing,
  placeOrder,
  getPosMeta,
  loadMenu
} from "../../actions/billing";

import Product from "./Product";
import Cart from "./Cart";
import Customer from "./Customer";
import Cookies from "js-cookie";
import {Link} from "react-router-dom";

class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branch_name: "All branch",
      branch_id: "",
      serve_type: "",
      coupon_text: "",
      orderSource: "",
      paymentType: "",
      reference_number: "",
      instruction: "",
      customer_id: "",
      address_id: "",
      discount_amount: "",
      delivery_charge: "",
      packaging_charge: "",
      product_list: {},
      productListMeta: {},
      cartList: {},
      update: false,
      key: 0,
      errorModal: false,
      errorMsg: "",
      delivery_by: 0
    };
    this.props.getLoggedInUser();
    this.props.getPosMeta();

    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.branchtoggle = this.branchtoggle.bind(this);
    this.handleBranchChange = this.handleBranchChange.bind(this);
    this.handleCustomCharges = this.handleCustomCharges.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.addtoCart = this.addtoCart.bind(this);
    this.validatePricing = this.validatePricing.bind(this);
    this.validateCoupon = this.validateCoupon.bind(this);
    this.placeOrder = this.placeOrder.bind(this);
    this.toggleErrorModal = this.toggleErrorModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    setTimeout(
      function() {
        this.props.clearMessage();
      }.bind(this),
      15000
    );
    if (
      this.props.orderSuccess &&
      this.props.orderSuccess != prevProps.orderSuccess
    ) {
      // console.log("order success");
      // window.open(`${config.baseUrl}pos/printBill/${this.props.orderSuccess}`, "_blank"); <Link to={`/print-order/${order.bill_id}`} target="_blank"
      // window.open(`${config.baseUrl}pos/printBill/${this.props.orderSuccess}?access_token=${`${Cookies.get('token_dashboard')}`}`, "_blank");
      window.open(`/#/print-order/${this.props.orderSuccess}`, "_blank");
      // window.open(`/#/print-order/${this.props.orderSuccess}`, "_blank");
      window.location.reload();
        // /print-order/${order.bill_id}
    }
    const { posMeta, orderMeta } = this.props;
    const { serve_type, paymentType, orderSource } = this.state;
    if (prevProps.posMeta != posMeta) {
      if (
        !serve_type &&
        posMeta &&
        posMeta.service_types &&
        posMeta.service_types.length > 0
      ) {
        this.setState({ serve_type: posMeta.service_types[0].serve_type_id });
      }
      if (
        !paymentType &&
        posMeta &&
        posMeta.payment_modes &&
        posMeta.payment_modes.length > 0
      ) {
        this.setState({
          paymentType: posMeta.payment_modes[0].payment_mode_id
        });
      }
      if (
        !orderSource &&
        posMeta &&
        posMeta.order_sources &&
        posMeta.order_sources.length > 0
      ) {
        this.setState({
          orderSource: posMeta.order_sources[0].order_source_id
        });
      }
    }
  }

  toggleErrorModal() {
    this.setState(prevState => ({
      errorModal: !prevState.errorModal
    }));
  }

  onDismiss() {
    this.props.clearMessage();
  }

  branchtoggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  handleBranchChange(branch_id, branch_name) {
    if (branch_id) {
      this.props.loadMenu(branch_id);
      this.props.getCategoryList();
    }

    this.setState({ branch_id: branch_id, branch_name: branch_name });
    console.log(this.state);
  }

  handleCustomerChange(customer_id) {
    console.log(customer_id);
    this.setState({ customer_id });
  }

  handleAddressChange(address_id) {
    this.setState({ address_id });
  }

  handleCustomCharges(discount_amount, delivery_charge, packaging_charge) {
    const { coupon_text } = this.state;
    this.setState(
      { discount_amount, delivery_charge, packaging_charge },
      () => {
        this.validatePricing();
      }
    );
  }

  addtoCart(productMeta, type) {
    // push to meta list
    this.setState({ update: false });
    let { productListMeta, cartList } = this.state;

    let tempProductListMeta = productListMeta;
    let tempCartList = cartList;

    console.log(productMeta);
    let price_id = productMeta.product_price_id.toString();
    console.log(price_id);
    tempProductListMeta[price_id] = productMeta;
    // add pricing id and qty to cart list
    if (tempCartList[price_id] >= 0 && type === "add") {
      console.log("in add");
      tempCartList[price_id] = tempCartList[price_id] + 1;
    } else if (tempCartList[price_id] && type === "sub") {
      console.log("in sub");
      tempCartList[price_id] = tempCartList[price_id] - 1;
    } else if (!tempCartList[price_id] && type === "add") {
      tempCartList[price_id] = 1;
    }

    if (tempCartList[price_id] == 0) {
      delete tempCartList[price_id];
    }
    this.setState({
      productListMeta: tempProductListMeta,
      cartList: tempCartList
    });
    console.log(cartList);
    this.setState({ key: Math.random() });
    this.validatePricing();
  }

  validatePricing() {
    const { cartList, branch_id, serve_type } = this.state;
    // if (Object.keys(cartList).length === 0) {
    //   return;
    // }
    let params = {};
    params.items = cartList;
    params.branch = branch_id;
    params.service_type = serve_type;
    params.coupon_text = this.state.coupon_text;

    //Custom charges
    params.discount_amount = this.state.discount_amount;
    params.packaging_charge = this.state.packaging_charge;
    params.delivery_charge = this.state.delivery_charge;

    if (this.state.discount_amount) {
      this.setState({ coupon_text: "" });
    }

    this.props.validatePricing(params);
    this.setState({ key: Math.random() });
  }

  validateCoupon(coupon_text) {
    // debugger;
    this.setState({ coupon_text: coupon_text }, () => {
      this.validatePricing();
    });
  }

  placeOrder(defParams) {
    // defParams = {serviceType,
    //   orderSource,
    //   paymentType}

    const {
      cartList,
      customer_id,
      address_id,
      serve_type,
      orderSource,
      paymentType,
      delivery_by
    } = this.state;
    if (!customer_id || !address_id) {
      console.log("missing params");
      this.toggleErrorModal();
      if (!address_id) {
        this.setState({ errorMsg: "Please choose customer address" });
        return;
      }
      if (!customer_id) {
        this.setState({ errorMsg: "Please choose a customer" });
        return;
      }
    }
    if (Object.keys(cartList).length === 0 || cartList.length == 0) {
      this.toggleErrorModal();
      this.setState({ errorMsg: "Please add items to cart" });
      return;
    }
    const { orderMeta } = this.props;
    if (!orderMeta) {
      console.log("missing order validation");
      return;
    }

    let params = {
      serve_type: parseInt(defParams.serviceType || serve_type),
      order_source: parseInt(defParams.orderSource || orderSource),
      reference_number: defParams.reference_number || "", //@TODO
      instruction: defParams.instruction || "", //@TODO
      payment_mode: parseInt(defParams.paymentType || paymentType),
      customer_id: parseInt(customer_id),
      address_id: parseInt(address_id),
      product_list: cartList,
      // override @TODO
      discount_amount: defParams.discount_amount,
      packaging_charge: defParams.packaging_charge,
      delivery_charge: defParams.delivery_charge,
      delivery_by: parseInt(defParams.delivery_by)
    };
    params.coupon_id = "";
    if (orderMeta.coupon && orderMeta.coupon.coupon_id) {
      params.coupon_id = parseInt(orderMeta.coupon.coupon_id);
    }
    this.props.placeOrder(params);
  }

  render() {
    const {
      spinnerActive,
      message,
      alertClass,
      posMeta,
      orderMeta
    } = this.props;
    const {
      branch_id,
      delivery_charge,
      discount_amount,
      packaging_charge
    } = this.state;
    let products = this.props.menu;
    let categories = this.props.categoryList;
    let branch_list = [];
    if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
      branch_list = this.props.loggedInUser.branch_list;
    }
    return (
      <div className="animated fadeIn billing">
        {spinnerActive && (
          <div className="h-100 row align-items-center spinner-overlay">
            <MDSpinner
              singleColor="#ee5a36"
              size="75"
              borderSize={10}
              style={{ margin: "0 auto" }}
              className=""
            />
          </div>
        )}
        {message && (
          <Alert
            className="m-5 fixed-top"
            color={alertClass}
            isOpen={message !== "" ? true : false}
            toggle={this.onDismiss}
          >
            {message}
          </Alert>
        )}

        <Row className="p-0">
          <Col sm="2" className="billing-side bg-white pr-0">
            <Customer
              history={this.props.history}
              handleAddressChange={this.handleAddressChange}
              handleCustomerChange={this.handleCustomerChange}
              handleBranchChange={this.handleBranchChange}
              branch_id={branch_id}
              posMeta={posMeta}
            />
          </Col>
          <Col sm="7" className="p-4 billing-main">
            <Row>
              <Col sm="12">
                {categories &&
                  categories.length > 0 &&
                  categories.map((row, index) => {
                    // return <ListGroupItem className="pl-1 "  onClick={this.handleShow(row.category_id)}>{row.category_name}</ListGroupItem>;
                    return (
                      <scroll.Link
                        activeClass="active"
                        to={`category-section-${row.category_id}`}
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                      >
                        <Badge
                          className="p-2 mr-2 mb-2 categories"
                          key={`category-badge-${row.category_id}`}
                        >
                          {" "}
                          {row.category_name}
                        </Badge>
                      </scroll.Link>
                    );
                  })}
              </Col>
              {/* <Col sm="12" className="mt-4 live-orders">
                View Details
              </Col> */}
              <Col sm="12" className="prodCat mt-4">
                {products.length === 0 && (
                  <div className="no-product text-center">
                    <img
                      src="/assets/img/startbilling-01.svg"
                      width="300"
                      alt=""
                    />
                    <p>Please enter Customer Phone number to continue</p>
                  </div>
                )}
                {products &&
                  products.length > 0 &&
                  products.map((row, index) => {
                    return (
                      <section
                        id={`category-section-${row.category_id}`}
                        key={`category-section-${row.category_id}`}
                      >
                        <Row className="p-2">
                          <Col
                            sm="12"
                            className="product-category text-primary"
                          >
                            {row.category_name}
                          </Col>

                          {row.products &&
                            row.products.length > 0 &&
                            row.products.map((eachProduct, index) => {
                              return (
                                <Product
                                  key={`product-${eachProduct.product_id}`}
                                  productData={eachProduct}
                                  branch_id={branch_id}
                                  addtoCart={this.addtoCart}
                                  cartList={this.state.cartList}
                                  tempKey={this.state.key}
                                />
                              );
                            })}
                        </Row>
                      </section>
                    );
                  })}
              </Col>
            </Row>
          </Col>
          <Col sm="3" className="billing-cart bg-white pl-0">
            <Cart
              cartList={this.state.cartList}
              productListMeta={this.state.productListMeta}
              orderMeta={orderMeta}
              key={this.state.key}
              addtoCart={this.addtoCart}
              placeOrder={this.placeOrder}
              branch_id={branch_id}
              posMeta={posMeta}
              validateCoupon={this.validateCoupon}
              handleCustomCharges={this.handleCustomCharges}
              discount_amount={discount_amount}
              packaging_charge={packaging_charge}
              delivery_charge={delivery_charge}
            />
          </Col>
        </Row>
        <Modal
          isOpen={this.state.errorModal}
          fade={false}
          toggle={this.toggleErrorModal}
        >
          <ModalBody className="text-center">
            <div className="alert alert-danger w-100">
              {this.state.errorMsg}
            </div>
            <Button
              color="primary"
              onClick={() => {
                this.toggleErrorModal();
              }}
            >
              Okay
            </Button>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function bindActions(dispatch) {
  return {
    getLoggedInUser: () => dispatch(getLoggedInUser()),
    clearMessage: () => dispatch(clearMessage()),
    getProductList: () => dispatch(getProductList()),
    getCategoryList: () => dispatch(getCategoryList()),
    validatePricing: params => dispatch(validatePricing(params)),
    placeOrder: params => dispatch(placeOrder(params)),
    getPosMeta: () => dispatch(getPosMeta()),
    loadMenu: branch_id => dispatch(loadMenu(branch_id))
  };
}

function mapStateToProps(state) {
  return {
    orderSuccess: state.billing.orderSuccess,
    orderMeta: state.billing.orderMeta,
    menu: state.billing.menu,
    posMeta: state.billing.posMeta,
    loggedInUser: state.commonData.loggedInUser,
    productsList: state.products.productsList,
    categoryList: state.products.categoryList,
    tokenStatus: state.commonData.tokenStatus,
    spinnerActive: state.commonData.spinnerActive,
    message: state.commonData.message,
    alertClass: state.commonData.messageType
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(Billing);
