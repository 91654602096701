import config from "../config";
import { getToken, clearToken } from "../utils";
import { isEmpty } from "lodash";
import axios from "axios";

var axiosInstance = axios.create({
  baseURL: config.baseUrl,
  timeout: 150000,
  headers: {
    "Content-Type": "application/json",
    "version": "2.0",
    "Authorization": `Token=${getToken()}`,
  }
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  } else {
    // Something happened in setting up the request that triggered an Error
  }
  return Promise.reject(error);
});

export const SET_SPINNER = "SET_SPINNER";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_MESSAGE_TYPE = "SET_MESSAGE_TYPE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const AUTH_TOKEN = "AUTH_TOKEN"; // false is expired

export const SET_LOGGED_IN_USER = "SET_LOGGED_IN_USER";
export const SET_MENU_LIST = "SET_MENU_LIST";

export const setAuthToken = payload => {
  return {
    type: AUTH_TOKEN,
    payload
  };
};


export const startLoading = payload => {
  return {
    type: START_LOADING,
    payload
  };
};

export const stopLoading = () => {
  return {
    type: START_LOADING
  };
};



export const setMessage = payload => {
  return {
    type: SET_MESSAGE,
    payload
  };
};

export const setMessageType = payload => {
  return {
    type: SET_MESSAGE_TYPE,
    payload
  };
};

export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE
  };
};

export const setSpinner = payload => {
  return {
    type: SET_SPINNER,
    payload
  };
};


function setLoggedInUser(payload) {
  return { type: SET_LOGGED_IN_USER, payload: payload };
}

export function getLoggedInUser() {
  return function (dispatch) {
    axiosInstance.get(`authentication/getUserProfile`)
      .then(response => {
        if (response.data) {
          dispatch(setLoggedInUser(response.data.data));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }

        // dispatch(setSettingsList(allConfigurations));
      }).catch(err => {
        dispatch(setSpinner(false));
        if (err.response && err.response.status === 401) {
          clearToken();
        }
        if(err.response && err.response.data) {
          dispatch(setMessage(err.response.data.errors));
        } else {
          dispatch(setMessage("Oops!! Something went wrong"));
        }

        dispatch(setMessageType("danger"));
      });;
  };
}

function setMenuList(payload) {
  return { type: SET_MENU_LIST, payload: payload };
}

export function getMenuList() {
  return function (dispatch) {
    axiosInstance.get(`dashboard/getMenuList`)
      .then(response => {
        if (response.data) {
          dispatch(setMenuList(response.data.data));
        } else {
          dispatch(setMessage(response.errors));
          dispatch(setMessageType("danger"));
        }

        // dispatch(setSettingsList(allConfigurations));
      });
  };
}