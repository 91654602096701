import React, { Component, lazy, Suspense } from "react";
import {
  Badge,
  Button,
  ButtonDropdown,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  Row,
  Table
} from "reactstrap";


class Cash extends Component {


  render() {
    return (
      <div className="animated fadeIn dashboard text-center">

          <img className="img-fluid" src="/images/branch_settings.jpg" alt=""/>
          <h1 className="heading_text">Branch Settings</h1>
        <p>Manage Serving area, tax, charges here</p>
      </div>
    );
  }
}

export default Cash;
