import config from "../config";
import {getToken} from "../utils";
import {setMessage, setMessageType, setSpinner} from "./commonData";
import axios from "axios";
import moment from "moment";

export const SET_PRODUCTS_LIST = "SET_PRODUCTS_LIST";
export const SET_CURRENT_PRODUCT = "SET_CURRENT_PRODUCT";
export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST";
export const SET_PRODUCT_SUGGESTION_LIST = "SET_PRODUCT_SUGGESTION_LIST";
export const SET_PRODUCT_SERVING_LIST = "SET_PRODUCT_SERVING_LIST";
export const SET_ANALYTICS_CATEGORY_LIST = "SET_ANALYTICS_CATEGORY_LIST";

var axiosInstance = axios.create({
    baseURL: config.baseUrl,
    timeout: 150000,
    headers: {
        "Content-Type": "application/json",
        version: "2.0",
        Authorization: `Token=${getToken()}`
    }
});

var axiosInstanceImageUpload = axios.create({
    baseURL: config.adminUrl,
    timeout: 150000,
    headers: {
        "Content-Type": "multipart/form-data",
        version: "2.0",
        Authorization: `Token=${getToken()}`,
    },
});

axiosInstance.interceptors.response.use(
    function (response) {
        // console.log("interceptors.response");
        return response;
    },
    function (error) {
        if (error.response) {

        } else if (error.request) {

        } else {

        }
        return Promise.reject(error);
    }
);

function setProduct(payload) {
    return {type: SET_CURRENT_PRODUCT, payload: payload};
}

function setProductList(payload) {
    return {type: SET_PRODUCTS_LIST, payload: payload};
}

function setCategoryList(payload) {
    return {type: SET_CATEGORY_LIST, payload: payload};
}

function setProductSuggestions(payload) {
    return {type: SET_PRODUCT_SUGGESTION_LIST, payload: payload};
}

function setAnalyticsCategoryList(payload) {
    return {type: SET_ANALYTICS_CATEGORY_LIST, payload: payload};
}

function setProductServingList(payload) {
    return {type: SET_PRODUCT_SERVING_LIST, payload: payload};
}

export function getProduct(productId, branchId) {
    if (!branchId) {
        branchId = "";
    }
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .get(`products/getProductDetails/${productId}/${branchId}`)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(setProduct(response.data.data));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                dispatch(setMessage("Oops!! Something went wrong"));
                dispatch(setMessageType("danger"));
            });
    };
}

export function getProductServingList(productId, branchId) {
    if (!branchId) {
        branchId = 1;
    }
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .get(`products/getProductDetails/${productId}/${branchId}`)
            .then(response => {
                // console.log("responseeeeee", response)
                dispatch(setSpinner(false));
                if (response.data && response.data.data) {
                    dispatch(setProductServingList(response.data.data.serving_size));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                dispatch(setMessage("Oops!! Something went wrong"));
                dispatch(setMessageType("danger"));
            });
    };
}

export function getProductList() {
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .get(`products/loadProductList`)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(setProductList(response.data.data));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                dispatch(setMessage("Oops!! Something went wrong"));
                dispatch(setMessageType("danger"));
            });
    };
}

export function getCategoryList() {
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .get(`products/listCategories`)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(setCategoryList(response.data.data));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                dispatch(setMessage("Oops!! Something went wrong"));
                dispatch(setMessageType("danger"));
            });
    };
}

export function getAnalyticsCategoryList(params) {
    console.log(params, "params");
    let default_params = {
        branch_id: this.params.branch_id,
        status: 0,
        from_date: moment()
            .subtract(7, "d")
            .format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        searchText: "",
        offset: 0,
        perPage: 10,
        sort: "ASC"
    };
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .get(`analytics/getProductSalesReport?from_date=${params.from_date}&to_date=${params.to_date}&branch_id=${params.branch_id}`)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(setAnalyticsCategoryList(response.data.data));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                dispatch(setMessage("Oops!! Something went wrong"));
                dispatch(setMessageType("danger"));
            });
    };
}

export function createProduct(data) {
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .post(`products/createProduct`, data)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(getProductList());
                    dispatch(setMessage("Product created successfully."));
                    dispatch(setMessageType("success"));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                if (err.response && err.response.data) {
                    dispatch(setMessage(err.response.data.errors));
                } else {
                    dispatch(setMessage("Oops!! Something went wrong"));
                }

                dispatch(setMessageType("danger"));
            });
    };
}

export function createCategory(data) {
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .post(`products/createCategory`, data)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(getCategoryList());
                    dispatch(setMessage("Category created successfully."));
                    dispatch(setMessageType("success"));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                if (err.response && err.response.data) {
                    dispatch(setMessage(err.response.data.errors));
                } else {
                    dispatch(setMessage("Oops!! Something went wrong"));
                }

                dispatch(setMessageType("danger"));
            });
    };
}

export function listProductSuggestions(product_id, branch_id) {
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .get(`products/listProductSuggestions/${product_id}/${branch_id}`)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(setProductSuggestions(response.data));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                if (err.response && err.response.data) {
                    dispatch(setMessage(err.response.data.errors));
                } else {
                    dispatch(setMessage("Oops!! Something went wrong"));
                }

                dispatch(setMessageType("danger"));
            });
    };
}

export function addPrice(data) {
    let product_id = data.product_id;
    let branch_id = data.branch_id;
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .post(`manage_branch/addBranchProductPrice/`, data)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(getProduct(product_id, branch_id));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                if (err.response && err.response.data) {
                    dispatch(setMessage(err.response.data.errors));
                } else {
                    dispatch(setMessage("Oops!! Something went wrong"));
                }

                dispatch(setMessageType("danger"));
            });
    };
}

export function addSuggestion(data) {
    let product_id = data.product_id;
    let branch_id = data.branch_id;
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .post(`products/addProductSuggestion/`, data)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(getProduct(product_id, branch_id));
                    dispatch(listProductSuggestions(product_id, branch_id));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                if (err.response && err.response.data) {
                    dispatch(setMessage(err.response.data.errors));
                } else {
                    dispatch(setMessage("Oops!! Something went wrong"));
                }

                dispatch(setMessageType("danger"));
            });
    };
}

export function sortCategories(sortOrder) {
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .patch(`products/sortCategories`, sortOrder)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(getCategoryList());
                    dispatch(getProductList());
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                if (err.response && err.response.data) {
                    dispatch(setMessage(err.response.data.errors));
                } else {
                    dispatch(setMessage("Oops!! Something went wrong"));
                }

                dispatch(setMessageType("danger"));
            });
    };
}

export function sortProducts(sortOrder) {
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .patch(`products/sortProducts`, sortOrder)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(getProductList());
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                if (err.response && err.response.data) {
                    dispatch(setMessage(err.response.data.errors));
                } else {
                    dispatch(setMessage("Oops!! Something went wrong"));
                }

                dispatch(setMessageType("danger"));
            });
    };
}

export function updateProductPriceStatus(product_id, branch_id, params) {
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .patch(`products/updateProductPriceStatus`, params)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(getProduct(product_id, branch_id));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                if (err.response && err.response.data) {
                    dispatch(setMessage(err.response.data.errors));
                } else {
                    dispatch(setMessage("Oops!! Something went wrong"));
                }

                dispatch(setMessageType("danger"));
            });
    };
}

export function updateBranchProductStatus(product_id, branch_id, params) {
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .patch(`manage_branch/updateBranchProductStatus`, params)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(getProduct(product_id, branch_id));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                if (err.response && err.response.data) {
                    dispatch(setMessage(err.response.data.errors));
                } else {
                    dispatch(setMessage("Oops!! Something went wrong"));
                }

                dispatch(setMessageType("danger"));
            });
    };
}

export function updateProductSuggestion(product_id, branch_id, params) {
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .patch(`manage_branch/updateProductSuggestion`, params)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(listProductSuggestions(product_id, branch_id));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                if (err.response && err.response.data) {
                    dispatch(setMessage(err.response.data.errors));
                } else {
                    dispatch(setMessage("Oops!! Something went wrong"));
                }

                dispatch(setMessageType("danger"));
            });
    };
}

export function uploadProductImage(product_id, product) {
    return function (dispatch) {
        const formData = new FormData();
        formData.append("product_image", product);
        dispatch(setSpinner(true));
        axiosInstanceImageUpload
            .post(`products/updateProductImage/${product_id}`, formData)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(setMessage("Product upload complete"));
                    dispatch(getProduct(product_id));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                if (err.response && err.response.data) {
                    dispatch(setMessage(err.response.data.errors));
                } else {
                    dispatch(setMessage("Oops!! Something went wrong"));
                }
                dispatch(setMessageType("danger"));
            });
    };
}

export function uploadCategoryImage(category_id, image) {
    return function (dispatch) {
        const formData = new FormData();
        formData.append("category_image", image);
        dispatch(setSpinner(true));
        axiosInstanceImageUpload
            .post(`products/updateCategoryImage/${category_id}`, formData)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(setMessage("Category upload complete"));
                    dispatch(getProduct(category_id));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                if (err.response && err.response.data) {
                    dispatch(setMessage(err.response.data.errors));
                } else {
                    dispatch(setMessage("Oops!! Something went wrong"));
                }
                dispatch(setMessageType("danger"));
            });
    };
}

export function getProductOptionList() {
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .get(`products/loadProductOptionList`)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(setProductServingList(response.data));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                dispatch(setMessage("Oops!! Something went wrong"));
                dispatch(setMessageType("danger"));
            });
    };
}

export function uploadProductOptionImage(option_id, image) {
    return function (dispatch) {
        const formData = new FormData();
        formData.append("product_option_image", image);
        dispatch(setSpinner(true));
        axiosInstanceImageUpload
            .post(`products/updateProductOptionImage/${option_id}`, formData)
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(setMessage("Options Image upload complete"));
                    dispatch(getProductOptionList());
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                if (err.response && err.response.data) {
                    dispatch(setMessage(err.response.data.errors));
                } else {
                    dispatch(setMessage("Oops!! Something went wrong"));
                }
                dispatch(setMessageType("danger"));
            });
    };
}

export function createProductOption(optionName) {
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance
            .patch(`products/createServingSize`, {option_name:optionName})
            .then(response => {
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(setMessage("Product option successfully created"));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            })
            .catch(err => {
                dispatch(setSpinner(false));
                if (err.response && err.response.data) {
                    dispatch(setMessage(err.response.data.errors));
                } else {
                    dispatch(setMessage("Oops!! Something went wrong"));
                }

                dispatch(setMessageType("danger"));
            });
    };
}


