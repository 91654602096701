import React, { Component } from "react";
import config from "../../config";
import {
  Col,
  Row, Container, Media, CardHeader, Badge,
  Card, Button, CardTitle, CardText
} from "reactstrap";
import moment from "moment";

class OrderTimeline extends Component {
  render() {
    const orderStatus = {
      1: "Temp",
      2: "Payment Pending",
      3: "New Order",
      4: "In Process",
      5: "Ready",
      6: "Assigned",
      7: "Dispatched",
      8: "Delivered",
      9: "Rejected",
      10: "Canceled",
    }
    const orderData = this.props.order;
    console.log(orderData, "the order data")
    return (
      <div className="bg-white p-4 timeline">
        <Badge color="success" className="p-2 mb-2">{orderStatus[orderData.current_status]}</Badge>
        {orderData.bill_status && orderData.bill_status.length > 0 &&
          orderData.bill_status.map((element, index) => {
            console.log(element);
            return (
              <div className="timeline-entry" key={element.id}>
                <div className="timeline-img">
                  <Media
                    object
                    src={`images/restro.png`}
                    alt={orderData.order_source_name}
                    className="user_img"
                  />
                  <Badge className="timestamp">{moment(element.date_time).format("hh:mm a")}</Badge>
                </div>
                <div className="timeline-label">
                  <Button className="text-left pl-0" color="link">{element.user_first_name} {element.user_second_name}</Button><br />
                  <Badge color="success" className="p-2">{element.bill_status_name}</Badge>
                  {element.bill_status == 6 && element.assigned_to &&
                  <span className="text-muted ml-2">Assigned to {element.assigned_to.name}</span>
                  }
                  {element.bill_status == 9 && element.bill_cancelled_reason &&
                  <span className="text-muted ml-2">Reason : {element.bill_cancelled_reason.cancel_reason_text}</span>
                  }
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

export default OrderTimeline;
