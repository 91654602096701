import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { Link } from "react-router-dom";
import React, { Component } from "react";

import {
  Col,
  Row,
  Container,
  Media,
  CardHeader,
  Badge,
  Card,
  Button,
  CardTitle,
  CardText,
  Label,
  Input
} from "reactstrap";
import moment from "moment";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";

const RemotePagination = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  columns
}) => (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page,
          sizePerPage,
          totalSize
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <div className="table-responsive align-m-table new-table-css last-child-right table-margin-minus">
            <BootstrapTable
              remote
              keyField="bill_id"
              data={data}
              columns={columns}
              onTableChange={onTableChange}
              {...paginationTableProps}
            />
            <div className="float-left text-muted pt-2">
              <p>
                Showing:{" "}
                {(paginationProps.page - 1) * paginationProps.sizePerPage + 1} to{" "}
                {paginationProps.page * paginationProps.sizePerPage <
                  paginationProps.totalSize
                  ? paginationProps.page * paginationProps.sizePerPage
                  : paginationProps.totalSize}{" "}
                of {paginationProps.totalSize} entries
            </p>
            </div>
            <div className="float-right">
              <PaginationListStandalone {...paginationProps} />
            </div>
          </div>
        )}
      </PaginationProvider>
    </div>
  );

class OrdersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: this.props.orders,
      sizePerPage: 10
    };
  }

  billIdFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <Link
        to={`/order/${row.bill_id}`} target="_blank"
        className="mb-2 mt-2 d-block text-primary font-weight-bold"
      >
        #{row.bill_id}
      </Link>
    );
  }

  timeFormatter(cell, row, rowIndex, formatExtraData) {
    return moment(cell).format("MMM DD, YYYY hh:mm a");
  }

  actionFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div>
        <Link to={`/order/${row.bill_id}`} target="_blank" className="">
          <Button color="danger" outline>
            Order Details
          </Button>
        </Link>
        <Link to={`/print-order/${row.bill_id}`} target="_blank" className="ml-2">
          <Button color="danger" outline>
            <i className="fa fa-print" />
          </Button>
        </Link>
      </div>
    );
  }

  handleTableChange = (type, { page, sizePerPage }) => {
    this.setState(() => ({
      page
    }));
    this.props.handlePagination((page - 1) * sizePerPage);
  };

  render() {
    const columns = [
      {
        dataField: "bill_id",
        text: "Order ID",
        sort: true,
        formatter: this.billIdFormatter
      },
      {
        dataField: "date_time",
        text: "Order Time",
        sort: true,
        formatter: this.timeFormatter
      },
      {
        dataField: "branch_name",
        text: "Branch",
        sort: true
        // formatter: this.areaFormatter
      },
      {
        dataField: "order_source_name",
        text: "Source",
        sort: true
        // formatter: this.areaFormatter
      },
      {
        dataField: "bill_status_name",
        text: "Status",
        sort: true
      },
      {
        dataField: "bill_id",
        text: "Action",
        sort: true,
        formatter: this.actionFormatter
      }
    ];

    const { sizePerPage, page } = this.state;
    return (
      <div className="w-100 orders-table custom-table-no-border">
        <RemotePagination
          data={this.props.orders}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={this.props.totalData}
          onTableChange={this.handleTableChange}
          columns={columns}
        />
      </div>
    );
  }
}

export default OrdersTable;
