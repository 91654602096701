import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";

import {
  Row,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import SelectBranch from "./SelectBranch";
import moment from "moment";
import { getPackingChargeList, addPackingCharge } from "../../actions/branch"

function amtFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div>
      {Math.round(cell)}
    </div>
  );
}

function statusFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div>
      {cell === 1 ? "Overall" : "Per Product"}
    </div>
  );
}

const columns = [
  {
    dataField: "packing_charge_value",
    text: "Packing Charges",
    sort: true,
    formatter: amtFormatter
  },
  {
    dataField: "packing_charge_id",
    text: "Packing Type",
    sort: true,
    formatter: statusFormatter
  }
];

class PackingCharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formModal: false,
      packingType: "",
      amount: "",
      branch_id: this.props.branch_id
    };

    this.toggleFormModal = this.toggleFormModal.bind(this);
    this.submitPacking = this.submitPacking.bind(this);
    if (this.props.branch_id) {
      this.props.getPackingChargeList(this.state.branch_id);
    }
  }

  componentDidUpdate(previousProps, previousState) {
    const { branch_id } = this.props;
    if (branch_id !== previousProps.branch_id) {
      if (branch_id) {
        this.props.getPackingChargeList(branch_id);
      }
      this.setState({ branch_id: branch_id });
    }
  }

  toggleFormModal() {
    this.setState(prevState => ({
      formModal: !prevState.formModal
    }));
  };

  submitPacking() {
    let data = {};
    const {
      packingType,
      amount,
      branch_id,
    } = this.state;
    data = {
      branch_id: branch_id,
      packingType: packingType,
      amount: amount,
    };
    this.props.addPackingCharge(data);

    this.setState({
      formModal: false,
      packingType: "",
      amount: "",
    });
  }


  render() {
    const {
      formModal,
      packingType,
      amount,
      branch_id
    } = this.state;
    let data = this.props.packingChargeList;
    return (
      <div className="animated fadeIn dashboard w-100">
        <Col sm="12" className="">
          <Row>
            <Col sm="9" className="m-0 mb-2">
              <div className="mb-2">PACKING CHARGES</div>
              <div className="">
                Note! Packing charges of type 'each product' is applicable on products which apply packing charges
            </div>
            </Col>

            {branch_id == 0 ? <SelectBranch /> : " "}
            {branch_id != 0 &&
              <Col sm="3" className="m-0 pl-0">
                <Button
                  color="primary"
                  className="place-order w-100"
                  onClick={this.toggleFormModal}
                >
                  Add Charge
              </Button>
                <Modal
                  isOpen={this.state.formModal}
                  fade={false}
                  toggle={this.toggleFormModal}
                  className={this.props.className}
                >
                  <ModalHeader toggle={this.toggleFormModal}>
                    Add Packing Charge
                </ModalHeader>
                  <ModalBody>
                    <div className="alert alert-danger w-100">
                      Note! Packing charges of type 'each product' is applicable on products which apply packing charges
                  </div>
                    <FormGroup>
                      <Input
                        type="select"
                        className="mb-3"
                        name="productType"
                        id="product_type"
                        onChange={e => {
                          this.setState({ packingType: e.target.value });
                        }}
                      >
                        <option value="0">Per Product</option>
                        <option value="1">Overall</option>
                      </Input>
                      <Input
                        type="text"
                        className="mb-3"
                        placeholder="Value"
                        autoComplete="value"
                        onChange={e => {
                          this.setState({ amount: e.target.value });
                        }}
                        value={amount}
                      />

                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      outline
                      onClick={this.toggleFormModal}
                    >
                      Cancel
                  </Button>{" "}
                    <Button
                      color="primary"
                      onClick={() => {
                        this.submitPacking();
                      }}
                    >
                      Confirm and Add
                  </Button>
                  </ModalFooter>
                </Modal>
              </Col>

            }
          </Row>
        </Col>
        <Col sm="12" className="">
          {branch_id != 0 &&
            <div className="custom-table-no-border">
              <div className="table-responsive new-table-css last-child-right table-margin-minus">
                <BootstrapTable
                  remote
                  keyField="packing_charge_id"
                  data={data}
                  columns={columns}
                />
              </div>
            </div>
          }
        </Col>

      </div>
    );
  }
}



function bindActions(dispatch) {
  return {
    getPackingChargeList: branch_id => dispatch(getPackingChargeList(branch_id)),
    addPackingCharge: params => dispatch(addPackingCharge(params)),
  };
}

function mapStateToProps(state) {
  return {
    packingChargeList: state.branch.packingChargeList,
  };
}
export default connect(
  mapStateToProps,
  bindActions
)(PackingCharge);