import config from "../config";
import {getToken, setFCMToken} from "../utils";
import {setMessage, setMessageType, setAuthToken} from "./commonData";
import {isEmpty} from "lodash";
import axios from "axios";
import {setSpinner} from "./commonData";

export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";

var axiosInstance = axios.create({
    baseURL: config.baseUrl,
    timeout: 150000,
    headers: {
        "Content-Type": "application/json",
        "version": "2.0",
        "Authorization": `Token=${getToken()}`,
    }
});


// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
    } else {
        // Something happened in setting up the request that triggered an Error
    }
    return Promise.reject(error);
});


function setDashboardData(payload) {
    return {type: SET_DASHBOARD_DATA, payload: payload};
}

export function getDashboardData(branch,city_id) {
    if (isEmpty(branch)) {
        branch = 0;
    }
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance.post(`dashboard/dashboardData?branch=${branch}&city_id=${city_id}`)
            .then(response => {
                // console.log("responseeeeee", response)
                dispatch(setSpinner(false));
                if (response.data) {
                    dispatch(setDashboardData(response.data.data));
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            }).catch(err => {
            dispatch(setSpinner(false));
            dispatch(setMessage("Oops!! Something went wrong"));
            dispatch(setMessageType("danger"));
        });
    };
}

export function sendFCMToken(token) {
    if (isEmpty(token)) {
        return;
    }
    return function (dispatch) {
        dispatch(setSpinner(true));
        axiosInstance.post(`authentication/update_fcm/1`, {"fcm_token": token})
            .then(response => {
                // console.log("responseeeeee", response);
                dispatch(setSpinner(false));
                if (response.data) {
                    // dispatch(setDashboardData(response.data.data));
                  setFCMToken(token);
                  // console.log("FCM token updated");
                } else {
                    dispatch(setMessage(response.errors));
                    dispatch(setMessageType("danger"));
                }
            }).catch(err => {
            dispatch(setSpinner(false));
            dispatch(setMessage("Oops!! Something went wrong"));
            dispatch(setMessageType("danger"));
        });
    };
}
