import React, { Component } from "react";
import {
  Col
} from "reactstrap";
import { formatNumbersWithThousandSeparator } from "../../utils";


class MonthView extends Component {

  render() {
    return (
      <div className={this.props.data.className}>
        <h6 className="mb-1">{this.props.data.name}</h6>
        <h4 className="mb-0">{formatNumbersWithThousandSeparator(this.props.data.current)}</h4>
        <div>{formatNumbersWithThousandSeparator(this.props.data.before)}</div>
      </div>
    );
  }
}
export default MonthView;
