import React, {Component} from "react";
import {connect} from "react-redux";
import {debounce} from 'throttle-debounce';
import soundfile from '../../audio/a_old_telephone.mp3'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Button,
    Col,
    Badge,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";
import TodayTable from "./DispatchTodayTable";

import {
    getLiveOrdersList,
    getLiveOrderMeta,
    dispatchOrder
} from "../../actions/orders";
import {messaging} from "../../init-fcm";
import {toast} from "react-toastify";


class Dispatch extends Component {
    constructor(props) {
        super(props);
        this.state = {

            // date default is -7 days
            orderStatus: 0, // default all orders
            perPage: 10,
            offset: 0,
            searchText: "",
            dropdownOpen: false,
            branch_name: "All branch",
            branch_id: 0,
        };
        this.audio = new Audio(soundfile);

        this.filterOrders = this.filterOrders.bind(this);
        this.branchtoggle = this.branchtoggle.bind(this);
        this.handleBranchChange = this.handleBranchChange.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        const {branch_id, orderStatus, perPage, offset, searchText} = this.state;
        // console.log("order status ", this.state.orderStatus);
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC"
        };
        this.props.getLiveOrdersList(params); // this was commeted
        this.props.getLiveOrderMeta();
        this.onOrderReceived = this.onOrderReceived.bind(this);
        this.registerFCMReceiver();


    }


    registerFCMReceiver() {
        navigator.serviceWorker.addEventListener("message", (message) => {
            console.log(message);
            if (message.data['firebase-messaging-msg-data'] && message.data['firebase-messaging-msg-data'].data && message.data['firebase-messaging-msg-data'].data['order_details'] !== undefined) {
                console.log(message.data['firebase-messaging-msg-data'].data['order_details']);
                const newOrderData = JSON.parse(message.data['firebase-messaging-msg-data'].data['order_details']);
                this.onOrderReceived(newOrderData);
            }

            if (message.data['data'] && message.data['data'] && message.data['data']['order_details'] !== undefined) {
                console.log(message.data['data']['order_details']);
                const newOrderData = JSON.parse(message.data['data']['order_details']);
                this.onOrderReceived(newOrderData);
            }
        });
    }

    componentDidMount() {
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let firstBranchName = branch_list[0].branch_name;
                let firstBranchId = branch_list[0].branch_id;
                this.handleBranchChange(firstBranchId, firstBranchName);
            }
        }
    }


    componentWillReceiveProps(nextProps) {
        if (this.props.loggedInUser && (this.props.loggedInUser.branch_list != nextProps.loggedInUser.branch_list)) {
            let branch_list = [];
            branch_list = this.props.loggedInUser.branch_list;

            if (branch_list.length > 0) {
                let firstBranchName = branch_list[0].branch_name;
                let firstBranchId = branch_list[0].branch_id;
                this.handleBranchChange(firstBranchId, firstBranchName);
            }
        }
    }

    branchtoggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    filterOrders(bill_status) {
        const {branch_id, perPage, searchText} = this.state;
        this.setState({orderStatus: bill_status});
        // console.log("order status ", this.state.orderStatus);
        let params = {
            branch_id: branch_id,
            status: bill_status,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC"
        };
        this.props.getLiveOrdersList(params);
        // console.log(this.state);
    }

    handleBranchChange(branch_id, branch_name) {
        const {orderStatus, perPage, searchText} = this.state;
        this.setState({branch_id: branch_id, branch_name: branch_name});
        // console.log("branch id ", branch_id);
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC"
        };
        this.props.getLiveOrdersList(params);
        // console.log(this.state);
    }

    handleSearch(searchText) {
        const {branch_id, perPage, bill_status} = this.state;
        this.setState({searchText: searchText});
        // console.log("searchText ", this.state.searchText);
        let params = {
            branch_id: branch_id,
            status: bill_status,
            searchText: searchText,
            offset: 0,
            perPage: perPage,
            sort: "ASC"
        };
        this.props.getLiveOrdersList(params);
        // console.log(this.state);
    }
    handleOrder(order_id) {
        const {branch_id, perPage, bill_status} = this.state;
        this.setState({order_id: order_id});
        console.log("order_id ", order_id);
        let params = {
            order_id: order_id,
        };
        this.props.dispatchOrder(params);
        console.log(this.state, "handle order");
    }

    onOrderReceived(order) {
        var found = false;
        console.log(this.props.liveOrders);
        if (this.props.liveOrders && this.props.liveOrders.aaData && this.props.liveOrders.aaData.length > 0) {
            let arrayList = this.props.liveOrders.aaData.length;
            for (var i = 0; i < arrayList.length; i++) {
                let item = arrayList[i];
                if (item['bill_id'] === order['bill_id']) {
                    found = true;
                    break;
                }
            }
        }
        if (!found) {
            this.props.liveOrders.aaData.unshift(order);
            this.forceUpdate();
        }
    }


    handlePagination(offset) {
        const {orderStatus, perPage, branch_id, searchText} = this.state;
        this.setState({offset: offset});
        // console.log("pagingation offset ", offset);
        let params = {
            branch_id: branch_id,
            status: orderStatus,
            searchText: searchText,
            offset: offset,
            perPage: perPage,
            sort: "ASC"
        };

        this.props.getLiveOrdersList(params);
        console.log(this.state);
    }

    render() {
        const {
            orderStatus,
            perPage,
            branch_id,
            searchText,
            order_id,
            offset,
            branch_name
        } = this.state;
        let products = [];

        if (
            this.props.liveOrders &&
            this.props.liveOrders.aaData &&
            this.props.liveOrders.aaData.length > 0
        ) {
            products = this.props.liveOrders.aaData;
        }

        console.log("orders lissdsdt", products);

        let columns = [
            {
                dataField: "bill_id",
                text: "Product ID",
                formatter: this.orderFormatter
            }
        ];
        let orderStatusList = this.props.liveOrders.order_meta;

        let statusList = {
            ORDER_TEMP: "Temp",
            ORDER_ONLINE_PAYMENT_PROCESSING: "Payment Pending",
            ORDER_PLACED: "New Order",
            ORDER_ACCEPTED: "In Process",
            ORDER_PACKED: "Ready",
            ORDER_ASSIGNED: "Assigned",
            ORDER_DISPATCHED: "Dispatched",
            ORDER_DELIVERED: "Delivered",
            ORDER_REJECTED: "Rejected",
            ORDER_CANCELLED: "Canceled"
        };
        // console.log(this.props.loggedInUser);
        let branch_list = [];
        if (this.props.loggedInUser && this.props.loggedInUser.branch_list) {
            branch_list = this.props.loggedInUser.branch_list;
        }
        let all_branch_outline = true;
        if (orderStatus === 0) {
            all_branch_outline = false;
        }

        let filterParams = {
            orderStatus: orderStatus,
            perPage: perPage,
            branch_id: branch_id,
            searchText: searchText,
            order_id: order_id,
            offset: offset
        };

        return (

            <div className="animated fadeIn">
                <Input
                    type="text"
                    placeholder="Dispatch"
                    autoComplete="order_id"
                    className="w-100"
                    onClick={e => {
                        this.handleOrder(e.target.value);
                    }}
                    // onKeyDown={this._handleKeyDown}
                    value=""
                />
                {/* Notification audio */}

                {/*<audio controls id="play" className="audio-play">*/}
                {/*    <source src={'https://vocaroo.com/media_command.php?media=s1hlpfk0Bs3J&command=download_mp3'}*/}
                {/*            type="audio/mpeg"></source>*/}
                {/*    Your browser does not support the audio element.*/}
                {/*</audio>*/}
                <Row className="ml-2 mr-2 mt-2 today">
                    <Col sm="4" md="3" lg="2" className="p-md-3 p-2 today-side">
                        {/*// vishwa change*/}
                        <Dropdown
                            isOpen={this.state.dropdownOpen}
                            toggle={this.branchtoggle}
                            className="w-100 mb-2"
                        >
                            <DropdownToggle caret color="primary" className="w-100">
                                {branch_name}
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {branch_list &&
                                branch_list.map((row, index) => {
                                    return (
                                        <DropdownItem
                                            onClick={() => {
                                                this.handleBranchChange(
                                                    row.branch_id,
                                                    row.branch_name
                                                );
                                            }}
                                        >
                                            {row.branch_name}
                                        </DropdownItem>
                                    );
                                })}
                            </DropdownMenu>
                        </Dropdown>
                        <hr/>
                        <label className="filter-label">STATUS</label>
                        <Button
                            color="primary"
                            className="w-100 text-left orderStatusBtn"
                            outline={all_branch_outline}
                            onClick={() => {
                                this.filterOrders(0);
                            }}
                        >
                            All Orders
                            <Badge className="float-right ml-1 p-1 orderCount bg-white">
                                {this.props.liveOrders && this.props.liveOrders.iTotalRecords}
                            </Badge>
                        </Button>
                        {orderStatusList &&
                        orderStatusList.length > 0 &&
                        orderStatusList.map((element, index) => {
                            // console.log(element);
                            let outline =
                                element.bill_status !== this.state.orderStatus ? true : false;
                            return (
                                <Button
                                    color="primary"
                                    className="w-100 text-left orderStatusBtn"
                                    outline={outline}
                                    onClick={() => {
                                        // console.log(element.bill_status);
                                        this.filterOrders(element.bill_status);
                                    }}
                                >
                                    {statusList[element.order_status_value]}
                                    <Badge className="float-right ml-1 p-1 orderCount bg-white">
                                        {element.bill_count}
                                    </Badge>
                                </Button>
                            );
                        })}
                    </Col>

                    <Col sm="8" md="9" lg="10" className="today-main p-4">
                        <Row className="custom-table-no-border today-table">
                            <Col sm="12" className="p-0">
                                <Row>
                                    <Col sm="9">
                                        <h4 className="order_count_0">Today's Orders</h4>
                                    </Col>
                                    <Col sm="3">
                                        <InputGroup className="mb-0 w-100">
                                            <Input
                                                type="text"
                                                placeholder="Search"
                                                autoComplete="searchText"
                                                className="w-100"
                                                onChange={e => {
                                                    this.handleSearch(e.target.value);
                                                }}
                                                value={searchText}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm="12" className="p-0">
                                <TodayTable
                                    orders={products}
                                    totalData={
                                        this.props.liveOrders && this.props.liveOrders.iTotalRecords
                                    }
                                    filterParams={filterParams}
                                    handlePagination={this.handlePagination}
                                    // liveOrderMeta={this.props.liveOrderMeta}
                                />
                            </Col>
                        </Row>
                        {/* <Row className="p-2">
              <div className="alert alert-primary w-100">
                <strong>Note:</strong> Only today's orders are visible here
              </div>
            </Row> */}
                    </Col>
                </Row>
            </div>
        );
    }
}

function bindActions(dispatch) {
    return {
        getLiveOrdersList: params => dispatch(getLiveOrdersList(params)),
        getLiveOrderMeta: () => dispatch(getLiveOrderMeta()),
        dispatchOrder: params => dispatch(dispatchOrder(params))
    };
}

function mapStateToProps(state) {
    return {
        liveOrders: state.orders.liveOrdersList,
        liveOrderMeta: state.orders.liveOrderMeta,
        loggedInUser: state.commonData.loggedInUser
    };
}

export default connect(
    mapStateToProps,
    bindActions
)(Dispatch);
